"use strict";

canopy.service("trixStylesParser", ["trixIsCssProperty", "htmlService", function (trixIsCssProperty, htmlService) {
    
    function isValidStyleDefinition(styleDefinition){ 

        // Check missing style definition properties:
        if (!styleDefinition.hasOwnProperty("id") ||
            !styleDefinition.hasOwnProperty("tagName") ||
            !styleDefinition.hasOwnProperty("title") ||
            !styleDefinition.hasOwnProperty("description") ||
            !styleDefinition.hasOwnProperty("icon") ||
            !styleDefinition.hasOwnProperty("properties") ||
            !styleDefinition.hasOwnProperty("hidden")) {
            return false;
        }

        // Is style definition tagName a protected tag name?
        if (htmlService.isProtectedTagName(styleDefinition.tagName)) {
            console.warn("trix-styles-parser.js: isValidStyleDefinition: Ignoring style definition '" + styleDefinition.title + "' because of disallowed tagName encountered (" + styleDefinition.tagName + ")");
            return false;
        }

        return true;
    }

    var getDefinitions = function(jsonString) {
        var parsedJson = JSON.parse(jsonString);
        var trixStyleDefinitions = [];

        if (!parsedJson.hasOwnProperty("definitions")) {
            console.error("trix-styles-parser.js: We got custom styles JSON, but the expected 'definitions' array is missing");
            return 
        }

        var parserFnBody;
        var stylePropertiesCount;
        parsedJson.definitions.forEach(function(definition, index, arr) {

            if (!isValidStyleDefinition(definition)) {
                return;
            }

            // define our base object which is going to be populated
            var oTrixDefinition = {
                id: definition.id,
                description: definition.description,
                title: definition.title,
                icon: definition.icon,
                hidden: definition.hidden,
                style: { 
                    // map properties and their values that defines this style
                }, 
                tagName: definition.tagName,
                parser: function(element) 
                {
                    // match all properties above and their values to find out if the style is applied
                }, 
                inheritable: true 
            }

            // Each style definition has a parser function and this function is put together by hand to match all the properties and their
            // values for each style definition...
            parserFnBody = "return "; // trailing space is important!

            // For each style we need to keep track of the number of properties so we can build a return statement with || or && operators
            stylePropertiesCount = 0;

            // definition.properties is an object with many props (css rules) and we need to iterate over all of them
            for (var propertyName in definition.properties) {
                var propertyVal = definition.properties[propertyName];

                if (!trixIsCssProperty.hasProp(propertyName)) {

                    console.warn("trixStylesParser: property: '" + propertyName + "' doesn't exist, ignoring");

                    // prop doesnt exist
                    continue;
                }

                if (stylePropertiesCount === 0) {
                    parserFnBody += "(element.style['" + propertyName + "'] === '" + propertyVal + "') ";
                } else if (stylePropertiesCount >= 1) {
                    parserFnBody += " && "
                    parserFnBody += "(element.style['" + propertyName + "'] === '" + propertyVal + "')";
                }

                oTrixDefinition.style[propertyName] = propertyVal;

                stylePropertiesCount ++;
            };

            parserFnBody += ";";

            oTrixDefinition.parser = Function("element", parserFnBody);

            trixStyleDefinitions.push(oTrixDefinition);
        });

        return trixStyleDefinitions;
    }
	
	return {
        isValidStyleDefinition: isValidStyleDefinition,
		getDefinitions: getDefinitions
	};
}]);