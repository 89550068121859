"use strict";

canopy.directive("canopyWidgetOverlay", function () {
	return {
		controller: function ($scope, canopySession) {
			var user = canopySession.getUser();
			
			if (user.hasService("application.content_management.edit_pages")) {
				$scope.overlay = {
					isVisible: true
				};
			}
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/canopy-widget-overlay.html"
	};
});