canopy.directive("canopyFileUploader", function () {
	return {
		controller: function ($scope) {
			$scope.openFileUploader = function () {
				angular.element("#file_uploader").trigger("click");
			};
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/file-uploader/file-uploader.html",
		scope: {
			uploader: "="
		},
		replace: true
	};
});