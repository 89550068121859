canopy.factory('adaptationMedia', ['$http','lumaPaths', function($http, lumaPaths ){
	
	var getAdaptationMedia = function(id){	
		// Return the promise from the $http service // return $http({
		return $http({
			url: lumaPaths.entropyServiceURI + '.' + 'GetAdaptationMedia',method: 'POST',
			params: {'adaptationID' : id},
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};


	// Return the service object with methods // events
	return {
		getAdaptationMedia: function(adaptationID) {
			return getAdaptationMedia(adaptationID);
		}	
	};
}]); 