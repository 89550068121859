"use strict";

canopy.directive("canopyBudgetTransactionChart", function () {
	return {
		restrict: "A",
		controller: function ($scope) {
			$scope.quarterTransactions = {};
			
			$scope.focus = {
				group: null
			};
			
			$scope.focusTransactions = function (key) {
				$scope.focus.group = key;
				$scope.$apply();
			};
			
			$scope.onChartClicked = function (key) {
				$scope.focusTransactions(key);
			};
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/charts/budget-transaction-chart.html",
		link: function (scope, element) {
			var canvas  = element.find("#canvas")[0];
			var context = canvas.getContext("2d");
				
			canvas.onclick = function (evt) {
				if (scope.chart == undefined) {
					return;
				}
				
				var activePoints = scope.chart.getElementAtEvent(evt);
				
				if (activePoints[0]) {
					var chartData = activePoints[0]['_chart'].config.data;
					
					var index = activePoints[0]['_index'];
					var label = chartData.labels[index];

					scope.onChartClicked(label);
				}
			};
			
			var chartInfo = {
				type: "bar",
				data: {
					labels: [],
					datasets: [{
						label: "Pending",
						backgroundColor: "#f0AD4E",
						borderColor: "#f0AD4E",
						data: []
					}, {
						label: "Confirmed",
						backgroundColor: "#cccccc",
						borderColor: "#cccccc",
						data: []
					}, {
						label: "Closed",
						backgroundColor: "#aaaaaa",
						borderColor: "#aaaaaa",
						data: []
					}, {
						label: "Cancelled",
						backgroundColor: "#ffffff",
						borderColor: "#cccccc",
						borderWidth: 2,
						data: []
					}]
				},
				options: {					
					responsive: true,
					maintainAspectRatio: false,	
					legend: {
						display: true
					},			
					scales: {
						xAxes: [{
							display: true,
			                stacked: true
						}],
						yAxes: [{
							display: true,
			                stacked: true
						}]
					}
				}
			};
			
			var renderChart = function () {
				if (scope.chart) {
					scope.chart.destroy();
				}
				
				scope.chart = new Chart(context, chartInfo);
			};
			
			var onTransactionsUpdated = function () {
				var transactionFilter = function (state) {
					return function (transaction) {
						return transaction.state == state;
					};
				};
				
				var transactionAccumulator = function (acc, obj) {
					return acc + parseFloat(obj.value * -1);
				};
				
				chartInfo.data.labels = [];
				chartInfo.data.datasets[0].data = [];
				chartInfo.data.datasets[1].data = [];
				chartInfo.data.datasets[2].data = [];
				chartInfo.data.datasets[3].data = [];
				
				var momentToKey = function (date, log) {
					return date.format("MMM") + " " + date.year();
				};
				
				var start = scope.budget.start ? moment(Dates.parse(scope.budget.start)) : moment(Dates.parse(scope.budget.created));
				var end   = scope.budget.end   ? moment(Dates.parse(scope.budget.end))   : moment();
							
				var tick    = start;
				var nextKey = momentToKey(start);
				var lastKey = momentToKey(end);
				
				while (tick.isBefore(end)) {
					scope.quarterTransactions[ momentToKey(tick) ] = [];
					
					tick = tick.add(1, "month");
				}
				
				scope.quarterTransactions[lastKey] = [];
				
				scope.budget.transactions.forEach(function (transaction) {
					transaction.group = momentToKey(moment(Dates.parse(transaction.created)), true);
					
					var transactionKey   = transaction.group;
					var transactionValue = transaction.value * -1;
					
					if (scope.quarterTransactions[transactionKey] instanceof Array) {
						scope.quarterTransactions[transactionKey].push(transaction);
					}
				});
				
				for (var key in scope.quarterTransactions) {
					var transactions = scope.quarterTransactions[key];
					
					var totals = {
						pending: 0,
						confirmed: 0,
						closed: 0,
						cancelled: 0
					};
					
					chartInfo.data.labels.push(key);
					
					var total = 0;

					if (transactions.length) {
						totals.pending   = transactions.filter(transactionFilter("Pending")).reduce(transactionAccumulator, 0);
						totals.closed    = transactions.filter(transactionFilter("Closed")).reduce(transactionAccumulator, 0);
						totals.confirmed = transactions.filter(transactionFilter("Confirmed")).reduce(transactionAccumulator, 0);
						totals.cancelled = transactions.filter(transactionFilter("Cancelled")).reduce(transactionAccumulator, 0);
					}
					
					chartInfo.data.datasets[0].data.push(totals.pending);
					chartInfo.data.datasets[1].data.push(totals.confirmed);
					chartInfo.data.datasets[2].data.push(totals.closed);
					chartInfo.data.datasets[3].data.push(totals.cancelled);
				}
				
				renderChart();
			};
			
			scope.$watch("budget.transactions", function (transactions) {
				if (transactions instanceof Array) {
					onTransactionsUpdated();
				}
			});
		},
		scope: {
			budget: "=",
			actions: "="
		}
	}
});