"use strict";

canopy.service("canopyWidgetDictionary", function () {
	var _widgets = {
	};
	
	_widgets["canopyWidgetCarousel"] = {
		title: "Canopy Carousel",
		directive: "canopy-widget-carousel",
		isMultiFaceted: true,
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/carousel/editor.html",
			facet: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/carousel/facet.html"
		}
	};
	
	_widgets["canopyWidgetCardHorizontal"] = {
		title: "Canopy Card (Horizontal)",
		directive: "canopy-widget-card-horizontal",
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/card-horizontal/editor.html"
		}
	};
	
	_widgets["canopyWidgetCardVertical"] = {
		title: "Canopy Card (Vertical)",
		directive: "canopy-widget-card-vertical",
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/card-vertical/editor.html"
		}
	};
	
	_widgets["canopyWidgetHeading"] = {
		title: "Canopy Heading",
		directive: "canopy-widget-heading",
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/heading/editor.html"
		}
	};
	
	_widgets["canopyWidgetPanel"] = {
		title: "Canopy Panel",
		directive: "canopy-widget-panel",
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/panel/editor.html"
		}
	};
	
	_widgets["canopyWidgetCards"] = {
		title: "Canopy Cards",
		directive: "canopy-widget-cards",
		isMultiFaceted: true,
		maxFacets: 20,
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/cards/editor.html",
			facet: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/cards/facet.html"
		}
	};
	
	_widgets["canopyWidgetButtons"] = {
		title: "Canopy Buttons",
		directive: "canopy-widget-buttons",
		isMultiFaceted: true,
		maxFacets: 4,
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/buttons/editor.html",
			facet: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/buttons/facet.html"
		}
	};
	
	_widgets["canopyWidgetLinks"] = {
		title: "Canopy Links",
		directive: "canopy-widget-links",
		isMultiFaceted: true,
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/links/editor.html",
			facet: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/links/facet.html"
		}
	};
		
	_widgets["canopyWidgetAssetGrid"] = {
		title: "Canopy Asset Grid",
		directive: "canopy-widget-asset-grid",
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/asset-grid/editor.html"
		}
	};
		
	_widgets["canopyWidgetAssetSearch"] = {
		title: "Canopy Asset Search",
		directive: "canopy-widget-asset-search",
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/asset-search/editor.html"
		}
	};
		
	_widgets["canopyWidgetSpacer"] = {
		title: "Canopy Spacer",
		directive: "canopy-widget-spacer",
		editors: {
			widget: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/spacer/editor.html"
		}
	};
	
	return {
		getAvailableWidgets: function () {
			// TODO: Inefficient.
			var available = [];
			
			for (var key in _widgets) {
				var definition = _widgets[key];
				
				definition.name = key;
				
				available.push(definition);
			}
			
			return available;
		},
		getDefinition: function (name) {
			return _widgets[name];
		},
		setDefinition: function (name, definition) {
			_widgets[name] = definition;
		}
	};
});

canopy.directive("canopyWidgetCarousel", function (htmlService) {
	return {
		restrict: "A",
		controller: function ($scope, $sce) {
			$scope.luma = Luma;
			
			if ($scope.data.size == undefined) {
				$scope.data.size = "small";
			}

			$scope.trustAsHtml = function(htmlString) {
				return htmlService.trustAsHtml(htmlString);
			}
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/carousel/widget.html",
		link: function (scope, element, attributes) {
		},
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidgetCardHorizontal", function (htmlService) {
	return {
		restrict: "A",
		controller: function ($scope) {
            $scope.luma = Luma;
            
			$scope.trustAsHtml = function(htmlString) {
				return htmlService.trustAsHtml(htmlString);
			}
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/card-horizontal/widget.html",
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidgetCardVertical", function (htmlService) {
	return {
		restrict: "A",
		controller: function ($scope) {
            $scope.luma = Luma;
            
			$scope.trustAsHtml = function(htmlString) {
				return htmlService.trustAsHtml(htmlString);
			}
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/card-vertical/widget.html",
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidgetHeading", function () {
	return {
		restrict: "A",
		controller: function ($scope) {
			$scope.luma = Luma;
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/heading/widget.html",
		scope: {
			data: "="
		}
	};
});


canopy.directive("canopyWidgetPanel", function (htmlService) {
	return {
		restrict: "A",
		controller: function ($scope) {
			$scope.luma = Luma;
            
			$scope.trustAsHtml = function(htmlString) {
				return htmlService.trustAsHtml(htmlString);
			}

			$scope.$watch("data.layout", function (layout) {
				if (!layout) {
					return;
				}

				if (layout.name === "no-asset") {
					$scope.data.asset       = null;
					$scope.data.hasDownload = false;
				}
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/panel/widget.html",
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidgetCards", function () {
	return {
		restrict: "A",
		controller: function ($scope) {
			$scope.luma = Luma;
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/cards/widget.html",
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidgetButtons", function () {
	return {
		restrict: "A",
		controller: function ($scope) {
			$scope.luma = Luma;
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/buttons/widget.html",
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidgetLinks", function () {
	return {
		restrict: "A",
		controller: function ($scope) {
			$scope.luma = Luma;
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/links/widget.html",
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidgetAssetGrid", function () {
	return {
		restrict: "A",
		controller: function ($scope, canopySession, listService, $state, $location,$window) {
			$scope.luma = Luma;
			$scope.list = null;
			
			var init = function () {
				$scope.isInitalising = true;

				var valid = true;

				var servlet = "ListAssets";
				var lensID  = null;
				var inherit = false;
				var limit   = null;
				var sort    = null;
				var order   = null;

				if ($scope.data) {
					if ($scope.data.servlet) {
						servlet = $scope.data.servlet.name;
					}
					
					if ($scope.data.lensID) {
						lensID = $scope.data.lensID;
					}
					
					if ($scope.data.inherit) {
						inherit = true;
					}
					
					if ($scope.data.limit) {
						limit = $scope.data.limit.value;
					}
					
					if ($scope.data.sort) {
						sort = $scope.data.sort.value;
					}
					
					if ($scope.data.order) {
						order = $scope.data.order.value;
					}
				}
				
				if (valid) {
					$scope.list = listService.instantiate(servlet);
					$scope.list.setParameter("listing-style", "Page");
					$scope.list.setParameter("listing-limit", limit);
					
					if (sort) {
						$scope.list.setParameter("listing-sort", sort);
						$scope.list.setParameter("listing-order", order || "Ascending");
					}
					
					if (servlet === "ListAssets") {
						var domain = canopySession.getActiveDomain();
						
						$scope.list.setParameter("domainID", domain.id);
						$scope.list.setParameter("lensID", lensID);
						$scope.list.setParameter("inherit", inherit);
						$scope.list.setParameter("tree", null);
					}
					
					load();
				}
				else {
					console.log("Error: Bad widget data.");
				}
			};
			
			var load = function () {
				if ($scope.list == undefined) {
					return;
				}
				
				var onSuccess = function (response) {
					$scope.results = response.data;
					
					if ($scope.isInitalising) {
						$scope.isInitalising = false;
					}
				};
				
				$scope.list.load().then(onSuccess);
			};
			
			$scope.onViewList = function () {
				var destination = $scope.data.href;
				
				if ($scope.data.hrefLensID) {
					destination = destination + "/list?lensID=" + $scope.data.hrefLensID;
				}
				
				window.location.href = destination;
			};
			
			$scope.onViewDetails = function (resource,$event) { 
				var destination = $scope.data.href + "/detail/" + resource.type.toLowerCase() + "/" + resource.id;
				
				if($event.metaKey || $event.ctrlKey){											
					$window.open(destination, '_blank');

				}else{
					window.location.href = destination;
				}
				
			};
			
			init();
			
			$scope.$watch("data.servlet", function (servlet) {
				if (servlet == undefined || $scope.isInitalising) {
					return;
				}
				
				$scope.list.setService(servlet.name);
				
				if (servlet.name === "ListAssets") {
					$scope.list.setParameter("domainID", canopySession.getActiveDomain().id);
					$scope.list.setParameter("tree", null);
					
					if ($scope.data.lensID) {
						$scope.list.setParameter("lensID", $scope.data.lensID);
					}
				}
				
				if (servlet.name === "ListUserAdaptations") {
					$scope.list.setParameter("domainID", null);
					$scope.list.setParameter("lensID", null);
				}
				
				load();
			});
			
			$scope.$watch("data.lensID", function (lensID) {
				if (lensID == undefined || $scope.isInitalising) {
					return;
				}
				
				$scope.list.setParameter("lensID", lensID);
				load();
			});
			
			$scope.$watch("data.inherit", function (inherit) {
				if (inherit == undefined || $scope.isInitalising) {
					return;
				}
				
				$scope.list.setParameter("inherit", inherit);
				load();
			});
			
			$scope.$watch("data.sort", function (sort) {
				if (sort == undefined || $scope.isInitalising) {
					return;
				}
				
				$scope.list.setParameter("listing-sort", sort.value);
				load();
			});
			
			$scope.$watch("data.order", function (order) {
				if (order == undefined || $scope.isInitalising) {
					return;
				}
				
				$scope.list.setParameter("listing-order", order.value);
				load();
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/asset-grid/widget.html",
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidgetSpacer", function () {
	return {
		restrict: "A",
		controller: function ($scope) {
			$scope.luma = Luma;
			
			if ($scope.data.height == undefined) {
				$scope.data.height = 0;
			}

			if (typeof $scope.data.height != "number") {
				$scope.data.height = parseInt($scope.data.height);
			}
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/spacer/widget.html",
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidgetAssetSearch", function () {
	return {
		restrict: "A",
		controller: function ($scope, canopySession, listService, $state) {
			var init = function () {
				$scope.isInitalising = true;
			
				$scope.list = listService.instantiate("ListAssets");
				$scope.list.setParameter("domainID", canopySession.getActiveDomain().id);
				$scope.list.setParameter("tree", null);
				$scope.list.setParameter("listing-style", "Page");
				$scope.list.setParameter("listing-limit", 0);
				$scope.list.setParameter("listing-limit", 0);
			
				if ($scope.data) {
					if ($scope.data.lensID) {
						$scope.list.setParameter("lensID", $scope.data.lensID);
					}
				
					if ($scope.data.inherit) {
						$scope.list.setParameter("inherit", true);
					}
				}
				
				$scope.list.registerFilter({
					name: "keywordFilter",
					type: "com.intrepia.luma.KeywordFilter",
					ignoreDomains: true
				});
					
				load();
			};
			
			var load = function () {
				if ($scope.list == undefined) {
					return;
				}
			
				var onSuccess = function (response) {
					if (response.data.filters) {
						$scope.keywords = [];
					
						var keywordFilter = response.data.filters.find(function (element) {
							return element.name == "keywordFilter";
						});
					
						keywordFilter.taxonomy.forEach(function (genus) {
							genus.keywords.forEach(function (keyword) {
								$scope.keywords.push({
									id: keyword.id,
									name: keyword.name,
									genus: {
										id: genus.genusID,
										name: genus.name,
										alias: genus.alias
									}
								});
							})
						});
					}
					
					if ($scope.isInitalising) {
						$scope.isInitalising = false;
					}
				};
				
				$scope.list.load().then(onSuccess);
			};
			
			$scope.onTypeaheadOptionSelect = function (item, model, label) {
				var destination = $scope.data.href;
		
				if (destination) {				
					if (item.id) {		
						var filterState = [{
							name: "keywordFilter",
							applied: [ item.id ],
							keywordIDs: [ item.id ]	
						}];
				
						destination = destination + "?filters=" + btoa(JSON.stringify(filterState));
					}
				
					if (item.text) {
						var searchFilter = {
							name: "searchFilter",
							applied: [],
							clauses: []	
						};
						
						var searchTerms = item.name.split(" ");
			
						searchTerms.forEach(function (element) {
							searchFilter.applied.push({ value: element, flags: ["Name", "Field", "Metadata", "Fuzzy"] });
						});
						
						var filterState = [ searchFilter ];
				
						destination = destination + "?filters=" + btoa(JSON.stringify(filterState));			
					}
					
					window.location.href = destination;
				}
			};
			
			init();
			
			$scope.$watch("data.lensID", function (lensID) {
				if (lensID == undefined || $scope.isInitalising) {
					return;
				}
				
				$scope.list.setParameter("lensID", lensID);
				load();
			});
			
			$scope.$watch("data.inherit", function (inherit) {
				if (inherit == undefined || $scope.isInitalising) {
					return;
				}
				
				$scope.list.setParameter("inherit", inherit);
				load();
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/widgets/asset-search/widget.html",
		scope: {
			data: "="
		}
	};
});

canopy.directive("canopyWidget", function ($compile, canopyWidgetDictionary) {
	return {
		restrict: "A",
		link: function (scope, elem, attrs) {
			var previous = [];
			
			attrs.$observe("canopyWidget", function (widgetName) {
				if (widgetName) {
					var definition = canopyWidgetDictionary.getDefinition(widgetName);
					
					if (definition) {
						if (previous.length) {
							previous.forEach(function (directive) {
								elem.removeAttr(directive);
							});
							
							previous = [];
						}
						
						elem.attr(definition.directive, "");
						elem.removeAttr("canopy-widget");
						elem.addClass("canopy-widget");
						
						previous.push(definition.directive);
						
						$compile(elem)(scope);
					}
				}
	      });
    	}
	};
});