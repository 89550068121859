"use strict";

console.log("marqueeBase/_main.js");

var canopy = angular.module("canopy", ["ui.router", "ui.bootstrap", "infinite-scroll", "ngAnimate", "ngCookies", "ngSanitize", "angularFileUpload", "canopyUtils", "moveable-modal", "angularTrix"]);

var stateDictionary = function () {
	var _definitions = {};
	
	return {
		getDefinition: function (name) {
			return _definitions[name];
		},
		setDefinition: function (name, definition) {
			_definitions[name] = definition;
		}
	};
};

if (!Array.prototype.find) {
	Object.defineProperty(Array.prototype, "find", {
		value: function(predicate) {
			"use strict";
			if (this == null) {
				throw new TypeError("Array.prototype.find called on null or undefined");
			}
			if (typeof predicate !== "function") {
				throw new TypeError("predicate must be a function");
			}
			
			var list = Object(this);
			var length = list.length >>> 0;
			var thisArg = arguments[1];
			var value;

			for (var i = 0; i < length; i++) {
				value = list[i];
				
				if (predicate.call(thisArg, value, i, list)) {
					return value;
				}
			}
			
			return undefined;
		}
	});
}

if (!Array.prototype.findIndex) {
	Object.defineProperty(Array.prototype, 'findIndex', {
		value: function(predicate) {
			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			var o = Object(this);

			var len = o.length >>> 0;

			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function');
			}

			var thisArg = arguments[1];

			var k = 0;

			while (k < len) {
				var kValue = o[k];
				
				if (predicate.call(thisArg, kValue, k, o)) {
					return k;
				}
				k++;
			}

			return -1;
		}
	});
}

function getObjectProperty(obj, properties) {
    var property;
    var properties = properties.split(".");

    while (property = properties.shift()) {        
        if (obj.hasOwnProperty(property) == false) {
        	return undefined;
        }
        
        obj = obj[property];
    } 

    return obj;
}

angular.module("moveable-modal", ["ui.bootstrap.modal"])
	.provider("moveableModal", ["$provide", function ($provide) {
		var moveableModal = {
			options: {
				elSelector: "",
				targetSelector: ""
			},
			$get: function () {
				var startX = 0;
				var startY = 0;
				var x = 0;
				var y = 0;

				if (!moveableModal.options.elSelector &&
					!moveableModal.options.targetSelector) {
					return;
				}

				var $document = angular.element(document);
				var element = angular.element(moveableModal.options.elSelector);
				var target = angular.element(moveableModal.options.targetSelector);

				function mousemove (event) {
					y = event.pageY - startY;
					x = event.pageX - startX;
					target.css({
						top: y + "px",
						left: x + "px"
					});
				}

				function mouseup () {
					$document.unbind("mousemove", mousemove);
					$document.unbind("mouseup", mouseup);
				}

				element.css({
					position: "relative",
					cursor: "move"
				});

				element.on("mousedown", function (event) {
					// Prevent default dragging of selected content
					event.preventDefault();
					startX = event.pageX - x;
					startY = event.pageY - y;
					$document.on("mousemove", mousemove);
					$document.on("mouseup", mouseup);
				});
			}
		};

		$provide.decorator("$uibModal", ["$delegate", function ($delegate) {
			var $uibModal = angular.copy($delegate);

			$delegate.open = function (options) {
				var instance = $uibModal.open(options);
				instance.opened.then(function () {
					setTimeout(function () {
						moveableModal.$get();
					}, 100);
				});
				return instance;
			};

			return $delegate;
		}]);

		return moveableModal;
	}]);

canopy.config(["moveableModalProvider", "$locationProvider", "$stateProvider", "$urlRouterProvider", "$httpProvider", "$qProvider", function (moveableModal, $locationProvider, $stateProvider, $urlRouterProvider, $httpProvider, $qProvider) {
	canopy.stateDictionary = new stateDictionary();

	moveableModal.options = {
		elSelector: ".modal-header",
		targetSelector: ".modal-content"
	};

	$qProvider.errorOnUnhandledRejections(false);

	$httpProvider.interceptors.push("errorInterceptor");

	$locationProvider.html5Mode(false);
	
	$stateProvider.state("application", {
		url: "/",
		controller: "ApplicationController",
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/views/canopy.html"
	});
	
	$stateProvider.state("direct", {
		"abstract": true,
		url: "direct/"
	});
	
	$stateProvider.state("direct.orders", {
		"abstract": true,
		url: "orders/"
	});
	
	canopy.stateProvider     = $stateProvider;
	canopy.urlRouterProvider = $urlRouterProvider;

	// defer intecepting 
	$urlRouterProvider.deferIntercept();

	canopy.stateDictionary.setDefinition("TermsAndConditions", [{
		isDefault: true,
		name: "terms",
		controller: "TermsAndConditionsController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/terms-and-conditions.html"
	}]);
	
	canopy.stateDictionary.setDefinition("UpdateAccount", [{
		isDefault: true,
		name: "UpdateAccount",
		controller: "UpdateAccountController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/update-account.html"
	}]);

	canopy.stateDictionary.setDefinition("AssetList", [{
		isDefault: true,
		name: "list",
		path: "/list?lensID&filters",
		controller: "AssetListTypeaheadController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/asset-list-typeahead.html"
	}, {
		name: "detail",
		path: "/detail/:assetType/:assetID",
		controller: "AssetDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/asset-detail.html"
	}, {
		name: "orderAsset",
		path: "/order/:transformID/:assetID",
		controller: "AssetOrderController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/asset-order.html"
	}, {
		name: "orderAdaptation",
		path: "/order/:transformID/:assetID/:adaptationID?",
		controller: "AssetOrderController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/asset-order.html"
	}]);
	
	canopy.stateDictionary.setDefinition("BasketList", [{
		isDefault: true,
		name: "list",
		controller: "BasketListController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/basket-list.html"
	}, {
		name: "detail",
		path: "/detail/:assetType/:assetID",
		controller: "AssetDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/asset-detail.html"
	}, {
		name: "order",
		path: "/order/:transformID/:assetID",
		controller: "AssetOrderController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/asset-order.html"
	}]);
	
	canopy.stateDictionary.setDefinition("OrderList", [{
		isDefault: true,
		path: "/list",
		name: "list",
		controller: "OrderListController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/order-list.html"
	}, {
		name: "detail",
		path: "/detail/:orderID",
		controller: "OrderDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/order-detail.html"
	}]);
	
	canopy.stateDictionary.setDefinition("RegistrationList", [{
		isDefault: true,
		path: "/list",
		name: "list?filters",
		controller: "RegistrationListController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/registration-list.html"
	}, {
		name: "detail",
		path: "/detail/:registrationID",
		controller: "RegistrationDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/registration-detail.html"
	}]);
	
	canopy.stateDictionary.setDefinition("ProjectList", [{
		isDefault: true,
		path: "/list?filters",
		name: "list",
		controller: "ProjectListController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/project-list.html"
	}, {
		name: "detail",
		path: "/detail/:projectID",
		controller: "ProjectDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/project-detail.html"
	}, {
		name: "request",
		path: "/request/:processID",
		controller: "ProjectRequestController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/project-request.html"
	}]);
	
	canopy.stateDictionary.setDefinition("ProjectTransactionList", [{
		isDefault: true,
		path: "/list?filters",
		name: "list",
		controller: "ProjectListController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/project-list-transactions.html"
	}, {
		name: "detail",
		path: "/detail/:projectID",
		controller: "ProjectDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/project-detail.html"
	}, {
		name: "request",
		path: "/request/:processID",
		controller: "ProjectRequestController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/project-request.html"
	}]);
	
	canopy.stateDictionary.setDefinition("CampaignList", [{
		isDefault: true,
		path: "/list?filters",
		name: "list",
		controller: "CampaignListController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/campaign-list.html"
	}, {
		name: "detail",
		path: "/detail/:campaignID",
		controller: "CampaignDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/campaign-detail.html"
	}, {
		name: "request",
		path: "/request/:processID",
		controller: "CampaignRequestController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/campaign-request.html"
	}]);
	
	canopy.stateDictionary.setDefinition("BudgetList", [{
		isDefault: true,
		path: "/list?filters",
		name: "list",
		controller: "BudgetListController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/budget-list.html"
	}, {
		name: "detail",
		path: "/detail/:budgetID",
		controller: "BudgetDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/budget-detail.html"
	}]);
		
	canopy.stateDictionary.setDefinition("ReimbursementList", [{
		isDefault: true,
		path: "/list?filters",
		name: "list",
		controller: "ReimbursementListController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/reimbursement-list.html"
	}, {
		name: "detail",
		path: "/detail/:reimbursementID",
		controller: "ReimbursementDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/reimbursement-detail.html"
	}]);
	
	canopy.stateDictionary.setDefinition("BatchList", [{
		isDefault: true,
		path: "/list?filters",
		name: "list",
		controller: "BatchListController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/batch-list.html"
	}, {
		name: "detail",
		path: "/detail/:batchID",
		controller: "BatchDetailController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/batch-detail.html"
	}]);
	
	canopy.stateDictionary.setDefinition("ContentManagedPage", {
		isDefault: true,
		name: "page",
		controller: "ContentManagedPageController",
		template: Luma.paths.context + "/system/mantle/marquee/site/views/states/content-managed-page.html"
	});
}]);

canopy.run(["$rootScope", "$state", "canopySession", "$window", "pageVersion", function ($rootScope, $state, canopySession, $window, pageVersion) {
	$rootScope.$on("$stateChangeStart", function (event, toState, toParams, fromState, fromParams, options) {
		if (toState.redirectTo) {
			event.preventDefault();
			$state.go(toState.redirectTo, toParams, {
				location: "replace"
			});
		}
	});
	
	$rootScope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState, fromParams, options) {
		var stateNode  = null;
		
		if (toState.resolve && toState.resolve.menuNode) {
			stateNode = toState.resolve.menuNode();
		}
		
		var activeSite = canopySession.getActiveSite() || {};
		var activeNode = canopySession.getActiveNode() || {};
		
		if (stateNode) {
			pageVersion.setCurrentRevisionToActiveRevision(stateNode.id);

			if (stateNode.siteID != activeSite.id) {
				canopySession.setActiveSite(stateNode.siteID);
			} 
		
			var site = canopySession.getActiveSite();
	
			if (site && site.metadata && site.metadata["terms-and-conditions"]) {
				var propertyKey = "com.intrepia.luma.canopy." + site.name +  ".terms-and-conditions";
				var userVersion = Luma.user.preferences.properties[propertyKey];

				if (userVersion == undefined || userVersion < site.metadata["terms-and-conditions"].version) {
					
					var nodes = canopySession.getFlattenedNodes();
					var node = nodes.find(function (node) {
						return node.name == "terms";
					});
	
					canopySession.setActiveNode(node, {
						stateParams: {
							nextState: toState
						}
					});
	
					return;
				}
			}
		
			if (stateNode.id != activeNode.id) {
				canopySession.setActiveNode(stateNode.id, {
					skipStateChange: true
				});
			}
		}
		
		$window.scrollTo(0, 0);
	});
}]);

canopy.run(["$urlRouter", "$stateParams", "$state", "canopySession", function ($urlRouter, $stateParams, $state, canopySession) {
	var addState = function (name, state) {
		if (!name || !state || name === undefined || state === undefined) {
			return;
		}
		
		if (!$state.get(name) || $state.get(name) === null) {
			state.reloadOnSearch = false;
			
			canopy.stateProvider.state(name, state);
		}
	};
	
	var aliases = {	
	};
	
	var addAlias = function (alias, node) {		
		if (aliases[alias]) {
			aliases[alias].push(node);
		}
		else {
			aliases[alias] = [ node ];
		}
	};

	var createAliasStates = function (aliases) {
		var onNodeSelection;
		var state;

		for (var key in aliases) {			
			var createAliasState = function (url, aliases, onNodeSelection) {
				return {
					url: url,
					templateUrl: Luma.paths.context + "/system/mantle/marquee/site/views/states/disambiguation.html",
					controller: function ($scope, nodes, $state, $stateParams, canopySession) {
						$scope.nodes     = nodes;
						$scope.isVisible = false;
						var activeSite = canopySession.getActiveSite();

						if (onNodeSelection) {
							$scope.onNodeSelection = onNodeSelection;
						}
						else {
							$scope.onNodeSelection = function (node) {
								$state.go("application." + node.id);
							};
						}
												
						if (activeSite) {
							$scope.nodes.find(function (node) {
								if (node.siteID == activeSite.id) {
									return $scope.match === true;
								}
							});
							
							if ($scope.match) {
								$scope.onNodeSelection($scope.match);
								return;
							}
						}
						
						if ($scope.nodes.length == 1) {
							$scope.onNodeSelection($scope.nodes[0]);
						}
						else {
							$scope.isVisible = true;
						}
					},
					resolve: {
						nodes: function () {
							return aliases;
						}
					}
				};
			};
			
			switch (key) {
				case "AssetList": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".list", {
						});
					};
					
					state = createAliasState("/asset/list/", aliases[key], onNodeSelection);
					
					addState("asset_list", state);
					
					break;
				}
				
				case "AssetDetail": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							assetType: "resource",
							assetID: $stateParams.assetID
						});
					};
					
					state = createAliasState("/asset/detail/:assetID", aliases[key], onNodeSelection);
					
					addState("asset_detail", state);
					
					break;
				}
				
				case "UserAdaptations": {
					onNodeSelection = function (node) {
						if ($stateParams.adaptationID) {
							$state.go("application." + node.id + ".detail", {
								assetType: "adaptation",
								assetID: $stateParams.adaptationID
							});
						}
						else {
							$state.go("application." + node.id);
						}
					};
					
					state = createAliasState("/user/adaptation/:adaptationID", aliases[key], onNodeSelection);
					
					addState("user_adaptation", state);
					
					break;
				}

				case "UserOrders": {
					onNodeSelection = function (node) {
						if ($stateParams.orderID) {
							$state.go("application." + node.id + ".detail", {
								orderID: $stateParams.orderID
							});
						}
						else {
							$state.go("application." + node.id + ".list", {});
						}
					};
					
					state = createAliasState("/user/order/:orderID", aliases[key], onNodeSelection);
					
					addState("user_order", state);
			
					break;
				}
				
				case "ApproverOrders": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							orderID: $stateParams.orderID
						});
					};
					
					state = createAliasState("/approver/order/:orderID", aliases[key], onNodeSelection);
					
					addState("approver_order", state);
				
					break;
				}
			
				case "PrinterOrders": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							orderID: $stateParams.orderID
						});
					};
					
					state = createAliasState("/printer/order/:orderID", aliases[key], onNodeSelection);
					
					addState("printer_order", state);
				
					break;
				}
				
				case "UserBudgets": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							budgetID: $stateParams.budgetID
						});
					};
					
					state = createAliasState("/user/budget/:budgetID", aliases[key], onNodeSelection);
					
					addState("user_budget", state);
			
					break;
				}
				
				case "ApproverBudgets": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							budgetID: $stateParams.budgetID
						});
					};
					
					state = createAliasState("/approver/budget/:budgetID", aliases[key], onNodeSelection);
					
					addState("approver_budget", state);
				
					break;
				}
				
				case "UserProjects": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							projectID: $stateParams.projectID
						});
					};
					
					state = createAliasState("/user/project/:projectID", aliases[key], onNodeSelection);
					
					addState("user_project", state);
			
					break;
				}
				
				case "ApproverProjects": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							projectID: $stateParams.projectID
						});
					};
					
					state = createAliasState("/approver/project/:projectID", aliases[key], onNodeSelection);
					
					addState("approver_project", state);
				
					break;
				}
			
				case "PrinterProjects": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							projectID: $stateParams.projectID
						});
					};
					
					state = createAliasState("/printer/project/:projectID", aliases[key], onNodeSelection);
					
					addState("printer_project", state);
				
					break;
				}
				
				case "UserCampaigns": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							campaignID: $stateParams.campaignID
						});
					};
					
					state = createAliasState("/user/campaign/:campaignID", aliases[key], onNodeSelection);
					
					addState("user_campaign", state);
			
					break;
				}
				
				case "ApproverCampaigns": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							campaignID: $stateParams.campaignID
						});
					};
					
					state = createAliasState("/approver/campaign/:campaignID", aliases[key], onNodeSelection);
					
					addState("approver_campaign", state);
				
					break;
				}
				
				case "ApproverReimbursements": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							reimbursementID: $stateParams.reimbursementID
						});
					};
					
					state = createAliasState("/approver/reimbursement/:reimbursementID", aliases[key], onNodeSelection);
					
					addState("approver_reimbursement", state);
				
					break;
				}
				
				case "ApproverRegistrations": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id);
					};
					
					state = createAliasState("/approver/registrations", aliases[key], onNodeSelection);
					
					addState("approver_registrations", state);
				
					break;
				}
				
				case "UserBatches": {
					onNodeSelection = function (node) {
						$state.go("application." + node.id + ".detail", {
							batchID: $stateParams.batchID
						});
					};
					
					state = createAliasState("/user/batch/:batchID", aliases[key], onNodeSelection);
					
					addState("user_batch", state);
			
					break;
				}
				
				case "UserBasket": {
					addState("user_basket", createAliasState("/user/basket", aliases[key]) );
				
					break;
				}
				
				default: {
					break;
				}
			}
			
		}
	};

	var createState = function (node, definition) {
		var state = {
			name: "application." + node.state,
			url: node.path,
			controller:  definition.controller,
			templateUrl: definition.template,
			resolve: {
				menuNode: function () {
					return node;
				}
			}
		};
		
		if (definition.name) {
			if (definition.isDefault) {
				addState(state.name, {
					url        : node.path,
					template   : "<ui-view />",
					redirectTo : state.name.concat(".", definition.name)
				});
			}
				
			state.url  = definition.path;
			state.name = state.name + "." + definition.name;
		}
		
		if (definition.params) {
			state.params = definition.params;
		}
		
		addState(state.name, state);
	};

	var createStatesForNode = function (node) {
		var type = null;
		
		try {
			type = node.metadata.page.type;
		}
		catch (e) {
			type = null;
		}
		
		if (type) {
			var defaultState = null;
			var definition   = canopy.stateDictionary.getDefinition(type);
		
			if (definition) {
				if (definition instanceof Array) {
					definition.forEach(function (element) {
						createState(node, element);
					});
				}
				else {
					createState(node, definition);
				}
			}
		}
		else {
			var name  = "application." + node.state;
			var state = {
				url: node.path,
				templateUrl: Luma.paths.context + "/system/mantle/marquee/site/views/states/undefined.html"
			};
			
			addState(name, state);
		}
		
		if (node.metadata && node.metadata.aliases instanceof Array) {
			node.metadata.aliases.forEach(function (alias) {
				addAlias(alias, node);
			});
		}
	};
	
	var initStates = function () {
		var sites = canopySession.getAvailableSites();
		
		sites.forEach(function (site) {
			var parseNodes = function (nodes) {
				nodes.forEach(function (node) {
					createStatesForNode(node);
					
					if (node.submenus) {
						parseNodes(node.submenus);
					}
				});
			};
			
			if (site.menu) {
				parseNodes(site.menu);
			}
		});
		
		createAliasStates(aliases);
	};
	
	canopySession.promise.then(function (response) {
		initStates();
		
		canopy.urlRouterProvider.otherwise("/");
	
		$urlRouter.sync();
		$urlRouter.listen();
    });
}]);

canopy.run(["$transitions", "canopySession","utilities", "$window", "eventDispatcher", "canopyEventProvider", function ($transitions, canopySession, utilities, $window, eventDispatcher, canopyEventProvider) {
	$transitions.onFinish({}, function(transition) {
		var stateNode;
		var toState = transition.to();
		var activeSite = canopySession.getActiveSite() || {};

		if (toState.resolve && toState.resolve.menuNode) {
			stateNode = toState.resolve.menuNode();
		}

		if (stateNode) {
			if (stateNode.siteID !== activeSite.id) {
				// Site has to be set before $transitions.onSuccess as otherwise the state will be loaded with possibly the incorrect (default) site being active:
				canopySession.setActiveSite(stateNode.siteID);
			} 
		}
	});

	$transitions.onSuccess({}, function (transition) {
		var oldStateNode;
		var stateNode;
		var toState = transition.to();
		var fromState = transition.from();
		var activeSite = canopySession.getActiveSite() || {};
		var activeNode = canopySession.getActiveNode() || {};
		var navigateWithinNode = false;

		if (fromState.resolve && fromState.resolve.menuNode) {
			oldStateNode = fromState.resolve.menuNode();
		}

		if (toState.resolve && toState.resolve.menuNode) {
			stateNode = toState.resolve.menuNode();
		}
		
		if (stateNode) {
			if (stateNode.siteID !== activeSite.id) {
				canopySession.setActiveSite(stateNode.siteID);
			} 
		
			if (stateNode.id !== activeNode.id) {
				canopySession.setActiveNode(stateNode.id, {
					skipStateChange: true
				});
			}
		} 

		if (stateNode && oldStateNode) {
			if (oldStateNode.id === stateNode.id) {
				navigateWithinNode = true;
			}
		}
		
		if (!navigateWithinNode) {
			$window.scrollTo(0, 0);
		}
	});
	
	var onNavigationEnd = function(fromState, toState) {
		if (fromState.resolve && fromState.resolve.menuNode) {
			var fromNode = fromState.resolve.menuNode();
		}
		if (toState.resolve && toState.resolve.menuNode) {
			var toNode = toState.resolve.menuNode();
		}
		var eventData = {
			fromNode: fromNode || {}, 
			toNode: toNode || {}
		};
		eventDispatcher.fireEvent(canopyEventProvider.NAVIGATION_END, eventData);
	};

	$transitions.onSuccess({}, function(transition) {
		onNavigationEnd(transition.from(), transition.to());
	});
}]);

/* ==================== */
/* 	 INTERCEPTORS       */
/* ==================== */

canopy.factory("errorInterceptor", ["$window", "$q", function ($window, $q) {  
    return {
        responseError: function (responseError) {
        	if (responseError.data) {
        		if (responseError.data.fault == "InvalidSession") {
    				$window.location.reload();
        		}
        	}
        	
            return $q.reject(responseError);
        }
    };
}]);

// For all `input` elements...
canopy.directive('input', function() {
	return {
		restrict: 'E',
		require: '?ngModel',
		link: function (scope, elem, attrs, ngModelCtrl) {
		// ...that are of type "number" and have `ngModel`...
			if ((attrs.type === 'number') && ngModelCtrl) {
				// ...remove the `step` validator.
				delete ngModelCtrl.$validators.step;
			}
		}
	};
})

canopy.directive("canopyStylesheet", function (canopySession) {
	return {
		restrict: "A",
		replace: true,
		controller: function ($scope) {
			$scope.session = canopySession;
			
			$scope.$watch("session.getActiveSite()", function (site) {
				if (site) {
					for (var i = 0; i != document.styleSheets.length; ++i) {
						var sheet    = document.styleSheets.item(i);
						var dataSite = sheet.ownerNode.dataset.site;

						if (dataSite) {
							sheet.disabled = dataSite != site.name;	
						}
					}
				}
			});
		},
		template: ""
	};
});