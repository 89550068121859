canopy.factory('order', ['$http', '$location', '$q', 'lumaPaths', function($http, $location, $q, lumaPaths ){
	var getOrder = function(id){
		// Return the promise from the $http service // return $http({
		return $http({
				url: lumaPaths.serviceURI + '.' + 'GetOrder',
				method: 'GET',
				params: {'orderID' : id},
				headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
			})
			.then(function(data, status) {
				return data;
			})
			.catch(function (data, status, headers, config) {
				// errorService.submit(data, status, headers, config);
			});
	};
	
	var getActions = function(id, role){	
		// Return the promise from the $http service // return $http({
		return $http({
			url: lumaPaths.serviceURI + '.' + 'GetOrderActions',method: 'GET',
			params: {'orderID' : id, 'role' : role},
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	
	var getAdaptationMedia = function(id){	
		// Return the promise from the $http service // return $http({
		return $http({
			url: lumaPaths.entropyServiceURI + '.' + 'GetAdaptationMedia',method: 'GET',
			params: {'adaptationID' : id},
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};

    
    var getOrderHistory = function(id){
         return $http({
            url: lumaPaths.serviceURI + '.ListOrderHistory', method: "GET",
            params: {'orderID': id},
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        });
    };
    
    var getOrderList = function(id){
         return $http({
            url: lumaPaths.serviceURI + '.ListOrderItems', method: "GET",
            params: {'orderID': id, 'listing-limit': '50', 'Listing-style': 'page'},
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        });
    };
    
    var updateState = function(id, state, reason){
    	// states are : Approver, Rejected etc..
    	return $http({
            url: lumaPaths.serviceURI  + '.SetOrderState',
            method: "POST",
            data: $.param({orderID: id, state: state, description: reason }),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            })
        	.then(function(data, status) {
				return data;
			})
			.catch(function (data, status, headers, config) {
				// errorService.submit(data, status, headers, config);
			})
    
    };
    
	
	var	getOrderDetails = function(response){
		// makes sure we're not repeating the last load from a simple refresh
		return  $q.all([
				response,
				getActions(response.data.id), //orderID
				getOrderHistory(response.data.id), //OrderID
				getOrderList(response.data.id) //adaptationID
			]).then(function(results) {
				if(results[0]){var order = results[0].data};
				if(results[1]){order.actions = results[1].data};
				if(results[2]){order.history = results[2].data};
				if(results[3]){
					order.items = results[3].data.table;
					if (results[3].data.total == 1){
						order.asset = results[3].data.table[0];
						order.assetID = order.asset.finalAssetID; 
						order.quantity = order.asset.quantity;	
					} else {
						order.lines = results[3].data;
					}
					
				};
				return order;
			});	
	};
	

	// Return the service object with methods // events
	return {
		load: function(orderID) {
			return getOrder(orderID);
		},

		loadOrderDetails: function(orderID){
			return getOrder(orderID)
						.then(getOrderDetails)
		},
		setState: function(orderID, state, reason){
			return updateState(orderID, state, reason);
		}
	
	
	};
}]); 
