"use strict";

canopy.service("trixStylesLoader", ["$http", "canopySession", "trixStylesParser", "$q", function ($http, canopySession, trixStylesParser, $q) {

    var customStylesPath;
    var customStylesDefinitions;
    var site;
    
    canopySession.promise.then(function(response) {
        site = canopySession.getActiveSite();
        loadStyles();
    });

    var loadStyles = function() {

        site = canopySession.getActiveSite();

        // Load Styles according to site data and create a global object that has the style definitions:
        if (site && site.hasOwnProperty("metadata")) {
            if (site.metadata.hasOwnProperty("custom-styles")) {
                customStylesPath = site.metadata["custom-styles"];

                $http({
                    method: "GET",
                    url: Luma.paths.context + customStylesPath,
                    transformResponse: undefined
                }).then(function successCallback(response) {
                    
                    // Parse the response data with the trix styles parser service:
                    customStylesDefinitions = trixStylesParser.getDefinitions(response.data);

                    // Set text attributes styles for Trix, according to style definitions:
                    customStylesDefinitions.forEach(function(styleDefinition) {
                        Trix.config.textAttributes[styleDefinition.id] = styleDefinition;
                    });
                }, function errorCallback(response) {
                    console.warn("trix-styles-loader.js: errorCallback: Something went wrong attempting to load custom styles: response: ", response);
                });
            }
        }
    }

    var getCustomStylesPromise = function() {
        var deferred = $q.defer();
        var response = undefined;

        site = canopySession.getActiveSite();

        // Load Styles according to site data and create a global object that has the style definitions:
        if (site && site.hasOwnProperty("metadata")) {
            if (site.metadata.hasOwnProperty("custom-styles")) {
                customStylesPath = site.metadata["custom-styles"];

                $http({
                    method: "GET",
                    url: Luma.paths.context + customStylesPath,
                    transformResponse: undefined
                }).then(function successCallback(response) {
                    
                    // Parse the response data with the trix styles parser service:
                    customStylesDefinitions = trixStylesParser.getDefinitions(response.data);

                    // Set text attributes styles for Trix, according to style definitions:
                    customStylesDefinitions.forEach(function(styleDefinition) {
                        Trix.config.textAttributes[styleDefinition.id] = styleDefinition;
                    });

                    response = customStylesDefinitions;
                    deferred.resolve(response);
                }, function errorCallback(response) {
                    console.warn("trix-styles-loader.js: errorCallback: Something went wrong attempting to load custom styles: response: ", response);
                    deferred.reject(response);
                });
            }
        } else {
            deferred.reject("Site info not available yet!")
        }

        response = deferred.promise;

        return $q.when(response);
    }

	return {
        getCustomStylesPromise: getCustomStylesPromise,
        load: loadStyles
	};
}]);