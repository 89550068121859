"use strict";

canopy.factory("lumaPaths", ["$location", function ($location) {
	return {
		context:               Luma.paths.context,
		images:                Luma.paths.images,
		absoluteContext:       "UNDEFINED_PATH",
		serviceURI:            Luma.paths.context + "/servlet/interface/com.intrepia.luma",
		downloadURI:           Luma.paths.context + "/servlet/download/com.intrepia.luma",
		entropyServiceURI:     Luma.paths.context + "/servlet/interface/com.intrepia.entropy",
		fusionServiceURI:      Luma.paths.context + "/servlet/interface/com.intrepia.fusion",
		downloadPreview:       Luma.paths.context + "/servlet/interface/com.intrepia.luma.DownloadAssetPreview?",
		downloadAssetPreview:  Luma.paths.context + "/servlet/interface/com.intrepia.luma.DownloadAssetPreview?",
		downloadAtomPreview:   Luma.paths.context + "/servlet/interface/com.intrepia.luma.DownloadAtomPreview?",
		downloadOrderPreview:  Luma.paths.context + "/servlet/interface/com.intrepia.luma.DownloadOrderPreview?",
		downloadTemplateProof: Luma.paths.context + "/servlet/interface/com.intrepia.entropy.DownloadTemplateProof?",
		logout:                Luma.paths.context + "/logout",
		login:                 Luma.paths.context + "/login",
		site:                  Luma.paths.context + "/resolve/site",
		forms:                 Luma.paths.context + "/resolve/site/forms/",
		domained:              Luma.paths.context + "/servlet/domained/",
		mantle:                Luma.paths.context + "/system/mantle/canopy/site/"
	};
}]);
	
canopy.factory("canopySession", function ($q, $state, lumaAPI, $location, $http, $rootScope) {
	console.log("Initialising canopy session...");
	
	var _user = null;
	
	var _domains = {
		active: null,
		available: []
	};
	
	var _sites = {
		active: null,
		available: []
	};
	
	var _nodes = {
		active: null,
		available: []
	};
	
	var _locales = {
		active: null,
		available: []
	};
	
	var getUser = function () {
		return _user;
	};
	
	var getActiveDomain = function () {
		return _domains.active;
	};
	
	var setActiveDomain = function (domain) {
		var id = domain.id || domain;
		
		var found = _domains.available.find(function (element) {
			return element.id == id;
		});
		
		if (found) {
			_domains.active = found;
			
			$state.reload();
		}
	};
	
	var getAvailableDomains = function () {
		return _domains.available;
	};
	
	var getAvailableSites = function () {
		return _sites.available;
	};
	
	var getActiveSite = function () {
		return _sites.active;
	};
	
	var setActiveSite = function (site, options) {
		_sites.available.forEach(function (element) {
			element.isActive = false;
		});
		
		var id = site.id || site;
		
		var found = _sites.available.find(function (element) {
			return element.id == id;
		});
		
		if (found) {
			_sites.active = found;
			
			onActiveSiteChanged();
			
			var cascade = false;
			
			if (options) {
				cascade = options.cascade;
			}
			
			if (cascade && _nodes.available.length) {
				setActiveNode(_nodes.available[0]);
			}
		}
	};
	
	var onActiveSiteChanged = function () {
		var site = _sites.active;
		
		var flattenNodes = function (nodes) {
			nodes.forEach(function (node) {
				_nodes.flattened.push(node);
				
				if (node.submenus) {
					flattenNodes(node.submenus);
				}
			});
		};
			
		_nodes.available = site.menu;
		_nodes.flattened = [];
		
		flattenNodes(site.menu);
	};
	
	var getAvailableNodes = function () {
		return _nodes.available;
	};
	
	var getFlattenedNodes = function () {
		return _nodes.flattened;
	};
	
	var getActiveNode = function () {
		return _nodes.active;
	};
	
	var isInitialLoad = true;
	
	var setActiveNode = function (node, options) {
		var getOrigin = function (root) {
			if (root.parent) {
				return getOrigin(root.parent);
			}
			else {
				return root;
			}
		};
		
		var getTerminus = function (root) {
			if (root.submenus && root.submenus.length) {
				return getTerminus(root.submenus[0]);
			}
			else {
				return root;
			}
		};
		
		var resetSelection = function (root) {
			root.isActive = false;
			
			var children = root.menu || root.submenus;
			
			if (children) {
				children.forEach(function (element) {
					resetSelection(element);
				});
			}
		};
		
		var cascadeSelection = function (terminus) {
			terminus.isActive = true;
			
			if (terminus.parent) {
				cascadeSelection(terminus.parent);
			}
		};
		
		if (_nodes.flattened == undefined) {
			console.log("ERROR: _nodes.flattened is undefined");
			return;
		}
		
		var id = (node.id || node);
		
		var found = _nodes.flattened.find(function (element) {
			return element.id == id;
		});
		
		if (found) {
			var terminus = getTerminus(found);
			
			_nodes.available.forEach(function (element) {
				resetSelection(element);
			});
			
			cascadeSelection(terminus);
			
			_nodes.active = terminus;
			
			var options = options || {};
			
			var stateName       = options.stateName;
			var stateParams     = options.stateParams;  
			var skipStateChange = options.skipStateChange;
						
			if (skipStateChange) {
			}
			else {
				if (_nodes.active) {
					var node = _nodes.active;
					var url  = window.location.href;
		
					history.pushState({
						nodeID: node.id,
						nodeName: node.name
					}, "", url);
				}
				
				if (stateName) {
					$state.go("application." + terminus.state + "." + stateName, stateParams);
				}
				else {
					$state.go("application." + terminus.state);
				}
			}
		}
		else {
			console.log("ERROR: Attempting to access non-existent node (ID: " + node.id + ")");
		}
	};
	
	var initUser = function () {
		_user = Luma.user;
	
		_user.hasModule = function (value) {
			for (var i = 0; i != _user.modules.values.length; ++i) {
				var module = user.modules.values[i];
			
				if (module.value.code == value || module.value.name == value) {
					return true;
				}
			}
		
			return false;
		};
	
		_user.hasService = function (code) {
			return _user.services.map[code] != undefined;
		};
	};
	
	var initDomains = function (domains) {
		_domains.available = [];
		
		var flattenDomains = function (domains) {
			domains.forEach(function (domain) {
				_domains.available.push(domain);
			
				if (domain.subdomains) {
					flattenDomains(domain.subdomains);
				}
			});
		};
		
		flattenDomains(domains);
		
		if (_domains.available.length) {
			_domains.active = _domains.available[0];
		}
	};
	
	var initSites = function (sites) {
		_sites.available = sites;
		
		if (_sites.available.length) {
			_sites.active = _sites.available[0];
		}
	};

	var initLocales = function (locales) {
		_locales.available = locales.filter(function (locale) {
			return locale.tag === "en";
		});
		
		if (_locales.available.length) {
			setActiveLocale( _locales.available[0] );
		}
	};
	
	var init = function () {
		initUser();
		
		var promises = {
			domains: lumaAPI.listUserDomainTree(),
			sites:   lumaAPI.listSitesForDomain(_user.domainID).then(function (response) {
				var menuPromises = [];
				
				response.data.forEach(function (site) {
					var sitePromise = lumaAPI.getSite(site.id).then(function (response) {
						if (response.data && response.data.metadata) {
							site.metadata = JSON.parse(response.data.metadata);
						}
					});
					
					var menuPromise = lumaAPI.getSiteMenuTree(site.id).then(function (response) {
						site.menu = response.data;
						
						var constructPath = function (node) {
							var path    = [];
							var current = node;
			
							while (current) {
								path.push(current.name);
				
								if (current.parent) {
									current = current.parent;
								}
								else {
									current = null;
								}
							}
							
							path.push(node.siteID);
			
							return path.reverse().join("/").toString();
						};
						
						var processNode = function (node) {
							node.state     = node.id;
							node.siteID    = site.id;
							node.siteName  = site.name;
							node.siteTitle = site.title;
							node.path      = constructPath(node);
								
							if (node.metadata) {
								node.metadata = JSON.parse(node.metadata);
							}
							
							if (node.submenus) {
								node.submenus.forEach(function (subnode) {
									subnode.parent = node;
									
									processNode(subnode);
								});
							}
						};
						
						if (site.menu.length) {
							site.menu.forEach(function (node) {
								processNode(node);
							});
						}
					});
					
					menuPromises.push(menuPromise);
				});
				
				return $q.all(menuPromises).then(function () {
					return response;
				});
			}),
			locales: $http({
				url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ListLocales"
			})
		};
		
		_promise = $q.all(promises).then(function (response) {
			initDomains(response.domains.data);
			initSites(response.sites.data);
			initLocales(response.locales.data);
		});
	};
	
	var getAvailableLocales = function () {
		return _locales.available;
	};
	
	var getActiveLocale = function () {
		return _locales.active;
	};
	
	var setActiveLocale = function (locale) {
		_locales.active = locale;
		
		if (locale.tag) {
			var onSuccess = function (response) {
				_locales.active.vocabulary = response.data;
			};
			
			$http({
				url: Luma.paths.context + "/servlet/vocabulary/core/" + locale.tag
			}).then(onSuccess);
		}
	};
	
	var _promise = {};
	
	init();
	
	return {
		promise: _promise,
		getUser: getUser,
		getAvailableDomains: getAvailableDomains,
		getActiveDomain: getActiveDomain,
		setActiveDomain: setActiveDomain,
		getAvailableSites: getAvailableSites,
		getActiveSite: getActiveSite,
		setActiveSite: setActiveSite,
		getAvailableNodes: getAvailableNodes,
		getFlattenedNodes: getFlattenedNodes,
		getActiveNode: getActiveNode,
		setActiveNode: setActiveNode,
		getAvailableLocales: getAvailableLocales,
		getActiveLocale: getActiveLocale,
		setActiveLocale: setActiveLocale,
		subscribe: function () {
			// STUB
		}
	};
});

canopy.factory("lumaAPI", function ($http) {
	var listUserDomainTree = function () {
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ListUserDomainTree",
			method: "POST",
			data: $.param({
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var listUserDomains = function () {
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ListUserDomains",
			method: "POST",
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var listSites = function (domain) {
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ListSites",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				listingSort: 'site-index'
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var listSitesForDomain = function (domain) {
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ListSitesForDomain",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				ignoreDomains: true,
				includeSubdomains: true,
				listingSort: 'site-index',
				enabled: true
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var getSite = function (site) {
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.GetSite",
			method: "POST",
			data: $.param({
				siteID: site.id || site
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var getSiteMenu = function (menu) {
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.GetSiteMenu",
			method: "POST",
			data: $.param({
				menuID: menu.id || menu
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var getSiteMenuTree = function (site) {
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.GetSiteMenuTree",
			method: "POST",
			data: $.param({
				siteID: site.id || site
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var listAssets = function (domain) {
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ListAssets",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				tree: null
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	return {
		listUserDomainTree: function () {
			return listUserDomainTree();
		},
		listUserDomains: function () {
			return listUserDomains();
		},
		listSites: function (domain) {
			return listSites(domain);
		},
		listSitesForDomain: function (domain) {
			return listSitesForDomain(domain);
		},
		getSite: function (site) {
			return getSite(site);
		},
		getSiteMenu: function (menu) {
			return getSiteMenu(menu);
		},
		getSiteMenuTree: function (site) {
			return getSiteMenuTree(site);
		},
		listAssets: function (domain) {
			return listAssets(domain);
		}
	};
});

canopy.factory("canopyBrowser",[function() {
    var ua= navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d\.]+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+(\.\d+)?)/g.exec(ua) || [];
        return "IE "+(tem[1] || "");
    }
    M= M[2]? [M[1], M[2]]:[navigator.appName, navigator.appVersion, "-?"];
    if((tem= ua.match(/version\/([\.\d]+)/i))!= null) M[2]= tem[1];
    return M.join(" ");
}]);