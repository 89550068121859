canopy.factory("orders", ["$http", "lumaPaths", "listService", function ($http, lumaPaths, listService) {
	var getOrder = function (orderID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetOrder",
			method: "POST",
			data: $.param({
				orderID: orderID
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var getOrderKeys = function (order) {
		return $http({
			url: lumaPaths.serviceURI + ".GetOrderKeys",
			method: "POST",
			data: $.param({
				orderID: order.id || order
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var placeOrder = function (order) {
		var data = {
			name: order.name,
			transformID: order.transformID,
			processID: order.processID,
			budgetID: order.budgetID,
			rateID: order.rateID,
			parentID: order.parentID,
			quantity: order.quantity,
			total: order.total,
			person: order.person,
			company: order.company,
			address: order.address,
			area: order.area,
			region: order.region,
			fao: order.fao,
			country: order.country,
			postcode: order.postcode,
			costCentre: order.costCentre,
			email: order.email,
			phone: order.phone,
			instructions: order.instructions,
			groupIDs: order.groupIDs,
			recipients: JSON.stringify(order.recipients),
			metadata: JSON.stringify(order.metadata),
			keys: JSON.stringify(order.keys)
		};
		
		var service = ".PlaceOrder";
		
		if (order.adaptationID) {
			data.adaptationID = order.adaptationID;
			service = ".PlaceOrder$Adaptation";
		}
		
		if (order.assetID) {
			data.assetID = order.assetID;
			service = ".PlaceOrder$Asset";
		}
		
		if (order.state) {
			data.state = order.state;
			
			if (order.state === "Approved") {
				data.autoApproved = true;
			}
		}
		
		if (order.total === undefined) {
			data.total = 0;
		}
		
		if (order.items) {
			data.items = JSON.stringify(order.items);
		}
		
		if (order.itemMetadata) {
			data.itemMetadata = JSON.stringify(order.itemMetadata);
		}
		
		for (var key in data) {
			if (data[key] == undefined) {
				delete data[key];
			}
		}
		
		return $http({
			url: lumaPaths.serviceURI + service,
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var setOrderArchived = function (order, archived) {
		return $http({
			url: lumaPaths.serviceURI + ".SetOrdersArchived",
			method: "POST",
			data: $.param({
				orderIDs: order.id || order,
				archived: archived
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var setOrderState = function (order, state, description, exceptions) {
		var data = {
			orderID: order.id || order,
			state: state
		};
		
		if (description && description.length > 0) {
			data.description = description;
		}
		
		if (exceptions && exceptions instanceof Array && exceptions.length) {
			data.exceptions = JSON.stringify(exceptions);
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".SetOrderState",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	return {
		getOrder: function (orderID) {
			return getOrder(orderID);
		},
		getOrderKeys: function (order) {
			return getOrderKeys(order);
		},
		placeOrder: function (order) {
			return placeOrder(order);
		},
		setOrderArchived: function (order, archived) {
			return setOrderArchived(order, archived);
		},
		setOrderState: function (order, state, description, exceptions) {
			return setOrderState(order, state, description, exceptions);
		},
		list: function () {
			var list = listService.instantiate("ListOrders");
			
			list.prime = function () {
			};
			
			return list;
		}
	};
}]);