canopy.controller("TermsAndConditionsController", function ($scope, $stateParams, canopySession, listService) {
	var site = canopySession.getActiveSite();
	var call = listService.instantiate("SetPreference");
	var key   = "com.intrepia.luma.canopy." + site.name +  ".terms-and-conditions";
	var value;
	var userVersion = Luma.user.preferences.properties[key];

	var init = function () {

		if (site.metadata) {
			$scope.terms = site.metadata["terms-and-conditions"];
			value = $scope.terms.version;
		}

		if (userVersion == undefined || userVersion < site.metadata["terms-and-conditions"].version) {
			$scope.disableSubmit = false;

		} else {
			$scope.disableSubmit = true;
		}
	};
	
	init();
		
	$scope.acceptTerms = function () {
		
		call.setParameter("key", key);
		call.setParameter("value", value);
		
		var onSuccess = function () {
			Luma.user.preferences.properties[key] = value;			
			var nodes = canopySession.getAvailableNodes();

			if (nodes.length) {
				canopySession.setActiveNode(nodes[0]);
			}
		};
		
		var onError = function () {
		};

		call.load().then(onSuccess, onError);
	};
});