canopy.controller("OrderDetailController", function ($scope, $stateParams, $window, orders, order, adaptationMedia, assets, shares, canopyModals, canopySession, lumaPaths, menuNode, $http) {
    $scope.init = function() {
		$scope.page = menuNode.metadata.page;
		$scope.role = null;

		try {
		    $scope.role = menuNode.metadata.page.list.parameters.role;
	    }
	    catch (e) {
	    	$scope.role = null;
	    }
	    
        $scope.showReason = false;
        $scope.showCommentPanel = false;
        $scope.reason = {};
        $scope.reason.message = "";
        $scope.canAmend = false;
        $scope.orderService = order;
        $scope.mediaService = adaptationMedia;
		$scope.actions = [];

        loadData();
    };

    var loadData = function(){
    	var user = canopySession.getUser();
		$scope.isLoading = true;

    	var rates = { };
    	
        $scope.orderService.loadOrderDetails($stateParams.orderID)
            .then(function(order) {
                $scope.order  = order;
                parseActions(order.actions);
                
                $http({                	
					url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ListOrderItems",
					method: "GET",
					params: {
						"orderID": order.id
					},
					headers: {
						"Content-Type": "application/x-www-form-urlencoded"
					}
                }).then(function (response) {
                	order.items = response.data;
                	
					order.items.forEach(function (item) {
						item.actions = [];
						item.fallbackImage = Luma.paths.context + "/system/mantle/marquee/site/static-images/fallback.png";

						var destination = Luma.paths.context + "/servlet/actions/amend-order-item/" + item.id + "?orderID=" + order.id;

						if ($scope.role) {
							destination = destination + "&canopyRole=" + $scope.role;
						}
						
						if (item["asset-type"] === "Adaptation" && (order.state == "New" || order.state == "Unapproved") && item.state != "Pending" && $scope.canAmend && user.hasService("application.orders.amend")) {
							item.actions.push({
								name: "amend",
								label: "Amend",
								href: destination
							});
						}
					});
                });

                $scope.orderHistory = order.history;
                
                initOrderKeys();
            },
            function(response){
                $scope.loadError = response.data.loadError;
            }
			)
			.then(function() {
				initActions();
				$scope.isLoading = false;
			});;
    };

	var getAdaptationMedia = function(id){
        $scope.mediaService.getAdaptationMedia(id)
            .then(function(response) {
                $scope.order.media = response.data;
            },
            function(response){
                $scope.loadError = response.data.loadError;
            }
        );
    };

	$scope.toggleCommentPanel = function (prospectiveState) {
		$scope.prospectiveState = prospectiveState;
		$scope.showCommentPanel = !$scope.showCommentPanel;
		$scope.commentFormInvalid = false;
	};
	
	$scope.updateOrderState = function (state, isCommentRequired) {
		$scope.commentFormInvalid = false;
		
		if (isCommentRequired && $scope.reason.message.length <= 0) {
			$scope.commentFormInvalid = true;
			
			return;
		}
		
		$scope.updateState(state, $scope.reason.message);
		$scope.showCommentPanel = false;
		$scope.commentFormInvalid = false;
	};

    $scope.rejectOrder = function(){
        if($scope.reason.message.length > 0){
            $scope.updateState('Rejected', $scope.reason.message);
            $scope.showReason = false;
        }else{
            $scope.showReason = true;
        }
    };
    
    $scope.cancelRejection = function(){
        $scope.showReason = false;
    };

    $scope.printOrder = function(){
        $window.print();
    };

    $scope.amendOrder = function(){
    	if ($scope.order.items){
	        $window.location.href = $scope.paths.context + '/servlet/actions/amend-order-item/' + $scope.order.items[0].id + '?orderID=' + $scope.order.id;
    	}
    	else {
    		$window.location.href = $scope.paths.context + '/servlet/actions/amend-order-item/' + $scope.order.id;
    	}
    };

    var parseActions = function(data){
        for ( var i = 0; i < data.length; i++){
            if (data[i] == "Download") {
                $scope.canDownload = true;
            }
            if (data[i] == "Transition") {
                $scope.canApprove = true;
            }
            if (data[i] == "Amend"){
                $scope.canAmend = true;
            }
        }
    };

    $scope.updateState = function (state, reason) {
        $scope.orderService.setState($scope.order.id, state, reason)
            .then(function() {
                loadData();
            });
            
    };
    
    $scope.$watch("order.state", function (state) {
		$scope.potentialStates = [];
		
		if ($scope.role == "Approver") {
			if (state == "Unapproved") {
				$scope.potentialStates.push({"value": "Approved", "isDescribable": true});
				$scope.potentialStates.push({"value": "Rejected", "isDescribable": true});
			}
		}
		
		if ($scope.role == "Printer") {
			if (state == "Approved") {
				$scope.potentialStates.push({"value": "Processed", "isDescribable": true});
			}
			
			if (state == "Processed") {
				$scope.potentialStates.push({"value": "Dispatched", "isDescribable": true});
			}
		}
	});
	
	$scope.handleStateChange = function (stateChange) {
		var onSuccess = function () {
			if (stateChange.state === "Approved") {
				assets.setAssetState($scope.order.items[0].finalAssetID, "Active");
				assets.setAssetAvailability($scope.order.items[0].finalAssetID, moment().toDate(), null);
			}
	    	
			$scope.init();
		};
		
		var onError = function () {
		};
		
		$scope.orderService.setState($scope.order.id, stateChange.state, stateChange.description).then(onSuccess).catch(onError);
	};
	
	
	var updateOrderState = function (state) {
		var onSuccess = function () {
			$scope.init();
		};
		
		var onError = function (response) {
			var modal = canopyModals.definitions.serverError(response.data);
			
			canopyModals.instantiate(modal);
		};
		
		var modal = canopyModals.definitions.form({
			header: "Set Order State",
			form: Luma.paths.context + "/system/mantle/marquee/site/forms/set-state.html"
		});
	
		canopyModals.instantiate(modal).result.then(function (form) {
			$scope.orderService.setState($scope.order.id, state, form.comment).then(onSuccess).catch(onError);
		});
	};
	
	var setOrderArchived = function (archived) {
		var onSuccess = function () {
			$scope.init();
		};
		
		var onError = function (response) {
			var modal = canopyModals.definitions.serverError(response.data);
			
			canopyModals.instantiate(modal);
		};
		
		orders.setOrderArchived($scope.order.id, archived).then(onSuccess).catch(onError);
	};
	
	var initOrderKeys = function () {
		var onSuccess = function (response) {
			$scope.order.keys = response.data;
		};
		
		var onError = function (response) {
		};
		
		orders.getOrderKeys($stateParams.orderID).then(onSuccess, onError);
	};
	
	var initActions = function () {
		var user = canopySession.getUser();
			
		var downloadAction = {
			label: "Download order items",
			href: lumaPaths.serviceURI + ".ShrinkWrapOrder?orderID=" + $scope.order.id + "&com.intrepia.luma.Download.name=" + encodeURIComponent($scope.order.name + " [" + $scope.order.id + "]"),
			permitted: function () {
				return $scope.order.state != "New" && $scope.canDownload;
			}
		};
		
		var shareAction = {
			label: "Share order",
			execute: function () {
				if ($scope.order.items == undefined) {
					return;
				}
				
				var assetIDs = $scope.order.items.map(function (item) {
					return item.finalAssetID;
				});
				
				shares.quickShareAssets(assetIDs);
			},
			permitted: function () {
				return $scope.order.state != "New" && $scope.canDownload && user.hasService("application.orders.share");
			}
		};
		
		var printAction = {
			label: "Print order details",
			execute: function () {
				window.print();
			},
			permitted: function () {
				return true;
			}
		};
		
		var approveAction = {
			label: "Mark as approved",
			execute: function () {
				updateOrderState("Approved");
			},
			permitted: function () {
				return $scope.role == "Approver" && $scope.order.state == "Unapproved" && $scope.canApprove;
			}
		};
		
		var rejectAction = {
			label: "Mark as rejected",
			execute: function () {
				updateOrderState("Rejected");
			},
			permitted: function () {				
				if ($scope.role == "Approver" && $scope.order.state == "Unapproved") {
					return $scope.canApprove;
				}
				
				if ($scope.role == "Printer" && $scope.order.state == "Approved") {
					return $scope.canApprove;
				}
			}
		};
		
		var processAction = {
			label: "Mark as processed",
			execute: function () {
				updateOrderState("Processed");
			},
			permitted: function () {
				return $scope.role == "Printer" && $scope.order.state == "Approved" && $scope.canApprove;
			}
		};
		
		var dispatchAction = {
			label: "Mark as dispatched",
			execute: function () {
				updateOrderState("Dispatched");
			},
			permitted: function () {
				return $scope.role == "Printer" && $scope.order.state == "Processed" && $scope.canApprove;
			}
		};
		
		var archiveAction = {
			label: "Archive order",
			execute: function () {
				setOrderArchived(true);
			},
			permitted: function () {
				return $scope.role == "Approver" && !$scope.order.archived;
			}
		};
		
		var unarchiveAction = {
			label: "Unarchive order",
			execute: function () {
				setOrderArchived(false);
			},
			permitted: function () {
				return $scope.role == "Approver" && $scope.order.archived;
			}
		};
		
		$scope.actions = [{
			label: "Use",
			actions: [
				downloadAction,
				shareAction,
				printAction
			]
		}, {
			label: "Approve",
			actions: [
				approveAction,
				rejectAction,
				archiveAction,
				unarchiveAction
			]
		}, {
			label: "Process",
			actions: [
				processAction,
				dispatchAction
			]
		}];
		
		$scope.actionCount = 0;
		
		$scope.actions.forEach(function (category) {
			category.actions = category.actions.filter(function (action) {
				return action.permitted();
			});
			
			$scope.actionCount += category.actions.length;
		});
	};
	
    $scope.init();
});