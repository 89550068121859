canopy.factory("registrations", ["$http", "lumaPaths", "listService", function ($http, lumaPaths, listService) {
	
	var listRegistrations = function (domain) {
		return $http({
			url: lumaPaths.serviceURI + ".ListRegistrations",
			method: "POST",
			data: $.param({
				"domainID": domain.id || domain
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		}); 
	};
	
	var getRegistration = function (registrationID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetRegistration",
			method: "POST",
			data: $.param({
				"registrationID": registrationID
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		}); 
	};
	
	var getRegistrationGroups = function (registration) {
		return $http({
			url: lumaPaths.serviceURI + ".GetRegistrationGroups",
			method: "POST",
			data: $.param({
				"registrationID": registration.id || registration
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		}); 
	};
	
	var approveRegistration = function (registration) {
		return $http({
			url: lumaPaths.serviceURI + ".ApproveRegistrations",
			method: "POST",
			data: $.param({
				"registrationIDs": registration.id || registration
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		}); 
	};
	
	var rejectRegistration = function (registration) {
		return $http({
			url: lumaPaths.serviceURI + ".RejectRegistrations",
			method: "POST",
			data: $.param({
				"registrationIDs": registration.id || registration
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		}); 
	};
	
	return {
		listRegistrations: function (domain) {
			return listRegistrations(domain);
		},
		getRegistration: function (registrationID) {
			return getRegistration(registrationID);
		},
		getRegistrationGroups: function (registration) {
			return getRegistrationGroups(registration);
		},
		approveRegistration: function (registration) {
			return approveRegistration(registration);
		},
		rejectRegistration: function (registration) {
			return rejectRegistration(registration);
		},
		list: function () {
			var list = listService.instantiate("ListRegistrations");
			
			list.prime = function () {
			};
			
			return list;
		}
	}
}]);