"use strict";

canopy.service("trixIsCssProperty", [function () {

    var el = document.createElement("div");

    var hasProp = function(propertyName) {
        return el.style.hasOwnProperty(propertyName);
    }
	
	return {
		hasProp: hasProp
	};
}]);