canopy.factory("downloads", ["$http", "lumaPaths", function ($http, lumaPaths) {
	var download = function (href) {
		var link = document.createElement("a");
	
		link.href = href;
		
		var clickEvent = null;
	
		try {
			clickEvent = new MouseEvent("click", {
				"view": window,
				"bubbles": true,
				"cancelable": false
			});
		}
		catch (e) {			
			clickEvent = document.createEvent("Event");
			clickEvent.initEvent("click", true, false);
		}
		
		document.body.appendChild(link);
		
		link.dispatchEvent(clickEvent);
		
		document.body.removeChild(link);
	};
	
	var shrinkWrapAssets = function (assetIDs) {
		var href = lumaPaths.downloadURI + ".ShrinkWrapAssets?assetIDs=" + assetIDs.toString();
		
		download(href);
	};
	
	var shrinkWrapAssetDerivatives = function (assetIDs, transformIDs) {
		var href = lumaPaths.downloadURI + ".ShrinkWrapAssetDerivatives?assetIDs=" + assetIDs.toString() + "&transformIDs=" + transformIDs.toString();;
		
		download(href);
	};
	
	var shrinkWrapBasket = function () {			
		var href = lumaPaths.downloadURI + ".ShrinkWrapBasket";
		
		download(href);
	};
	
	var shrinkWrapBasketDerivatives = function (transformIDs) {
		var href = lumaPaths.downloadURI + ".ShrinkWrapBasketDerivatives?transformIDs=" + transformIDs.toString();
		
		download(href);
	};
	
	var shrinkWrapOrders = function (orderIDs) {
		var href = lumaPaths.downloadURI + ".ShrinkWrapOrders?orderIDs=" + orderIDs.toString();
		
		download(href);
	};
	
	return {
		shrinkWrapAssets: function (assetIDs) {
			return shrinkWrapAssets(assetIDs);
		},
		shrinkWrapAssetDerivatives: function (assetIDs, transformIDs) {
			return shrinkWrapAssetDerivatives(assetIDs, transformIDs);
		},
		shrinkWrapBasket: function () {
			return shrinkWrapBasket();
		},
		shrinkWrapBasketDerivatives: function (transformIDs) {
			return shrinkWrapBasketDerivatives(transformIDs);
		},
		shrinkWrapOrders: function (orderIDs) {
			return shrinkWrapOrders(orderIDs);
		}
	};
}]);