canopy.controller("ProjectRequestController", function ($scope, canopySession, projects, assets, listService, processes, utilities, canopyModals, $state, $stateParams, $location, FileUploader) {
	$scope.init = function () {
		initProject();
		initAncillary();
		initDomains();
		
		if ($stateParams.processID) {
			initProcess();
			initProcessTaxonomy();
		}
		else {
			initProcesses();
		}
		
		initGroups();
		
		$scope.uploader = new FileUploader({    	
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.AddProjectAssets",
			alias: "payload"
		});
		
		$scope.forms = [];
	};
	
	var initAncillary = function () {
		$scope.ancillary = {};
	};
	
	var initDomains = function () {
		var domain = canopySession.getActiveDomain();
		
		if (domain) {
			$scope.project.domain = domain;
		}
	};
    
    var initProcess = function () {
    	var onSuccess = function (response) {
    		$scope.process = response.data;
    		
    		if ($scope.process.metadata) {
    			$scope.process.metadata = JSON.parse($scope.process.metadata);
    			
    			if ($scope.process.metadata.project) {
    				for (var key in $scope.process.metadata.project) {
    					$scope.project[key] = $scope.process.metadata.project[key];
    				}
    			}
    		}
    	};
    	
    	processes.getProcess($stateParams.processID).then(onSuccess);
    };
	
	var initProcessTaxonomy = function () {
		var onSuccess = function (response) {
			$scope.ancillary.taxonomy = response.data;
		};
		
		var onError = function () {
		};
		
		if ($stateParams.processID) {
			processes.getProcessTaxonomy($stateParams.processID).then(onSuccess, onError);
		}
	};
	
	var initProcesses = function () {
		$scope.ancillary.processes = {
			available: undefined,
			selected:  undefined
		};
		
		var onSuccess = function (response) {
			$scope.ancillary.processes.available = response.data;
			
			if ($scope.ancillary.processes.available.length) {
				$scope.ancillary.processes.selected = $scope.ancillary.processes.available[0];
			}
		};
		
		var onError = function (response) {
		};
		
		var domain = canopySession.getActiveDomain();
		
		if (domain) {
			var processList = listService.instantiate("ListProcessesForDomain");
			
			processList.setParameter("domainID", domain.id);
			processList.setParameter("type", "Project");
			processList.setParameter("enabled", true);
			
			processList.load().then(onSuccess, onError);
		}
	};
	
	var initBudgets = function () {
		var genusReducer = function (acc, genus) {
			genus.keywords.forEach(function (keyword) {
				if (keyword.selected) {
					acc.push(keyword.id);
				}
			});
			
			return acc;
		};
		
		$scope.ancillary.budgets = {
			available: undefined,
			selected:  undefined
		};
		
		var onSuccess = function (response) {
			$scope.ancillary.budgets.available = response.data;
		};

		$scope.budgetExpiration = function(expirationDate) {

			// Set the date we're counting down to
			var countDownDate = new Date(expirationDate).getTime();

			// Get todays date and time
			var now = new Date().getTime();
				
			// Find the distance between now and the count down date
			var distance = countDownDate - now;
				
			// Time calculations for days, hours, minutes and seconds
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

			// Output the result in an element with id="countDownDate"
			var message = document.querySelector( '#countDownDate' );

			if(expirationDate) {

				message.innerHTML = "This budget expires in <strong>" + days + " day(s)</strong> & <strong>" + hours + " hour(s)</strong>." ;

				if (days >= 30) {
					message.classList.remove("alert-danger", "alert-warning");
					message.classList.add("alert-info");

				} else if (days > 5 && days < 30) {
					message.classList.remove("alert-danger", "alert-info");
					message.classList.add("alert-warning");

				} else {
					message.classList.remove("alert-info", "alert-warning");
					message.classList.add("alert-danger");
				}
			} 
		}
		
		var domain = canopySession.getActiveDomain();
		var site   = canopySession.getActiveSite();
		
		if (domain && site) {			
			var budgetList = listService.instantiate("ListBudgetsForDomain");
			var keywordIDs = $scope.ancillary.taxonomy.reduce(genusReducer, []);
			
			budgetList.setParameter("domainID", domain.id);
			budgetList.setParameter("siteID", site.id);
			budgetList.setParameter("enabled", true);
			
			budgetList.registerFilter({
				name: "keywords",
				type: "com.intrepia.luma.KeywordFilter",
				applied: keywordIDs
			});
			
			budgetList.load().then(onSuccess);
		}
	};
    
    var initGroups = function () {
    	var groupTypeFilter = function (type) {
			return function (element) {
				return element.value.type === type;
			};
		};
		
		var groupMapper = function (element) {
			return {
				id: element.value.id,
				name: element.value.name,
				type: element.value.type
			};
		};
    	
    	var user   = canopySession.getUser();
    	var groups = user.groups.values;
    	
    	var units    = groups.filter(groupTypeFilter("Unit"));
    	var regions  = groups.filter(groupTypeFilter("Region"));
    	var branches = groups.filter(groupTypeFilter("Branch"));
    	
    	units    = units.map(groupMapper);
    	regions  = regions.map(groupMapper);
    	branches = branches.map(groupMapper);
    	
    	$scope.ancillary.groups = {
    		unit: {
    			available: units,
    			selected: undefined
    		},
    		region: {
    			available: regions,
    			selected: undefined
    		},
    		branch: {
    			available: branches,
    			selected: undefined
    		}
			};
			
			if (!$scope.ancillary.groups.unit.available.length) {
				$scope.ancillary.groups.unit.selected = undefined;
			}
	
			if (!$scope.ancillary.groups.region.available.length) {
				$scope.ancillary.groups.region.selected = undefined;
			}
	
			if (!$scope.ancillary.groups.branch.available.length) {
				$scope.ancillary.groups.branch.selected = undefined;
			}
    };
    
    var getGroupIDs = function () {
    	var groupIDs = [];
    	
    	for (var key in $scope.ancillary.groups) {
    		var group = $scope.ancillary.groups[key];
    		
    		if (group && group.selected) {
    			groupIDs.push(group.selected.id);
    		}
    	}
    	
    	return groupIDs.toString();
    };
    
    var getSelectedGroups = function () {
    	var selectedGroups = [];
    	
    	for (var key in $scope.ancillary.groups) {
    		var groupType = $scope.ancillary.groups[key];
    		
			var groupTypeSelected = groupType.available.filter(function (group) {
				return group.selected;
			});
    		
    		if (groupTypeSelected.length) {
    			selectedGroups = selectedGroups.concat(groupTypeSelected);
    		}
    		else {
				if (groupType.selected) {
					selectedGroups.push(groupType.selected);
				}
			}
    	}
    	
    	return selectedGroups;
    };
    
	var submitPayload = function (projectID) {
		if ($scope.uploader.queue.length) {			
			$scope.uploader.onBeforeUploadItem = function (item) {
				item.formData.push({
					"projectID": projectID,
					"bucket": "Working"
				});
			};
		
			$scope.uploader.onCompleteAll = function () {
				$scope.isSubmitting = false;
				openSuccessModal(projectID);
			};
		
			$scope.uploader.uploadAll();
		}
		else {
			$scope.isSubmitting = false;
			openSuccessModal(projectID);
		}
    };
	
	var initProject = function () {
		$scope.project = {
			name: "",
			processID: $stateParams.processID,
			domain: null,
			owner: null,
			subscribe: false,
			state: "",
			allocations: [],
			groupIDs: [],
			taxonomy: [],
			metadata: {
				canopy: {},
				client: {}
			},
			availability: {
				start: null,
				end: null
			}
		};
		
		var associateIDs = $location.search().associateIDs;
		
		if (associateIDs) {
			$scope.project.associateIDs = associateIDs;
		}
	};
	
	$scope.registerForm = function (element, name) {
		$scope.forms.push({
			name: name,
			node: element
		});
	};
    
    $scope.submitRequest = function (form) {
    	if ($scope.isSubmitting) {
    		return;
    	}
    	
    	$scope.isSubmitting = true;
    	$scope.$broadcast("submit");
    	
    	var invalid = false;
    	
    	$scope.forms.forEach(function (element) {
    		var form = element.node[element.name];
    		
    		if (form.$invalid) {
				element.node.$parent.isOpen = true;
				invalid = true;
    		}
    	});
    	
    	if (invalid) {
    		var modal = canopyModals.definitions.message({
    			header: "Could not submit project",
    			body: "You haven't entered any data into one or more required form fields. Please check the form, enter the required information, and resubmit."
    		});
    		
    		canopyModals.instantiate(modal).result.then(function () {
		    	$scope.isSubmitting = false;
    		});
    		
		    return;
    	}
    	
    	var groupMapper = function (element) {
    		return element.id;
    	};
    	
    	var selectedGroups   = getSelectedGroups($scope.ancillary.groups);
    	var selectedGroupIDs = selectedGroups.map(groupMapper).toString();
    	
    	$scope.project.metadata.canopy.groups = selectedGroups;
    	$scope.project.groupIDs               = selectedGroupIDs;
    	
    	if ($scope.project.allocations.length) {
    		$scope.project.allocations = $scope.project.allocations.map(function (allocation) {
    			return {
    				label: allocation.label,
    				value: allocation.value,
    				budgetID: allocation.budget.id,
    				reimbursable: allocation.reimbursable
    			};
    		});
    	}
    	
    	var taxonomyReducer = function (acc, element) {
			var keywordFilter = function (keyword) {
				return keyword.selected;
			};
		
			var keywordMapper = function (keyword) {
				return keyword.id;
			};
			
    		var mapped = {
    			id: element.id,
    			keywordIDs: element.keywords.filter(keywordFilter).map(keywordMapper)
    		};
    		
    		if (element.type == "Interactive" && mapped.keywordIDs.length) {
    			acc.push(mapped);
    		}
    		
    		return acc;
    	};
    	
    	$scope.project.taxonomy = $scope.ancillary.taxonomy.reduce(taxonomyReducer, []);
    	
    	if ($scope.project.owner) {
    		$scope.project.ownerID = $scope.project.owner.id;
    	}
    	
		var onSuccess = function (response) {    	
			var projectID = "";
	
			try {
				var element = document.createElement("html");
				element.innerHTML = response.data;
				projectID = jQuery(element).find("#response")[0].innerHTML;
			}
			catch (e) {
			}
			
			if (projectID) {
				if ($scope.project.availability.start || $scope.project.availability.end) {
					assets.setAssetAvailability(projectID, $scope.project.availability.start, $scope.project.availability.end).then(function (response) {			
						submitPayload(projectID);
					});
				}
				else {
					submitPayload(projectID);
				}
			}
		};
		
		var onError = function (response) {
			$scope.isSubmitting = false;
			
			var content = {};
				
			try {
				var element = document.createElement("html");
				element.innerHTML = response.data;
				content = JSON.parse(jQuery(element).find("#response")[0].innerHTML);
			}
			catch (e) {
			}
			
			canopyModals.instantiate(canopyModals.definitions.serverError(content));
		};
		
		projects.addProject($scope.project).then(onSuccess).catch(onError);
    };
    
    var openSuccessModal = function (projectID) {
		var modal = canopyModals.definitions.message({
			header: "SUCCESS",
			body: "Your project has been created. Your project ID is: " + projectID
		});

		canopyModals.instantiate(modal).result.then(function () {
			$state.go("^.detail", {
				projectID: projectID
			});
		});
    };
        
    $scope.$watch("ancillary.processes.selected", function (process) {
		if (process) {
			$scope.project.processID               = process.id;
			$scope.project.metadata.canopy.process = process;
		}
	});
	
	$scope.$watch("ancillary.taxonomy", function (taxonomy) {
		if (taxonomy) {
			initBudgets();
		}
	}, true);
    	
	$scope.init();
});