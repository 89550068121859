canopy.service("basket", function ($http, lumaPaths, listService, canopyEvents) {
	var getBasketSize = function () {
		return $http({
			url: lumaPaths.serviceURI + ".GetBasketSize",
			method: "POST",
			data: $.param({}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var editBasket = function (assetIDs, operation) {
		return $http({
			url: lumaPaths.serviceURI + ".EditBasket",
			method: "POST",
			data: $.param({
				assetIDs: assetIDs.toString(),
				operation: operation
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		}).then( function () {
			onBasketEdit(assetIDs, operation);
		});
	};
	
	var emptyBasket = function () {
		return $http({
			url: lumaPaths.serviceURI + ".EmptyBasket",
			method: "POST",
			data: $.param({}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		}).then( function () {
			onBasketEmpty();
		});
	};
	
	var getBasketDerivativeMenu = function () {
		return $http({
			url: lumaPaths.serviceURI + ".GetBasketDerivativeMenu",
			method: "POST",
			data: $.param({}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var onBasketEdit = function (assetIDs, operation) {
		var count = 0;
		
		if (assetIDs instanceof Array) {
			count = assetIDs.length;
		}
		else {
			count = assetIDs.toString().split(",").length;
		}
		
		canopyEvents.dispatch("userBasketWasModified", {
			count: count,
			operation: operation
		});
	};
	
	var onBasketEmpty = function () {
		canopyEvents.dispatch("userBasketWasEmptied", {});
	};
	
	return {
		getBasketSize: function () {
			return getBasketSize();
		},
		editBasket: function (assetIDs, operation) {
			return editBasket(assetIDs, operation);
		},
		addAssets: function (assetIDs) {
			return editBasket(assetIDs, "Add");
		},
		removeAssets: function (assetIDs) {
			return editBasket(assetIDs, "Remove");
		},
		emptyBasket: function () {
			return emptyBasket();
		},
		getBasketDerivativeMenu: function () {
			return getBasketDerivativeMenu();
		},
		shrinkWrapBasket: function () {
			return shrinkWrapBasket();
		},
		list: function () {
			var list = listService.instantiate("ListBasket");

			list.setParameter("listing-style", "Page");
			list.setParameter("listing-limit", 40);

			return list;
		}
	};
});
