"use strict";

canopy.directive("canopyProjectStub", function () {
	return {
		restrict: "A",
		scope: {
			project: "=",
			taxonomy: "="
		},
		controller: function ($scope, canopySession, listService, processes) {
			var initProject = function () {
				$scope.project = $scope.project || { };
				
				$scope.project.valid = function () {
					var valid = true;
					
					$scope.forms.forEach(function (element) {
						var form = element.node[element.name];
						
						if (form.$invalid) {
							valid = false;
						}
					});
					
					return valid;
				};
			};
			
			var initProcess = function () {
				if ($scope.project.processID === undefined) {
					return;
				}
				
				var onSuccess = function (response) {
					$scope.ancillary.process = response.data;
					
					if ($scope.ancillary.process.metadata) {
						$scope.ancillary.process.metadata = JSON.parse($scope.ancillary.process.metadata);
						
						if ($scope.ancillary.process.metadata.project) {
							for (var key in $scope.ancillary.process.metadata.project) {
								$scope.project[key] = $scope.ancillary.process.metadata.project[key];
							}
						}
					}
					
					var onSuccess = function (response) {
						var inheritable = null;
							
						if ($scope.ancillary.process.metadata.project && $scope.ancillary.process.metadata.project.taxonomy) {
							inheritable = $scope.ancillary.process.metadata.project.taxonomy;
						}
							
						var isInheritable = function (genusName, keywordName) {
							if (!inheritable) {
								return false;
							}
							
							var keywords = inheritable[genusName];
							
							if (keywords) {
								return keywords.indexOf(keywordName) >= 0;
							}
							
							return false;
						};
						
						$scope.ancillary.process.taxonomy = response.data;
						
						var campaignTaxonomy   = $scope.taxonomy;
						var campaignKeywordIDs = [];
						
						campaignTaxonomy.forEach(function (genus) {
							if (genus.type === "Interactive") {
								genus.keywords.forEach(function (keyword) {
									if (keyword.selected) {
										campaignKeywordIDs.push(keyword.id);
									}
								});
							}
						});
						
						var projectTaxonomy = response.data;
						
						projectTaxonomy.forEach(function (genus) {
							if (genus.type === "Interactive") {
								genus.keywords.forEach(function (keyword) {
									if (campaignKeywordIDs.indexOf(keyword.id) >= 0 && isInheritable(genus["genus-name"], keyword.name)) {
										keyword.selected = true;
									}
								});
							}
						});
						
						$scope.project.taxonomy = projectTaxonomy.reduce(function (reduced, taxon) {
							var keywords = taxon.keywords.filter(function (keyword) {
								return keyword.selected;
							});
							
							var keywordIDs = keywords.map(function (keyword) {
								return keyword.id;
							});
							
							if (keywordIDs.length > 0) {
								reduced.push({
									id: taxon.id,
									keywordIDs: keywordIDs
								});
							}
							
							return reduced;
						}, []);
						
						initBudgets();
					};
					
					processes.getProcessTaxonomy($scope.project.processID).then(onSuccess);
				};
				
				var onError = function (response) {
				};
				
				processes.getProcess($scope.project.processID).then(onSuccess, onError);
			};
			
			var initBudgets = function () {
				if ($scope.ancillary.budgets === undefined) {		
					$scope.ancillary.budgets = {
						available: [],
						selected:  undefined
					};
				}
		
				var onSuccess = function (response) {
					$scope.ancillary.budgets.available = response.data;
			
					if ($scope.ancillary.budgets.available.length) {
						if ($scope.project.allocations) {
							$scope.project.allocations.forEach(function (allocation) {
								allocation.budget = $scope.ancillary.budgets.available[0];
							});
						}
					}
				};
		
				var domain = canopySession.getActiveDomain();
				var site   = canopySession.getActiveSite();
		
				if (domain && site) {
					var budgetList = listService.instantiate("ListBudgetsForDomain");
			
					budgetList.setParameter("domainID", domain.id);
					budgetList.setParameter("siteID", site.id);
					budgetList.setParameter("enabled", true);
			
					if ($scope.ancillary.process.taxonomy) {
						var keywordIDs = [];
						
						$scope.ancillary.process.taxonomy.forEach(function (genus) {
							if (genus.type == "Static") {
								genus.keywords.forEach(function (keyword) {
									if (keyword.selected) {
										keywordIDs.push(keyword.id);
									}
								});
							}
						});
					
						if (keywordIDs.length) {
							budgetList.registerFilter({
								name: "keywords",
								type: "com.intrepia.luma.KeywordFilter",
								applied: keywordIDs
							});
						}
					}
			
					budgetList.load().then(onSuccess);
				}
			};
		
			var init = function () {
				$scope.forms = [];
				$scope.ancillary = {};
				
				$scope.taxonomy = $scope.taxonomy || [];
				
				initProject();
				initProcess();
			};
	
			$scope.registerForm = function (element, name) {
				$scope.forms.push({
					name: name,
					node: element
				});
			};
			
			$scope.$watch("ancillary.budgets.selected", function (budget) {
				if (budget) {
					$scope.project.budgetID = budget.id;
				}
			});
			
			init();
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/project-stub/project-stub.html"
	}
});