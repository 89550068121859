"use strict";

canopy.controller("GlobalController", function ($scope, canopySession) {
	$scope.application = {
		paths: Luma.paths
	};
});

canopy.controller("ApplicationController", function ($scope, $timeout, $state, $stateParams, canopySession, contentManagement, canopyModals, canopyEvents, $location, $window, pageWidgets, pageVersion) {
	$scope.init = function () {
		$scope.luma    = Luma;
		$scope.session = canopySession;
		$scope.pageVersion = pageVersion;

		var node;
		
		try {
			node = $state.current.resolve.menuNode();
		}
		catch (e) {
			node = null;
		}
		
		if (node) {
			canopySession.setActiveSite(node.siteID, { skipStateChange: true });
			canopySession.setActiveNode(node.id, { skipStateChange: true });
		}
		
		$scope.user = canopySession.getUser();

		$scope.menu = {
			domains: canopySession.getAvailableDomains(),
			sites:   canopySession.getAvailableSites(),
			nodes:   canopySession.getAvailableNodes(),
			locales: canopySession.getAvailableLocales(),
			admin: $scope.user.modules.values.map(function (element) {
				return {
					name: element.value.name,
					code: element.value.code
				};
			})
		};
		
		$scope.active = {
			site: canopySession.getActiveSite(),
			node: canopySession.getActiveNode()
		};
		
		if (node === null) {
			var site = $scope.menu.sites.find(function (element) {
				return element.menu.length > 0 && !element.anonymous;
			});
			
			if (site) {
				$scope.onSiteSelection(site);
			}
			
		}
	};
	
	$scope.onDomainSelection = function (domain) {
		canopySession.setActiveDomain(domain);
	};
	
	$scope.onSiteSelection = function (site) {
		canopySession.setActiveSite(site, {
			cascade: true
		});
	};
	
	$scope.onNodeSelection = function (node) {
		canopySession.setActiveNode(node);
	};
	
	$scope.onLocaleSelection = function (locale) {
		canopySession.setActiveLocale(locale);
	};
	
	canopySession.promise.then(function (response) {
		$scope.init(response);
	});
	
	$scope.revertToPublishedRevision = function() {		
		var modal = canopyModals.definitions.message({
			header: "Are you sure?",
			body: "Any unsaved changes will be lost and the page will revert to the currently Published version.",
			confirm: "Confirm",
			cancel: "Cancel"
		});
	
		canopyModals.instantiate(modal).result.then(function () {
			$state.go($state.current, {}, {reload: true});
		});	
	}
	
	$scope.onEdit = function () {
		canopyModals.instantiate(canopyModals.definitions.pageEditor($scope.active.node));
	};

	$scope.onRevisions = function() {
		canopyModals.instantiate(canopyModals.definitions.pageVersions($scope.active.node))
	}

	$scope.$watch("pageVersion.revision", function (value) {
		$scope.menu.revision = value;
	});

	$scope.$watch("pageVersion.getCurrentRevision()", function (value) {
		$scope.menu.revision = value;
	});

	$scope.$watch("session.getActiveDomain()", function (domain) {
		$scope.active.domain = domain;
	});
	
	$scope.$watch("session.getActiveSite()", function (site) {
		$scope.active.site = site;
		
		$scope.menu.nodes = canopySession.getAvailableNodes();
	});
	
	$scope.$watch("session.getActiveNode()", function (node) {
		$scope.active.node = node;
	
		if (node.metadata.page && node.metadata.page.type === "ContentManagedPage" && Luma.user.hasService("application.content_management.edit_pages")) {
			$scope.menu.revision = pageVersion.getCurrentRevision();
			$scope.menu.cms = [
				{
					title: "Edit...",
					onClick: $scope.onEdit
				},
				{
					title: "Revisions...",
					onClick: $scope.onRevisions
				},
				{
					title: "Discard changes...",
					onClick: $scope.revertToPublishedRevision
				}
			];
		}
		else {
			$scope.menu.cms = [];
		}
	});
});