"use strict";

canopy.directive("canopyApprovalPanel", function () {
	return {
		restrict: "A",
		scope: {
			states: "=",
			callbacks: "="
		},
		controller: function ($scope) {
			$scope.init = function () {			
				$scope.stateChange = {
					state: "",
					description: ""
				};
				
				$scope.isDescriptionPanelVisible = false;
			};
			
			$scope.onStateChange = function () {
 				if ($scope.callbacks.onStateChange) {
 					$scope.callbacks.onStateChange($scope.stateChange);
 				}
 				
 				$scope.init();
			};
			
			$scope.onStateClick = function (state) {
				$scope.stateChange.state = state.value;
				$scope.isDescriptionPanelVisible = false;
				
				if (state.isDescribable) {
					$scope.isDescriptionPanelVisible = true;
				}
				else {
					$scope.onStateChange();
				}
			};
			
			$scope.onCancel = function () {
				$scope.init();
			};
			
			$scope.init();
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/requests/approval-panel.html"
	}
});