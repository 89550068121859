canopy.factory("shares", ["$http", "$window", "lumaPaths", "listService", "canopyModals", "utilities", function ($http, $window, lumaPaths, listService, canopyModals, utilities) {
	var getShare = function (shareID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetShare",
			method: "POST",
			data: $.param({
				shareID: shareID
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var listShareAssets = function (share) {
		return $http({
			url: lumaPaths.serviceURI + ".ListShareAssets",
			method: "POST",
			data: $.param({
				shareID: share.id || share
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var listShareGuests = function (share) {
		return $http({
			url: lumaPaths.serviceURI + ".ListShareGuests",
			method: "POST",
			data: $.param({
				shareID: share.id || share
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var addShare = function (share, assetIDs) {
		var data = {
			name:        share.name,
			description: share.description,
			start:       share.start ? Dates.encode( budget.start ) : Dates.encode( new Date() ),
			end:         share.end   ? Dates.encode( budget.end )   : null
		};
		
		if (assetIDs) {
			data.assetIDs = assetIDs.toString();
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".AddShare",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var addShareGuests = function (share, invitation) {
		var addresses = invitation.addresses;
		
		addresses = addresses.replace(/\s/g, "");
		addresses = addresses.split(",");
		addresses = addresses.filter(function(item, pos, self) {
			return self.indexOf(item) == pos;
		});
		
		var hours = invitation.hours.value;
		
		return $http({
			url: lumaPaths.serviceURI + ".AddShareGuests",
			method: "POST",
			data: $.param({
				shareID:   share.id || share,
				addresses: addresses.toString(),
				hours:     hours,
				message:   invitation.message
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	var addBasketToShare = function (share) {
		return $http({
			url: lumaPaths.serviceURI + ".AddBasketToShares",
			method: "POST",
			data: $.param({
				shareIDs:   share.id || share
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
		
	var quickShareModal = canopyModals.definitions.form({
		header: "Quick Share",
		form:   Luma.paths.context + "/system/mantle/marquee/site/forms/quick-share.html"
	});
	
	var onQuickShareSuccess = function (shareID) {		    	
		var modal = canopyModals.definitions.message({
			header: "Share created",
			body: "Your share has been created and invitations have been sent to the email addresses you entered."
		});

		canopyModals.instantiate(modal).result.then(function () {
			// $window.location = "#!/shares/" + shareID;
		});
	};
	
	var onQuickShareError = function () {
	};
	
	var quickShareAssets = function (assetIDs) {
		canopyModals.instantiate(quickShareModal).result.then(function (result) {
			var share = {
				name: "Share " + utilities.date().toUnix()
			};
			
			var invitation = {
				addresses: result.addresses,
				message:   result.message,
				hours:     result.hours
			};
			
			addShare(share, assetIDs).then(function (response) {
				var shareID = response.data;
				
				addShareGuests(shareID, invitation).then(function () {
					onQuickShareSuccess(shareID);
				}).catch(onQuickShareError);
			}).catch(onQuickShareError);
		});
	};
	
	var quickShareBasket = function () {
		canopyModals.instantiate(quickShareModal).result.then(function (result) {
			var share = {
				name: "Share " + utilities.date().toUnix()
			};
			
			var invitation = {
				addresses: result.addresses,
				message:   result.message,
				hours:     result.hours
			};
			
			addShare(share).then(function(response) {
				var shareID = response.data;
				
				addBasketToShare(shareID).then(function () {				
					addShareGuests(shareID, invitation).then(function () {
						onQuickShareSuccess(shareID);
					}).catch(onQuickShareError);
				}).catch(onQuickShareError);
			}).catch(onQuickShareError);
		});
	};
	
	return {
		getShare: function (shareID) {
			return getShare(shareID);
		},
		listShareAssets: function (share) {
			return listShareAssets(share);
		},
		listShareGuests: function (share) {
			return listShareGuests(share);
		},
		addShare: function (share, assetIDs) {
			return addShare(share, assetIDs);
		},
		addShareGuests: function (share, invitation) {
			return addShareGuests(share, invitation);
		},
		quickShareAssets: function (assetIDs) {
			return quickShareAssets(assetIDs);
		},
		quickShareBasket: function (assetIDs) {
			return quickShareBasket(assetIDs);
		},
		list: function () {
			var list = listService.instantiate("ListShares");
			
			list.setParameter("listing-style", "Page");
			list.setParameter("listing-limit", 40);
			
			return list;
		}
	};
}]);