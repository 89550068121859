canopy.factory("assets", ["$http", "lumaPaths", "listService", "canopyModals", function ($http, lumaPaths, listService, canopyModals) {
	var getAsset = function (assetID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAsset",
			method: "POST",
			data: $.param({
				assetID: assetID
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	var getAdaptation = function (adaptationID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAdaptation",
			method: "POST",
			data: $.param({
				adaptationID: adaptationID
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	var getAdaptationMedia = function (adaptationID) {
		return $http({
			url: lumaPaths.entropyServiceURI + ".GetAdaptationMedia",
			method: "POST",
			data: $.param({
				adaptationID: adaptationID
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var getAssetInfo = function (asset, type) {
		var service = null;
		
		switch (type) {
			case "Template":
				service = "GetAssetInfo$Template";
				break;
				
			default:
				service = "GetAssetInfo";
				break;
		}
		
		return $http({
			url: lumaPaths.serviceURI + "." + service,
			method: "POST",
			data: $.param({
				assetID: asset.id || asset,
				name: name
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var getAssetFields = function (asset) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAssetFields",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	var getAssetNarrative = function (asset, name) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAssetNarrative",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset,
				name: name
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	var listAssetAvailability = function (asset) {
		return $http({
			url: lumaPaths.serviceURI + ".ListAssetAvailability",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	var getAssetTaxonomy = function (asset, role) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAssetTaxonomy",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset,
				role: role || "Orderer"
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	var getAssetTransforms = function (asset, role) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAssetTransforms",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset,
				role: role || "Orderer"
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var getAssetDerivatives = function (asset, role) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAssetDerivativeMenu",
			method: "POST",
			data: $.param({
				assetIDs: asset.id || asset
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	var getAssetRates = function (asset) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAssetRates",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset,
				type: "Budget"
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var setAssetState = function (asset, state) {
		return $http({
			url: lumaPaths.serviceURI + ".SetAssetState",
			method: "POST",
			data: $.param({
				assetIDs: asset.id || asset,
				state: state
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var setAssetURN = function (asset, urn) {
		return $http({
			url: lumaPaths.serviceURI + ".SetAssetURN",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset,
				urn: urn
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var revokeAssetAvailability = function (asset) {
		var data = {
			assetIDs: asset.id || asset
		};
		
		return $http({
			url: lumaPaths.serviceURI + ".RevokeAssetAvailability",
			method: "POST",
			data: $.param(data),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var setAssetAvailability = function (asset, start, end) {
		var data = {
			assetIDs: asset.id || asset,
			start: Dates.encode(start),
			expire: false
		};
		
		if (end) {
			data.expire = true;
			data.end = Dates.encode(end);
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".SetAssetAvailability",
			method: "POST",
			data: $.param(data),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var getAssetDerivativeMenu = function (assetIDs) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAssetDerivativeMenu",
			method: "POST",
			data: $.param({
				assetIDs: assetIDs.toString()
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var getAssetActions = function (asset, role) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAssetActions",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset,
				role: role
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var submitAdaptationDeferment = function (deferment) {
		return $http({
			url: lumaPaths.serviceURI + ".SubmitAdaptationDeferment",
			method: "POST",
			data: $.param({
				adaptationID: deferment.adaptationID,
				actionID: deferment.actionID,
				name: deferment.name,
				description: deferment.description,
				groupIDs: deferment.groupIDs,
				metadata: JSON.stringify(deferment.metadata)
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var listAssetTasks = function (asset) {
		return $http({
			url: lumaPaths.serviceURI + ".ListAssetTasks",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var listAssetComments = function (asset) {
		return $http({
			url: lumaPaths.serviceURI + ".ListAssetComments",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var addAssetComment = function (asset, title, body) {
		return $http({
			url: lumaPaths.serviceURI + ".AddAssetComment",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset,
				title: title,
				body: body
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var deleteAsset = function (asset) {
		return $http({
			url: lumaPaths.serviceURI + ".DeleteAssets",
			method: "POST",
			data: $.param({
				assetIDs: asset.id || asset
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var getAssetSubscriptions = function (asset) {
		return $http({
			url: lumaPaths.serviceURI + ".GetAssetSubscriptions",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var editAssetSubscriptions = function (asset, events, operation) {
		return $http({
			url: lumaPaths.serviceURI + ".EditAssetSubscriptions",
			method: "POST",
			data: $.param({
				assetIDs: asset.id || asset,
				events: events,
				operation: operation
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var listAssetAssociates = function (asset) {
		return $http({
			url: lumaPaths.serviceURI + ".ListAssetAssociates",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset,
				associations: "Default"
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	var editAssetAssociations = function (asset, associates, operation) {
		var data = {
			assetIDs: asset.id || asset,
			operation: operation
		};
		
		if (associates instanceof Array) {
			data.associates = associates.toString();
		}
		else {
			data.associates = associates;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".EditAssetAssociations",
			method: "POST",
			data: $.param(data),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	var listAssetRelatives = function (asset) {
		return $http({
			url: lumaPaths.serviceURI + ".ListAssetRelatives",
			method: "POST",
			data: $.param({
				assetID: asset.id || asset
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	var setAssetMetadata = function (asset, name, value) {
		return $http({
			url: lumaPaths.serviceURI + ".SetAssetMetadata",
			method: "POST",
			data: $.param({
				assetIDs: asset.id || asset,
				name: name,
				value: value
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};
	
	return {
		getAsset: function (assetID) {
			return getAsset(assetID);
		},
		getAdaptation: function (adaptationID) {
			return getAdaptation(adaptationID);
		},
		getAdaptationMedia: function (adaptationID) {
			return getAdaptationMedia(adaptationID);
		},
		getAssetInfo: function (assetID, type) {
			return getAssetInfo(assetID, type);
		},
		getAssetFields: function (assetID) {
			return getAssetFields(assetID);
		},
		getAssetNarrative: function (asset, name) {
			return getAssetNarrative(asset, name);
		},
		listAssetAvailability: function (asset) {
			return listAssetAvailability(asset);
		},
		getAssetTaxonomy: function (asset, role) {
			return getAssetTaxonomy(asset, role);
		},
		getAssetTransforms: function (asset, role) {
			return getAssetTransforms(asset, role);
		},
		getAssetDerivatives: function (asset) {
			return getAssetDerivatives(asset);
		},
		getAssetRates: function (asset) {
			return getAssetRates(asset);
		},
		setAssetState: function (asset, state) {
			return setAssetState(asset, state);
		},
		setAssetURN: function (asset, urn) {
			return setAssetURN(asset, urn);
		},
		revokeAssetAvailability: function (asset) {
			return revokeAssetAvailability(asset);
		},
		setAssetAvailability: function (asset, start, end) {
			return setAssetAvailability(asset, start, end);
		},
		getAssetDerivativeMenu: function (assetIDs) {
			return getAssetDerivativeMenu(assetIDs);
		},
		getAssetActions: function (asset, role) {
			return getAssetActions(asset, role);
		},
		submitAdaptationDeferment: function (deferment) {
			return submitAdaptationDeferment(deferment);
		},
		listAssetComments: function (asset) {
			return listAssetComments(asset);
		},
		addAssetComment: function (asset, title, body) {
			return addAssetComment(asset, title, body);
		},
		listAssetTasks: function (asset) {
			return listAssetTasks(asset);
		},
		deleteAsset: function (asset) {
			return deleteAsset(asset);
		},
		getAssetSubscriptions: function (asset) {
			return getAssetSubscriptions(asset);
		},
		editAssetSubscriptions: function (asset, events, operation) {
			return editAssetSubscriptions(asset, events, operation);
		},
		listAssetAssociates: function (asset) {
			return listAssetAssociates(asset);
		},
		editAssetAssociations: function (asset, associates, operation) {
			return editAssetAssociations(asset, associates, operation);
		},
		listAssetRelatives: function (asset) {
			return listAssetRelatives(asset);
		},
		setAssetMetadata: function (asset, name, value) {
			return setAssetMetadata(asset, name, value);
		},
		list: function () {
			var list = listService.instantiate("ListAssets");

			list.prime = function () {
				this.setParameter("tree", null);
			};

			return list;
		},
		utilities: {
			manageAssetSubscriptions: function (asset, callback) {
				canopyModals.instantiate({
					templateUrl: Luma.paths.context + "/system/mantle/marquee/site/modals/modal-manage-asset-subscriptions.html",
					controller: "manageAssetSubscriptionsModalController",
					resolve: {
						args: function () {
							return {
								asset: asset,
								callback: callback
							};
						}
					}
				});
			},
			addAssets: function (callback) {
				canopyModals.instantiate({
					templateUrl: "modalFileUpload.html",
					controller: "addAssetsModalController",
					resolve: {
						args: function () {
							return {
								callback: callback
							};
						}
					}
				});
			},
			updateAsset: function (asset, callback) {
				canopyModals.instantiate({
					templateUrl: Luma.paths.context + "/system/mantle/marquee/site/modals/modal-update-asset.html",
					controller: "updateAssetModalController",
					resolve: {
						args: function () {
							return {
								asset: asset,
								callback: callback
							};
						}
					}
				});
			},
			setAssetURN: function (asset, callback) {
				var modal = canopyModals.definitions.form({
					header: "Set asset URN",
					form:   Luma.paths.context + "/system/mantle/marquee/site/forms/set-asset-urn.html"
				});
				
				canopyModals.instantiate(modal).result.then(function (form) {
					var onSuccess = function (response) {
						if (callback) {
							callback(form.urn);
						}
					};
					
					setAssetURN(asset, form.urn).then(onSuccess);
				});
			},
			setAssetAvailability: function (asset, callback) {
				canopyModals.instantiate({
					templateUrl: Luma.paths.context + "/system/mantle/marquee/site/modals/modal-set-asset-availability.html",
					controller: "setAssetAvailabilityModalController",
					resolve: {
						args: function () {
							return {
								asset: asset,
								callback: callback
							};
						}
					}
				});
			},
			isAssetSelectable: function (asset) {
				return asset.type != "Template" && asset.type != "Adaptation" && asset.type != "Profile";
			},
			isTransformValid: function (asset, transform) {
				return true;
			}
		}
	}
}]);

canopy.controller("manageAssetSubscriptionsModalController", function ($scope, $q, $uibModalInstance, assets, args) {
	var init = function () {
		var assetType = args.asset.type || "Asset";
		assetType = assetType.toLowerCase();
		
		$scope.selectAll = false;

		$scope.subscriptions = [
			{ event: "Association", description: "A system asset is associated with this " + assetType, subscribed: false },
			{ event: "Comment", description: "A user comments on this " + assetType, subscribed: false },
			{ event: "Relation", description: "A related asset is added to this " + assetType, subscribed: false } 
		];
		
		$scope.onSelectAll = function () {
			if ($scope.selectAll) {
				$scope.subscriptions.forEach(function (subscription) {
					subscription.subscribed = false;
				});
			}
			else {
				$scope.subscriptions.forEach(function (subscription) {
					subscription.subscribed = true;
				});	
			}
			
			$scope.selectAll = !$scope.selectAll;
		};
		
		$scope.$watch("subscriptions", function (subscriptions) {
			var unsubscribed = subscriptions.filter(function (subscription) {
				return !subscription.subscribed;
			});
			
			if (unsubscribed.length) {
				$scope.selectAll = false;
			}
		}, true);
		
		var onSuccess = function (response) {
			response.data.forEach(function (element) {
				var subscription = $scope.subscriptions.find(function (subscription) {
					return subscription.event == element.event;
				});
				
				if (subscription) {
					subscription.subscribed = true;
				}
			});
		};
		
		var onError = function () {
		};
	
		assets.getAssetSubscriptions(args.asset).then(onSuccess, onError);
	};
	
	$scope.dismiss = function () {
		$uibModalInstance.dismiss();
	};
	
	$scope.confirm = function () {
		if ($scope.isSubmitting) {
			return;
		}
		
		$scope.isSubmitting = true;
		
		var subscribedFilter = function (subscribed) {
			return function (element) {
				return element.subscribed == subscribed;
			};
		};
		
		var subscriptionMapper = function (element, acc) {
			return element.event;
		};
		
		var subscribed   = $scope.subscriptions.filter(subscribedFilter(true)).map(subscriptionMapper);
		var unsubscribed = $scope.subscriptions.filter(subscribedFilter(false)).map(subscriptionMapper);
		
		var deferred = $q.defer();
		var promises = [];
		
		if (subscribed.length) {
			promises.push(assets.editAssetSubscriptions(args.asset, subscribed.toString(), "Add"));
		}
		if (unsubscribed.length) {
			promises.push(assets.editAssetSubscriptions(args.asset, unsubscribed.toString(), "Remove"));
		}
		
		var onSuccess = function () {
			$scope.isSubmitting = false;
			$uibModalInstance.close();
			
			if (args.callback) {
				args.callback($scope.subscriptions);
			}
		};
		
		var onError = function () {
			$scope.isSubmitting = false;
		};
		
		$q.all(promises).then(onSuccess, onError);
	};
	
	init();
});

canopy.controller("addAssetsModalController", function ($scope, args, $uibModalInstance, FileUploader, canopySession, listService) {
	var init = function () {
		initHoppers();
	};
	
	var initHoppers = function () {
		var onSuccess = function (response) {
			$scope.hoppers = {
				available: [],
				selected: null
			};
		};
		
		var onError = function () {
		};
		
		var list = listService.instantiate("ListHoppers");
		
		list.setParameter("domainID", canopySession.getActiveDomain().id);
		
		list.load().then(onSuccess, onError);
	};
	
	$scope.uploader = new FileUploader({    	
		url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.AddAssets",
		alias: "payload"
	});
	
	$scope.uploader.onBeforeUploadItem = function (item) {
		var args = {
			domainID: Luma.user.userID
		};
		
		if ($scope.hoppers.selected) {
			args.hopperID = $scope.hoppers.selected.id;
		}
		
		item.formData.push(args);
	};
	
	$scope.dismiss = function () {
		$uibModalInstance.close();
	};

	$scope.confirm = function () {
		if ($scope.isUploading) {
			return;
		}
		
		$scope.isUploading = true;
		
		$scope.uploader.onCompleteAll = function () {
			$scope.isUploading = false;
			
			$uibModalInstance.close();
			
			if (args.callback) {
				args.callback();
			}
		};
							
		$scope.uploader.uploadAll();
	};
	
	init();
});

canopy.controller("updateAssetModalController", function ($scope, args, $uibModalInstance, FileUploader) {
	var init = function () {
		$scope.increments = {
			available: [
				{ value: "Micro" },
				{ value: "Minor" },
				{ value: "Major" }
			],
			selected: null
		};
		
		$scope.increments.selected = $scope.increments.available[0];
	};
	
	$scope.uploader = new FileUploader({    	
		url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.AddAtom",
		alias: "payload",
		queueLimit: 1
	});
	
	$scope.uploader.onBeforeUploadItem = function (item) {
		item.formData.push({
			assetID: args.asset.id || args.asset,
//			increment: $scope.increments.selected.value,
			increment: "Major",
			autopromote: true
		});
	};
	
	$scope.dismiss = function () {
		$uibModalInstance.close();
	};

	$scope.confirm = function () {
		if ($scope.isUploading) {
			return;
		}
		
		$scope.isUploading = true;
		
		$scope.uploader.onCompleteItem = function (item, response) {
			$scope.isUploading = false;
			$uibModalInstance.close();
			
			var atomID = null;
			
			try {
				var element = document.createElement("html");
				element.innerHTML = response;
				atomID = jQuery(element).find("#response")[0].innerHTML;
			}
			catch (e) {
			}
			
			if (args.callback) {
				args.callback({
					atomID: atomID,
					size: item.file.size
				});
			}
		};
							
		$scope.uploader.uploadAll();
	};
	
	init();
});

canopy.controller("setAssetAvailabilityModalController", function ($scope, args, $uibModalInstance, listService, assets) {
	var init = function () {
		var onSuccess = function (response) {
			if (response.data) {
				var window = response.data[0];
				
				$scope.availability = {
					start: window.start || null,
					end: window.end || null,
					expires: window.end ? true : false
				};
			}
		};
		
		if (args.asset) {
			var list = listService.instantiate("ListAssetAvailability");
			
			list.setParameter("assetID", args.asset.id || args.asset);
			
			list.load().then(onSuccess);
		}
	};
	
	$scope.dismiss = function () {
		$uibModalInstance.close();
	};

	$scope.confirm = function () {
		if ($scope.isSubmitting) {
			return;
		}
		
		$scope.$broadcast("submit");
		
		if ($scope.availability.expires && !$scope.availability.end) {
			return;
		}
		
		var assetID = args.asset.id || args.asset;
		
		if (assetID) {
			var ononAvailabilitySet = function () {
				$scope.isSubmitting = false;
			
				if (args.callback) {
					args.callback();
				}
				
				$uibModalInstance.close();
			};
			
			var onAvailabilityRevoked = function () {
				assets.setAssetAvailability(assetID, $scope.availability.start, $scope.availability.end).then(ononAvailabilitySet);
			};
		
			assets.revokeAssetAvailability(assetID).then(onAvailabilityRevoked);
		
			$scope.isSubmitting = true;
		}
	};
	
	$scope.$watch("availability.expires", function (expires) {
		if (!expires) {
			$scope.availability.end = null;	
		}
	});
	
	init();
});