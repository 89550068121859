canopy.directive("canopyClassifier", function () {
	return {
		controller: function ($scope) {    
			$scope.onKeywordSelected = function (genus, keyword) {
				if (genus.type == "Static") {
					return;
				}
		
				if (genus.cardinality == "Singular") {
					genus.keywords.forEach(function (element) {
						element.selected = false;
					});
			
					keyword.selected = true;
				}
				else {
					keyword.selected = !keyword.selected;
				}
			};
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/classifier/classifier.html",
		scope: {
			taxonomy: "="
		},
		replace: true
	};
});