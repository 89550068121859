canopy.filter("keywordTypeaheadFilter", function() {
    return function (options, text) {
        var filtered = [];
        
        text = text.toLowerCase();
        
        angular.forEach(options, function (option) {
            if (option.name.toLowerCase().indexOf(text) != -1) {
                filtered.push(option);
            }
        });
        
        filtered.push({
        	name: text,
        	text: true
        });
        
        return filtered;
    };
});

canopy.controller("AssetListTypeaheadController", function ($controller, $scope, $timeout, assets, listService, basket, shares, lumaPaths, $location, $state, $stateParams, events, canopySession, menuNode) {
	"use strict";
	$controller("ListController", {
		$scope:    $scope,
		$timeout:  $timeout,
		lumaPaths: lumaPaths,
		menuNode:  menuNode
	});
	
	$scope.user = Luma.user;
    $scope.menuNode = menuNode;
	
	$scope.grid = {
    	format: "small"
    };
    
    $scope.typeahead = {
		model: null,
		options: [],
		onSelect: function (item, model, label) {
			if (item.id) {
				var keywordFilter = $scope.list.getFilter("keywordFilter");
				
				if (keywordFilter) {
					$scope.list.toggleFilter(keywordFilter, item);
				}
				
				$scope.typeahead.model = null;
				
				$scope.load();
			}
			
			if (item.text) {
				var searchTerms = item.name.split(" ");
				
				searchTerms.forEach(function (element) {
					// $scope.addParam("search", element);
					$scope.list.toggleFilter("searchFilter", { value: element, flags: ["Name", "Field", "Metadata", "Fuzzy"] });
				});

				$scope.typeahead.model = null;					
				$scope.load();
			}
			
			$scope.typeahead.search = null;
		}
	};
	
	$scope.filterPanel = {};
	
	try {
		$scope.filterPanel.isCollapsed = !menuNode.metadata.page.list["expand-filters"];
	}
	catch (e) {	
		$scope.filterPanel.isCollapsed = true;
	}
	
	$scope.onFilterPanelToggled = function () {
		$scope.filterPanel.isCollapsed = !$scope.filterPanel.isCollapsed;
	};
    
    $scope.$watch("filters", function (filters) {    	
    	var keywordFilter = filters.find(function (filter) {
    		return filter.name === "keywordFilter";
    	});
    	
    	if (keywordFilter) {
	    	$scope.typeahead.options = [];
	    	
	    	keywordFilter.taxonomy.forEach(function (genus) {
	    		if (genus.type == "Public") {
					genus.keywords.forEach(function (keyword) {
						$scope.typeahead.options.push({
							id: keyword.id,
							name: keyword.name,
							genus: {
								id: genus.genusID,
								name: genus.name,
								alias: genus.alias
							}
						});
					});
				}
	    	});
	    }
    });
    
    $scope.initList = function () {
    	if ($scope.list == undefined) {
    		return;
    	}
    	
    	var domain = canopySession.getActiveDomain();
		var node   = canopySession.getActiveNode();
		var page   = menuNode.metadata.page;
		
		var dateFilter = {
			name: "Date",
			type: "com.intrepia.luma.PeriodFilter",
			instant: "Created",
			from: null,
			until: null
		};
		
		var keywordFilter = {
			name: "keywordFilter",
			type: "com.intrepia.luma.KeywordFilter",
			applied: [],
			ignoreDomains: true,
			ignoreApplied: false
		};
		
		var searchFilter = {
			name: "searchFilter",
			type: "com.intrepia.luma.AdvancedSearchFilter",
			applied: []
		};
		
		$scope.list.registerFilter(dateFilter);
		$scope.list.registerFilter(keywordFilter);
		$scope.list.registerFilter(searchFilter);
		
		$scope.list.setService($scope.list.getService() || "ListAssets");
		
		$scope.list.setParameter("listing-sort", "asset-created");
		$scope.list.setParameter("listing-order", "Descending");
			
		switch ($scope.list.getService() ) {
			case "ListAssets":
				$scope.list.setParameter("domainID", domain.id);
				$scope.list.setParameter("inherit", true);
				$scope.list.setParameter("available", true);
				$scope.list.setParameter("status", true);
				$scope.list.setParameter("tree", null);
				break;
		}
	};
	
	$scope.initActions = function () {
		if ($scope.actions == undefined) {
			return;
		}
		
		var user = canopySession.getUser();

		if (user.hasService("application.basket")) {
			$scope.actions.push({
				title: "Add to Basket",
				icon: "fa-shopping-cart",
				execute: function () {
					basket.addAssets($scope.flattenSelection("id"));
				}
			});
		}

		if (user.hasService("application.assets.share")) {
			$scope.actions.push({
				title: "Add to Share",
				icon: "fa-share-alt",
				execute: function () {
					shares.quickShareAssets($scope.flattenSelection("id"));
				}
			});
		}
	};
	
	$scope.onViewDetails = function (asset) {
		$state.go("^.detail", {
			assetType: asset.type.toLowerCase(),
			assetID: asset.id
		});
	};
	
	$scope.$watch("results.table", function (table) {
		if (table) {
			$scope.grid.hasSelectable = table.find(function (asset) {
				return assets.utilities.isAssetSelectable(asset);
			});
		}
	});

	$scope.init();
});