"use strict";

canopy.service("htmlService", ["$sce", function ($sce) {
    function trustAsHtml(htmlString) {
        return $sce.trustAsHtml(htmlString);
    }

    function isProtectedTagName(tagName) {
        var strElements = "html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video";

        return strElements.indexOf(tagName) !== -1;
    }

    function replaceTrixRichTextBaseTextAttributes(htmlStr) {

        // Run the htmlStr through some regular expressions that turn <em>, <strong> and <del> elements to span equivalents with inline styling...
        var rxDel = /<del>/gi;
        var replaceDel = "<span style=\"text-decoration: line-through;\">";

        var rxStrong = /<strong>/gi;
        var replaceStrong = "<span style=\"font-weight: bold;\">";
 
        var rxEm = /<em>/gi;
        var replaceEm = "<span style=\"font-style: italic;\">";

        var rxEnd = /<\/strong>|<\/em>|<\/del>/gi;
        var replaceEnd = "</span>";

        var newHtmlStr = htmlStr
            .replace(rxDel, replaceDel)
            .replace(rxStrong, replaceStrong)
            .replace(rxEm, replaceEm)
            .replace(rxEnd, replaceEnd);

        // elNew is now a jqlite / jq object with above elements replaced...
        var elNew = angular.element("<div>" + newHtmlStr + "</div>");

       elNew
            .find("*")
            .contents()
            .filter(function() {
                return this.nodeType === Node.TEXT_ELEMENT;
            })
            .wrap("<span></span>");

        return elNew.html();
    }

    return {
        isProtectedTagName: isProtectedTagName,
        replaceTrixRichTextBaseTextAttributes: replaceTrixRichTextBaseTextAttributes,
        trustAsHtml: trustAsHtml
    };
}]);
