canopy.controller("ContentManagedPageController", function ($scope, menuNode, contentManagement, pageWidgets, pageVersion) {
	$scope.page = {
		widgets: []
	};
	
	menuNode.page = $scope.page;

	var onSuccess = function (response) {
		var widgets = pageWidgets.parseServerFragments(response.data.fragments);

		// Set current page revision to the currently 'active' revision
		pageVersion.setCurrentRevisionToActiveRevision(menuNode.id);

		$scope.page.widgets = widgets;
		
		menuNode.page = $scope.page;
	};
	
	if (menuNode["has-page"]) {
		contentManagement.getSiteMenuPage(menuNode.id).then(onSuccess);
	}
});