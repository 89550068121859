canopy.factory("contacts", ["$http", "lumaPaths", function ($http, lumaPaths) {
	var getContact = function (contactID) {
		return $http({
			url: lumaPaths.serviceURI + '.GetContact',
			method: 'POST',
			data: $.param({
				"contactID": contactID
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	var getContactAddress = function (address) {
		return $http({
			url: lumaPaths.serviceURI + '.GetContact$Address',
			method: 'POST',
			data: $.param({
				"addressID": address.id || address
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	var getContactNumber = function (number) {
		return $http({
			url: lumaPaths.serviceURI + '.GetContact$Number',
			method: 'POST',
			data: $.param({
				"numberID": number.id || number
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	var getContactEmail = function (email) {
		return $http({
			url: lumaPaths.serviceURI + '.GetContact$Email',
			method: 'POST',
			data: $.param({
				"emailID": email.id || email
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	var listContactAddresses = function (contact) {
		return $http({
			url: lumaPaths.serviceURI + '.ListContacts$Addresses',
			method: 'POST',
			data: $.param({
				"contactID": contact.id || contact
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	var listContactNumbers = function (contact) {
		return $http({
			url: lumaPaths.serviceURI + '.ListContacts$Numbers',
			method: 'POST',
			data: $.param({
				"contactID": contact.id || contact
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	var listContactEmails = function (contact) {
		return $http({
			url: lumaPaths.serviceURI + '.ListContacts$Emails',
			method: 'POST',
			data: $.param({
				"contactID": contact.id || contact
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	var listUserContacts = function (user) {
		return $http({
			url: lumaPaths.serviceURI + '.ListUserContacts',
			method: 'POST',
			data: $.param({
				"userID": user.id || user
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	return {
		getContact: function (contact) {
			return getContact(contact);
		},
		getContactAddress: function (address) {
			return getContactAddress(address);
		},
		getContactNumber: function (number) {
			return getContactNumber(number);
		},
		getContactEmail: function (email) {
			return getContactEmail(email);
		},
		listContactAddresses: function (contact) {
			return listContactAddresses(contact);
		},
		listContactNumbers: function (contact) {
			return listContactNumbers(contact);
		},
		listContactEmails: function (contact) {
			return listContactEmails(contact);
		},
		listUserContacts: function (user) {
			return listUserContacts(user);
		}
	}
}]);