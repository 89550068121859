canopy.controller("ProjectDetailController", function ($state, $scope, $stateParams, $location, menuNode, lumaPaths, projects, assets, budgets, reimbursements, campaigns, listService, $window, canopyModals, canopySession, canopyEvents, $timeout, $filter) {
	// TODO: Find a better way to handle keeping track of forms.
	$scope.forms = [];
	
	var ACTION_EDIT_TRANSACTION_VALUE = "Edit transaction value";
	var ACTION_CLOSE_TRANSACTION = "Close transaction";
	var ACTION_REOPEN_TRANSACTION = "Reopen transaction";
	var ACTION_ADD_REIMBURSEMENT = "Add reimbursement";
	var ACTION_MARK_AS_STATE = function(state) {
		return "Mark as " + state.toLowerCase()
	}
	var ACTION_DOWNLOAD_ASSETS = "Download assets";	
	
	var STATE_APPROVED = "Approved";
	var STATE_CANCELLED = "Cancelled";
	var STATE_COMPLETED = "Completed";
	var STATE_DISPATCHED = "Dispatched";
	var STATE_NEW = "New";
	var STATE_PROCESSED = "Processed";
	var STATE_REJECTED = "Rejected";
	var STATE_UNAPPROVED = "Unapproved";
	var STATE_CONFIRMED = "Confirmed";

	var userHasRole = function (role) {
		return $scope.roles.indexOf(role) >= 0;
	};
	
	var initProject = function () {
		$scope.isInit = true;
		$scope.roles = null;
		$scope.canSave = false;
		$scope.canEdit = false;
		
		var user = canopySession.getUser();
		
		$scope.canCreateReimbursements  = user.hasService("budget.reimbursement.creator");
		$scope.canApproveReimbursements = user.hasService("budget.reimbursement.approver");
		$scope.canProcessReimbursements = user.hasService("budget.reimbursement.payment-processor");
		$scope.canCloseTransactions     = user.hasService("budget.transaction.close");
		$scope.canReopenTransactions    = user.hasService("budget.transaction.reopen");
		
		var detail = null;
		var list   = null;
		
		try {
		    detail = menuNode.metadata.page.detail;
		    list   = menuNode.metadata.page.list;
	    }
	    catch (e) {
	    }
	    
	    if (detail && detail.roles) {
	    	$scope.roles = detail.roles;
	    }
	    else if (list && list.parameters && list.parameters.role) {
	    	$scope.roles = [ list.parameters.role ];
	    }
	    else {
	    	$scope.roles = [ "Orderer" ];
	    }
	    
		$scope.canTransitionAssets = userHasRole("Approver");
		
		var onSuccess = function (response) {
			$scope.project = response.data;
			
			initProjectAssetInfo();
			initProjectAssociates();
			initProjectAssets();
			
			initProjectTransactions().then(function () {
				initProjectActivity();
			});
			
			initProjectAvailability();
			initActions();
			initHoppers();
			
			if ($scope.project.campaignID) {
				initProjectCampaign();
			}
			
			$scope.isInit = false;
			$scope.canEdit = (userHasRole("Orderer") && $scope.project.state == STATE_NEW) || 
							 (userHasRole("Printer") && $scope.project.state == STATE_APPROVED) ||
							 (userHasRole("Printer") && $scope.project.state == STATE_COMPLETED) ||
							 (userHasRole("Printer") && $scope.project.state == STATE_PROCESSED) ||
							 (userHasRole("Printer") && $scope.project.state == STATE_DISPATCHED);
		};
		
		projects.getProject($stateParams.projectID).then(onSuccess);
	};
	
	var initProjectAssetInfo = function () {
		var onSuccess = function (response) {
			$scope.project.asset.info = response.data;
		};
		
		assets.getAssetInfo($stateParams.projectID).then(onSuccess);
	};
	
	var initProjectAssociates = function () {
		var onSuccess = function (response) {
			$scope.project.associates = response.data;
		};
		
		assets.listAssetAssociates($stateParams.projectID).then(onSuccess);
	};
	
	var initProjectAssets = function () {
		$scope.projectAssetList = listService.instantiate("ListProjectContent");
		$scope.projectAssetList.setParameter("listing-style", "Page");
		$scope.projectAssetList.setParameter("projectID", $stateParams.projectID);
		
		var keywordFilter = {
			name: "keywordFilter",
			type: "com.intrepia.luma.KeywordFilter"
		};
		
		var searchFilter = {
			name: "searchFilter",
			type: "com.intrepia.luma.AdvancedSearchFilter",
			applied: []
		};
		
		$scope.projectAssetList.registerFilter(keywordFilter);
		$scope.projectAssetList.registerFilter(searchFilter);
		
		loadProjectAssets();
	};
	
	var flattenTaxonomy = function (taxonomy) {		
		var keywords = [];
		
		taxonomy.forEach(function (domain) {
			domain.genera.forEach(function (genus) {
				genus.keywords.forEach(function (keyword) {
					keywords.push({
						id: keyword.id,
						name: keyword.name,
						applied: keyword.applied,
	    				genus: {
	    					id: genus.genusID,
	    					name: genus.name,
	    					alias: genus.alias
	    				}
					});
				});
			});
		});
		
		return keywords;
	};

	$scope.$watch("project.assets.filters", function (filters) {
		if (filters) {
				
			$scope.typeahead = {
				model: null,
				options: [],
				applied: [],
				clauses: [],
				onSelect: function (item, model, label) {
					if (item.id) {
						$scope.toggleProjectAssetFilter(model);
		
						$scope.typeahead.model = null;
					}
	
					if (item.text) {
						var searchTerms = item.name.split(" ");
		
						searchTerms.forEach(function (element) {
							$scope.projectAssetList.toggleFilter("searchFilter", { value: element, flags: ["Name", "Field", "Metadata", "Fuzzy"] });
						});

						$scope.typeahead.model = null;					
						loadProjectAssets();
					}
	
					$scope.typeahead.search = null;
				}
			};
				
			var keywordFilter = filters.find(function (filter) {
				return filter.name == "keywordFilter";
			});
		
			if (keywordFilter) {
				var appliedFilter = function (keyword) {
					return keyword.applied;
				};
				
				var keywords = flattenTaxonomy(keywordFilter.taxonomy);
				
				$scope.typeahead.options = keywords;
				$scope.typeahead.applied = keywords.filter(appliedFilter);
			}
			
			var searchFilter = filters.find(function (filter) {
				return filter.name == "searchFilter";
			});
		
			if (searchFilter) {
				$scope.typeahead.clauses = searchFilter.clauses;
			}
		}
	});
	
	$scope.toggleProjectAssetFilter = function (keyword) {		
		var keywordFilter = $scope.projectAssetList.getFilter("keywordFilter");

		if (keywordFilter) {
			$scope.projectAssetList.toggleFilter(keywordFilter, keyword);
		}
							
		loadProjectAssets();
	};
	
	$scope.toggleProjectAssetClause = function (clause) {
		var searchFilter = $scope.projectAssetList.getFilter("searchFilter");

		if (searchFilter) {
			$scope.projectAssetList.toggleFilter(searchFilter, clause);
		}
							
		loadProjectAssets();
	};
	
	var processAssetComments = function (asset, response) {
		var comments = response.data.reverse();
		var grouped  = {};
		
		comments.forEach(function (comment) {
			if (grouped[comment.atomID]) {
				grouped[comment.atomID].comments.push(comment);
			}
			else {
				grouped[comment.atomID] = {
					atomID: comment.atomID,
					revision: comment.revision,
					comments: [
						comment
					]
				};
			}
		});
		
		var open = false;
		
		if (asset.comments) {
			open = asset.comments.open;
		}
		
		asset.comments = [];
		
		asset.comments.open = open;
		
		for (var atomID in grouped) {
			asset.comments.unshift(grouped[atomID]);
		}
		
		asset.comments.total = comments.length;
	};
	
	var loadProjectAssets = function () {
		var onSuccess = function (response) {
			$scope.project.assets = response.data.table;
			$scope.project.assets.filters = response.data.filters;
			
			$scope.project.assets.forEach(function (asset) {
				assets.listAssetComments(asset.id).then(function (response) {
					processAssetComments(asset, response);
				});
				
				asset.comment = {
					title: "",
					body: "",
					subscribe: false
				};
				
				assets.getAssetTaxonomy(asset.id).then(function (response) {
					asset.keywords = flattenTaxonomy(response.data);
				});
			});
		};
		
		if ($scope.projectAssetList) {
			$scope.projectAssetList.load().then(onSuccess);
		}
	};
	
	$scope.toggleAssetFilterPanel = function () {
		canopyModals.instantiate(
			canopyModals.definitions.keywordFilter($scope.projectAssetList, $scope.project.filters, $scope.magicCallbacks, $scope)
		);
	};
	
	var initProjectComments = function () {
		var onSuccess = function (response) {
			$scope.project.comments = response.data;
		};
		
		assets.listAssetComments($stateParams.projectID).then(onSuccess);
	};
	
	var initProjectHistory = function () {
		var onSuccess = function (response) {
			$scope.project.history = response.data;
		};
		
		projects.listProjectHistory($stateParams.projectID).then(onSuccess);
	};
	
	var initProjectActivity = function () {
		var onSuccess = function (response) {
			var limit = 4;
			
			if ($scope.project.activity) {
				limit = $scope.project.activity.viewLimit || limit;
			}
			
			$scope.project.activity = response;
			$scope.project.activity.viewLimit = limit;
		};
		
		projects.listProjectActivity($scope.project, $scope.project.transaction).then(onSuccess);
		
		$scope.comment = {
			title: undefined,
			body: ""
		};
	};
	
	var initProjectTransactions = function () {
		var onSuccess = function (response) {
			$scope.project.transactions = response.data;
			
			var transactionStateFilter = function (state) {
				return function (element) {
					if (state) {
						return element.state == state;
					}
					else {
						return true;
					}
				};
			};
			
			var transactionReducer = function (acc, element) {
				if (element.value) {
					acc = parseFloat(acc) + parseFloat(element.value);
				}
				
				return acc;
			};
			
			$scope.project.transactionTotals = {
				all: $scope.project.transactions.reduce(transactionReducer, 0) * -1,
				confirmed: $scope.project.transactions.filter(transactionStateFilter("Confirmed")).reduce(transactionReducer, 0) * -1
			};
			
			$scope.project.transactions.forEach(function (transaction) {
				transaction.ui = {
					expanded: false
				};
				
				transaction.actions = [{
					label: ACTION_EDIT_TRANSACTION_VALUE,
					execute: function () {
						var onComplete = function () {
							canopyEvents.dispatch("genericEvent", {
								message: "The transaction value has been updated."
							});
							
							initProjectActivity();
						};
		
						budgets.utilities.editBudgetTransaction(transaction, onComplete);
					},
					permitted: function () {
						return ((userHasRole("Orderer")  && transaction.state == "Pending") ||
								(userHasRole("Approver") && transaction.state == "Pending") ||
								(userHasRole("Approver") && transaction.state == "Confirmed") ||
								(userHasRole("Printer")  && transaction.state == "Pending") ||
								(userHasRole("Printer")  && transaction.state == "Confirmed"));
					}
				}, {
					label: ACTION_CLOSE_TRANSACTION,
					execute: function () {
						var onComplete = function () {
							initActions();
							initProjectActivity();
						};
		
						budgets.utilities.closeBudgetTransaction(transaction, onComplete);
					},
					permitted: function () {
						return  transaction.reimbursable &&
								transaction.state == "Confirmed" &&
								$scope.canCloseTransactions;
					}
				}, {
					label: ACTION_REOPEN_TRANSACTION,
					execute: function () {
						var onComplete = function () {
							initActions();
							initProjectActivity();
						};
		
						budgets.utilities.reopenBudgetTransaction(transaction, onComplete);
					},
					permitted: function () {
						return  transaction.reimbursable &&
								transaction.state == "Closed" &&
								$scope.canReopenTransactions;
					}
				}, {
					label: ACTION_ADD_REIMBURSEMENT,
					execute: function () {
						var onComplete = function () {
							loadTransactionReimbursements(transaction);
						};
		
						reimbursements.utilities.createReimbursement(transaction, onComplete);
					},
					permitted: function () {
						return  transaction.reimbursable &&
								transaction.state == "Confirmed" &&
								$scope.canCreateReimbursements;
					}
				}];
				
				transaction.actions = transaction.actions.filter(function (action) {
					return action.permitted();
				});
			});
		};
		
		var list = listService.instantiate("ListProjectTransactions");
		
		list.setParameter("projectID", $scope.project.id);
		
		return list.load().then(onSuccess);
	};
	
	var initProjectTransactionReimbursements = function () {
		if ($scope.project.transaction == undefined) {
			return;
		}
		
		var onSuccess = function (response) {
			$scope.project.transaction.reimbursements = response.data;
		};
		
		reimbursements.listBudgetReimbursementsForTransaction($scope.project.transaction).then(onSuccess);
	};
	
	var initProjectAvailability = function () {
		var onSuccess = function (response) {
			$scope.project.availability = response.data;
		};

		assets.listAssetAvailability($scope.project.id).then(onSuccess);
	};
	
	var updateProjectState = function (state, description, timeWorking) {
		var onSuccess = function () {
			$scope.init();
		};
		
		var onError = function (response) {
			canopyModals.instantiate(
				canopyModals.definitions.serverError(response.data)
			);
		};
		
		projects.setProjectState($scope.project.id, state, description, timeWorking).then(onSuccess, onError);
	};
	
	var initActions = function () {
		var createUpdateStateAction = function (state, permitted) {		
			return {
				label: ACTION_MARK_AS_STATE(state),
				execute: function () {
					var initialValues = { };
					
					var lastActivity = $scope.project.activity.find(function (element) {
						return element.type == "Progress";
					});
					
					if (lastActivity) {
						var then = new moment(lastActivity.date);
						var now  = new moment();
					
						var duration = moment.duration(now.diff(then));
						
						initialValues.timeWorking = duration.asHours().toFixed(1);
					}
					
					var modal = canopyModals.definitions.form({
						header: $filter("localise")("Mark as " + state.toLowerCase()),
						form: Luma.paths.context + "/resolve/marquee/site/forms/set-state.html",
						values: initialValues,
						fields: {
							workingTime: true
						}
					});
					
					canopyModals.instantiate(modal).result.then(function (form) {
						var secondsWorking = parseFloat(form.timeWorking) * 3600.0;
						
						updateProjectState(state, form.comment, secondsWorking);
					});
				},
				permitted: permitted
			};
		};
		
		var downloadAction = {
			label: ACTION_DOWNLOAD_ASSETS,
			execute: function () {
				var assetIDs = $scope.project.assets.map(function (element) {
					return element.id;
				});
				
				if (assetIDs.length) {
					window.location = Luma.paths.context + "/servlet/interface/com.intrepia.luma.ShrinkWrapAssets?assetIDs=" + assetIDs.toString();
				}
			},
			permitted: function () {
				return true;
			}
		};
		
		var cancelAction = createUpdateStateAction(STATE_CANCELLED, function () {
			return userHasRole("Orderer") &&
				   $scope.project.state != STATE_CANCELLED &&
				   $scope.project.state != STATE_COMPLETED;
					
		});
		var newAction = createUpdateStateAction(STATE_NEW, function () {
			return (userHasRole("Orderer") && $scope.project.state == STATE_REJECTED) || userHasRole("Approver");
		});
		var unapproveAction = createUpdateStateAction(STATE_UNAPPROVED, function () {
			return (userHasRole("Orderer") && $scope.project.state == STATE_NEW) ||
	    		   (userHasRole("Approver") && $scope.project.state == STATE_APPROVED) ||
	    		   (userHasRole("Approver") && $scope.project.state == STATE_REJECTED);
		});
		var approveAction = createUpdateStateAction(STATE_APPROVED, function () {
			return (userHasRole("Approver") && $scope.project.state == STATE_UNAPPROVED) ||
				   (userHasRole("Approver") && $scope.project.state == STATE_REJECTED);
		});
		var rejectAction = createUpdateStateAction(STATE_REJECTED, function () {
			return (userHasRole("Approver") && $scope.project.state == STATE_UNAPPROVED) || 
				   (userHasRole("Approver") && $scope.project.state == STATE_APPROVED) || 
				   (userHasRole("Printer")  && $scope.project.state == STATE_APPROVED) || 
				   (userHasRole("Printer")  && $scope.project.state == STATE_PROCESSED) ||
				   (userHasRole("Printer")  && $scope.project.state == STATE_DISPATCHED);
		});
		var completeAction = createUpdateStateAction(STATE_COMPLETED, function () {
			return (userHasRole("Printer") && $scope.project.state == STATE_APPROVED) || 
				   (userHasRole("Printer") && $scope.project.state == STATE_PROCESSED) ||
				   (userHasRole("Printer") && $scope.project.state == STATE_DISPATCHED);
		});
		var processAction = createUpdateStateAction(STATE_PROCESSED, function () {
			return (userHasRole("Printer") && $scope.project.state == STATE_APPROVED) || 
				   (userHasRole("Printer") && $scope.project.state == STATE_COMPLETED) ||
				   (userHasRole("Printer") && $scope.project.state == STATE_DISPATCHED);
		});
		var dispatchAction = createUpdateStateAction(STATE_DISPATCHED, function () {
			return (userHasRole("Printer") && $scope.project.state == STATE_APPROVED) || 
				   (userHasRole("Printer") && $scope.project.state == STATE_COMPLETED) ||
				   (userHasRole("Printer") && $scope.project.state == STATE_PROCESSED);
		});

		var addAssetAction = {
			label: "Add assets",
			execute: function () {				
				var args = {
					service: "AddProjectAssets?projectID=" + $scope.project.id + "&bucket=Working",
					alias: "payload",
					hoppers: $scope.ancillary.hoppers
				};
				
				var modal = canopyModals.definitions.fileUpload(args);
				
				canopyModals.instantiate(modal).result.then(function (form) {
					initProjectAssets();
				});
			},
			permitted: function () {
				return true;
			}
		};
		
		var setAvailabilityAction = {
			label: "Set availability",
			execute: function () {
				var onComplete = function () {
					initProjectAvailability();
				};
				
				assets.utilities.setAssetAvailability($scope.project, onComplete);
			},
			permitted: function () {
				return $scope.project.state == STATE_NEW;
			}
		};
		
		var revokeAvailabilityAction = {
			label: "Revoke availability",
			execute: function () {
				var onSuccess = function () {
					initProjectAvailability();
				};
				
				assets.revokeAssetAvailability($scope.project).then(onSuccess);
			},
			permitted: function () {
				return $scope.project.state == STATE_NEW;
			}
		};
		
		var manageSubscribtionsAction = {
			label: "Manage subscriptions",
			execute: function () {
				var onComplete = function (subscriptions) {
					var subscribed = subscriptions.filter(function (element) {
						return element.subscribed;
					});
					
					$scope.project.asset.info.subscribed = subscribed.length;
				};
				
				var asset = {
					id: $scope.project.id,
					type: "Project"
				};
				
				assets.utilities.manageAssetSubscriptions(asset, onComplete);
			},
			permitted: function () {
				return true;
			}
		};
		
		$scope.actions = [{
			label: "Use",
			actions: [
				downloadAction,
				manageSubscribtionsAction
			]
		}, {
			label: "Edit",
			actions: [
				addAssetAction,
				setAvailabilityAction,
				revokeAvailabilityAction
			]
		}, {
			label: "Set state",
			actions: [
				newAction,
				unapproveAction,
				approveAction,
				rejectAction,
				completeAction,
				processAction,
				dispatchAction,
				cancelAction
			]
		}];
		
		$scope.actions.forEach(function (category) {
			category.actions = category.actions.filter(function (action) {
				return action.permitted();
			});
		});
	};
	
	var initHoppers = function () {		
		var hopperUUIDs = [];
			
		try {
			hopperUUIDs = $scope.project.process.metadata.hoppers;
		}
		catch (e) {
			hopperUUIDs = [];
		}
		
		var handleResult = function (result) {
			$scope.ancillary.hoppers = result.data;
			
			if (hopperUUIDs) {
				var hopperFilter = function (hopper) {
					return hopperUUIDs.indexOf(hopper.uuid) >= 0;
				};
				
				$scope.ancillary.hoppers = $scope.ancillary.hoppers.filter(hopperFilter);
			}
		};
		
		if (hopperUUIDs && hopperUUIDs.length) {
			var hopperList = listService.instantiate("ListHoppersForDomain");
		
			hopperList.setParameter("domainID", canopySession.getActiveDomain().id );
			hopperList.setParameter("ignoreDomains", true );
		
			hopperList.load().then(handleResult);
		}
	};
	
	var initProjectCampaign = function () {
		var onSuccess = function (response) {
			$scope.project.campaign = response.data;
		};
		
		var onError = function (response) {
		};
		
		campaigns.getCampaign($scope.project.campaignID).then(onSuccess, onError);
	};
	
	$scope.hasReimbursableTransactions = function() {
		return $scope.project.transactions.some(function(transaction) {
			return transaction.actions.some(function(action) {
				return action.label === ACTION_ADD_REIMBURSEMENT && transaction.state === STATE_CONFIRMED;
			});
		});
	}

	$scope.hasCloseableTransactions = function() {
		return $scope.project.transactions.some(function(transaction) {
			return transaction.actions.some(function(action) {
				return action.label === ACTION_CLOSE_TRANSACTION;
			});
		});
	}

	$scope.hasEditableTransactions = function() {
		return $scope.project.transactions.some(function(transaction) {
			return transaction.actions.some(function(action) {
				return action.label === ACTION_EDIT_TRANSACTION_VALUE;
			});
		});
	}

	$scope.hasReopenableTransactions = function() {
		return $scope.project.transactions.some(function(transaction) {
			return transaction.actions.some(function(action) {
				return action.label === ACTION_REOPEN_TRANSACTION;
			});
		});
	}

	$scope.bulkTransactionsActionClickHandler = function(e, fnAction) {
		e.preventDefault();
		fnAction();
	}

	$scope.bulkActionCloseTransations = function() {
		var onComplete = function(data) {
			$state.reload();
		}

		budgets.utilities.closeBudgetTransactions($scope.project.transactions, onComplete);
	}

	$scope.bulkActionReimburseTransactions = function() {
		var onComplete = function (transactions) {
			transactions.forEach(function(transaction) {
				loadTransactionReimbursements(transaction);
			})	
		};

		reimbursements.utilities.createReimbursements($scope.project.transactions, onComplete);
	}

	$scope.goToReimbursement = function(reimbursement) {
		$state.go("approver_reimbursement", {
			reimbursementID: reimbursement.id
		});
	}

	$scope.init = function () {
		initProject();
		
		$scope.ancillary = {
		};
	};
	
	$scope.registerForm = function (form, name) {
		var onDirtyStateChanged = function (dirty) {
			if (dirty) {
				$scope.canSave = true;
			}
		};
		
		$scope.$watch(function () {
			return form[name].$dirty;
		}, onDirtyStateChanged, true);
		
		$timeout(function () {
			$scope.forms.push(form[name]);
		}, 0);
	};
	
	$scope.submitComment = function () {
		var onSuccess = function () {
			initProjectActivity();
		};
		
		assets.addAssetComment($scope.project.id, $scope.comment.title, $scope.comment.body).then(onSuccess);
	};
	
	$scope.submitAssetComment = function (asset) {
		var onSuccess = function () {
			assets.listAssetComments(asset.id).then(function (response) {
				processAssetComments(asset, response);
			});
			
			asset.comment = {
				title: "",
				body: "",
				subscribe: false
			};
		};
		
		var onSubscribeSuccess = function () {
			asset.subscribed = true;
		};
		
		assets.addAssetComment(asset.id, asset.comment.title, asset.comment.body).then(onSuccess);
		assets.editAssetSubscriptions(asset.id, "Comment", "Add").then(onSubscribedSuccess);
	};
	
	$scope.onSave = function () {
		var onSuccess = function () {
			$scope.init();
			
			$scope.forms.forEach(function (form) {
				form.$setPristine();
			});
		};
		
		var onError = function () {
		};
		
		projects.setProjectMetadata($scope.project).then(onSuccess, onError);
	};
	
	$scope.onAssetDelete = function (asset) {
		var onSuccess = function () {
			initProjectAssets();
		};
		
		var onError = function () {
		};
		
		assets.deleteAsset(asset.id).then(onSuccess, onError);
	};
	
	$scope.onAssetUpdate = function (asset) {
		var onComplete = function (response) {
			asset.atomID = response.atomID;
			asset.size   = response.size;
			
			canopyEvents.dispatch("genericEvent", {
				message: "A new version of " + asset.name + " has been uploaded."
			});
		};
		
		assets.utilities.updateAsset(asset, onComplete);
	};
	
	$scope.onAssetManageSubscriptions = function (asset) {
		var onComplete = function (subscriptions) {
			var subscribed = subscriptions.filter(function (element) {
				return element.subscribed;
			});
			
			asset.subscribed = subscribed.length;
		};
		
		assets.utilities.manageAssetSubscriptions({
			id: asset.id,
			type: "asset"
		}, onComplete);
	};
	
	$scope.onAssetTransition = function (asset, bucket) {
		var onComplete = function () {
			asset.bucket = bucket;
			
			canopyEvents.dispatch("genericEvent", {
				message: asset.name + " state successfully changed."
			});		
		};
		
		projects.setProjectContentBucket($scope.project.id, asset.id, bucket).then(onComplete);
	};
	
	var loadTransactionReimbursements = function (transaction) {
		var onSuccess = function (response) {
			transaction.reimbursements = response.data;
		};
		
		reimbursements.listBudgetReimbursementsForTransaction(transaction).then(onSuccess);
	};
	
	$scope.onTransactionExpanded = function (transaction) {
		transaction.ui.expanded = !transaction.ui.expanded;
		
		if (transaction.reimbursements == undefined) {
			loadTransactionReimbursements(transaction);
		}
	};
	
	$scope.$watch("project.state", function (state) {
		$scope.context = state == STATE_NEW ? "edit" : "read";
	});
	
	$scope.init();
});