canopy.controller("ListController", function ($scope, $timeout, $location, lumaPaths, utilities, $state, canopySession, canopyModals, menuNode, listService) {
	$scope.init = function () {
		var service    = null;
		var parameters = {};
		
		$scope.list    = listService.instantiate();
		$scope.actions = [];
		
		if (menuNode) {
			$scope.menuNode = menuNode;

			var page = menuNode.metadata.page;
			
			if (page && page.list) {
				var service    = page.list.service;
				var parameters = page.list.parameters;
			}
		}
		
		if (service) {
			$scope.list.setService(service);
		}
		
		$scope.list.setParameter("listing-style", "Page");
		$scope.list.setParameter("listing-limit", 40);

		$scope.initList();
		$scope.initFilters();
		$scope.initSearch();
		$scope.initActions();
				
		if (parameters instanceof Object) {
			for (var key in parameters) {
				var value = parameters[key];
				
				switch (value) {
					case "__domainID__":
						value = canopySession.getActiveDomain().id;
						break;
						
					case "__siteID__":
						value = canopySession.getActiveSite().id;
						break;
						
					case "__userID__":
						value = Luma.user.userID;
						break;
						
					case "__userDomainID__":
						value = Luma.user.domainID;
						break;
						
					default:
						break;
				}
				
				$scope.list.setParameter(key, value);
			}
		}
		
		var urlParameters = $location.search();
		
		for (var key in urlParameters) {
			if (key == "filters") {
				continue;
			}
			
			var searchFinder = function (element) {
				return element.property == key;
			};
			
			var search = $scope.search.types.find(searchFinder);
			
			if (search) {
				$scope.search.types.selected = search;
				$scope.search.value = urlParameters[key];
			}
			
			$scope.list.setParameter(key, urlParameters[key]);
		}
 	
		$scope.busy = false;
		$scope.results = {};
		$scope.errors  = {};
		
		if ($scope.list) {
			try {
				$scope.load();
			}
			catch (e) {
				console.log(e);
			}
		}
		
		$scope.selection = [];
	};
	
	var timeoutBusyState = function (isBusy) {
		$timeout( function () {
			$scope.busy = isBusy;
		}, 0);
	};
	
	var applyFilters = function (filters) {
		filters.forEach(function (filter) {
			if (!filter) {
				return;
			}
			
			if (filter.name === undefined || filter.applied === undefined) {
				return;
			}
			
			filter.applied.forEach(function (option) {
				$scope.list.toggleFilter(filter, option);
			});
		});
	};
	
	$scope.processMetadata = function () {
		var pageFilters = [];
		
		if ($scope.page && $scope.page.metadata && $scope.page.metadata.listFilters) {
			pageFilters = $scope.page.metadata.listFilters;
		}
		
		if (pageFilters.length) {
			applyFilters(pageFilters);
		}
	};
	
	$scope.load = function () {
		if ($scope.busy || $scope.list === undefined) {
			return;
		}
		
		$scope.busy = true;
		
		var onLoad = function (response) {
			$scope.processResponse(response.data);
			$scope.results = response.data;
			
			timeoutBusyState(false);
			$scope.onLoad(response.data);
		};
		
		var onLoadError = function (response) {
			timeoutBusyState(false);
			$scope.onLoadError(response.data);
		};
		
		$scope.selection = [];
		
		$scope.list.clearOffset();
		$scope.list.load().then(onLoad).catch(onLoadError);
		
		var state  = $scope.list.serialiseFilterState();
		
		$location.search("filters", state);
		
		$scope.search.types.forEach(function (type) {
			$location.search(type.property, null);
		});
		
		var searchProperty = $scope.search.types.selected.property;
		var searchValue    = $scope.search.value.length ? $scope.search.value : null;
		
		$location.search(searchProperty, searchValue);
	};
	
	$scope.loadMore = function () {
		if ($scope.busy || $scope.list === undefined) {
			return;
		}
		
		if ($scope.results && $scope.results.table && ($scope.results.table.length >= $scope.results.total)) {
			return;
		}
		
		$scope.busy = true;
		
		var onLoadMore = function (response) {
			$scope.processResponse(response.data);
		
			if ($scope.results.table) {
				$scope.results.table = $scope.results.table.concat(response.data.table || response.data);
			}
			else {
				$scope.results = $scope.results.concat(response.data.table || response.data);
			}
		
			timeoutBusyState(false);
			$scope.onLoadMore(response.data);
		};
		
		var onLoadMoreError = function (response) {
			timeoutBusyState(false);
			$scope.onLoadError(response.data);
		};
		
		$scope.list.loadMore().then(onLoadMore).catch(onLoadMoreError);
	};
	
 	$scope.toggleFilterOption = function (filter, option) {
 		$scope.list.toggleFilter(filter, option);
 		$scope.load();
 	};
 	
 	$scope.clearFilters = function () {
 		$scope.list.clearFilters();
 		$scope.load();
 	};
			
	var processFilters = function (data) {
		var filters = angular.extend(data);
		
		filters.forEach(function (filter) {
			if (filter.taxonomy) {
				filter.taxonomy.forEach(function (genus) {
					var applied = genus.keywords.filter(function (keyword) {
						return keyword.applied;
					});
					
					var available = genus.keywords.filter(function (keyword) {
						return !keyword.applied;
					});
					
					genus.appliedCount   = applied.length;
					genus.availableCount = available.length;
				});
			}
			else if (filter.applied && filter.applied.length > 0) {
				filter.applied.forEach(function (applied) {
					filter.options.forEach(function (option) {
						if (applied === option.name || applied === option.id) {
							option.applied = true;
						}
					});
				});
			}
			
			if (filter.taxonomy || filter.name === "Date" || (filter.options && filter.options.length == 0)) {
				filter.isHidden = true;
			}
		});
	
		return filters;
	};
	
	$scope.$watch("results.filters", function (filters) {
		if (filters) {
			$scope.filters = processFilters(filters);
			
			$scope.filterState = {
				data: $scope.filters,
				hasAppliedFilters: false,
				hasAvailableKeywordFilters: false,
				availableKeywordFilterCount: 0
			};
			
			$scope.filters.forEach(function (filter) {
				if (filter.taxonomy) {
					if (filter.keywords && filter.keywords.length) {
						$scope.filterState.hasAppliedFilters = true;
					}
					
					filter.taxonomy.forEach(function (genus) {
						if (genus.availableCount > 0) {
							$scope.filterState.hasAvailableKeywordFilters = true;
							$scope.filterState.availableKeywordFilterCount += 1;
						}
					});
				}
				if (filter.clauses && filter.clauses.length) {
					$scope.filterState.hasAppliedFilters = true;
				}
			});
		}
	}, true);
 	
 	$scope.$watch("listFilters.date.state", function (current, previous) {
 		if (current !== previous) {
 			var start = {
 				date: null,
 				unix: null
 			};
 			
 			var end = {
 				date: null,
 				unix: null
 			};
 			
 			if (current.start !== null) {
 				var date = utilities.date(current.start).startOf("day");
 				
 				if (date) {
					start.date = date.toDate();
					start.unix = date.toUnix();
				}
 			}
 			
 			if (current.end !== null) {
 				var date = utilities.date(current.end).endOf("day");
 				
 				if (date) {
					end.date = date.toDate();
					end.unix = date.toUnix();
				}
 			}
 			
 			$scope.list.setDateFilter(start.date, end.date, "Date");
 			$scope.load();
 		}
 		else {
 		}
 	}, true);
 	
 	$scope.$watch("listFilters.date.presets.selected", function (selected, previous) {
 		if (selected && selected.callback) {
 			selected.callback($scope.listFilters.date.state);
 		}
 	}, true);
 	
	$scope.initList = function (list) {
	};
	
	$scope.initFilters = function () {
		var dateFilter = $scope.list.getFilter("Date");
			
		$scope.filters = [];
		$scope.listFilters = {
			date: {
				state: {
					start: dateFilter ? dateFilter.from  : null,
					end:   dateFilter ? dateFilter.until : null
				}
			}
		};
		
		var filters = null;
		
		if ($location.search().filters) {
			try {
				filters = JSON.parse(atob($location.search().filters));
			}
			catch (e) {
			}
		}
		
		if (filters) {
			$scope.list.parseFilterState(filters);
			
			var dateFilter = filters.find(function (filter) {
				return filter.type && filter.type.indexOf("PeriodFilter") > 0;
			});
			
			if (dateFilter) {
				$scope.listFilters.date.state.start = dateFilter.from  || null;
				$scope.listFilters.date.state.end   = dateFilter.until || null;
			}
		}
	};
	
	$scope.initSearch = function () { 	
		$scope.search = {
			types: [],
			value: "",
			timeout: null
		};
 	
		$scope.search.types = $scope.initSearchTypes();
		
		if ($scope.search.types.length) {
			$scope.search.types.selected = $scope.search.types[0];
		}
		
		$scope.$watch("search.value", function (value, old) {
			if (value !== old) {
				$timeout.cancel($scope.search.timeout);
			
				$scope.search.timeout = $timeout(function () {
					$scope.list.setParameter($scope.search.types.selected.property, value);
					$scope.load();
				}, 500);
			} 	
		});
	};
	
	$scope.initSearchTypes = function () {
		return [{
			property: "search",
			title: "Name"
		}];
	};
	
	$scope.onSearchTypeSelected = function (type) {		
		$scope.search.types.selected = type;
		
		$scope.search.types.forEach(function (type) {
			$scope.list.setParameter(type.property, null);
		});
		
		if ($scope.search.value) {
			$scope.search.value = "";
		}
	};
	
	$scope.initActions = function () {
	};
	
	$scope.onLoad = function (data) {
	};
	
	$scope.onLoadError = function (data) {
	};
	
	$scope.onLoadMore = function (data) {
	};
	
	$scope.onLoadMoreError = function (data) {
	};
	
	$scope.processFilters = function (filters) {
	};
	
	$scope.processResponse = function (data) {
	};
	
	$scope.focusItem = function (item) {
		if ($scope.isDetailsServicePending) {
			return;
		}
		
		$scope.isDetailsServicePending = true;
		
		if ($scope.focussedItem === item) {
			$scope.focussedItem = null;
		}
		else {
			try {
				$scope.getDetailsService(item)
					.then(function (response) {
						$scope.focussedItem = response.data;
					}).then(function () {
						$scope.isDetailsServicePending = true;
					});
			}
			catch (e) {
				if (e.name === "TypeError") {
					console.log("TypeError: Details service likely not set.");
				}
				
				$scope.focussedItem = {};
			}
		}
	};
	
	var getActiveFilter = function () {
		var activeFilter = null;
		
		$scope.filters.forEach(function (element) {
			if (element.isActive === true) {
				activeFilter = element;
			}
		});
		
		return activeFilter;
	};
	
	$scope.toggleFilterActive = function (filter) {
		$scope.filters.forEach(function (element) {
			if ((element.title || element.name) === (filter.title || filter.name)) {
				element.isActive = !element.isActive;
			}
			else {
				if (element.isActive) {
					element.isActive = false;
				}
			}
		});
	};
	
	$scope.setDateFilter = function () {
		$scope.list.setDateFilter(from, to, "Date");
		$scope.load();
	};
    
    $scope.exportList = function () {
    	if ($scope.isExporting) {
    		return;
    	}
    	
    	$scope.isExporting = true;
    	
    	$scope.list.exportList()
    		.then( function (response) {
				if (response.data) {
					window.location.assign(lumaPaths.serviceURI + '.Retriever?name=Statistics&uuid=' + response.data.replace(/\"/g, ""));
				}
				
		    	$scope.isExporting = false;
			})
			.catch(function () {
		    	$scope.isExporting = false;
			});
    };
    
	$scope.getDetailsService = function (item) {
	};
	
	$scope.toggleItemSelection = function (item) {
		var index = $scope.selection.indexOf(item);

		if (index >= 0) {
			$scope.selection.splice(index, 1);
			item.isSelected = false;
			
		} else {
			item.isSelected = true;
			$scope.selection.push(item);
		}
	};
	
	$scope.selectLoaded = function () {
		$scope.selection = [];
		
		$scope.results.table.forEach(function (item) {
			if (item.type == "Template") {

			} else if(!(item['template-current'] == false)) {
				item.isSelected = true;
				$scope.selection.push(item);
			}
		});
	};
	
	$scope.selectResourcesLoaded = function () {
		$scope.selection = [];
		
		$scope.results.table.forEach(function (item) {
			if (item.type == "Template") {

			} else if(!(item['template-current'] == false)) {
				if (item.type == "Resource") {
					item.isSelected = true;
					$scope.selection.push(item);
				}
			}
		});
	};

	$scope.clearSelection = function () {
		$scope.selection.forEach( function (element) {
			if (element.isSelected) {
				element.isSelected = false;
			}
		});
		
		$scope.selection = [];
	};
	
	$scope.flattenSelection = function (property) {
		var selection = $scope.selection;
		
		selection = selection.filter(function (element) {
			return element[property] !== undefined;
		});
		
		selection = selection.map(function (element) {
			return element[property];
		});
		
		return selection;
	};
	
	$scope.onDateFilter = function () {
		var modal = canopyModals.definitions.dateRange($scope.listFilters.date.state);
		
		canopyModals.instantiate(modal).result.then(function(response) {
			if (response.range) {
				$scope.listFilters.date.state = response.range;
			}
		});
	};
	
	$scope.setListingLimit = function (limit) {
		$scope.list.setParameter("listing-limit", limit);
		$scope.load();
	};
});