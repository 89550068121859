canopy.controller("BatchListController", function ($scope, $controller, $stateParams, lumaPaths, $timeout, $interval, $q, canopySession, menuNode, listService, fusion) {
	'use strict';
	$controller("ListController", {
		$scope:    $scope,
		$timeout:  $timeout,
		lumaPaths: lumaPaths,
		menuNode:  menuNode
	});
	
	$scope.filterPanel = {
		isCollapsed: true
	};
	
	$scope.menuNode = menuNode;
	
	$scope.initList = function () {
		if ($scope.list == undefined) {
			return;
		}
		
		var user   = canopySession.getUser();
		var domain = canopySession.getActiveDomain();
		
		$scope.list.setService($scope.list.getService() || "ListBatches");
		
		$scope.list.setParameter("domainID", domain.id);
		$scope.list.setParameter("listing-sort", "batch-created");
		$scope.list.setParameter("listing-order", "Descending");
	};
	
	$scope.initSearchTypes = function () {
		return [{
			property: "search",
			title: "Name"
		}, {
			property: "id",
			title: "ID"
		}];
	};
		
	$scope.init();
	$scope.initSearchTypes();
	
	$scope.interval = $interval(function () {
		var batchIDs = $scope.results.table.reduce(function (acc, element) {
			var isPending = ["New", "Queued", "Working"].indexOf(element.state) >= 0;
			
			if (isPending) {
				acc.push(element.id);
			}
			
			return acc;
		}, []);
		
		var promise = function () {
			var defer    = $q.defer();
			var promises = batchIDs.map(function (batchID) {
				return fusion.getBatch(batchID);
			});
		
			var resolve = function (response) {
				defer.resolve(response);
			};
		
			$q.all(promises).then(resolve);
		
			return defer.promise;
		};
		
		promise().then(function (responses) {
			responses.forEach(function (response) {
				var data = response.data;
				
				var result = $scope.results.table.find(function (element) {
					return element.id == data.id;
				});
				
				if (result) {
					result.state    = data.state;
					result.complete = data.complete;
				}
			});
		});
	}, 2000);
	
	$scope.$on("$destroy", function () {
    	$interval.cancel($scope.interval);
	});
});