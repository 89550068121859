canopy.factory("budgets", ["$http", "lumaPaths", "listService", "canopyModals", "$q", function ($http, lumaPaths, listService, canopyModals, $q) {
	var listBudgets = function (domain) {
		return $http({
			url: lumaPaths.serviceURI + ".ListBudgets",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				listingStyle: "Page"
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetsForDomain = function (domain) {
		return $http({
			url: lumaPaths.serviceURI + ".ListBudgetsForDomain",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetsForRole = function (domain, role) {
		return $http({
			url: lumaPaths.serviceURI + ".ListBudgetsForRole",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				listingStyle: "Page",
				role: role,
				includeSubDomains: true
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};

	var getBudget = function (budgetID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetBudget",
			method: "POST",
			data: $.param({
				budgetID: budgetID
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetProcesses = function (domain) {
		return $http({
			url: lumaPaths.serviceURI + ".ListProcessesForDomain",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				type: "Budget"
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetCurrencies = function () {
		return $http({
			url: lumaPaths.serviceURI + ".ListCurrencies",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetTransactions = function (budget) {
		return $http({
			url: lumaPaths.serviceURI + ".ListBudgetTransactions",
			method: "POST",
			data: $.param({
				budgetID: budget.id || budget
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var getBudgetTransaction = function (transaction) {
		return $http({
			url: lumaPaths.serviceURI + ".GetBudgetTransaction",
			method: "POST",
			data: $.param({
				transactionID: transaction.id || transaction
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetTransactionHistory = function (transaction) {
		return $http({
			url: lumaPaths.serviceURI + ".ListBudgetTransactionHistory",
			method: "POST",
			data: $.param({
				transactionID: transaction.id || transaction
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetOrders = function (domain, budget) {
		return $http({
			url: lumaPaths.serviceURI + ".ListOrders",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				budgetID: budget.id || budget
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetProjects = function (domain, budget) {
		return $http({
			url: lumaPaths.serviceURI + ".ListProjects",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				budgetID: budget.id || budget
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetHistory = function (budget) {
		return $http({
			url: lumaPaths.serviceURI + ".ListBudgetHistory",
			method: "POST",
			data: $.param({
				budgetID: budget.id || budget
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var setBudgetState = function (budget, state, description) {
		var data = {
			budgetID: budget.id || budget,
			state: state
		};
		
		if (description) {
			data.description = description;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".SetBudgetState",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var submitBudget = function (budget) {
		var domain = budget.domain;
		
		return $http({
			url: lumaPaths.serviceURI + ".SubmitBudget",
			method: "POST",
			data: $.param({
				domainID: domain.id,
				processID: budget.processID,
				name: budget.name,
				description: budget.description,
				currencyID: budget.currencyID,
				total: budget.total,
				enforcement: budget.enforcement,
				enabled: true,
				start: budget.start ? Dates.encode( budget.start ) : Dates.encode( new Date() ),
				end: budget.end ? Dates.encode( budget.end ) : null,
				groupIDs: budget.groupIDs,
				metadata: JSON.stringify(budget.metadata)
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var editBudgetTransaction = function (transaction, value, description) {
		var data = {
			transactionID: transaction.id || transaction,
			value: value
		};
		
		if (description) {
			data.description = description;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".EditBudgetTransaction",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var closeBudgetTransaction = function (transaction, description) {
		var data = {
			transactionIDs: transaction.id || transaction
		};
		
		if (description) {
			data.description = description;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".CloseBudgetTransactions",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};

	var closeBudgetTransactions = function (transactions, description) {
		var arrPromises = [];

		transactions.forEach(function(transaction, index) {
			arrPromises.push(closeBudgetTransaction(transaction, description));
		});

		return $q.all(arrPromises);
	};
	
	var reopenBudgetTransaction = function (transaction, description) {
		var data = {
			transactionIDs: transaction.id || transaction
		};
		
		if (description) {
			data.description = description;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".ReopenBudgetTransactions",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var addTransaction = function (budget, value, description) {
		var data = {
			budgetID: budget.id || budget,
			value: value
		};
		
		if (description) {
			data.description = description;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".AddTransaction",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var adjustBudget = function (budget, value, description) {
		var data = {
			budgetID: budget.id || budget,
			value: value
		};
		
		if (description) {
			data.description = description;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".AdjustBudget",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};

	return {
		listBudgets: function (domain) {
			return listBudgets(domain);
		},
		listBudgetsForDomain: function (domain) {
			return listBudgetsForDomain(domain);
		},
		listBudgetsForRole: function (domain, role) {
			return listBudgetsForRole(domain, role);
		},
		getBudget: function (budgetID) {
			return getBudget(budgetID);
		},
		listBudgetProcesses: function (domain) {
			return listBudgetProcesses(domain);
		},
		listBudgetCurrencies: function () {
			return listBudgetCurrencies();
		},
		listBudgetTransactions: function (budget) {
			return listBudgetTransactions(budget);
		},
		getBudgetTransaction: function (transaction) {
			return getBudgetTransaction(transaction);
		},
		listBudgetTransactionHistory: function (transaction) {
			return listBudgetTransactionHistory(transaction);
		},
		listBudgetOrders: function (domain, budget) {
			return listBudgetOrders(domain, budget);
		},
		listBudgetProjects: function (domain, budget) {
			return listBudgetProjects(domain, budget);
		},
		listBudgetHistory: function (budget) {
			return listBudgetHistory(budget);
		},
		setBudgetState: function (budget, state, description) {
			return setBudgetState(budget, state, description);
		},
		submitBudget: function (budget) {
			return submitBudget(budget);
		},
		editBudgetTransaction: function (transaction, value, description) {
			return editBudgetTransaction(transaction, value, description);
		},
		closeBudgetTransaction: function (transaction, description) {
			return closeBudgetTransaction(transaction, description);
		},
		closeBudgetTransactions: function (transactions, description) {
			return closeBudgetTransactions(transactions, description);
		},
		reopenBudgetTransaction: function (transaction, description) {
			return reopenBudgetTransaction(transaction, description);
		},
		addTransaction: function (budget, value, description) {
			return addTransaction(budget, value, description);
		},
		adjustBudget: function (budget, value, description) {
			return adjustBudget(budget, value, description);
		},
		list: function () {
			var list = listService.instantiate("ListBudgets");
			
			list.prime = function () {
			};
			
			return list;
		},
		utilities: {	
			editBudgetTransaction: function (transaction, callback) {
				var onSuccess = function (value) {
					transaction.value = value;
					
					if (callback) {
						callback();
					}
				};
				
				var onError = function () {
				};
				
				var modal = canopyModals.definitions.form({
					header: "Edit transaction value",
					form: Luma.paths.context + "/resolve/marquee/site/forms/edit-budget-transaction.html"
				});
				
				canopyModals.instantiate(modal).result.then(function (form) {
					var value = form.value * -1;
					var description = form.description;
					
					editBudgetTransaction(transaction.id || transaction, value, description).then(onSuccess(value), onError);
				});
			},
			closeBudgetTransaction: function (transaction, callback) {
				var onSuccess = function () {
					if (transaction.state) {
						transaction.state = "Closed";
					}
					
					if (callback) {
						callback();
					}
				};
				
				var onError = function () {
				};
				
				var modal = canopyModals.definitions.form({
					header: "Close transaction",
					form: Luma.paths.context + "/resolve/marquee/site/forms/close-budget-transaction.html"
				});
				
				canopyModals.instantiate(modal).result.then(function (form) {
					closeBudgetTransaction(transaction.id || transaction, form.description).then(onSuccess, onError);
				});
			},
			closeBudgetTransactions: function (transactions, callback) {
				var onSuccess = function (data) {
					transactions.forEach(function(transaction) {
						if (transaction.state) {
							transaction.state = "Closed";
						}
					});

					
					if (callback) {
						callback();
					}
				};
				
				var onError = function () {
				};
				
				var modal = canopyModals.definitions.form({
					header: "Close transactions",
					form: Luma.paths.context + "/resolve/marquee/site/forms/close-budget-transaction.html"
				});
				
				canopyModals.instantiate(modal).result.then(function (form) {
					closeBudgetTransactions(transactions, form.description).then(onSuccess, onError);
				});
			},
			reopenBudgetTransaction: function (transaction, callback) {
				var onSuccess = function () {
					if (transaction.state) {
						transaction.state = "Confirmed";
					}
					
					if (callback) {
						callback();
					}
				};
				
				var onError = function () {
				};
				
				var modal = canopyModals.definitions.form({
					header: "Reopen transaction",
					form: Luma.paths.context + "/resolve/marquee/site/forms/close-budget-transaction.html"
				});
				
				canopyModals.instantiate(modal).result.then(function (form) {
					reopenBudgetTransaction(transaction.id || transaction, form.description).then(onSuccess, onError);
				});
			}
		}
	}
}]);