canopy.factory("transforms", ["$http", "lumaPaths", "listService", function ($http, lumaPaths, transforms) {
	var getTransform = function (transformID) {
		return $http({
			url: lumaPaths.serviceURI + '.GetTransform',
			method: 'POST',
			data: $.param({
				transformID: transformID
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	return {
		getTransform: function (transformID) {
			return getTransform(transformID);
		},
		list: function () {
			var list = listService.instantiate("ListTransforms");
			
			list.prime = function () {
			};
			
			return list;
		}
	};
}]);