"use strict";

canopy.directive("canopyLocalise", function () {
	return {
		restrict: "A",
		scope: {
			keys: "@"
		},
		controller: function ($scope, $element, canopySession, $timeout) {
			var localise = function (vocabulary) {
				var keyString = $scope.keys || "";
				var keys  = keyString.replace(/ /g, "").split(",");
				var value = null;
				
				keys.reverse().forEach(function (key) {
					if (key.length) {
						value = vocabulary[key];
					
						if (!value) {
							console.log("localisation: unrecognised key: " + key);
						}
					}
				});
				
				value = value || $scope.fallback || "UNDEFINED";
				
				$element[0].innerHTML = value;
			};
				
			$timeout(function () {
				$scope.fallback = $element[0].innerHTML;
				$scope.session  = canopySession;
		
				$scope.$watch("session.getActiveLocale()", function (locale) {
					localise(locale.vocabulary);
				});
			});
		},
		link: function (scope, element) {
		}
	}
});