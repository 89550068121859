canopy.factory("fusion", ["$http", "lumaPaths", "canopyModals", "canopyEvents", function ($http, lumaPaths, canopyModals, canopyEvents) {
	var submitBatch = function (domain, template, name, variables) {
		return $http({
			url: lumaPaths.fusionServiceURI + ".SubmitBatch",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				templateID: template.id || template,
				name: name,
				variables: JSON.stringify(variables)
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var resubmitBatch = function (batch, domain, name) {
		return $http({
			url: lumaPaths.fusionServiceURI + ".ResubmitBatch",
			method: "POST",
			data: $.param({
				batchID: batch.id || batch,
				domainID: domain.id || domain,
				name: name
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};

	var getBatch = function (batchID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetBatch",
			method: "POST",
			data: $.param({
				batchID: batchID
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		});
	};

	return {
		submitBatch: submitBatch,
		resubmitBatch: resubmitBatch,
		getBatch: function (batchID) {
			return getBatch(batchID);
		},
		utilities: {
			submitBatch: function (domain, template, source, onComplete) {
				var domainID   = domain.id || domain;
				var templateID = template.id || template;
				var sourceID   = source.id || source
						
				var modal = canopyModals.definitions.form({
					header: "Submit batch",
					form:   Luma.paths.context + "/system/mantle/marquee/site/forms/submit-batch.html"
				});
				
				canopyModals.instantiate(modal).result.then(function (form) {
					var variables = [{
						name: "source",
						type: "Database",
						value: {
							assetID: sourceID
						}
					}];
					
					submitBatch(domainID, templateID, form.name, variables).then(function (response) {
						if (onComplete) {
							onComplete(response.data);
						}
					});
				});
			},
			resubmitBatch: function (batch, onComplete) {
				var batchID   = batch.id  || batch;
				var domainID  = batch.domainID;
						
				var modal = canopyModals.definitions.form({
					header: "Resubmit batch",
					form:   Luma.paths.context + "/system/mantle/marquee/site/forms/submit-batch.html"
				});
				
				canopyModals.instantiate(modal).result.then(function (form) {
					resubmitBatch(batchID, domainID, form.name).then(function (response) {
						if (onComplete) {
							onComplete(response.data);
						}
					});
				});
			},
			addDataSource: function (asset, callback) {
				canopyModals.instantiate({
					templateUrl: "modalFileUpload.html",
					controller: "addDataSourceModalController",
					resolve: {
						args: function () {
							return {
								asset: asset,
								callback: callback
							};
						}
					}
				});
			}
		}
	};
}]);

canopy.controller("submitBatchModalController", function ($scope, args, listService) {
	var init = function () {
		var list = listService.instantiate("ListAssets");
		
		list.setParameter("domainID", Luma.user.userID);
		list.setParameter("lensID", args.lensID);
		
		list.load().then(function (response) {
			$scope.assets = response.data;
		});
		
		$scope.source = {
			selected: null
		};
	};
	
	init();
});

canopy.controller("addDataSourceModalController", function ($scope, $q, args, $uibModalInstance, FileUploader, canopySession, listService, assets) {
	var init = function () {
	};
	
	$scope.uploader = new FileUploader({    	
		url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.AddUserAssets",
		alias: "payload",
		queueLimit: 1
	});
	
	$scope.uploader.onBeforeUploadItem = function (item) {
		item.formData.push({
 			domainID: Luma.user.userID,
 			adaptors: "Document"
		});
	};
	
	$scope.dismiss = function () {
		$uibModalInstance.dismiss();
	};

	$scope.confirm = function () {
		if ($scope.isUploading) {
			return;
		}
		
		$scope.isUploading = true;
		
		var responses = [];
		
		$scope.uploader.onCompleteItem = function (item, response) {
			var json = null;
	
			try {
				var element = document.createElement("html");
				element.innerHTML = response;
				json = JSON.parse(jQuery(element).find("#response")[0].innerHTML);
			}
			catch (e) {
				console.log(e);
			}
			
			if (json && json.loaded) {
				responses = responses.concat(json.loaded);
			}
		};
		
		$scope.uploader.onCompleteAll = function () {
			$scope.isUploading = false;
			
			$uibModalInstance.close();
			
			var promises = [];
			
			responses.forEach(function (asset) {
				promises.push(assets.setAssetMetadata(asset.id, "com.intrepia.canopy.FusionTemplateUUID", args.asset.uuid));
			});
			
			$q.all(promises).then(function () {
				if (args.callback) {
					args.callback(responses);
				}
			});
		};
					
		$scope.uploader.uploadAll();
	};
	
	init();
});