"use strict";

canopy.directive("canopyMenuLevelMobile", function (recursionHelper) {
	return {
		restrict: "A",
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/menu/menu-level-mobile.html",
		controller: function ($scope) {
			$scope.level = $scope.depth || 0;
			
			$scope.onSelect = function (node) {				
				if ($scope.callbacks && $scope.callbacks.onSelect) {
					$scope.callbacks.onSelect(node);
				}
			};
		},
		scope: {
			root: "=",
			depth: "=",
			callbacks: "="
		},
		compile: function (element) {
            return recursionHelper.compile(element);
        }
	};
});