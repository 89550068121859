"use strict";

canopy.directive("canopyBasketIcon", function () {
	return {
		restrict: "A",
		controller: function ($scope, $timeout, basket, canopyEvents) {
			var initBasketSize = function () {
				var onSuccess = function (response) {
					$scope.basket.size = response.data;
				};
				
				var onError = function (response) {
				};
				
				basket.getBasketSize().then(onSuccess).catch(onError);
			};
			
			$scope.init = function () {
				$scope.popover = {
					message: "",
					isOpen: false
				};
				
				$scope.basket = {
					size: 0
				};
				
				initBasketSize();
			};
			
			$scope.init();
			
			var onBasketEvent = function () {
				$scope.pulse = true;
				
				$timeout( function () {
					$scope.pulse = false;
				}, 250);
			};
			
			var onUserBasketWasEmptied = function (args) {
				$scope.basket.size = 0;
				
				onBasketEvent();
			};
			
			var onUserBasketWasModified = function (args) {
				initBasketSize();
				
				onBasketEvent();
			};
			
			$scope.isPopoverActive = function () {
				return $scope.popover.isOpen;
			};
			
			canopyEvents.subscribe("userBasketWasEmptied",  onUserBasketWasEmptied);
			canopyEvents.subscribe("userBasketWasModified", onUserBasketWasModified);
			
			$scope.$on("$destroy", function () {
				canopyEvents.unsubscribe("userBasketWasEmptied",  onUserBasketWasEmptied);
				canopyEvents.unsubscribe("userBasketWasModified", onUserBasketWasModified);
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/icons/basket.html"
	}
});