canopy.controller("ReimbursementDetailController", function ($scope, $stateParams, menuNode, lumaPaths, canopySession, canopyModals, listService, budgets, reimbursements) {
	var initReimbursement = function () {
		var onSuccess = function (response) {
			$scope.reimbursement = response.data;
			
			initHistory();
			initTransaction();
		};
		
		reimbursements.getBudgetReimbursement($stateParams.reimbursementID).then(onSuccess);
	};
	
	var initHistory = function () {
		var onSuccess = function (response) {
			$scope.reimbursement.history = response.data.reverse();
		};
		
		var list = listService.instantiate("ListBudgetReimbursementHistory");
		
		list.setParameter("reimbursementID", $stateParams.reimbursementID);
		
		list.load().then(onSuccess);
	};
	
	var initTransaction = function () {
		$scope.reimbursement.transaction = { };
			
		var onSuccess = function (response) {
			$scope.reimbursement.transaction = response.data;
			
			initActions();
		};
		
		budgets.getBudgetTransaction($scope.reimbursement.transactionID).then(onSuccess);
	};
	
	var initActions = function () {
		var user = canopySession.getUser();
		
		$scope.actions = [];
		
		var approveAction = {
			label: "Approve this reimbursement",
			execute: function () {
				var onComplete = function () {
					$scope.reimbursement.state = "Approved";
					$scope.init();
				};
				
				reimbursements.utilities.approveBudgetReimbursement($scope.reimbursement, onComplete);
			},
			permitted: function () {
				return  $scope.reimbursement.state == "Unapproved" &&
						$scope.reimbursement.transaction.state != "Closed" &&
						user.hasService("budget.reimbursement.approver");
			}
		};
		
		var rejectAction = {
			label: "Reject this reimbursement",
			execute: function () {
				var onComplete = function () {
					$scope.reimbursement.state = "Rejected";
					$scope.init();
				};
				
				reimbursements.utilities.rejectBudgetReimbursement($scope.reimbursement, onComplete);
			},
			permitted: function () {
				return  $scope.reimbursement.state == "Unapproved" &&
						$scope.reimbursement.transaction.state != "Closed" &&
						user.hasService("budget.reimbursement.approver");
			}
		};
		
		var editAction = {
			label: "Edit reimbursement",
			execute: function () {
				var onComplete = function () {
					$scope.init();
				};
				
				reimbursements.utilities.editBudgetReimbursement($scope.reimbursement, onComplete);
			},
			permitted: function () {
				return $scope.reimbursement.state != "Paid" && $scope.reimbursement.transaction.state != "Closed" && user.hasService("budget.reimbursement.creator");
			}
		};
		
		var paidAction = {
			label: "Mark as paid",
			execute: function () {
				var onComplete = function () {
					$scope.reimbursement.state = "Paid";
					$scope.init();
				};
				
				var modal = canopyModals.definitions.message({
					header: "Mark as paid",
					body: "This action cannot be undone, are you sure you wish to continue?",
					cancel: "No",
					confirm: "Yes"
				});
				
				canopyModals.instantiate(modal).result.then(function () {
					reimbursements.setBudgetReimbursementPaid($scope.reimbursement).then(onComplete);
				});
			},
			permitted: function () {
				return $scope.reimbursement.state == "Approved" && user.hasService("budget.reimbursement.payment-processor");
			}
		};
	
		$scope.actions = [{
			label: "Approval",
			actions: [
				approveAction,
				rejectAction
			]
		}, {
			label: "Edit",
			actions: [
				editAction
			]
		}, {
			label: "Process",
			actions: [
				paidAction
			]
		}];
		
		$scope.actions.forEach(function (category) {
			category.actions = category.actions.filter(function (action) {
				return action.permitted();
			});
		});
	};
	
	$scope.init = function () {
		initReimbursement();
	};
	
	$scope.init();
});