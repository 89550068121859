canopy.factory("events", [function () {
	var _subscribers = {};
	
	var addSubscriber = function (event, callback) {
	};
	
	return {
		dispatch: function (event, args) {
			if (!_subscribers.hasOwnProperty(event)) {
				return;
			}
			
			_subscribers[event].forEach(function (callback) {
				callback(args);
			});
		},
		subscribe: function (event, callback) {
			if (!_subscribers.hasOwnProperty(event)) {
				_subscribers[event] = [];
			}
			
			_subscribers[event].push(callback);
		},
		unsubscribe: function (event, callback) {
			if (_subscribers[event] instanceof Array) {
				_subscribers[event] = _subscribers[event].filter(function (element) {
					return element != callback;
				});
			}
		}
	};
}]);

canopy.factory("canopyEvents", [function () {
	var _subscribers = {};
	
	var addSubscriber = function (event, callback) {
	};
	
	return {
		dispatch: function (event, args) {
			if (!_subscribers.hasOwnProperty(event)) {
				return;
			}
			
			_subscribers[event].forEach(function (callback) {
				callback(args);
			});
		},
		subscribe: function (event, callback) {
			if (!_subscribers.hasOwnProperty(event)) {
				_subscribers[event] = [];
			}
			
			_subscribers[event].push(callback);
		},
		unsubscribe: function (event, callback) {
			if (_subscribers[event] instanceof Array) {
				_subscribers[event] = _subscribers[event].filter(function (element) {
					return element != callback;
				});
			}
		}
	};
}]);