canopy.directive("canopyLink", function () {
	return {
		restrict: "A",
		controller: function ($scope, canopySession, $window) {
			$scope.onClick = function () {
				var data = $scope.canopyLink.data;
				
				switch ($scope.canopyLink.type) {
					case "internal":
						canopySession.setActiveNode(data.nodeID);
						break;
						
					case "external":
						$window.location.href = data.href;
						break;
						
					default:
						break;
				}
			};
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/link/canopy-link.html",
		scope: {
			canopyLink: "="
		},
		replace: true
	};
});