"use strict";

canopy.directive("canopyAssetSelector", function (canopyModals) {
	return {
		controller: function ($scope) {
			$scope.luma = Luma;
			
			$scope.onClick = function () {
				var modal = canopyModals.definitions.assetSelect({
					lensID: ""
				});
				
				canopyModals.instantiate(modal).result.then(function (asset) {
					$scope.asset = {
						id: asset.id,
						adaptor: asset.adaptor
					};
				});
			};
		},
		link: function (scope, element) {
		},
		scope: {
			asset: "="
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/tools/canopy-asset-selector.html"
	};
});