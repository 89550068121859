"use strict";

canopy.directive("canopyActionsDropdown", function () {
	return {
		restrict: "A",
		scope: {
			entityType: "@",
			actions: "="
		},
		controller: function ($scope) {
			$scope.$watch("actions", function (actions) {
				if (!actions instanceof Array) {
					return;
				}
		
				$scope.actionCount = 0;
		
				if ($scope.actions) {
					$scope.actions.forEach(function (category) {
						$scope.actionCount += category.actions.length;
					});
				}
			});			
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/actions-dropdown/actions-dropdown.html"
	}
});