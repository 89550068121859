"use strict";

canopy.directive("canopyEventLog", function () {
	return {
		restrict: "A",
		scope: {
		},
		controller: function ($scope, $timeout, canopyEvents) {			
			$scope.events = [ ];
			
			$scope.addEvent = function (event) {
				var event = {
					id: moment().valueOf(),
					message: event.message
				};
		
				$scope.events.push(event);
		
				$timeout(function () {
					$scope.removeEvent(event.id)
				}, 5000);
			};
	
			$scope.removeEvent = function (eventID) {
				var eventIndex = $scope.events.findIndex(function (event) {
					return event.id == eventID;
				});
				
				if (eventIndex >= 0) {
					$scope.events.splice(eventIndex, 1);
				}
			};
			
			var onUserBasketWasEmptied = function (eventArgs) {
				$scope.addEvent({
					message: "Basket emptied"
				});
			};
			
			var onUserBasketWasModified = function (eventArgs) {
				$scope.addEvent({
					message: eventArgs.count + " " + (eventArgs.count > 1 ? "items were " : "item was ") + (eventArgs.operation == "Add" ? "added to " : "removed from ") + " your basket"
				});
			};
			
			var onGenericEvent = function (eventArgs) {
				$scope.addEvent({
					message: eventArgs.message
				});
			};
			
			canopyEvents.subscribe("userBasketWasEmptied",  onUserBasketWasEmptied);
			canopyEvents.subscribe("userBasketWasModified", onUserBasketWasModified);
			canopyEvents.subscribe("genericEvent", onGenericEvent);
			
			$scope.$on("$destroy", function () {
				canopyEvents.unsubscribe("userBasketWasEmptied",  onUserBasketWasEmptied);
				canopyEvents.unsubscribe("userBasketWasModified", onUserBasketWasModified);
				canopyEvents.unsubscribe("genericEvent", onGenericEvent);
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/event-log/event-log.html"
	}
});