canopy.factory("campaigns", ["$http", "$q", "lumaPaths", "listService", "assets", "budgets", "canopyModals", function ($http, $q, lumaPaths, listService, assets, budgets, canopyModals) {
	var getCampaign = function (campaignID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetCampaign",
			method: "POST",
			data: $.param({
				campaignID: campaignID
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var getCampaignInfo = function (campaignID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetCampaignInfo",
			method: "POST",
			data: $.param({
				campaignID: campaignID
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var addCampaign = function (campaign) {
		var data = {
			name: campaign.name,
			domainID: campaign.domainID,
			processID: campaign.processID,
			start: Dates.encode(campaign.start),
			end: Dates.encode(campaign.end)
		};
		
		if (campaign.budgetID) {
			data.budgetID = campaign.budgetID;
		}
		if (campaign.allocation) {
			data.allocation = campaign.allocation;
		}
		
		if (campaign.state) {
			data.state = campaign.state;
		}
		
		if (campaign.metadata) {
			data.metadata = JSON.stringify(campaign.metadata);
		}
		if (campaign.keys) {
			data.keys = JSON.stringify(campaign.keys);
		}
		if (campaign.metadata) {
			data.taxonomy = JSON.stringify(campaign.taxonomy);
		}
		
		return $http({
            method: "POST",
			url: lumaPaths.serviceURI + ".AddCampaign",
            data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
        });
	};
	
	var setCampaignState = function (campaign, state, description) {
		var data = {
			campaignID: campaign.id || campaign,
			state: state
		};
		
		if (description) {
			data.description = description;
		}
		else {
			if (state == "Rejected") {
				data.description = "Rejected without comment.";
			}
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".SetCampaignState",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var setCampaignMetadata = function (campaign, metadata) {
		return $http({
			url: lumaPaths.serviceURI + ".SetCampaignMetadata",
			method: "POST",
			data: $.param({
				campaignID: campaign.id || campaign,
				metadata: JSON.stringify(metadata || campaign.metadata)
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listCampaignProcesses = function (domain) {
		return $http({
			url: lumaPaths.serviceURI + ".ListProcessesForDomain",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				type: "Campaign"
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listCampaignHistory = function (campaign) {
		return $http({
			url: lumaPaths.serviceURI + ".ListCampaignHistory",
			method: "POST",
			data: $.param({
				campaignID: campaign.id || campaign
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listCampaignActivity = function (campaign) {
		var defer    = $q.defer();
		var promises = [];
		
		promises.push(
			listCampaignHistory(campaign),
			assets.listAssetComments(campaign)
		);
		
		var mergeResponses = function (responses) {
			var campaignHistoryMapper = function (element) {
				return {
					"user-forename": element["user-forename"],
					"user-surname": element["user-surname"],
					"user-email": element["user-email"],
					"date": element.date,
					"type": "Progress",
					"subType": element.type,
					"state": element.state,
					"body": element.description
				};
			};
			
			var assetCommentsMapper = function (element) {
				return {
					"user-forename": element["user-forename"],
					"user-surname": element["user-surname"],
					"user-email": element["user-email"],
					"date": element.created,
					"type": "Comment",
					"subType": element.type,
					"body": element.body
				};
			};
			
			var campaignHistory    = responses[0].data.map(campaignHistoryMapper);
			var assetComments      = responses[1].data.map(assetCommentsMapper);
			
			var combined = campaignHistory.concat(assetComments);
			
			combined = combined.sort(function (a, b) {
				var dateA = moment(a.date, Dates.formats.universal).toDate();
				var dateB = moment(b.date, Dates.formats.universal).toDate();
				
				return dateB - dateA;
			});
			
			defer.resolve(combined);
		};
		
		$q.all(promises).then(mergeResponses);
		
		return defer.promise;
	};

	return {
		getCampaign: function (campaignID) {
			return getCampaign(campaignID);
		},
		getCampaignInfo: function (campaignID) {
			return getCampaignInfo(campaignID);
		},
		addCampaign: function (campaign) {
			return addCampaign(campaign);
		},
		setCampaignState: function (campaign, state, description) {
			return setCampaignState(campaign, state, description);
		},
		setCampaignMetadata: function (campaign, metadata) {
			return setCampaignMetadata(campaign, metadata);
		},
		listCampaignProcesses: function (domain) {
			return listCampaignProcesses(domain);
		},
		listCampaignHistory: function (campaign) {
			return listCampaignHistory(campaign);
		},
		listCampaignActivity: function (campaign) {
			return listCampaignActivity(campaign);
		},
		list: function () {
			var list = listService.instantiate("ListCampaigns");
			
			list.prime = function () {
			};
			
			return list;
		},
		utilities: {
			addCampaignProject: function (campaign, callback) {
				canopyModals.instantiate({
					templateUrl: Luma.paths.context + "/system/mantle/marquee/site/modals/modal-add-campaign-project.html",
					controller: "AddCampaignProjectModalController",
					resolve: {
						args: function () {
							return {
								campaign: campaign,
								callback: callback
							};
						}
					}
				});
			}
		},
		supplemental: {
			request: {
				spawnProjects: function () {
					return [];
				}
			},
			onAddCampaign: function (campaign, ancillary) {
			}
		}
	};
}]);

// TODO: This duplicates a lot of the functionality of the RequestProjectController view controller. It could probably be rolled into one controller.
canopy.controller("AddCampaignProjectModalController", function ($scope, args, $uibModalInstance, listService, canopySession, canopyEvents, processes, projects) {
	var initProject = function () {
		$scope.project = {
			domain: canopySession.getActiveDomain(),
			campaignID: args.campaign.id,
			value: 0.00,
			reimbursable: true,
			metadata: {
				canopy: { },
				client: { }
			},
			state: "New"
		};
	};
	
	var initProcesses = function () {
		var onSuccess = function (response) {			
			var valid = [];
			
			try {
				valid = args.campaign.process.metadata.projects;
			}
			catch (e) {
				valid = [];
			}
				
			var validFilter = function (element) {
				if (valid.length) {
					return valid.find(function (process) {
						return element.uuid == process || element.name == process;
					});
				}
				
				return true;
			};
			
			$scope.ancillary.processes = {
				available: response.data.filter(validFilter),
				selected: null
			};
		};
		
		var list = listService.instantiate("ListProcessesForDomain");
		
		list.setParameter("domainID", canopySession.getActiveDomain().id);
		list.setParameter("type", "Project");
		
		list.load().then(onSuccess);
	};
	
	var initCampaign = function () {
		var onSuccess = function (response) {			
			$scope.campaign = {
				taxonomy: response.data
			};
		};
		
		var list = listService.instantiate("GetAssetTaxonomy");
		
		list.setParameter("assetID", args.campaign.id);
		list.setParameter("ignoreDomains", true);
		
		list.load().then(onSuccess);
	};
	
	var initGroups = function () {
    	var groupTypeFilter = function (type) {
			return function (element) {
				return element.value.type === type;
			};
		};
		
		var groupMapper = function (element) {
			return {
				id: element.value.id,
				name: element.value.name,
				type: element.value.type
			};
		};
    	
    	var user   = canopySession.getUser();
    	var groups = user.groups.values;
    	
    	var units    = groups.filter(groupTypeFilter("Unit"));
    	var regions  = groups.filter(groupTypeFilter("Region"));
    	var branches = groups.filter(groupTypeFilter("Branch"));
    	
    	units    = units.map(groupMapper);
    	regions  = regions.map(groupMapper);
    	branches = branches.map(groupMapper);
    	
    	$scope.ancillary.groups = {
    		unit: {
    			available: units,
    			selected: undefined
    		},
    		region: {
    			available: regions,
    			selected: undefined
    		},
    		branch: {
    			available: branches,
    			selected: undefined
    		}
    	};
		
		if (!$scope.ancillary.groups.unit.available.length) {
			$scope.ancillary.groups.unit.selected = undefined;
		}

		if (!$scope.ancillary.groups.region.available.length) {
			$scope.ancillary.groups.region.selected = undefined;
		}

		if (!$scope.ancillary.groups.branch.available.length) {
			$scope.ancillary.groups.branch.selected = undefined;
		}
    };
	
	var initBudgets = function () {
		if ($scope.ancillary.budgets === undefined) {		
			$scope.ancillary.budgets = {
				available: [],
				selected:  undefined
			};
		}

		var onSuccess = function (response) {
			$scope.ancillary.budgets.available = response.data;
	
			if ($scope.ancillary.budgets.available.length) {
				$scope.ancillary.budgets.selected = $scope.ancillary.budgets.available[0];
			}
		};

		var domain = canopySession.getActiveDomain();
		var site   = canopySession.getActiveSite();

		if (domain && site) {
			var budgetList = listService.instantiate("ListBudgetsForDomain");
	
			budgetList.setParameter("domainID", domain.id);
			budgetList.setParameter("siteID", site.id);
			budgetList.setParameter("enabled", true);
	
			if ($scope.ancillary.processes.selected) {
				var keywordIDs = [];
				
				$scope.ancillary.processes.selected.taxonomy.forEach(function (genus) {
					if (genus.type == "Static") {
						genus.keywords.forEach(function (keyword) {
							if (keyword.selected) {
								keywordIDs.push(keyword.id);
							}
						});
					}
				});
			
				if (keywordIDs.length) {
					budgetList.registerFilter({
						name: "keywords",
						type: "com.intrepia.luma.KeywordFilter",
						applied: keywordIDs
					});
				}
			}
	
			budgetList.load().then(onSuccess);
		}
	};
	
	var init = function () {
		$scope.ancillary = { };
		$scope.forms = [];
		
		initProject();
		initProcesses();
		initCampaign();
		initGroups();
	};
	
	$scope.dismiss = function () {
		$uibModalInstance.close();
	};
	
    var getGroupIDs = function () {
    	var groupIDs = [];
    	
    	for (var key in $scope.ancillary.groups) {
    		var group = $scope.ancillary.groups[key];
    		
    		if (group && group.selected) {
    			groupIDs.push(group.selected.id);
    		}
    	}
    	
    	return groupIDs.toString();
    };
    
    var getSelectedGroups = function () {
    	var selectedGroups = [];
    	
    	for (var key in $scope.ancillary.groups) {
    		var group = $scope.ancillary.groups[key];
    		
    		if (group && group.selected) {
    			selectedGroups.push(group.selected);
    		}
    	}
    	
    	return selectedGroups;
    };

	$scope.confirm = function () {
		if ($scope.isSubmitting) {
			return;
		}
		
		$scope.$broadcast("submit");
		
    	var invalid = false;
    	
    	$scope.forms.forEach(function (element) {
    		var form = element.node[element.name];
    		
    		if (form.$invalid) {
				element.node.$parent.isOpen = true;
				invalid = true;
    		}
    	});
    	
    	if (invalid) {
		    $scope.isSubmitting = false;
    		
		    return;
    	}
    	
    	var groupMapper = function (element) {
    		return element.id;
    	};
    	
    	var selectedGroups   = getSelectedGroups($scope.ancillary.groups);
    	var selectedGroupIDs = selectedGroups.map(groupMapper).toString();
    	
    	$scope.project.metadata.canopy.groups = selectedGroups;
    	$scope.project.groupIDs               = selectedGroupIDs;
    	
    	if ($scope.ancillary.budgets.selected) {
    		$scope.project.budgetID = $scope.ancillary.budgets.selected.id;
    	}
		
		var onSuccess = function (response) {
			$scope.isSubmitting = false;
			
			$uibModalInstance.close();
			
			if (args.callback) {
				args.callback();
			}
		};
		
		$scope.isSubmitting = true;
	
		projects.addProject($scope.project).then(onSuccess);
	};
	
	$scope.registerForm = function (element, name) {
		$scope.forms.push({
			name: name,
			node: element
		});
	};
	
	$scope.$watch("ancillary.processes.selected", function (current, previous) {
		initProject();
		
		var onSuccess = function (response) {
			var process = $scope.ancillary.processes.selected;
			
			for (var key in response.data) {
				if (process[key] == undefined) {
					process[key] = response.data[key];
				}
			}
			
			if (process.metadata) {
				process.metadata = JSON.parse(process.metadata);
				
				if (process.metadata.project) {
					for (var key in process.metadata.project) {
						$scope.project[key] = process.metadata.project[key];
					}
				}
			}
			
			processes.getProcessTaxonomy(process.id).then(function (response) {	
				var process = $scope.ancillary.processes.selected;
				
				var inheritable = null;
					
				if (process.metadata.project && process.metadata.project.taxonomy) {
					inheritable = process.metadata.project.taxonomy;
				}
					
				var isInheritable = function (genusName, keywordName) {
					if (!inheritable) {
						return false;
					}
					
					var keywords = inheritable[genusName];
					
					if (keywords) {
						return keywords.indexOf(keywordName) >= 0;
					}
					
					return false;
				};						
						
				process.taxonomy = response.data;
				
				var campaignTaxonomy   = $scope.campaign.taxonomy;
				var campaignKeywordIDs = [];
				
				campaignTaxonomy.forEach(function (genus) {
					genus.keywords.forEach(function (keyword) {
						campaignKeywordIDs.push(keyword.id);
					});
				});
				
				var projectTaxonomy = response.data;
				
				projectTaxonomy.forEach(function (genus) {
					if (genus.type === "Interactive") {
						genus.keywords.forEach(function (keyword) {
							if (campaignKeywordIDs.indexOf(keyword.id) >= 0 && isInheritable(genus["genus-name"], keyword.name)) {
								keyword.selected = true;
							}
						});
					}
				});
				
				$scope.project.taxonomy = projectTaxonomy.reduce(function (reduced, taxon) {
					var keywords = taxon.keywords.filter(function (keyword) {
						return keyword.selected;
					});
					
					var keywordIDs = keywords.map(function (keyword) {
						return keyword.id;
					});
					
					if (keywordIDs.length > 0) {
						reduced.push({
							id: taxon.id,
							keywordIDs: keywordIDs
						});
					}
					
					return reduced;
				}, []);
		
				initBudgets();
			});
		};
		
		var load = current != undefined;
		
		if (previous) {
			if (current.id == previous.id) {
				load = false;
			}
		}
		
		if (load) {
			processes.getProcess(current.id).then(onSuccess);
			
			$scope.project.processID = current.id;
		}
	});
	
	init();
});