"use strict";

canopy.directive("canopyToggle", function () {
	return {
		restrict: "A",
		scope: {
			model: "=",
			disabled: "="
		},
		controller: function ($scope) {
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/toggle/toggle.html"
	}
});