canopy.controller("UpdateAccountController",['$scope', '$state', '$timeout', '$http', 'canopySession', 'lumaPaths', 'canopyModals', '$window', function ($scope, $state, $timeout, $http, canopySession, lumaPaths, canopyModals, $window) {

	/* Function for the "Update contact details" section */
    var populateContactDetails = function() {

		/* Variables used */
        $scope.userEmail = canopySession.getUser().getContact().getEmail().getValue();
        $scope.userForename = canopySession.getUser().getContact().getForename();
        $scope.userSurname = canopySession.getUser().getContact().getSurname();
		$scope.profileUpdate = {
			forename: $scope.userForename,
			surname: $scope.userSurname
		};
		$scope.contactID = canopySession.getUser().getContact().id;
		$scope.newPhone =
		{
			"available": [
				{
					"label": "Main"
				},
				{	
					"label": "Office"
				},
				{
					"label": "Home"
				},
				{	
					"label": "Mobile"
				}
			],

			"selected": null
		}
		$scope.userNumbersList = [];


		/* Generate a list of the user's available phone numbers through API GET to enable the $state instant update */
		var getUserNumbersList = function() {

		 	$http({
				url: lumaPaths.serviceURI + '.ListContacts$Numbers',
				method: 'GET',
				params: {
					"contactID": $scope.contactID
				},
				headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
			})
			.then(
				function (list) {
					onSuccess(list);
				},
				function () {	
					console.dir('API failed');	
				}
			);
		}

		var onSuccess = function(list) {
			list.data.forEach(function(obj) {

				$http({
					url: lumaPaths.serviceURI + '.GetContact$Number',
					method: 'GET',
					params: {
						"numberID": obj.id
					},
					headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
				})
				.then(
					function (obj) {
						$scope.userNumbersList.push(obj.data);
					},
					function () {	
						console.dir('API failed');	
					}
				);
			})
		}

		getUserNumbersList();
		
		/* Modals Contact used for the success/error */
		$scope.closeAlertContact = function() {
			$scope.showContact = false;
		};

		var successAlert = function(msg) {
			$scope.alert =
				{ 
					type: 'success',
					msg: msg
				}
			;

			$scope.showContact = true;
		};

		var errorAlert = function(errorResponse) {

			var message = errorResponse.data.message;

			if (errorResponse.reasons && errorResponse.reasons.length) {
				errorResponse.reasons.forEach(function (element) {
					message = message + " [" + element.key + ": " + element.value + "]";
				});
			};

			$scope.alert =
				{ 
					type: 'danger', 
					msg: 'Oh snap! ' +  message
				};

			$scope.show = true;
		};

		/* The Forename / Surname editing API function */
		var updateName = function () {
			$scope.isSubmitting = true;

			var requestData = {
				"forename": $scope.profileUpdate.forename,
				"surname": $scope.profileUpdate.surname
			};

			$http({
				url: lumaPaths.serviceURI + ".EditProfile",
				method: "POST",
				data: $.param(requestData),
				headers: {"Content-Type": "application/x-www-form-urlencoded"}

			})			
			.then(
				function () {
					successAlert('Contact details updated');
					$scope.isSubmitting = false;
				}, 
				function (response) {			
					errorAlert(response);				
				}
			);
		};

		/* The Phone editing API function */
		var updateContactNumber = function () {
			$scope.userNumbersList.forEach(function(obj) {
				$scope.isSubmitting = true;

				var requestData = {
					"numberID": obj.id,
					"label": obj.label,
					"value": obj.value
				};
	
				$http({
					url: lumaPaths.serviceURI + '.EditContact$Number',
					method: 'POST',
					data: $.param(requestData),
					headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
	
				})
				.then(
					function () {
						successAlert('Contact details updated');
						$scope.isSubmitting = false;
					}, 
					function (response) {			
						errorAlert(response);				
					}
				);
			})
		};

		$scope.updateContactDetails = function() {
			updateName();
			updateContactNumber();
		}		

		/* The Phone adding API function */
		$scope.addContactNumber = function (number) {

			var requestData = {
				"contactID": $scope.contactID,
				"label": number.label,
				"value": number.value
			};

			$http({
				url: lumaPaths.serviceURI + '.AddContact$Number',
				method: 'POST',
				data: $.param(requestData),
				headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
			})
			.then(
				function () {
					$scope.isSubmitting = true;

					$timeout(function () { 
						$state.reload();
					}, 500);

					successAlert('New number added');
				}, 
				function (response) {
					$scope.isSubmitting = false;		
		
					errorAlert(response);				
				}
			);
		}

		/* The Phone remove API function */
		$scope.deleteContactNumbers = function ( number ) {
			this.$onInit = function() {
				$scope.deletedID = this.numberObject.id
			}

			$http({
				url: lumaPaths.serviceURI + '.DeleteContacts$Number',
				method: 'POST',
				data: $.param({
					"numberIDs": number.id
				}),
				headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
			})
			.then(
				function () {
					$scope.isSubmitting = true;
					$state.reload();
				}, 
				function (response) {	
					$scope.isSubmitting = false;		
					errorAlert(response);				
				}
			)
		};
    };
	
	/* Function for the "Group and business unit information" section */
    var populateUserPanel = function() {

		/* Variables used */
        var userGroups = canopySession.getUser().getGroups();
        var branchGroupsString = "";
        var regionGroupsString = "";
        var unitGroupsString = "";
        var permissionGroupsString = "";
        var userDomain = canopySession.getAvailableDomains().find(function (domain) {
            return domain.id == canopySession.getUser().getDomainID();
		});
		
		/* Populate the user's group info */ 
        $scope.userDomainTitle = userDomain.title;

        userGroups.forEach(
            function(group) {

                if(group.type == "Branch") {
                    return branchGroupsString += group.name + " & ";
                } 
    
                if(group.type == "Region") {
                    return regionGroupsString += group.name + " & ";
                };
    
                if(group.type == "Unit") {
                    return unitGroupsString += group.name + " & ";
                };
    
                if(group.type == "Permission") {
                    return permissionGroupsString += group.name + " & ";
                };
            }
        );

        (!branchGroupsString.length == 0) ? $scope.branchGroups = branchGroupsString.slice(0, -2) : $scope.branchGroups = "There are no Branch group(s) assigned to this user.";
        (!regionGroupsString.length == 0) ? $scope.regionGroups = regionGroupsString.slice(0, -2) : $scope.regionGroups = "There are no Region group(s) assigned to this user.";
        (!unitGroupsString.length == 0) ? $scope.unitGroups = unitGroupsString.slice(0, -2) : $scope.unitGroups = "There are no Unit group(s) assigned to this user.";
        (!permissionGroupsString.length == 0) ?  $scope.permissionGroups = permissionGroupsString.slice(0, -2) : $scope.permissionGroups = "There are no Permission group(s) assigned to this user.";
        
		var onSuccess = function(response) {
			$window.localStorage.setItem("deletedUserInfo", JSON.stringify(response.data));

			var modal = canopyModals.definitions.message({
				header: "ACCOUNT CLOSED	",
				body: "<p>Your account has been reset so you can update your group information. Please click continue to update your group info.</p>" + "<p>User email: " + response.data.email + "</p>" + "<p>First name: " + response.data.forename + ", Last name: " + response.data.surname + "</p>",
				confirm: "Continue"
			});
			
			canopyModals.instantiate(modal).result
				.then(
					function () {
						$window.location.href = lumaPaths.context + '/#!/register';
					}, 
					function() {
						$window.location.href = lumaPaths.context + '/#!/register';
					}
				);
		}

		/* Modals Contact used for the success/error */
		$scope.closeAlertUserInfo = function() {
			$scope.userInfo = false;
		};
		
		var onError = function(response) {

			$scope.alert =
				{ 
					type: 'danger', 
					msg: response.data.message
				};

			$scope.userInfo = true;
		}

		/* Delete and reset user */
		var closeUserAccount = function() {

			$http({
				url: lumaPaths.serviceURI + '.CloseUserAccount',
				method: 'POST',
				headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
			})
			.then(
				function (response) {
					onSuccess(response);
				},
				function (response) {
					onError(response);
				}
			);
		}

		/* Modal to update the user's group info */ 
		$scope.onUpdate = function() {
    		var modal = canopyModals.definitions.message({
    			header: "IMPORTANT INFORMATION",
				body: "By updating your details you will be resetting your account. This means you will no longer have access to your previous orders or saved artwork. Please confirm you want to continue.",
				cancel: "Cancel",
				confirm: "Continue"
    		});
    		
			canopyModals.instantiate(modal).result
				.then(function () {
					closeUserAccount();
				});

		    return;
		}
	};

	/* Function for the "Password reset (via Email)" section */
	$scope.passwordReset = function() {

		$scope.userEmail = canopySession.getUser().getContact().getEmail().getValue();

		var modal = canopyModals.definitions.message({
			header: "Password reset",
			body: "After logging out, your previous password will no longer be valid. Do you want to proceed ?",
			cancel: "Cancel",
			confirm: "Confirm"
		});
		
		canopyModals.instantiate(modal)
			.result
			.then(
				function () {
					$http({
						url: lumaPaths.serviceURI + ".RequestUserPasswordReset",
						method: "POST",
						data: $.param({
							email: $scope.userEmail
						}),
						headers: {"Content-Type": "application/x-www-form-urlencoded"}
					})
					.then(
						function() {
							successAlert();
						},
						function () {
							errorAlert();
						}
					);
				}
			);

		/* Modals Contact used for the success/error */
		$scope.closeAlertEmail = function() {$scope.showEmail = false;};

		var successAlert = function() {
			$scope.alert =
			{ 
				type: 'success',
				msg: 'Well done! You successfully reset your password.' 
			};

			$scope.showEmail = true;
		}

		var errorAlert = function () {
			$scope.alert =
			{ 
				type: 'danger', 
				msg: 'Oh snap! Something went wrong with your password reset'
			};

			$scope.show = true;
		};
	};

	/* Initiate function */
    var init = function () {		
        populateContactDetails();
		populateUserPanel();
    };
    
	init();
	
}]);