canopy.controller("AssetOrderController", function ($scope, $state, $stateParams, canopySession, orders, assets, contacts, budgets, transforms, processes, canopyModals) {    	    	
	$scope.order = {
		processID: undefined
	};
	
	$scope.init = function () {
		$scope.transformID  = $stateParams.transformID;
		$scope.assetID      = $stateParams.assetID;
		$scope.adaptationID = $stateParams.adaptationID;
		$scope.autoApprove  = false;
		$scope.forms        = [];
		
		if ($scope.transformID && $scope.assetID) {
			initOrder();
			initAncillary();
			initResource();
			initRoute();
			initRates();
			initBudgets();
			initContacts();
			initGroups();
		}
	};
	
	var initOrder = function () {
		$scope.order = {
			name: "",
			transformID: $scope.transformID,
			processID: null,
			budgetID: null,
			rateID: null,
			quantity: 0,
			total: 0,
			person: "",
			fao: "",
			company: "",
			address: "",
			area: "",
			region: "",
			country: "",
			postcode: "",
			email: "",
			phone: "",
			instructions: "",
			groupIDs: [],
			recipients: "",
			metadata: {},
			itemMetadata: {},
			keys: {}
		};
	};
	
	var initAncillary = function () {
		$scope.ancillary = {
		};
	};
	
	var initResource = function () {
		var onSuccess = function (response) {
			$scope.asset = response.data;
				
			if ($scope.adaptationID) {
				$scope.order.adaptationID = response.data.id;
			}
			else {
				$scope.order.assetID = response.data.id;
			}
			
			$scope.order.name = response.data.name + " Order";
			$scope.done = true;
		};
		
		assets.getAsset($scope.adaptationID || $scope.assetID).then(onSuccess);
		
		if ($scope.adaptationID) {
			assets.getAdaptationMedia($scope.adaptationID).then(function (response) {
				$scope.order.itemMetadata.adaptation = {
					media: response.data
				};
			});
		}
	};
	
	var initRoute = function () {
		var onGetProcessSuccess = function (response) {
			$scope.order.processID = response.data.id;
			$scope.form = response.data.form;
			
			$scope.ancillary.process = response.data;
			
			if ($scope.ancillary.process.metadata) {
				$scope.ancillary.process.metadata = JSON.parse($scope.ancillary.process.metadata);
			}
		};
		
		var onGetTransformSuccess = function (response) {
			if (response.data.processID) {
				processes.getProcess(response.data.processID).then(onGetProcessSuccess);
			}
			
			if (response.data.metadata) {
				var metadata = JSON.parse(response.data.metadata);
			
				if (metadata.hasOwnProperty("autoApprove") && metadata.autoApprove === true) {
					$scope.ancillary.autoApprove = true;
				}
			}
		};
		
		transforms.getTransform($scope.transformID).then(onGetTransformSuccess);
	};
	
	var initRates = function () {
		$scope.ancillary.rates = {
			available: undefined,
			selected:  undefined
		};
		
		var onGetAssetRatesSuccess = function (response) {
			$scope.ancillary.rates.available = response.data;
			
			if ($scope.ancillary.rates.available.length) {
				$scope.ancillary.rates.selected = "";
			}
		};
		
		assets.getAssetRates($scope.assetID).then(onGetAssetRatesSuccess);
	};
	
	var initBudgets = function () {
		$scope.ancillary.budgets = {
			available: undefined,
			selected:  undefined
		};
		
		var domain = canopySession.getActiveDomain();
		
		budgets.listBudgetsForDomain(domain.id)
			.then( function (response) {
    			$scope.ancillary.budgets.available = response.data;
				
				if ($scope.ancillary.budgets.available.length) {
	    			$scope.ancillary.budgets.selected = "";
	    		}
			})
			.catch( function (response) {
    		});
    };
    
    var initContacts = function () {
    	$scope.ancillary.contacts = {
    		company: {
    			available: undefined,
    			selected:  undefined
    		},
    		person: {
    			available: undefined,
    			selected:  undefined
    		}
    	};
    	
    	var contactTypeFilter = function (type) {
			return function (element) {
				return element.type === type;
			};
		};		
		
		var contactMapper = function (type) {
			return function (element) {
				var contact = element;
				
				if (type === "Person") {
					contact.name = element.forename + " " + element.surname;
				}
				
				return contact;
			};
		};
    	
		contacts.listUserContacts(Luma.user.userID)
			.then( function (response) {
    			var userContacts = response.data;
    			
    			if (userContacts.length) {				
					var companies = userContacts.filter(contactTypeFilter("Company"));
					var personals = userContacts.filter(contactTypeFilter("Person"));
				
					companies = companies.map(contactMapper("Company"));
					personals = personals.map(contactMapper("Person"));
				
					if (companies.length) {
						$scope.ancillary.contacts.company.available = companies;
						$scope.ancillary.contacts.company.selected  = undefined
					}
				
					if (personals.length) {
						$scope.ancillary.contacts.person.available = personals;
						$scope.ancillary.contacts.person.selected  = undefined
					}
				}
			})
			.catch( function (response) {
    		});
    };
    
    var initGroups = function () {
    	var groupTypeFilter = function (type) {
			return function (element) {
				return element.value.type === type;
			};
		};
		
		var groupMapper = function (element) {
			return {
				id: element.value.id,
				name: element.value.name,
				type: element.value.type
			};
		};
    	
    	var user   = canopySession.getUser();
    	var groups = user.groups.values;
    	
    	var units    = groups.filter(groupTypeFilter("Unit"));
    	var regions  = groups.filter(groupTypeFilter("Region"));
    	var branches = groups.filter(groupTypeFilter("Branch"));
    	
    	units    = units.map(groupMapper);
    	regions  = regions.map(groupMapper);
    	branches = branches.map(groupMapper);
    	
    	$scope.ancillary.groups = {
    		unit: {
    			available: units,
    			selected: undefined
    		},
    		region: {
    			available: regions,
    			selected: undefined
    		},
    		branch: {
    			available: branches,
    			selected: undefined
    		}
    	};
    	
    	if (!$scope.ancillary.groups.unit.available.length) {
			$scope.ancillary.groups.unit.selected = undefined;
		}

		if (!$scope.ancillary.groups.region.available.length) {
			$scope.ancillary.groups.region.selected = undefined;
		}

		if (!$scope.ancillary.groups.branch.available.length) {
			$scope.ancillary.groups.branch.selected = undefined;
		}
    };
    
    var getGroupIDs = function () {
    	var groupIDs = [];
    	
    	for (var key in $scope.ancillary.groups) {
    		var group = $scope.ancillary.groups[key];
    		
    		if (group && group.selected) {
    			groupIDs.push(group.selected.id);
    		}
    	}
    	
    	return groupIDs.toString();
    };
    
    var getSelectedGroups = function () {
    	var selectedGroups = [];
    	
    	for (var key in $scope.ancillary.groups) {
    		var group = $scope.ancillary.groups[key];
    		
    		if (group && group.selected) {
    			selectedGroups.push(group.selected);
    		}
    	}
    	
    	return selectedGroups;
    };
    
    var getRecipients = function () {
    	var recipients = [];
    	
    	if ($scope.order.recipients.length) {
			var emails = $scope.order.recipients.replace(/ /g, "").split(",");

			emails.forEach(function (email) {
				recipients.push({
					email: email,
					state: "Approved"
				});
			});
    	}
    	
    	return recipients;
    };
    
    $scope.submitRequest = function (form) {
    	$scope.isSubmitting = true;
    	$scope.$broadcast("submit");
    	
    	var invalid = false;
    	
    	$scope.forms.forEach(function (element) {
    		var form = element.node[element.name];
    		
    		if (form.$invalid) {
				element.node.$parent.isOpen = true;
				invalid = true;
    		}
    	});
    	
    	if (invalid) {
    		var modal = canopyModals.definitions.message({
    			header: "Could not submit order",
    			body: "You haven't entered any data into one or more required form fields. Please check the form, enter the required information, and resubmit."
    		});
    		
    		canopyModals.instantiate(modal).result.then(function () {
		    	$scope.isSubmitting = false;
    		});
    		
		    return;
    	}
    	
    	var groupMapper = function (element) {
    		return element.id;
    	};
    	
    	var selectedGroups   = getSelectedGroups($scope.ancillary.groups);
    	var selectedGroupIDs = selectedGroups.map(groupMapper).toString();
    	
    	$scope.order.metadata.groups = selectedGroups;
    	$scope.order.groupIDs        = selectedGroupIDs;
    	
    	if ($scope.ancillary.groups.unit.selected) {
    		$scope.order.area = $scope.ancillary.groups.unit.selected.name;
    	}
    	
    	if ($scope.ancillary.groups.region.selected) {
    		$scope.order.region = $scope.ancillary.groups.region.selected.name;
    	}
		
		if ($scope.ancillary.process && $scope.ancillary.process.destination === "Download") {
			$scope.order.quantity = undefined;
			$scope.order.total    = undefined;
			$scope.order.rateID   = undefined;
			
			if ($scope.order.metadata && $scope.order.metadata.rate) {
				delete $scope.order.itemMetadata.rate;
			}
		}
		
    	$scope.order.recipients = getRecipients();
    	
    	if ($scope.ancillary.autoApprove) {
    		$scope.order.state = "Approved";
    	}
    	
		orders.placeOrder($scope.order)
			.then(function(response) {
		    	$scope.isSubmitting = false;
		    	
		    	var successMessage = "";
		    	
		    	if ($scope.ancillary.autoApprove) {
		    		successMessage = "Your order has been submitted. Your order ID is: " + response.data;
		    	}
		    	else {
		    		successMessage = "Your order has been submitted. It is now waiting approval. Your order ID is: " + response.data;
		    	}
		    	
				var modal = canopyModals.definitions.message({
					header: "SUCCESS",
					body: successMessage
				});
		
				canopyModals.instantiate(modal).result.then(function () {
					$state.transitionTo("user_order", {
						orderID: response.data
					});
				});
			})
			.catch(function (response) {
		    	$scope.isSubmitting = false;
				
				var header = "Error";
				var message = response.data.message
				
				if (response.data.reasons.length) {
					var lineItemError = response.data.reasons.find(function (element) {
						return element.key == "lineItems";
					});
					
					if (lineItemError) {
						var unavailableIndices = lineItemError.children.map(function (entry) {
							return parseInt(entry.key);
						});
						
						if (unavailableIndices.length > 0) {
							header  = "Unavailable item";
							message = "Unfortunately, the item in your order is unavailable for ordering.";
						}
					}
					else {
						response.data.reasons.forEach(function (element) {
							message = message + " [" + element.key + ": " + element.value + "]";
						});
					}
				}
				
				canopyModals.instantiate(canopyModals.definitions.message({
					header: header,
					body: message
				}));
			});
    };
    
	$scope.$watch("ancillary.rates.selected", function (rate) {
		if (rate) {
			$scope.ancillary.volumes = {
				available: undefined,
				selected:  undefined
			};
			
			$scope.ancillary.volumes.available = rate.volumes;
			
			$scope.order.rateID            = rate.id;
			$scope.order.itemMetadata.rate = rate;
		}
	});
	
	$scope.$watch("ancillary.volumes.selected", function (volume) {
		if (volume) {
			$scope.order.quantity = volume.min;
			$scope.order.total    = volume.value;
		}
	});
	
	$scope.$watch("ancillary.budgets.selected", function (budget) {
		if (budget) {
			$scope.order.budgetID        = budget.id;
			$scope.order.metadata.budget = budget;
		}
	});
	
	var handleCompanyAddressSelection = function (address) {
		if (address) {
			var addressString = "";

			var getAddressLine = function (address, key) {
				var value = address[key];
	
				if (value && value.length) {
					return value + "\n";
				}
	
				return "";
			};

			addressString += getAddressLine(address, "street");
			addressString += getAddressLine(address, "line2");
			addressString += getAddressLine(address, "line3");
			addressString += getAddressLine(address, "town");
			addressString += getAddressLine(address, "city");
			addressString += getAddressLine(address, "state");

			$scope.order.address  = addressString;
			$scope.order.country  = address.country;
			$scope.order.postcode = address.postcode;
		}
	};
	
	var handleCompanyContactSelection = function (contact) {
		$scope.order.address  = "";
		$scope.order.country  = "";
		$scope.order.postcode = "";
		
		contacts.getContact(contact.id)
			.then( function (response) {
				$scope.order.company = response.data.title;
				
				if (response.data.addresses.length) {
					var addresses = response.data.addresses;
					
					$scope.ancillary.contacts.company.addresses = {
						available: addresses,
						selected:  undefined
					};
				}
				
			})
			.catch( function (response) {
			});
	};
	
	var handlePersonNumberSelection = function (number) {
		if (number) {
			$scope.order.phone = number.value;
		}
	};
	
	var handlePersonEmailSelection = function (email) {
		if (email) {
			$scope.order.email = email.value;
		}
	};
	
	var handlePersonContactSelection = function (contact) {
		$scope.order.person = "";
		$scope.order.fao    = "";
		$scope.order.phone  = "";
		$scope.order.email = "";
		
		contacts.getContact(contact.id)
			.then( function (response) {
				$scope.order.person = response.data.forename + " " + response.data.surname;
				$scope.order.fao    = response.data.forename + " " + response.data.surname;
				
				if (response.data.numbers.length) {
					var numbers = response.data.numbers;
					
					$scope.ancillary.contacts.person.numbers = {
						available: numbers,
						selected:  undefined
					};
				}
				
				if (response.data.emails.length) {
					var emails = response.data.emails;
					
					$scope.ancillary.contacts.person.emails = {
						available: emails,
						selected:  undefined
					};
				}
			})
			.catch( function (response) {
			});
	};
	
	$scope.registerForm = function (element, name) {
		$scope.forms.push({
			name: name,
			node: element
		});
	};
    
    $scope.$watch("ancillary.contacts.company.addresses.selected", function (address) {
    	if (address) {
    		handleCompanyAddressSelection(address);
    	}
    });
    
    $scope.$watch("ancillary.contacts.person.numbers.selected", function (number) {
    	if (number) {
    		handlePersonNumberSelection(number);
    	}
    });
    
    $scope.$watch("ancillary.contacts.person.emails.selected", function (email) {
    	if (email) {
    		handlePersonEmailSelection(email);
    	}
    });
    
    $scope.$watch("ancillary.contacts.company.selected", function (contact) {
    	if (contact) {
    		handleCompanyContactSelection(contact);
    	}
    });
    
    $scope.$watch("ancillary.contacts.person.selected", function (contact) {
    	if (contact) {
    		handlePersonContactSelection(contact);
    	}
    });
    	
	$scope.init();
});