canopy.directive("canopyContactSelector", function () {
	return {
		restrict: "A",
		controller: function ($scope, contacts) {			
			$scope.onContactChanged = function (contact) {
				var callback = $scope.onContactSelected;
				
				if (callback) {
					callback(contact);
				}
				
				loadContactAddresses(contact);
				loadContactNumbers(contact);
				loadContactEmails(contact);
			};
			
			$scope.onAddressChanged = function (address) {
				var onSuccess = function (response) {
					var callback = $scope.onAddressSelected;
					
					if (callback) {
						callback(response.data);
					}
				};
				
				contacts.getContactAddress(address).then(onSuccess);
			};
			
			$scope.onNumberChanged = function (number) {
				var onSuccess = function (response) {
					var callback = $scope.onNumberSelected;
					
					if (callback) {
						callback(response.data);
					}
				};
				
				contacts.getContactNumber(number).then(onSuccess);
			};
			
			$scope.onEmailChanged = function (email) {
				var onSuccess = function (response) {
					var callback = $scope.onEmailSelected;
					
					if (callback) {
						callback(response.data);
					}
				};
				
				contacts.getContactEmail(email).then(onSuccess);
			};
			
			var loadContactAddresses = function (contact) {
				var onSuccess = function (response) {
					$scope.selected.contact.addresses = response.data;
					
					if ($scope.selected.contact.addresses.length) {
						$scope.selected.address = $scope.selected.contact.addresses[0];
					}
				};
				
				contacts.listContactAddresses(contact).then(onSuccess);
			};
			
			var loadContactNumbers = function (contact) {
				var onSuccess = function (response) {
					$scope.selected.contact.numbers = response.data;
					
					if ($scope.selected.contact.numbers.length) {
						$scope.selected.number = $scope.selected.contact.numbers[0];
					}
				};
				
				contacts.listContactNumbers(contact).then(onSuccess);
			};
			
			var loadContactEmails = function (contact) {				
				var onSuccess = function (response) {
					$scope.selected.contact.emails = response.data;
					
					if ($scope.selected.contact.emails.length) {
						$scope.selected.email = $scope.selected.contact.emails[0];
					}
				};
				
				contacts.listContactEmails(contact).then(onSuccess);
			};
			
			var onSuccess = function (response) {
				$scope.contacts = response.data;
				
				if ($scope.contactTypes) {
					var types = $scope.contactTypes.replace(/ /g, "").split(",");
					
					$scope.contacts = $scope.contacts.filter(function (element) {
						return types.indexOf(element.type) >= 0;
					});
				}
				
				if ($scope.contacts.length) {
					$scope.contacts.forEach(function (contact) {
						if (contact.type == "Person") {
							contact.label = contact.forename.concat(" ", contact.surname);
						}
						else {
							contact.label = contact.title;
						}
					});
					
					$scope.selected.contact = $scope.contacts[0];
				}
			};
			
			contacts.listUserContacts(Luma.user.userID).then(onSuccess);
			
			$scope.selected = {
				contact: null,
				address: null,
				number: null,
				email: null
			};
			
			$scope.$watch("selected.contact", function (contact) {
				if (contact) {
					$scope.onContactChanged(contact);
				}
			});
			
			$scope.$watch("selected.address", function (address, previous) {
				if (address) {
					if (previous && previous.id == address.id) {
						return;
					}
					
					$scope.onAddressChanged(address);
				}
			});
			
			$scope.$watch("selected.number", function (number, previous) {
				if (number) {
					if (previous && previous.id == number.id) {
						return;
					}
					
					$scope.onNumberChanged(number);
				}
			});
			
			$scope.$watch("selected.email", function (email, previous) {
				if (email) {
					if (previous && previous.id == email.id) {
						return;
					}
					
					$scope.onEmailChanged(email);
				}
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/contact-selector/contact-selector.html",
		scope: {
			contactTypes: "@",
			onContactSelected: "=",
			onAddressSelected: "=",
			onNumberSelected: "=",
			onEmailSelected: "="
		},
		replace: true
	};
});