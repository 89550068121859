"use strict";

canopy.directive("canopyTypeahead", function (canopyModals) {
	return {
		controller: function ($scope) {
			$scope.typeahead = {
				model: null,
				options: [],
				onSelect: function (item, model, label) {
					if ($scope.onSelect) {
						$scope.onSelect(item, model);
					}
				}
			};
			
			$scope.$watch("options", function (options) {
				if (options instanceof Array) {
					$scope.typeahead.options = options;
				}
			});
		},
		link: function (scope, element) {
		},
		scope: {
			options: "=",
			onSelect: "="
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/typeahead/typeahead.html"
	};
});