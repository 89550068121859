"use strict";

canopy.directive("canopyResourceGridItem", function () {
	return {
		restrict: "A",
		controller: function ($scope, $timeout, assets, basket) {
			var initPreview = function () {
				var recipe  = $scope.resource.type === "Template" ? "Thumbnail" : "Icon_256";
				var assetID = $scope.resource.id;
				$scope.resource.fallbackImage = Luma.paths.context + "/system/mantle/marquee/site/static-images/fallback.png";
				$scope.resource.previewURL = Luma.paths.context + "/servlet/interface/com.intrepia.luma.DownloadAssetPreview?assetID=" + assetID + "&recipe=" + recipe;
			};

			var initActions = function () {
				$scope.resource.actions = [];
				
				var editAction = {
					icon: "fa-pencil"
				};
				
				var addToBasketAction = {
					icon: "fa-shopping-cart",
					execute: function () {
						return basket.addAssets($scope.resource.id.toString());
					}
				};
				
				var removeFromBasketAction = {
					icon: "fa-times",
					execute: function () {
						return basket.removeAssets($scope.resource.id.toString());
					}
				};
			
				if ($scope.resource.type === "Resource") {
					$scope.resource.actions.push(addToBasketAction);
					$scope.resource.actions.push(removeFromBasketAction);
				}
			};
			
			$scope.onViewDetailsClicked = function () {
				if ($scope.onViewDetails) {
					$scope.onViewDetails($scope.resource);
				}
			};
			
			$scope.init = function () {
				initPreview();
				initActions();
			};
			
			$scope.init();
			
			$scope.$watch("resource", function (value, previous) {
				// TODO: What exactly is going on here?
				if (value !== previous) {
					$scope.init();
				}
			});
			
			var actionMenuCloseTimeout = null;
			
			$scope.onActionMenuClick = function () {
				$scope.isActionMenuOpen = true
			};
			
			$scope.onActionMenuMouseLeave = function () {
				actionMenuCloseTimeout = $timeout(function () {
					$scope.isActionMenuOpen = false;
				}, 500);
			};
			
			$scope.onActionMenuMouseEnter = function () {
				$timeout.cancel(actionMenuCloseTimeout);
			};
			
			$scope.executeAction = function (action) {
				if (action.executing) {
					return;
				}
				
				action.executing = true;
				
				var onSuccess = function () {
					$scope.isActionMenuOpen = false;
					action.executing = false;
				};
				
				var onError = function () {
					$scope.isActionMenuOpen = false;
					action.executing = false;
				};
				
				if (action.execute) {
					action.execute().then(onSuccess).catch(onError);
				}
			};
			
			$scope.isActionMenuOpen = false;
			$scope.enableSelect = $scope.onSelect !== undefined && assets.utilities.isAssetSelectable($scope.resource);
		},
		scope: {
			resource: "=",
			onSelect: "=",
			context: "@",
			onViewDetails: "="
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/resource-grid/resource-grid-item.html"
	}
});