"use strict";

canopy.directive("trixToolbarCustomStyles", [function () {
    return {
        restrict: "AE",
        replace: false,
        templateUrl: "trixToolbarCustomStyles.html",
        scope: {
            styleDefinitions: "=",
            trixEditor: "="
        },
        controller: function($scope) {
            $scope.clearFormatting = function() {
                var selectedPieces = $scope.trixEditor.editor.getDocument().getDocumentAtRange($scope.trixEditor.editor.getSelectedRange()).getPieces();

                selectedPieces.forEach(function(piece) {
                    Object.keys(piece.getAttributes()).forEach(function(attribute) {
                        $scope.trixEditor.editor.deactivateAttribute(attribute);
                    })
                });
            }
        }
	};
}]);