"use strict";

canopy.directive("canopySearch", function () {
	return {
		restrict: "A",
		replace: true,
		controller: function ($scope, $timeout) {
			$scope.search = $scope.model;
			
			$scope.clearSearch = function () {
				$scope.search = "";
			};
			
			$scope.fireSearch = function () {
				$scope.model = $scope.search;
			};
			
			$scope.$watch("search", function (value, old) {
				if (value !== old) {
					if ($scope.searchTimer) {
						$timeout.cancel($scope.searchTimer);
					}
					
					$scope.searchTimer = $timeout($scope.fireSearch, 500);
				}
			});
		},
		scope: {
			model: "="
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/list/search.html"
	};
});