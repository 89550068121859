canopy.factory("processes", ["$http", "$q", "lumaPaths", "listService", function ($http, $q, lumaPaths, listService) {
	var getProcess = function (processID) {
		return $http({
			url: lumaPaths.serviceURI + '.GetProcess',
			method: 'POST',
			data: $.param({
				processID: processID
			}),
			headers: {'Content-Type': 'application/x-www-form-urlencoded'} 
		});
	};
	
	var getProcessTaxonomy = function (process) {
		return $http({
			url: lumaPaths.serviceURI + ".GetProcessTaxonomy",
			method: "POST",
			data: $.param({
				processID: process.id || process
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listProcessTaxonomy = function (process) {
		return $http({
			url: lumaPaths.serviceURI + ".ListProcessTaxonomy",
			method: "POST",
			data: $.param({
				processID: process.id || process
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listProcessTaxonomyWithKeywords = function (process) {
		var defer    = $q.defer();
		var promises = [];
		
		listProcessTaxonomy(process).then(function (response) {
			response.data.forEach(function (taxonomy) {
				var onListKeywordsResponse = function (response) {
					taxonomy.keywords = response.data;
				};
				
				promises.push(listProcessTaxonomyKeywords(taxonomy).then(onListKeywordsResponse));
			});
			
			$q.all(promises).then(function () {
				defer.resolve(response.data);
			});
		});
		
		return defer.promise;
	};
	
	var listProcessTaxonomyKeywords = function (taxonomy) {
		return $http({
			url: lumaPaths.serviceURI + ".ListProcessTaxonomyKeywords",
			method: "POST",
			data: $.param({
				taxonomyID: taxonomy.id || taxonomy
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listProcesses = function (domain) {
		return $http({
			url: lumaPaths.serviceURI + ".ListProcesses",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	return {
		getProcess: function (processID) {
			return getProcess(processID);
		},
		getProcessTaxonomy: function (process) {
			return getProcessTaxonomy(process);
		},
		listProcessTaxonomy: function (process) {
			return listProcessTaxonomy(process);
		},
		listProcessTaxonomyWithKeywords: function (process) {
			return listProcessTaxonomyWithKeywords(process);
		},
		listProcessTaxonomyKeywords: function (taxonomy) {
			return listProcessTaxonomyKeywords(taxonomy);
		},
		listProcesses: function () {
			return listProcesses();
		},
		list: function () {
			var list = listService.instantiate("ListProcesses");
			
			list.prime = function () {
			};
			
			return list;
		}
	};
}]);