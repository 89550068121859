"use strict";

canopy.directive("canopyListHeaderController", function () {
	return {
		restrict: "A",
		controller: function ($scope, $timeout) {
			var STATES = {
				HIDDEN: 0,
				SINGLE: 1,
				ALL: 2
			};
			
			$scope.STATES = STATES;
			
			$scope.menu = {
				state: STATES.HIDDEN,
				items: []
			};
			
			var done = false;
			
			$scope.hasAppliedFilters = false;

			$scope.$watch("filters", function (filters) {
				$scope.hasAppliedFilters = false;

				$scope.categories = filters.filter(function (filter) {
					return !filter.isHidden;
				});
				
				$scope.categories.forEach(function (category) {
					if (category.options) {
						category.options.forEach(function (option) {
							var found = category.applied.find(function (applied) {
								return applied == option.name;
							});
						
							if (found) {
								option.applied = true;
							}

							if (option.applied && option.applied == true) {
								$scope.hasAppliedFilters = true;
							}
						});
					}
				});
			}, true);

			$scope.hasRemainingApplyableFilters = function() {
				return $scope.categories.find(function(filter) {
					if (filter.applied.length != filter.options.length) {
						return true;
					} else {
						return false;
					}
				});
			}
			
			$scope.onFilterSelect = function (filter) {
				$scope.menu.items = [];
				
				if (filter.isActive) {
					$scope.menu.state = STATES.HIDDEN;
					
					filter.isActive = false;
				}
				else {
					$scope.menu.items.push(filter);
					$scope.menu.state = STATES.SINGLE;
					
					$scope.categories.forEach( function (element) {
						element.isActive = false;
					});
					
					filter.isActive = true;
				}
			};
			
			$scope.onDisplayAllSelect = function () {
				$scope.menu.items = [];
				
				if ($scope.menu.state === STATES.ALL) {
					$scope.menu.state = STATES.HIDDEN;
				}
				else {
					$scope.categories.forEach( function (filter) {
						filter.isActive = false;
					
						if (!filter.isHidden) {
							$scope.menu.items.push(filter);
						}
					});
					
					$scope.menu.state = STATES.ALL;
				}
			};
			
			$scope.onFilterOptionSelect = function (filter, option) {				
				if (filter.type === "AND") {
					$scope.menu.items = [];
					$scope.menu.state = STATES.HIDDEN;
				}
				else {
					option.applied = option.applied ? false : true;
				}
				
				$scope.toggleFilterOption(filter, option);
			};
			
			$scope.onCategoryListOverflow = function () {
				$timeout( function () {
					$scope.isCategoryListOverflowing = true;
				}, 1);
			};
			
			$scope.onCategoryListUnderflow = function () {
				$timeout( function () {
					$scope.isCategoryListOverflowing = false;
				}, 1);
			};
		}
	};
});