"use strict";

canopy.directive("canopyOverflowWatcher", function () {
	return {
		controller: function ($scope, $element, $timeout) {
			$timeout(function () {
  				var element = $element[0];
  				
  				var threshhold = 45;
			
				if ((element.offsetHeight + threshhold) < element.scrollHeight || (element.offsetWidth + threshhold) < element.scrollWidth) {
					$scope.overflowModel = true;
				}
				else {
					$scope.overflowModel = false;
				}
			});
		},
		scope: {
			overflowModel: "="
		}
	};
});