canopy.controller("RegistrationListController", function ($scope, $controller, $timeout, lumaPaths, canopySession, listService, registrations, menuNode) {
	'use strict';
	$controller("ListController", {
		$scope:    $scope,
		$timeout:  $timeout,
		lumaPaths: lumaPaths,
		menuNode:  menuNode
	});
	
	$scope.initList = function () {
		if ($scope.list == undefined) {
			return;
		}
		
		$scope.list.setService($scope.list.getService() || "ListRegistrations");

 		var domain = canopySession.getActiveDomain();

		$scope.list.registerFilters([
			{"name": "Date", "type": "com.intrepia.luma.ListRegistrations$PeriodFilter", "from": null, "to": null, "instant": "Created" },
			{"name": "Unit", "type": "com.intrepia.luma.ListRegistrations$GroupFilter", "groupType": "Unit"},
			{"name": "Region", "type": "com.intrepia.luma.ListRegistrations$GroupFilter", "groupType": "Region"},
            {"name": "Branch", "type": "com.intrepia.luma.ListRegistrations$GroupFilter", "groupType": "Branch"},
            {"name": "State", "type": "com.intrepia.luma.ListRegistrations$KeyFilter", "key": "state", "applied": []}
		]);

		$scope.list.setParameter("domainID", domain.id );
		$scope.list.setParameter("includeSubDomains", true);
	};
	
	$scope.getDetailsService = function (item) {
		return registrations.getRegistration(item.id);
	};
		
	$scope.init();
});