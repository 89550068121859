canopy.controller("CampaignDetailController", function ($scope, $stateParams, $state, $location, menuNode, lumaPaths, campaigns, assets, budgets, reimbursements, listService, $window, canopyModals, canopySession, canopyEvents, $timeout, $filter) {
	// TODO: Find a better way to handle keeping track of forms.
	$scope.forms = [];
	
	var initCampaign = function () {
		$scope.isInit = true;
		$scope.role = null;
		$scope.canSave = false;
		$scope.canEdit = false;
		
		var user = canopySession.getUser();
		
		try {
		    $scope.role = menuNode.metadata.page.list.parameters.role;
	    }
	    catch (e) {
	    	$scope.role = null;
	    }
	    
	    if (!$scope.role) {
	    	$scope.role = "Owner";
	    }
		
		var onSuccess = function (response) {
			$scope.campaign = response.data;
			
			initCampaignInfo();
			initCampaignActivity();
			initCampaignProjects();
			initActions();
		};
		
		campaigns.getCampaign($stateParams.campaignID).then(onSuccess);
	};
	
	var initCampaignInfo = function () {
		var onSuccess = function (response) {
			$scope.campaign.info = response.data;
		};
		
		campaigns.getCampaignInfo($stateParams.campaignID).then(onSuccess);
	};
	
	var initCampaignProjects = function () {
		var onSuccess = function (response) {
			$scope.campaign.projects = response.data.table;
		};
		
		var list = listService.instantiate("ListProjects");
		
		list.setParameter("domainID", canopySession.getActiveDomain().id);
		list.setParameter("campaignIDs", $stateParams.campaignID);
		list.setParameter("includeSubdomains", true);
		list.setParameter("listing-style", "Page");
		list.setParameter("listing-sort", "asset-created");
		list.setParameter("listing-order", "Descending");
		
		list.load().then(onSuccess);
	};
	
	var initCampaignActivity = function () {
		var onSuccess = function (response) {
			var limit = 4;
			
			if ($scope.campaign.activity) {
				limit = $scope.campaign.activity.viewLimit || limit;
			}
			
			$scope.campaign.activity = response.data;
			$scope.campaign.activity.viewLimit = limit;
		};
		
		campaigns.listCampaignActivity($scope.campaign).then(onSuccess);
		
		$scope.comment = {
			title: undefined,
			body: ""
		};
	};
	
	var initActions = function () {
		var createUpdateStateAction = function (state, permitted) {		
			return {
				label: "Mark as " + state.toLowerCase(),
				execute: function () {
					var modal = canopyModals.definitions.form({
						header: $filter("localise")("Mark as " + state.toLowerCase()),
						form: Luma.paths.context + "/resolve/marquee/site/forms/set-state.html"
					});
					
					canopyModals.instantiate(modal).result.then(function (form) {
						updateCampaignState(state, form.comment);
					});
				},
				permitted: permitted
			};
		};
		
		var cancelAction = createUpdateStateAction("Cancelled", function () {
			return $scope.role == "Owner" &&
				   $scope.campaign.state != "Cancelled" &&
				   $scope.campaign.state != "Completed";
					
		});
		var newAction = createUpdateStateAction("New", function () {
			return ($scope.role == "Owner" && $scope.campaign.state == "Rejected");
		});
		var unapproveAction = createUpdateStateAction("Unapproved", function () {
			return $scope.role == "Owner" && $scope.campaign.state == "New";
		});
		var approveAction = createUpdateStateAction("Approved", function () {
			return $scope.role == "Approver" && $scope.campaign.state == "Unapproved";
		});
		var rejectAction = createUpdateStateAction("Rejected", function () {
			return ($scope.role == "Approver" && $scope.campaign.state == "Unapproved") || 
				   ($scope.role == "Printer"  && $scope.campaign.state == "Approved") || 
				   ($scope.role == "Printer"  && $scope.campaign.state == "Processed") ||
				   ($scope.role == "Printer"  && $scope.campaign.state == "Dispatched");
		});
		var completeAction = createUpdateStateAction("Completed", function () {
			return ($scope.role == "Printer" && $scope.campaign.state == "Approved") || 
				   ($scope.role == "Printer" && $scope.campaign.state == "Processed") ||
				   ($scope.role == "Printer" && $scope.campaign.state == "Dispatched");
		});
		
		var addProjectAction = {
			label: "Add project",
			execute: function () {
				var onComplete = function () {					
					canopyEvents.dispatch("genericEvent", {
						message: "A new project has been added to this campaign."
					});
	
					initCampaignProjects();
				};
				
				campaigns.utilities.addCampaignProject($scope.campaign, onComplete);
			},
			permitted: function () {
				return true;
			}
		};
		
		$scope.actions = [{
			label: "Set state",
			actions: [
				newAction,
				unapproveAction,
				approveAction,
				rejectAction,
				completeAction
			]
		}, {
			label: "Edit",
			actions: [
				addProjectAction
			]
		}];
		
		$scope.actions.forEach(function (category) {
			category.actions = category.actions.filter(function (action) {
				return action.permitted();
			});
		});
	};
	
	var updateCampaignState = function (state, description) {
		var onSuccess = function () {
			$scope.init();
		};
		
		var onError = function (response) {
			canopyModals.instantiate(
				canopyModals.definitions.serverError(response.data)
			);
		};
		
		campaigns.setCampaignState($scope.campaign.id, state, description).then(onSuccess, onError);
	};
	
	$scope.submitComment = function () {
		var onSuccess = function () {
			initCampaignActivity();
		};
		
		assets.addAssetComment($scope.campaign.id, $scope.comment.title, $scope.comment.body).then(onSuccess);
	};
	
	$scope.init = function () {
		initCampaign();
		
		$scope.ancillary = {
		};
	};
	
	$scope.init();
	
	$scope.onViewProjectDetails = function (project) {
		var state = $scope.role == "Approver" ? "approver_project" : "user_project";
		
		$state.go(state, {
			projectID: project.id
		});
	};
});