canopy.controller("BatchDetailController", function ($scope, $state, $stateParams, $interval, listService, fusion, canopyModals) {
	var init = function () {
 		initBatch();
	};
	
	var initBatchProperty = function (service, property, callback) {
		var onSuccess = function (response) {
			$scope.batch[property] = response.data;
			
			if (callback) {
				callback();
			}
		};
		
		var onError = function () {
		};
		
		var list = listService.instantiate(service);
		
		list.setParameter("batchID", $scope.batch.id);
		
		list.load().then(onSuccess, onError);
	};
	
	var initBatch = function () {
		var onSuccess = function (response) {
			$scope.batch = response.data;
			
			initBatchProperty("ListBatchAdaptations", "adaptations");
			initBatchProperty("ListBatchTasks", "tasks");
			initBatchProperty("ListBatchOutputs", "outputs", function () {
				var outputs = $scope.batch.outputs.map(function (output) {
					return output.name;
				});
				
				outputs = outputs.filter(function (element, index, self) {
					return self.indexOf(element) === index;
				});
				
				outputs = outputs.map(function (type) {
					return {
						name: type,
						outputs: $scope.batch.outputs.filter(function (output) {
							return output.name == type;
						})
					};
				});
				
				$scope.batch.outputs = outputs;
			});
			
			initActions();
		};
		
		var onError = function () {
		};
		
		fusion.getBatch($stateParams.batchID).then(onSuccess, onError);
	};

	var initActions = function () {	
		$scope.actions = [{
			name: "batch",
			label: "Batch...",
			actions: [{
				label: "Download",
				href: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ShrinkWrapBatchOutputs?batchIDs=" + $scope.batch.id,
				permitted: function () {
					return true;
				}
			}, {
				label: "Resubmit",
				execute: function () {
					var onComplete = function (batchID) {
						var modal = canopyModals.definitions.message({
							header: "Success",
							body: "Your batch was successfully resubmitted."
						});
						
						canopyModals.instantiate(modal).result.then(function () {
							$state.go($state.current, {
								batchID: batchID
							});
						});
					};
					
					fusion.utilities.resubmitBatch($scope.batch, onComplete);
				},
				permitted: function () {
					return Luma.user.hasService("batch.resubmit");
				}
			}]
		}];
		
		$scope.actionCount = 0;
		
		$scope.actions.forEach(function (category) {
			category.actions = category.actions.filter(function (action) {
				return action.permitted();
			});
			
			$scope.actionCount += category.actions.length;
		});
	};
	
	init();
	
	$scope.interval = $interval(function () {
		var isPending = ["New", "Queued", "Working"].indexOf($scope.batch.state) >= 0;
		
		if (isPending) {
			fusion.getBatch($scope.batch.id).then(function (response) {
				var data = response.data;
		
				$scope.batch.state    = data.state;
				$scope.batch.complete = data.complete;
				$scope.batch.failed   = data.failed;
			});
		}
	}, 2000);
	
	$scope.$on("$destroy", function () {
    	$interval.cancel($scope.interval);
	});
});