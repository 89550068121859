canopy.controller("OrderListController", function ($scope, $controller, $stateParams, $timeout, lumaPaths, canopySession, menuNode, orders) {
	'use strict';
	$controller("ListController", {
		$scope:    $scope,
		$timeout:  $timeout,
		lumaPaths: lumaPaths,
		menuNode:  menuNode
	});
	
	$scope.initList = function () {
		if ($scope.list == undefined) {
			return;
		}
		
		var user   = canopySession.getUser();
		var domain = canopySession.getActiveDomain();
		
		var dateFilter = {
			name: "Date",
			type: "com.intrepia.luma.ListOrders$PeriodFilter",
			from: null,
			to: null,
			instant: "Created"
		};
		
		var stateFilter = {
			name: "State",
			type: "com.intrepia.luma.ListOrders$StateFilter"
		};
		
		var processFilter = {
			name: "Process",
			type: "com.intrepia.luma.ListOrders$ProcessFilter"
		};
		
		var unitFilter = {
			name: "Unit",
			type: "com.intrepia.luma.ListOrders$OrderGroupFilter",
			groupType: "Unit"
		};
		
		var regionFilter = {
			name: "Region",
			type: "com.intrepia.luma.ListOrders$OrderGroupFilter",
			groupType: "Region"
		};
		
		var branchFilter = {
			name: "Branch",
			type: "com.intrepia.luma.ListOrders$OrderGroupFilter",
			groupType: "Branch"
		};
		
		$scope.list.setService($scope.list.getService() || "ListOrders");
		
		$scope.list.setParameter("listing-sort", "order-created");
		$scope.list.setParameter("listing-order", "Descending");
		
		switch ($scope.list.getService() ) {
			case "ListOrdersForRole":			
				$scope.list.setParameter("domainID", domain.id);
				$scope.list.setParameter("includeSubDomains", true);
				$scope.list.setParameter("enforcements", "Strict");
								
				dateFilter.from  = Dates.encode(moment().subtract(30, "days").startOf("day").toDate());
				dateFilter.until = Dates.encode(moment().endOf("day").toDate());
				break;
				
			default:
				$scope.list.setParameter("domainID", user.userID);
				break;
		}
		
		$scope.list.registerFilter(dateFilter);
		$scope.list.registerFilter(stateFilter);
		$scope.list.registerFilter(processFilter);
		$scope.list.registerFilter(unitFilter);
		$scope.list.registerFilter(regionFilter);
		$scope.list.registerFilter(branchFilter);
	};
	
	$scope.initSearchTypes = function () {
		return [{
			property: "search",
			title: "Name"
		}, {
			property: "id",
			title: "ID"
		}, {
			property: "searchUsers",
			title: "User"
		}];
	};
	
	$scope.getDetailsService = function (item) {
		return orders.getOrder(item.id);
	};
		
	$scope.init();
});