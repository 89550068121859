canopy.factory("reimbursements", ["$http", "lumaPaths", "canopyModals", "$q", function ($http, lumaPaths, canopyModals, $q) {
	var listBudgetReimbursementsForTransaction = function (transaction) {
		return $http({
			url: lumaPaths.serviceURI + ".ListBudgetReimbursements",
			method: "POST",
			data: $.param({
				transactionID: transaction.id || transaction
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var getBudgetReimbursement = function (reimbursement) {
		return $http({
			url: lumaPaths.serviceURI + ".GetBudgetReimbursement",
			method: "POST",
			data: $.param({
				reimbursementID: reimbursement.id || reimbursement
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listBudgetReimbursementHistory = function (reimbursement) {
		return $http({
			url: lumaPaths.serviceURI + ".ListBudgetReimbursementHistory",
			method: "POST",
			data: $.param({
				reimbursementID: reimbursement.id || reimbursement
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var submitBudgetReimbursement = function (transaction, reimbursement) {
		var data = reimbursement;
		
		if (transaction) {
			data.transactionID = transaction.id || transaction;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".SubmitBudgetReimbursement",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};

	var submitBudgetReimbursements = function(transactions, reimbursementData) {
		var arrRequests = [];
		var transactionData = {};

		transactions.forEach(function(transaction, index) {
			transactionData = reimbursementData;
			transactionData.value = transaction.amountReimbursed;
			arrRequests.push(submitBudgetReimbursement(transaction.id, transactionData));
		});

		return $q.all(arrRequests);
	}
	
	var approveBudgetReimbursement = function (reimbursement, value, description) {
		var data = {
			reimbursementID: reimbursement.id || reimbursement
		};
		
		if (value) {
			data.value = value;
			
			if (!description) {
				data.description = "No comment.";
			}
		}
				
		if (description) {
			data.description = description;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".ApproveBudgetReimbursement",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var rejectBudgetReimbursement = function (reimbursement, value, description) {
		var data = {
			reimbursementID: reimbursement.id || reimbursement
		};
				
		if (description) {
			data.description = description;
		}
		else {
			data.description = "No comment.";
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".RejectBudgetReimbursement",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var editBudgetReimbursement = function (reimbursement, value, description) {
		var data = {
			reimbursementID: reimbursement.id || reimbursement,
			value: value
		};
				
		if (description) {
			data.description = description;
		}
		else {
			data.description = "No comment.";
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".EditBudgetReimbursement",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var setBudgetReimbursementPaid = function (reimbursement) {
		return $http({
			url: lumaPaths.serviceURI + ".SetBudgetReimbursementsPaid",
			method: "POST",
			data: $.param({
				reimbursementIDs: reimbursement.id || reimbursement
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	return {
		listBudgetReimbursementsForTransaction: function (transaction) {
			return listBudgetReimbursementsForTransaction(transaction);
		},
		getBudgetReimbursement: function (reimbursement) {
			return getBudgetReimbursement(reimbursement);
		},
		listBudgetReimbursementHistory: function (reimbursement) {
			return listBudgetReimbursementHistory(reimbursement);
		},
		approveBudgetReimbursement: function (reimbursement, value, description) {
			return approveBudgetReimbursement(reimbursement, value, description);
		},
		rejectBudgetReimbursement: function (reimbursement, value, description) {
			return rejectBudgetReimbursement(reimbursement, value, description);
		},
		editBudgetReimbursement: function (reimbursement, value, description) {
			return editBudgetReimbursement(reimbursement, value, description);
		},
		setBudgetReimbursementPaid: function (reimbursement) {
			return setBudgetReimbursementPaid(reimbursement);
		},
		submitBudgetReimbursement: function (transaction, reimbursement) {
			return submitBudgetReimbursement(transaction, reimbursement);
		},
		submitBudgetReimbursements: function (transactions, reimbursement) {
			return submitBudgetReimbursements(transactions, reimbursement);	
		},
		utilities: {
			createReimbursement: function (transaction, callback) {
				var onComplete = function (reimbursement) {				
					var modal = canopyModals.definitions.message({
						header: "Success",
						body: "Your reimbursement was created"
					});
				
					callback = callback || function () {};
				
					canopyModals.instantiate(modal).result.then(function () {
						callback(reimbursement);
					});
				};
				
				var onError = function () {
				};
				
				if (transaction == undefined) {
					console.log("ERROR: reimbursements.utilities.createReimbursement - No transaction specified.");
					return;
				}
				
				var modal = canopyModals.definitions.submitBudgetReimbursement({
					transactionID: transaction.id || transaction
				});
				
				canopyModals.instantiate(modal).result.then(onComplete);
			},
			createReimbursements: function(transactions, callback) {
				// Bulk reimbursements...

				console.log("reimbursement_services.js: createReimbursements: ", transactions);

				var onComplete = function (reimbursements) {				
					var modal = canopyModals.definitions.message({
						header: "Success",
						body: "Your reimbursements were created"
					});
				
					callback = callback || function () {};
				
					canopyModals.instantiate(modal).result.then(function () {
						callback(reimbursements);
					});
				};
				
				var onError = function () {
				};
				
				if (transactions == undefined || !transactions.length) {
					console.log("ERROR: reimbursements.utilities.createReimbursement - No transaction specified.");
					return;
				}
				
				// instantiate plural version of this modal:
				var modal = canopyModals.definitions.submitBudgetReimbursements({
					transactions: transactions
				});
				
				canopyModals.instantiate(modal).result.then(onComplete);
			},
			approveBudgetReimbursement: function (reimbursement, callback) {
				var modal = canopyModals.definitions.form({
					header: "Approve reimbursement",
					form: Luma.paths.context + "/system/mantle/marquee/site/forms/approve-budget-reimbursement.html",
					values: {
						value: reimbursement.value
					}
				});
				
				canopyModals.instantiate(modal).result.then(function (form) {
					var onSuccess = function (response) {
						if (callback) {
							callback();
						}
					};
					
					var onError = function (response) {
						console.dir(response)
					};
					
					approveBudgetReimbursement(reimbursement, form.value, form.description).then(onSuccess, onError);
				});
			},
			rejectBudgetReimbursement: function (reimbursement, callback) {
				var modal = canopyModals.definitions.form({
					header: "Reject reimbursement",
					form: Luma.paths.context + "/system/mantle/marquee/site/forms/approve-budget-reimbursement.html"
				});
				
				callback = callback || function () {};
				
				canopyModals.instantiate(modal).result.then(function (form) {
					rejectBudgetReimbursement(reimbursement, form.value, form.description).then(callback);
				});
			},
			editBudgetReimbursement: function (reimbursement, callback) {
				var modal = canopyModals.definitions.form({
					header: "Edit reimbursement",
					form: Luma.paths.context + "/system/mantle/marquee/site/forms/edit-budget-transaction.html"
				});
				
				callback = callback || function () {};
				
				canopyModals.instantiate(modal).result.then(function (form) {
					editBudgetReimbursement(reimbursement, form.value, form.description).then(callback);
				});
			}
		}
	};
}]);