canopy.factory("contentManagement", function ($http) {
	var REVISION_MICRO = "micro";
	var REVISION_MINOR = "minor";
	var REVISION_MAJOR = "major";
	
	var getSiteMenuPage = function (menuID, revision) {
		var data = {
			menuID: menuID
		}

		if (revision) {
			data.revision = revision;
		}

		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.GetSiteMenuPage",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};

	var activateSiteMenuPageVersion = function(menuID, revision) {
		var data = {
			menuID: menuID,
			revision: revision
		};
		
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ActivateSiteMenuPageVersion",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	}

	var listSiteMenuPageVersions = function(menuID) {
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.ListSiteMenuPageVersions",
			method: "POST",
			data: $.param({
				menuID: menuID
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	}
	
	var saveSiteMenuPage = function (menuID, page, activate, revisionType, coalesce) {
		var data = {
			menuID: menuID,
			page: page
		};

		if (revisionType) {
			data.increment = revisionType;
		}

		if (activate === true) {
			data.autopromote = true;
		} else {
			data.autopromote = false;
		}

		if (typeof coalesce === "boolean") {
			data.coalesce = coalesce;
		}
		
		data.page = JSON.stringify(data.page);
		
		return $http({
			url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.SaveSiteMenuPage",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};
	
	return {
		activateSiteMenuPageVersion: activateSiteMenuPageVersion,
		listSiteMenuPageVersions: listSiteMenuPageVersions,
		getSiteMenuPage: getSiteMenuPage,
		saveSiteMenuPage: saveSiteMenuPage,
		REVISION_MICRO: REVISION_MICRO,
		REVISION_MINOR: REVISION_MINOR,
		REVISION_MAJOR: REVISION_MAJOR
	};
});
