canopy.service("pageWidgets", function (canopyWidgetDictionary, htmlService) {
	function getPageWidgets(page) {
        var data = {};
        
        if (page.widgets) {
            data.fragments = [];
            
            page.widgets.forEach(function (widget, index) {
                var fragment = {
                    name: "widget-" + index,
                    widget: widget.definition.name,
                    metadata: {}
                };
                
                for (var key in widget) {
                    if (key != "definition") {
                        fragment.metadata[key] = widget[key];
                    }
                }
                
                data.fragments.push(fragment);
            });
        }
        
        return data;
    };

    function parseServerFragments(fragments) {
        var widgets = [];

        if (fragments) {
            fragments.forEach(function (fragment) {
                var widget = {
                    name: fragment.name
                };
                
                var definition = canopyWidgetDictionary.getDefinition(fragment.widget);
                
                if (definition) {
                    widget.definition      = definition;
                    widget.definition.name = fragment.widget;
                }
                
                if (fragment.metadata) {
                    for (var key in fragment.metadata) {
                        if (!widget.hasOwnProperty(key)) {
                            widget[key] = fragment.metadata[key];
                        }
                    }
                }

                if (widget.hasOwnProperty("body")) {
                    widget.body = htmlService.replaceTrixRichTextBaseTextAttributes(widget.body);
                }

                if (widget.hasOwnProperty("facets")) {
                    widget.facets.forEach(function(facet) {
                        if (facet.copy) {
                            facet.copy = htmlService.replaceTrixRichTextBaseTextAttributes(facet.copy);
                        }
                        
                    })

                }
                
                widgets.push(widget);
            });
        }

        return widgets;  
    };

	return {
        getPageWidgets: getPageWidgets,
        parseServerFragments: parseServerFragments
	};
});
