canopy.controller("ReimbursementListController", function ($scope, $controller, $stateParams, $timeout, lumaPaths, canopySession, menuNode, listService, reimbursements) {
	'use strict';
	$controller("ListController", {
		$scope:    $scope,
		$timeout:  $timeout,
		lumaPaths: lumaPaths,
		menuNode:  menuNode
	});
		
	$scope.$watch("filters", function (filters) {    	
    	var keywordFilter = filters.find(function (filter) {
    		return filter.name === "keywordFilter";
    	});
    	
    	if (keywordFilter) {
	    	$scope.typeahead.options = [];
	    	
	    	keywordFilter.taxonomy.forEach(function (genus) {
	    		genus.keywords.forEach(function (keyword) {
	    			$scope.typeahead.options.push({
	    				id: keyword.id,
	    				name: keyword.name,
	    				genus: {
	    					id: genus.genusID,
	    					name: genus.name
	    				}
	    			});
	    		})
	    	});
	    }
    });
    
	$scope.initList = function () {
		if ($scope.list == undefined) {
			return;
		}
		
		var user   = canopySession.getUser();
		var domain = canopySession.getActiveDomain();
		
		var dateFilter = {
			name: "Date",
			type: "com.intrepia.luma.PeriodFilter",
			instant: "Created",
			from: null,
			until: null
		};
		
		var searchFilter = {
			name: "searchFilter",
			type: "com.intrepia.luma.AdvancedSearchFilter",
			applied: []
		};
		
		var stateFilter = {
			name: "State",
			type: "com.intrepia.luma.ListBudgetReimbursements$StateFilter"
		};
		
		$scope.list.registerFilter(dateFilter);
		$scope.list.registerFilter(searchFilter);
		$scope.list.registerFilter(stateFilter);
		
		$scope.list.setService($scope.list.getService() || "ListBudgetReimbursements");
		
		$scope.list.setParameter("listing-sort", "transaction-created");
		$scope.list.setParameter("listing-order", "Descending");
		
		switch ($scope.list.getService() ) {
			case "ListTransactionsForRole":			
				$scope.list.setParameter("domainID", domain.id);
				$scope.list.setParameter("includeSubDomains", true);
				$scope.list.setParameter("enforcements", "Strict");
				break;
				
			default:
				$scope.list.setParameter("domainID", domain.id);
				break;
		}
	};
	
	$scope.getDetailsService = function (item) {
	};
		
	$scope.init();
});