"use strict";

canopy.directive("canopyColourSelector", function () {
	return {
		restrict: "A",
		scope: {
			model: "="
		},
		controller: function ($scope) {
			$scope.presets = [
				{
					title: "Red",
					values: {
						r: 255,
						g: 0,
						b: 0
					}
				},
				{
					title: "Green",
					values: {
						r: 0,
						g: 255,
						b: 0
					}
				},
				{
					title: "Blue",
					values: {
						r: 0,
						g: 0,
						b: 255
					}
				}
			];
			
			if ($scope.model == undefined) {
				$scope.model = {
					r: 0,
					g: 0,
					b: 0
				};
			}
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/colour-selector/colour-selector.html"
	}
});