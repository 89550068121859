"use strict";

/* Filters */

canopy.filter("canopyDate", function () {
	return function (input, format) {
		var str = "";
		
		if (input) {
			var datePattern = "YYYY-MM-DD HH:mm:ss [GMT]ZZ";
			var dateParsed  = moment(input, datePattern);
			
			if (!dateParsed.isValid() )
			{
				dateParsed = moment(input);
			}
			
			if (format) {
				str = dateParsed.format(format);
			}
			else {
				str = dateParsed.format("D MMMM YYYY");
			}
		}
		
		return str;
	};
});

canopy.filter("preserve", function () {
	return function (input) {
		if (input) {
			return input.replace(/\n\r?/g, "<br>");
		}
	};
});


canopy.filter("budgetSpend", function () {
	return function (budget, property) {
		if (budget) {		
			var budgetValues = {
				confirmed: budget.valueConfirmed || budget["value-confirmed"],
				pending:   budget.valuePending || budget["value-pending"]
			};
			
			budgetValues.confirmed = budgetValues.confirmed * -1;
			budgetValues.pending   = budgetValues.pending * -1;
			budgetValues.remaining = budget.total - budgetValues.confirmed - budgetValues.pending;
			
			var currencySymbol = undefined;
			var propertyValue  = undefined;
			
			try {
				if (budget.currency) {
					currencySymbol = budget.currency.symbol;
				}
				else {
					currencySymbol = budget["currency-symbol"];
				}
				
				propertyValue = budgetValues[property];
			}
			catch (e) {
				return "[" + e + "]";
			}
			
			if (currencySymbol === undefined) {
				return "[Error: Invalid currency]";
			}
			if (propertyValue === undefined) {
				return "[Error: Invalid value]";
			}
			
			return currencySymbol + propertyValue;
		}
		else {
			return "[Error: Invalid budget]";
		}
	};
});
  
canopy.filter("bytes", function() {
	return function(bytes, precision) {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return "-";
		if (typeof precision === "undefined") precision = 1;
		var units = ["bytes", "kB", "MB", "GB", "TB", "PB"],
			number = Math.floor(Math.log(bytes) / Math.log(1000));
		return (bytes / Math.pow(1000, Math.floor(number))).toFixed(precision) +  " " + units[number];
	}
});


canopy.filter("stripquotes", function(){
	return function(input){
		if(input){
			var i=input.length;
			if (input.charAt(0)=="'") input=input.substring(1,i--);
			if (input.charAt(--i)=="'") input=input.substring(0,i);

			return input;
		}
	}
});


canopy.filter("ellipsis", function () {
	return function (input, chars) {
		if (isNaN(chars)) return input;
		if (chars <= 0) return "";
		if (input && input.length >= chars) {
			input = input.substring(0, chars);

			while(input.charAt(input.length-1) == " "){
				input = input.substr(0, input.length -1);
			}
		
			return input + "...";
		}
		return input;
	};
});


canopy.filter("orderalert", function() {
    return function(input) {
      var style = "";
      if (input == "Approved") {
        style = "alert-success";
      }else if (input == "Unapproved") {
        style = "alert-warning";
      }else if (input == "Rejected") {
        style = "alert-danger";
      }else if (input == "Processed") {
        style = "alert-success";
      }else if (input == "Dispatched") {
        style = "alert-success";
      }
      return style;
    }
  });



canopy.filter("trademark", function () {
	return function (input, symbol) {
		var newstring = ""
		if (symbol == "registered"){
			newstring = input.replace("™", "®");
		}else if (symbol == "trademark"){
			newstring = input
		}else if (symbol == "none"){
			newstring = input.replace("™", "").toLowerCase();	
		}else if (symbol == "css"){
			newstring = input.replace("™", "").toLowerCase();
			newstring = newstring.replace("'", "");
			newstring = newstring.split(" ").join("_");
		}
		return newstring;
	};
});


canopy.filter("longtext", function (){
	return function (input){
		if (input) {
			var text = JSON.parse(input);
			return (text.length > 0 ? "<p>" + text.replace(/[\r\n]+/, "</p><p>") + "</p>" : null);
		}
	}
});

canopy.filter("longstring", function (){
	return function (input){
		if (input) {
			var text = (input);
			return (text.length > 0 ? "<p>" + text.replace(/[\r\n]+/, "</p><p>") + "</p>" : null);
		}
	}
});

canopy.filter("initial", function (){
	return function (input){
		if (input) {
			var text = (input);
			return text.slice(0,1);
		}
	}
});

canopy.filter("currency", function() {
	return function (input, code) {
		if (input) {
			var currencySymbols = {
				"GBP": "£",
				"USD": "$",
				"AUD": "$",
				"NZD": "$"
			};
			
			var value  = input.toFixed(2);
			var symbol = currencySymbols[code.code]; 
			
			if (symbol) {
				value = symbol + value;
			}
			
			return value;
		}
	}
});

canopy.filter("transformLabel", function () {
	return function (input, args) {
		if (input) {
			var label = input.title || input.name;
			
			if (args) {
				if (args.action === "Order") {
					var orderLabel = null;
					
					try {
						orderLabel = input.metadata.labels.order;
					}
					catch (e) {
					}
					
					if (orderLabel) {
						label = orderLabel;
					}
				}
				
				if (args.action === "Download") {
					var downloadLabel = null;
					
					try {
						downloadLabel = input.metadata.labels.download;
					}
					catch (e) {
					}
					
					if (downloadLabel) {
						label = downloadLabel;
					}
				}
			}
		
			return label;
		}
	};
});

canopy.filter("orderTotal", function (canopyCurrencyFilter) {
	return function (input) {
		if (input) {
			var totals = {};
			var output = "";
			
			if (input.items) {
				input.items.forEach(function (item) {				
					if (item.metadata) {
						var total = parseFloat(item.total);
						var rate  = item.metadata.rate;
					
						if (total && (total > 0 && rate)) {
							var currency = rate.currency;
						
							if (totals[currency] == undefined) {
								totals[currency] = 0;
							}
						
							totals[currency] += parseFloat(item.total);
						}
					}
				});
			}

			output = Object.keys(totals).map(function (key) {
				return canopyCurrencyFilter(totals[key], { code: key });
			}).join(" / ");
			
			if (output.length) {
				return output;
			}
			else {
				return "-";
			}
		}
	};
});

canopy.filter("localise", function (canopySession) {
	function filter (input) {
		if (input) {
			var locale = canopySession.getActiveLocale();
			var response   = input;
			
			if (locale && locale.vocabulary) {
				response = locale.vocabulary[input] || input;
			}
			
			return response;
		}
	};
	
	filter.$stateful = true; // Update on digest.
	
	return filter;
});