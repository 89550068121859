canopy.controller("BasketListController", ["$controller", "$scope", "$timeout", "basket", "assets", "downloads", "shares", "lumaPaths", "$location", "canopyModals", "menuNode", function ($controller, $scope, $timeout, basket, assets, downloads, shares, lumaPaths, $location, canopyModals, menuNode) {
	"use strict";
	$controller("ListController", {
		$scope:    $scope,
		$timeout:  $timeout,
		lumaPaths: lumaPaths,
		menuNode:  menuNode
	});
	
	$scope.grid = {
    	format: "small"
    };
    
    $scope.initList = function () {
		if ($scope.list == undefined) {
			return;
		}
		
		$scope.list.setService($scope.list.getService() || "ListBasket");
	};
	
	$scope.removeSelectionFromBasket = function () {
		var onSuccess = function () {
			$scope.init();
		};
		
		var onError = function () {
		};
		
		var assetIDs = $scope.selection.map( function (element) {
			return element.id;
		});
		
		basket.removeAssets(assetIDs).then(onSuccess).catch(onError);
	};
	
	$scope.shareBasket = function () {
		shares.quickShareBasket();
	};
	
	$scope.downloadSelected = function () {		
		var modal = canopyModals.definitions.assetDownload({
			selection: $scope.selection
		});
		
		canopyModals.instantiate(modal);
	};
	
	$scope.downloadBasket = function () {
		var modal = canopyModals.definitions.assetDownload({
			selection: "basket"
		});
		
		canopyModals.instantiate(modal);
	};
	
	$scope.emptyBasket = function () {
		var onSuccess = function () {
			$scope.init();
		};
		
		var onError = function () {
		};
		
		basket.emptyBasket().then(onSuccess).catch(onError);
	};
	
	$scope.initActions = function () {
		$scope.actions.push({
			title: "Download selection",
			execute: function () {
				$scope.downloadSelected();
			}
		});
		
		if (Luma.user.hasService("application.assets.share")) {
			$scope.actions.push({
				title: "Share selection",
				execute: function () {
					shares.quickShareAssets($scope.flattenSelection("id"));
				}
			});
		}
		
		$scope.actions.push({
			title: "Remove selection",
			execute: function () {
				$scope.removeSelectionFromBasket();
			}
		});
	};
	
	$scope.init();
}]);