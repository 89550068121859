canopy.factory("tasks", ["$http", "lumaPaths", "listService", function ($http, lumaPaths, listService) {
	var getTask = function (task) {
		return $http({
			url: lumaPaths.serviceURI + ".GetTask",
			method: "POST",
			data: $.param({
				taskID: task.id || task
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listTaskAssets = function (task) {
		return $http({
			url: lumaPaths.serviceURI + ".ListTaskAssets",
			method: "POST",
			data: $.param({
				taskID: task.id || task
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listUserTasks = function () {
		return $http({
			url: lumaPaths.serviceURI + ".ListUserTasks",
			method: "POST",
			data: $.param({
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var getDefermentForTask = function (task) {
		return $http({
			url: lumaPaths.serviceURI + ".GetDefermentForTask",
			method: "POST",
			data: $.param({
				taskID: task.id || task
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"}
		});
	};

	return {
		getTask: function (task) {
			return getTask(task);
		},
		listTaskAssets: function (task) {
			return listTaskAssets(task);
		},
		listUserTasks: function () {
			return listUserTasks();
		},
		getDefermentForTask: function (task) {
			return getDefermentForTask(task);
		}
	};
}]);