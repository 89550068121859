canopy.run(["$rootScope", "$compile", "trixStylesLoader", '$window', '$transitions', function ($rootScope, $compile, trixStylesLoader, $window, $transitions) {
    // Make the default styles not add <em>, <strong> or <del> elements, instead of <span> (default) as otherwise these break our custom styles...   
    Trix.config.textAttributes.bold =   { style: { fontWeight: "bold" },                nestable: false, tagName: "", inheritable: true, parser: function(element) { return element.style.fontWeight === "bold" || element.style.fontWeight >= 600; } };
    Trix.config.textAttributes.italic = { style: { fontStyle: "italic" },               nestable: false, tagName: "", inheritable: true, parser: function(element) { return element.style.fontStyle === "italic" } };
    Trix.config.textAttributes.strike = { style: { textDecoration: "line-through" },    nestable: false, tagName: "", inheritable: true, parser: function(element) { return element.style.textDecoration === "line-through" } };

    // Fix href input field has type 'url' which enforces complete URL pattern, and we need it to be more forgiving...
    $rootScope.__trixFixLinkInputFieldCbFn__ = function(event) {
        var trixDialog = angular.element(".trix-dialogs .trix-dialog.trix-dialog--link");
        trixDialog.find("input[type=url]").attr("type", "text");
    }

    // Trix-initialize event handler callback:
    $rootScope.__trixInitializeCustomStylesCbFn__ = function(event) {

        var trixEditor = angular.element(event.target);
        var toolbarId = trixEditor.attr("toolbar");
        var trixToolbar = angular.element("#"+toolbarId);
        var hasCustomToolbar = trixToolbar.find("trix-toolbar-custom-styles");
        var trixScope;
        var styleScope;

        if (hasCustomToolbar.length) {
            return;
        }

        trixScope = angular.element(event.target).scope();
        styleScope = trixScope.$new(true);

        // Set directive data property binding:
        trixStylesLoader.getCustomStylesPromise().then(function successCallback(response) {
            styleScope.data = response;
            styleScope.trixEditor = trixEditor[0];

            // Add the HTML to the toolbar, bindings and all :-)
            trixToolbar.prepend($compile("<trix-toolbar-custom-styles data-trix-editor='trixEditor' data-style-definitions='data'/>")(styleScope));
        });
    }

    $transitions.onStart({}, function($transition){
        $window.removeEventListener("trix-initialize", $rootScope.__trixFixLinkInputFieldCbFn__);
        $window.removeEventListener("trix-initialize", $rootScope.__trixInitializeCustomStylesCbFn__);
    });

    $transitions.onSuccess({}, function($transition){
        $window.addEventListener("trix-initialize", $rootScope.__trixFixLinkInputFieldCbFn__);
        $window.addEventListener("trix-initialize", $rootScope.__trixInitializeCustomStylesCbFn__);
    });
}]);