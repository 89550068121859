canopy.factory("projects", ["$http", "$q", "lumaPaths", "listService", "assets", "budgets", "$filter", function ($http, $q, lumaPaths, listService, assets, budgets, $filter) {
	var getProject = function (projectID) {
		return $http({
			url: lumaPaths.serviceURI + ".GetProject",
			method: "POST",
			data: $.param({
				projectID: projectID
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var addProject = function (project) {
		var domain = project.domain;
		
		return $http({
            method: "POST",
			url: lumaPaths.serviceURI + ".AddProject",
            data: project,
            transformRequest: function (data) {
                var formData = new FormData();
                
				formData.append("domainID", domain.id || domain);
                
                if (data.name)          { formData.append("name", data.name); }
				if (data.processID)     { formData.append("processID", data.processID); }
				if (data.campaignID)    { formData.append("campaignID", data.campaignID); }
                if (data.reimbursable)  { formData.append("reimbursable", data.reimbursable); }
                if (data.subscribe)     { formData.append("subscribe", data.subscribe); }
                if (data.subscriptions) { formData.append("subscriptions", data.subscriptions); }
				if (data.state)         { formData.append("state", data.state); }
				if (data.groupIDs)      { formData.append("groupIDs", data.groupIDs); }
				if (data.ownerID)       { formData.append("ownerID", data.ownerID); }
                
                if (data.payload) {
					for (var i = 0; i != data.payload.length; ++i) {
						formData.append("payload", data.payload[i]);
					}
				}
                
                if (data.metadata)     { formData.append("metadata", JSON.stringify(data.metadata)) };
                if (data.keys)         { formData.append("keys", JSON.stringify(data.keys)) };
                if (data.taxonomy)     { formData.append("taxonomy", JSON.stringify(data.taxonomy)) };
                if (data.associateIDs) { formData.append("associateIDs", data.associateIDs); }
                
                if (data.allocations) {
                	formData.append("allocations", JSON.stringify(data.allocations));
                }
                
                return formData;
            },
            headers: { "Content-Type": undefined }
        });
	};
	
	var setProjectState = function (project, state, description, timeWorking) {
		var data = {
			projectID: project.id || project,
			state: state
		};
		
		if (description) {
			data.description = description;
		}
		else {
			if (state == "Rejected") {
				data.description = "Rejected without comment.";
			}
		}
		
		if (timeWorking) {
			data.timeWorking = timeWorking;
		}
		
		return $http({
			url: lumaPaths.serviceURI + ".SetProjectState",
			method: "POST",
			data: $.param(data),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var setProjectMetadata = function (project, metadata) {
		return $http({
			url: lumaPaths.serviceURI + ".SetProjectMetadata",
			method: "POST",
			data: $.param({
				projectID: project.id || project,
				metadata: JSON.stringify(metadata || project.metadata)
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listProjectAssets = function (project) {
		return $http({
			url: lumaPaths.serviceURI + ".ListProjectAssets",
			method: "POST",
			data: $.param({
				projectID: project.projectID || project
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listProjectProcesses = function (domain) {
		return $http({
			url: lumaPaths.serviceURI + ".ListProcessesForDomain",
			method: "POST",
			data: $.param({
				domainID: domain.id || domain,
				type: "Project"
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listProjectHistory = function (project) {
		return $http({
			url: lumaPaths.serviceURI + ".ListProjectHistory",
			method: "POST",
			data: $.param({
				projectID: project.id || project
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	var listProjectActivity = function (project, transactions) {
		var defer    = $q.defer();
		var promises = [];
		
		promises.push(
			listProjectHistory(project),
			assets.listAssetComments(project)
		);
		
		if (project.transactions) {
			project.transactions.forEach(function (transaction) {
				promises.push(budgets.listBudgetTransactionHistory(transaction).then(function (response) {
					response.data.forEach(function (entry) {
						entry.transaction = {
							label: transaction.label ? transaction.label : "Transaction #" + transaction.id,
							currency: {
								code: transaction["currency-code"],
								symbol: transaction["currency-symbol"]
							}
						};
					});
					
					return response;
				}));
			});			
		}
		
		var mergeResponses = function (responses) {
			var projectHistoryMapper = function (element) {
				var mapped = {
					"user-forename": element["user-forename"],
					"user-surname": element["user-surname"],
					"user-email": element["user-email"],
					"date": element.date,
					"type": "Progress",
					"subType": element.type,
					"state": element.state,
					"body": element.description,
					"time-elapsed": element["time-elapsed"],
					"time-working": element["time-working"]
				};
				
				if (mapped["time-working"]) {
					var duration  = moment.duration(element["time-working"], "seconds");
					var hourString   = duration.asHours() > 1 ? Math.floor(duration.asHours()) + "h" : "";
					var minuteString = duration.minutes() + "m";
					
					mapped["time-working"] = "".concat(hourString, " ", minuteString);
				}
				
				return mapped;
			};
			
			var assetCommentsMapper = function (element) {
				return {
					"user-forename": element["user-forename"],
					"user-surname": element["user-surname"],
					"user-email": element["user-email"],
					"date": element.created,
					"type": "Comment",
					"subType": element.type,
					"body": element.body
				};
			};
			
			var transactionHistoryFilter = function (element) {
				return element.type == "Edit" || element.type == "Close" || element.type == "Reopen";
			};
			
			var transactionHistoryMapper = function (element) {
				var mapped = {
					"user-forename": element["user-forename"],
					"user-surname": element["user-surname"],
					"user-email": element["user-email"],
					"date": element.date,
					"type": "Transaction",
					"subType": element.type,
					"value": element.value,
					"body": element.description,
					"transaction": element.transaction
				};
				
				return mapped;
			};
			
			var projectHistory       = responses[0].data.map(projectHistoryMapper);
			var assetComments        = responses[1].data.map(assetCommentsMapper);
			var transactionHistories = [];
			
			for (var i = 2; i != responses.length; ++i) {
				if (responses[i]) {
					transactionHistories = transactionHistories.concat(responses[i].data.filter(transactionHistoryFilter).map(transactionHistoryMapper));
				}
			}
			
			var combined = projectHistory.concat(assetComments).concat(transactionHistories);
			
			combined = combined.sort(function (a, b) {
				var dateA = moment(a.date, Dates.formats.universal).toDate();
				var dateB = moment(b.date, Dates.formats.universal).toDate();
				
				return dateB - dateA;
			});
			
			defer.resolve(combined);
		};
		
		$q.all(promises).then(mergeResponses);
		
		return defer.promise;
	};
	
	var setProjectContentBucket = function (project, asset, bucket) {
		return $http({
			url: lumaPaths.serviceURI + ".SetProjectContentBucket",
			method: "POST",
			data: $.param({
				projectID: project.id || project,
				assetIDs: asset.id || asset,
				bucket: bucket
			}),
			headers: {"Content-Type": "application/x-www-form-urlencoded"} 
		});
	};
	
	return {
		getProject: function (projectID) {
			return getProject(projectID);
		},
		addProject: function (project) {
			return addProject(project);
		},
		setProjectState: function (project, state, description, timeWorking) {
			return setProjectState(project, state, description, timeWorking);
		},
		setProjectMetadata: function (project, metadata) {
			return setProjectMetadata(project, metadata);
		},
		listProjectAssets: function (project) {
			return listProjectAssets(project);
		},
		listProjectProcesses: function (domain) {
			return listProjectProcesses(domain);
		},
		listProjectHistory: function (project) {
			return listProjectHistory(project);
		},
		listProjectActivity: function (project) {
			return listProjectActivity(project);
		},
		setProjectContentBucket: function (project, asset, bucket) {
			return setProjectContentBucket(project, asset, bucket);
		},
		list: function () {
			var list = listService.instantiate("ListProjects");
			
			list.prime = function () {
			};
			
			return list;
		}
	};
}]);