canopy.controller("CampaignRequestController", function ($scope, canopySession, campaigns, projects, listService, processes, utilities, canopyModals, $state, $stateParams, $location, FileUploader, $q, $timeout) {
	$scope.init = function () {
		initCampaign();
		initAncillary();
		initDomains();
		initProcess();
		initProcessTaxonomy();
		initGroups();
		initProjectProcesses();
		
		$scope.forms = [];
	};
	
	var initAncillary = function () {
		$scope.ancillary = {};
	};
	
	var initDomains = function () {
		var domain = canopySession.getActiveDomain();
		
		if (domain) {
			$scope.campaign.domain = domain;
		}
	};
    
    var initProcess = function () {
    	var onSuccess = function (response) {
    		$scope.process = response.data;
    		
    		if ($scope.process.metadata) {
    			$scope.process.metadata = JSON.parse($scope.process.metadata);
    			
    			if ($scope.process.metadata.campaign) {
    				for (var key in $scope.process.metadata.campaign) {
    					$scope.campaign[key] = $scope.process.metadata.campaign[key];
    				}
    			}
    		}
    	};
    	
    	processes.getProcess($stateParams.processID).then(onSuccess);
    };
	
	var initProcessTaxonomy = function () {
		var onSuccess = function (response) {
			$scope.ancillary.taxonomy = response.data;
		};
		
		var onError = function () {
		};
		
		if ($stateParams.processID) {
			processes.getProcessTaxonomy($stateParams.processID).then(onSuccess, onError);
		}
	};
	
	var initBudgets = function () {
		if ($scope.ancillary.budgets === undefined) {		
			$scope.ancillary.budgets = {
				available: [],
				selected:  undefined
			};
		}
		
		var genusReducer = function (acc, genus) {
			genus.keywords.forEach(function (keyword) {
				if (keyword.selected) {
					acc.push(keyword.id);
				}
			});
			
			return acc;
		};
		
		var onSuccess = function (response) {
			$scope.ancillary.budgets.available = response.data;
			
			if ($scope.ancillary.budgets.available.length) {
				$scope.ancillary.budgets.selected = $scope.ancillary.budgets.available[0];
			}
		};
		
		var domain = canopySession.getActiveDomain();
		var site   = canopySession.getActiveSite();
		
		if (domain && site) {			
			var budgetList = listService.instantiate("ListBudgetsForDomain");
			var keywordIDs = $scope.ancillary.taxonomy.reduce(genusReducer, []);
			
			budgetList.setParameter("domainID", domain.id);
			budgetList.setParameter("siteID", site.id);
			budgetList.setParameter("enabled", true);
			
			budgetList.registerFilter({
				name: "keywords",
				type: "com.intrepia.luma.KeywordFilter",
				applied: keywordIDs
			});
			
			budgetList.load().then(onSuccess);
		}
	};
    
    var initGroups = function () {
    	var user = canopySession.getUser();
    	
    	$scope.ancillary.groups = [];
    	
    	for (var key in user.groups.map) {
    		$scope.ancillary.groups.push(user.groups.map[key]);
    	}
    	
    	return;
    	
    	var groupTypeFilter = function (type) {
			return function (element) {
				return element.value.type === type;
			};
		};
		
		var groupMapper = function (element) {
			return {
				id: element.value.id,
				name: element.value.name,
				type: element.value.type
			};
		};
    	
    	var user   = canopySession.getUser();
    	var groups = user.groups.values;
    	
    	var units    = groups.filter(groupTypeFilter("Unit"));
    	var regions  = groups.filter(groupTypeFilter("Region"));
    	var branches = groups.filter(groupTypeFilter("Branch"));
    	
    	units    = units.map(groupMapper);
    	regions  = regions.map(groupMapper);
    	branches = branches.map(groupMapper);
    	
    	$scope.ancillary.groups = {
    		unit: {
    			available: units,
    			selected: undefined
    		},
    		region: {
    			available: regions,
    			selected: undefined
    		},
    		branch: {
    			available: branches,
    			selected: undefined
    		}
    	};
    	
    	$scope.ancillary.groups.unit.selected   = $scope.ancillary.groups.unit.available.length   ? $scope.ancillary.groups.unit.available[0]   : undefined;
    	$scope.ancillary.groups.region.selected = $scope.ancillary.groups.region.available.length ? $scope.ancillary.groups.region.available[0] : undefined;
    	$scope.ancillary.groups.branch.selected = $scope.ancillary.groups.branch.available.length ? $scope.ancillary.groups.branch.available[0] : undefined;
    };
    
    var initProjectProcesses = function () {
    	var onSuccess = function (response) {
    		$scope.ancillary.processes = {
    			project: response.data
    		};
    	};
    	
    	projects.listProjectProcesses(canopySession.getActiveDomain().id).then(onSuccess);
    };
    
    var getGroupIDs = function () {
    	var groupIDs = [];
    	
    	for (var key in $scope.ancillary.groups) {
    		var group = $scope.ancillary.groups[key];
    		
    		if (group && group.selected) {
    			groupIDs.push(group.selected.id);
    		}
    	}
    	
    	return groupIDs.toString();
    };
    
    var getSelectedGroups = function () {
    	var selectedGroups = [];
    	
    	for (var key in $scope.ancillary.groups) {
    		var group = $scope.ancillary.groups[key];
    		
    		if (group && group.selected) {
    			selectedGroups.push(group.selected);
    		}
    	}
    	
    	return selectedGroups;
    };
    
	var initCampaign = function () {
		$scope.campaign = {
			name: "",
			domainID: canopySession.getActiveDomain().id,
			processID: $stateParams.processID,
			budgetID: null,
			allocation: 0,
			state: "",
			groupIDs: [],
			taxonomy: [],
			metadata: {
				canopy: {},
				client: {}
			},
			availability: {
				start: null,
				end: null
			}
		};
	};
	
	$scope.registerForm = function (element, name) {
		$scope.forms.push({
			name: name,
			node: element
		});
	};
	
	$scope.onSaveCampaign = function () {
		var modal = canopyModals.definitions.message({
			header: "Are you sure you wish to continue?",
			body: "<p>A number of projects will automatically be created based on the selected campaign process.</p><p>If you continue, you will no longer be able to make further changes to the campaign form.</p>",
			confirm: "Continue",
			cancel: "Cancel"
		});
		
		canopyModals.instantiate(modal).result.then(function () {
			saveCampaign();
		});
	};
	
	$scope.onUnsaveCampaign = function () {
		var modal = canopyModals.definitions.message({
			header: "Continue",
			body: "This will reset any information you have entered into project forms. Are you sure you wish to continue?",
			confirm: "Yes",
			cancel: "No"
		});
		
		canopyModals.instantiate(modal).result.then(function () {
			$scope.tabs = {
				active: "campaign"
			};
			
			$scope.ancillary.projects = [];
		});
	};
	
	var saveCampaign = function () {	
		$scope.$broadcast("submit");
    	
    	var invalid = false;
    	
    	$scope.forms.forEach(function (element) {
    		var form = element.node[element.name];
    		
    		if (form.$invalid) {
				element.node.$parent.isOpen = true;
				invalid = true;
    		}
    	});
    	
    	if (invalid) {
    		var modal = canopyModals.definitions.message({
    			header: "Could not submit campaign",
    			body: "You haven't entered any data into one or more required form fields. Please check the form, enter the required information, and resubmit."
    		});
    		
    		canopyModals.instantiate(modal).result.then(function () {
		    	$scope.isSubmitting = false;
    		});
    		
		    return;
    	}
    	
    	$scope.campaign.locked = true;
    	
		$scope.ancillary.projects = campaigns.supplemental.request.spawnProjects($scope);
		
		$scope.ancillary.projects.forEach(function (project) {
			if (project.process) {
				var found = $scope.ancillary.processes.project.find(function (process) {
					return project.process == process.uuid || project.process == process.name;
				});
				
				if (found) {
					project.process   = found;
					project.processID = found.id;
				}
			}
		});
		
		$scope.ancillary.projects = $scope.ancillary.projects.filter(function (project) {
			return project.processID != undefined;
		});
		
		$scope.tabs = {
			active: "projects"
		};
	};
    
    $scope.submitRequest = function (form) {
    	if ($scope.isSubmitting) {
    		return;
    	}
    	
    	$scope.isSubmitting = true;
    	$scope.$broadcast("submit");
	
		var invalid = false;
	
		$scope.forms.forEach(function (element) {
			var form = element.node[element.name];
		
			if (form.$invalid) {
				element.node.$parent.isOpen = true;
				invalid = true;
			}
		});
	
		$scope.ancillary.projects.forEach(function (element) {
			if (element.valid() == false) {
				invalid = true;
			}
		});
	
		if (invalid) {
			var modal = canopyModals.definitions.message({
				header: "Could not submit campaign",
				body: "You haven't entered any data into one or more required form fields. Please check the form, enter the required information, and resubmit."
			});
		
			canopyModals.instantiate(modal).result.then(function () {
				$scope.isSubmitting = false;
			});
		
			return;
		}
	
		var groupMapper = function (element) {
			return element.id;
		};
	
		var selectedGroups = $scope.ancillary.groups.filter(function (element) {
			return element.selected;
		});
	
		var selectedGroupIDs = selectedGroups.map(groupMapper).toString();
	
		$scope.campaign.metadata.canopy.groups = selectedGroups;
		$scope.campaign.groupIDs               = selectedGroupIDs;
	
		var taxonomyReducer = function (acc, element) {
			var keywordFilter = function (keyword) {
				return keyword.selected;
			};
	
			var keywordMapper = function (keyword) {
				return keyword.id;
			};
		
			var mapped = {
				id: element.id,
				keywordIDs: element.keywords.filter(keywordFilter).map(keywordMapper)
			};
		
			if (element.type == "Interactive" && mapped.keywordIDs.length) {
				acc.push(mapped);
			}
		
			return acc;
		};
	
		$scope.campaign.taxonomy = $scope.ancillary.taxonomy.reduce(taxonomyReducer, []);    	
		
		var findGenusID = function (genusName) {
			var genusFinder = function (element) {
				return element["genus-name"] == genusName;
			};
			
			var genus = $scope.ancillary.taxonomy.find(genusFinder);
		
			if (genus) {
				return genus.genusID;
			}
		};
		
		var findKeywordID = function (genusName, keywordName) {
			var genusFinder = function (element) {
				return element["genus-name"] == genusName;
			};
		
			var keywordFinder = function (element) {
				return element.name == keywordName;
			};
		
			var genus   = $scope.ancillary.taxonomy.find(genusFinder);
			var keyword = null;
		
			if (genus) {
				var keyword = genus.keywords.find(keywordFinder);
			}
		
			return keyword ? keyword.id : null;
		};
	
		var onSuccess = function (response) { 
			var campaignID = response.data;
		
			var projectItems = [];
			
			if ($scope.ancillary.projects.length) {					
				var callAddProject = function (item) {
					return projects.addProject(item);
				};
					
				var createProjects = function (projectItems) {
					return projectItems.reduce(function (promise, item) {
						return promise.then(function (result) {
							return callAddProject(item);
						});
					}, $q.when());
				};
				
				$scope.ancillary.projects.forEach(function (element) {
					element.domain     = $scope.campaign.domainID;
					element.campaignID = campaignID;
					
					if (element.allocations) {
						element.allocations = element.allocations.map(function (allocation) {
							return {
								label: allocation.label,
								description: allocation.description,
								budgetID: allocation.budget ? allocation.budget.id : null,
								value: allocation.value,
								reimbursable: true
							};
						});
					}
				
					projectItems.push(
						element
					);
				});
				
				createProjects(projectItems).then(function () {
					openSuccessModal(campaignID)
				});
			}
			else {
				openSuccessModal(campaignID);
			}
		};
	
		var onError = function (response) {
			$scope.isSubmitting = false;
		
			canopyModals.instantiate(canopyModals.definitions.serverError(response.data));
		};
	
		campaigns.addCampaign($scope.campaign).then(onSuccess).catch(onError);
    };
    
    var openSuccessModal = function (campaignID) {
		var modal = canopyModals.definitions.message({
			header: "SUCCESS",
			body: "Your campaign has been created. Your campaign ID is: " + campaignID
		});

		canopyModals.instantiate(modal).result.then(function () {
			$state.go("^.detail", {
				campaignID: campaignID
			});
		});
    };
        
    $scope.$watch("ancillary.processes.selected", function (process) {
		if (process) {
			$scope.campaign.processID               = process.id;
			$scope.campaign.metadata.canopy.process = process;
		}
	});
	
	$scope.$watch("ancillary.budgets.selected", function (budget) {
		if (budget) {
			$scope.campaign.budgetID               = budget.id;
			$scope.campaign.metadata.canopy.budget = budget;
		}
		else {
			$scope.campaign.budgetID               = null;
			delete $scope.campaign.metadata.canopy.budget;
		}
	});
		
	$scope.init();
});