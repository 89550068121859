canopy.controller("BudgetListController", function ($scope, $controller, $stateParams, $timeout, lumaPaths, canopySession, menuNode, orders) {
	'use strict';
	$controller("ListController", {
		$scope:    $scope,
		$timeout:  $timeout,
		lumaPaths: lumaPaths,
		menuNode:  menuNode
	});
	
	$scope.menuNode = menuNode;
	
	$scope.filterPanel = {
		isCollapsed: true
	};
	
	// TODO: This is now used in multiple places.
    $scope.typeahead = {
		model: null,
		options: [],
		onSelect: function (item, model, label) {
			if (item.id) {
				var keywordFilter = $scope.list.getFilter("keywordFilter");
				
				if (keywordFilter) {
					$scope.list.toggleFilter(keywordFilter, item);
				}
				
				$scope.typeahead.model = null;
				
				$scope.load();
			}
			
			if (item.text) {
				var searchTerms = item.name.split(" ");
				
				searchTerms.forEach(function (element) {
					// $scope.addParam("search", element);
					$scope.list.toggleFilter("searchFilter", { value: element, flags: ["Name", "Fuzzy"] });
				});

				$scope.typeahead.model = null;					
				$scope.load();
			}
			
			$scope.typeahead.search = null;
		}
	};
	
	$scope.$watch("filters", function (filters) {    	
    	var keywordFilter = filters.find(function (filter) {
    		return filter.name === "keywordFilter";
    	});
    	
    	if (keywordFilter) {
	    	$scope.typeahead.options = [];
	    	
	    	keywordFilter.taxonomy.forEach(function (genus) {
	    		genus.keywords.forEach(function (keyword) {
	    			$scope.typeahead.options.push({
	    				id: keyword.id,
	    				name: keyword.name,
	    				genus: {
	    					id: genus.genusID,
	    					name: genus.name
	    				}
	    			});
	    		})
	    	});
	    }
    });
	
	$scope.initList = function () {
		if ($scope.list == undefined) {
			return;
		}
		
		var user   = canopySession.getUser();
		var domain = canopySession.getActiveDomain();
		
		var dateFilter = {
			name: "Date",
			type: "com.intrepia.luma.ListBudgets$ActivePeriodFilter",
			instant: "Created",
			from: null,
			until: null
		};
		
		var keywordFilter = {
			name: "keywordFilter",
			type: "com.intrepia.luma.KeywordFilter",
			applied: [],
			ignoreDomains: true,
			ignoreApplied: false
		};
		
		var searchFilter = {
			name: "searchFilter",
			type: "com.intrepia.luma.AdvancedSearchFilter",
			applied: []
		};
		
		var stateFilter = {
			name: "State",
			type: "com.intrepia.luma.ListBudgets$StateFilter"
		};
		
		var unitFilter = {
			name: "Unit",
			type: "com.intrepia.luma.ListBudgets$GroupFilter",
			groupType: "Unit"
		};
		
		var regionFilter = {
			name: "Region",
			type: "com.intrepia.luma.ListBudgets$GroupFilter",
			groupType: "Region"
		};
		
		var branchFilter = {
			name: "Branch",
			type: "com.intrepia.luma.ListBudgets$GroupFilter",
			groupType: "Branch"
		};
		
		$scope.list.setService($scope.list.getService() || "ListBudgetsForDomain");
		
		$scope.list.setParameter("listing-sort", "budget-created");
		$scope.list.setParameter("listing-order", "Descending");
		
		switch ($scope.list.getService() ) {
			case "ListBudgetsForRole":			
				$scope.list.setParameter("domainID", domain.id);
				$scope.list.setParameter("includeSubdomains", true);
				$scope.list.setParameter("enforcements", "Strict");
				
				dateFilter.from  = Dates.encode(moment().subtract(30, "days").startOf("day").toDate());
				dateFilter.until = Dates.encode(moment().endOf("day").toDate());
				break;
				
			default:
				$scope.list.setParameter("domainID", domain.id);
				break;
		}
		
		$scope.list.registerFilter(dateFilter);
 		$scope.list.registerFilter(keywordFilter);
		$scope.list.registerFilter(searchFilter);
		$scope.list.registerFilter(stateFilter);
		$scope.list.registerFilter(unitFilter);
		$scope.list.registerFilter(regionFilter);
		$scope.list.registerFilter(branchFilter);
	};
	
	$scope.getDetailsService = function (item) {
		return budgets.getBudget(item.id);
	};
		
	$scope.init();
});