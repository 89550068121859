"use strict";

canopy.directive("ellipsis", function ($window, $timeout) {
    return function(scope, element, attrs) {
    	var isOverflowing = function () {
    		return element[0].scrollHeight > element[0].clientHeight;
    	};
    	
    	var constrainText = function () {
    		var content = originalText;
    		var overflowed = false;
    		
			element[0].innerHTML = originalText;
    		
    		while (isOverflowing() && content.length) {
				content = content.substring(0, content.length - 1);
				element[0].innerHTML = content;
				
				overflowed = true;
			}
			
			if (overflowed) {
				element[0].innerHTML = content.substring(0, content.length - 3) + "...";
			}
    	};
    	
    	angular.element($window).bind("resize", function () {
    		constrainText();
    	});
    	
    	var originalText = undefined;
    	
    	$timeout( function () {
    		originalText = element[0].innerHTML;
	    	constrainText();
	    }, 0);
	};
});