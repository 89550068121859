"use strict";
canopy.filter("userTypeaheadFilter", function() {
    return function (options, text) {
        var filtered = [];
        
        text = text.toLowerCase();
        
        angular.forEach(options, function (option) {
            if (option.email.toLowerCase().indexOf(text) != -1) {
                filtered.push(option);
            }
        });
        
        return filtered;
    };
});

canopy.directive("canopyUserSelector", function (canopyModals) {
	return {
		controller: function ($scope, canopySession, listService) {
			var init = function () {
				$scope.domains = {
					selected: null,
					available: canopySession.getAvailableDomains()
				};
				
				if ($scope.domains.available.length) {
					$scope.domains.selected = $scope.domains.available[0];
				}
				
				$scope.users = {
					selected: null,
					available: []
				};
			};
			
			var onSelectedDomainChanged = function () {
				var onSuccess = function (response) {
					$scope.users.selected  = null;
					$scope.users.available = response.data;
				};
				
				var onError = function (response) {
				};
				
				var list = listService.instantiate("ListUsers");
				
				list.setParameter("domainID", $scope.domains.selected.id);
				
				list.load().then(onSuccess, onError);
			};
			
			$scope.$watch("domains.selected", function (domain) {
				$scope.modelDomain = $scope.domains.selected;
				
				if (domain) {
					onSelectedDomainChanged();
				}
			});
			
			$scope.$watch("users.selected", function () {
				$scope.modelUser = $scope.users.selected;
			});
			
			init();
		},
		link: function (scope, element) {
		},
		scope: {
			modelUser: "=",
			modelDomain: "="
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/tools/canopy-user-selector.html"
	};
});