canopy.controller("ProjectListController", function ($scope, $controller, $stateParams, $timeout, lumaPaths, canopySession, menuNode, listService, reimbursements, budgets) {
	'use strict';
	$controller("ListController", {
		$scope:    $scope,
		$timeout:  $timeout,
		lumaPaths: lumaPaths,
		menuNode:  menuNode
	});
	
	$scope.filterPanel = {
		isCollapsed: true
	};
	
	$scope.menuNode = menuNode;
    
    // TODO: This is now used in multiple places.
    $scope.typeahead = {
		model: null,
		options: [],
		onSelect: function (item, model, label) {
			if (item.id) {
				var keywordFilter = $scope.list.getFilter("keywordFilter");
				
				if (keywordFilter) {
					$scope.list.toggleFilter(keywordFilter, item);
				}
				
				$scope.typeahead.model = null;
				
				$scope.load();
			}
			
			if (item.text) {
				var searchTerms = item.name.split(" ");
				
				searchTerms.forEach(function (element) {
					// $scope.addParam("search", element);
					$scope.list.toggleFilter("searchFilter", { value: element, flags: ["Name", "Fuzzy"] });
				});

				$scope.typeahead.model = null;					
				$scope.load();
			}
			
			$scope.typeahead.search = null;
		}
	};
	
	$scope.$watch("filters", function (filters) {    	
    	var keywordFilter = filters.find(function (filter) {
    		return filter.name === "keywordFilter";
    	});
    	
    	if (keywordFilter) {
	    	$scope.typeahead.options = [];
	    	
	    	keywordFilter.taxonomy.forEach(function (genus) {
	    		genus.keywords.forEach(function (keyword) {
	    			$scope.typeahead.options.push({
	    				id: keyword.id,
	    				name: keyword.name,
	    				genus: {
	    					id: genus.genusID,
	    					name: genus.name
	    				}
	    			});
	    		})
	    	});
	    }
    });
	
	$scope.initList = function () {
		if ($scope.list == undefined) {
			return;
		}
		
		var user   = canopySession.getUser();
		var domain = canopySession.getActiveDomain();
		
		var dateFilter = {
			name: "Date",
			type: "com.intrepia.luma.PeriodFilter",
			instant: "Created",
			from: null,
			until: null
		};
		
		var keywordFilter = {
			name: "keywordFilter",
			type: "com.intrepia.luma.KeywordFilter",
			applied: [],
			ignoreDomains: true,
			ignoreApplied: false
		};
		
		var searchFilter = {
			name: "searchFilter",
			type: "com.intrepia.luma.AdvancedSearchFilter",
			applied: []
		};
		
		var stateFilter = {
			name: "State",
			type: "com.intrepia.luma.ListProjects$ProjectStateFilter"
		};
		
		var unitFilter = {
			name: "Unit",
			type: "com.intrepia.luma.ListProjects$ProjectGroupFilter",
			groupType: "Unit"
		};
		
		var regionFilter = {
			name: "Region",
			type: "com.intrepia.luma.ListProjects$ProjectGroupFilter",
			groupType: "Region"
		};
		
		var branchFilter = {
			name: "Branch",
			type: "com.intrepia.luma.ListProjects$ProjectGroupFilter",
			groupType: "Branch"
		};
		
		var campaignFilter = {
			name: "Campaign",
			type: "com.intrepia.luma.ListProjects$ProjectCampaignFilter"
		};
		
		$scope.list.setService($scope.list.getService() || "ListProjects");
		
		$scope.list.setParameter("listing-sort", "project-created");
		$scope.list.setParameter("listing-order", "Descending");
		
		switch ($scope.list.getService() ) {
			case "ListProjectsForRole":	
				$scope.list.setParameter("domainID", domain.id);
				$scope.list.setParameter("includeSubDomains", true);
				$scope.list.setParameter("enforcements", "Strict");
								
				dateFilter.from  = Dates.encode(moment().subtract(30, "days").startOf("day").toDate());
				dateFilter.until = Dates.encode(moment().endOf("day").toDate());
				break;
				
			default:
				$scope.list.setParameter("domainID", domain.id);
				break;
		}
		
		$scope.list.registerFilter(dateFilter);
		$scope.list.registerFilter(keywordFilter);
		$scope.list.registerFilter(searchFilter);
		$scope.list.registerFilter(stateFilter);
		$scope.list.registerFilter(unitFilter);
		$scope.list.registerFilter(regionFilter);
		$scope.list.registerFilter(branchFilter);
	};
	
	$scope.getDetailsService = function (item) {
		return projects.getProject(item.id);
	};
	
	$scope.initProcesses = function () {
		var onSuccess = function (response) {
			var inclusions = [];
			var exclusions = [];
			
			try {
				inclusions = menuNode.metadata.page.processes.include;
				exclusions = menuNode.metadata.page.processes.exclude;
			}
			catch (e) {
			}
			
			if (inclusions && inclusions.length) {
				$scope.processes = response.data.filter(function (element) {
					return inclusions.indexOf(element.uuid) >= 0;
				});
			}
			else if (exclusions && exclusions.length) {
				$scope.processes = response.data.filter(function (element) {
					return exclusions.indexOf(element.uuid) == -1;
				});
			}
			else {
				$scope.processes = response.data;
			}
		};
		
		var list = listService.instantiate("ListProcessesForDomain");
		
		list.setParameter("domainID", canopySession.getActiveDomain().id);
		list.setParameter("siteID", canopySession.getActiveSite().id);
		list.setParameter("type", "Project");
		list.setParameter("ignoreDomains", true);
		list.setParameter("role", "Orderer");
		
		list.load().then(onSuccess);
	};
		
	$scope.init();
	$scope.initProcesses();
	
	$scope.$watch("results.table", function (items) {
		if (items == undefined) {
			return;
		}
		
		items.forEach(function (element) {
			var transaction = element.transaction;
			
			if (transaction && transaction.reimbursements.length && transaction.reimbursements.total == undefined) {
				transaction.reimbursements.total = transaction.reimbursements.reduce(function (acc, reimbursement) {
					return parseFloat(acc) + parseFloat(reimbursement.value);
				}, 0);
			}
		});
	});
	
	$scope.onCreateReimbursement = function (transaction) {
		var onComplete = function (reimbursement) {
			transaction.reimbursements.push(reimbursement);
			transaction.reimbursements.total += parseFloat(reimbursement.value);
		};
		
		reimbursements.utilities.createReimbursement(transaction, onComplete);
	};
	
	$scope.onCloseTransaction = function (transaction) {
		var onComplete = function () {
			transaction.state = "Closed";
		};
		
		budgets.utilities.closeBudgetTransaction(transaction, onComplete);
	};
});