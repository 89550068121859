"use strict";

canopy.directive("canopyNodeSelector", function (canopyModals, canopySession) {
	return {
		controller: function ($scope) {
			$scope.viewModes = {
				selected: null,
				available: [
					{ name: "internal", title: "Internal" },
					{ name: "external", title: "External" },
					{ name: "asset", title: "Asset" }
				]
			};
			
			$scope.nodes = {
				selected: null,
				available: []
			};
			
			if ($scope.link) {
			}
			else {
				$scope.link = {};
			}
			
			var addNode = function (node) {
				$scope.nodes.available.push(node);
				
				if (node.submenus) {
					node.submenus.forEach(addNode);
				}
			};
			
			var processNode = function (node) {
				if ($scope.link && $scope.link.nodeID) {
 					if ($scope.link.nodeID == node.id) {
 						$scope.nodes.selected = node;
 					}
				}
				
				var ancestors = [ node ];
				var parent    = node.parent || null;
				
				while (parent != null) {
					ancestors.push(parent);
					
					if (parent.parent) {
						parent = parent.parent;
					}
					else {
						parent = null;
					}
				}
								
				node.reference = ancestors.reverse().map(function (element) {
					return element.title;
				}).join(" / ");
				
				node.reference = node.siteTitle + " - " + node.reference;
			};
			
			canopySession.getAvailableNodes().forEach(addNode);
			
			$scope.nodes.available.forEach(processNode);
			
			
			$scope.$watch("viewModes.selected", function (viewMode) {
				if (viewMode) {
					$scope.link.type = viewMode.name;
					$scope.link.data = {};
				}
			});
			
			$scope.$watch("nodes.selected", function (node) {
				if (node) {
					$scope.link.data = {
						nodeID: node.id
					};
				}
			});
		},
		link: function (scope, element) {
		},
		scope: {
			link: "="
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/tools/canopy-node-selector.html"
	};
});