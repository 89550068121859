canopy.controller("CampaignListController", function ($scope, $controller, $stateParams, $timeout, lumaPaths, canopySession, menuNode, listService, reimbursements, budgets, canopyModals) {
	'use strict';
	$controller("ListController", {
		$scope:    $scope,
		$timeout:  $timeout,
		lumaPaths: lumaPaths,
		menuNode:  menuNode
	});
	
	$scope.filterPanel = {
		isCollapsed: true
	};
	
	$scope.menuNode = menuNode;
    
    // TODO: This is now used in multiple places.
    $scope.typeahead = {
		model: null,
		options: [],
		onSelect: function (item, model, label) {
			if (item.id) {
				var keywordFilter = $scope.list.getFilter("keywordFilter");
				
				if (keywordFilter) {
					$scope.list.toggleFilter(keywordFilter, item);
				}
				
				$scope.typeahead.model = null;
				
				$scope.load();
			}
			
			if (item.text) {
				var searchTerms = item.name.split(" ");
				
				searchTerms.forEach(function (element) {
					// $scope.addParam("search", element);
					$scope.list.toggleFilter("searchFilter", { value: element, flags: ["Name", "Fuzzy"] });
				});

				$scope.typeahead.model = null;					
				$scope.load();
			}
			
			$scope.typeahead.search = null;
		}
	};
	
	$scope.$watch("filters", function (filters) {    	
    	var keywordFilter = filters.find(function (filter) {
    		return filter.name === "keywordFilter";
    	});
    	
    	if (keywordFilter) {
	    	$scope.typeahead.options = [];
	    	
	    	keywordFilter.taxonomy.forEach(function (genus) {
	    		genus.keywords.forEach(function (keyword) {
	    			$scope.typeahead.options.push({
	    				id: keyword.id,
	    				name: keyword.name,
	    				genus: {
	    					id: genus.genusID,
	    					name: genus.name
	    				}
	    			});
	    		})
	    	});
	    }
    });
	
	$scope.initList = function () {
		if ($scope.list == undefined) {
			return;
		}
		
		var user   = canopySession.getUser();
		var domain = canopySession.getActiveDomain();
		
		var dateFilter = {
			name: "Date",
			type: "com.intrepia.luma.PeriodFilter",
			instant: "Created",
			from: null,
			until: null
		};
		
		var keywordFilter = {
			name: "keywordFilter",
			type: "com.intrepia.luma.KeywordFilter",
			applied: [],
			ignoreDomains: true,
			ignoreApplied: false
		};
		
		var searchFilter = {
			name: "searchFilter",
			type: "com.intrepia.luma.AdvancedSearchFilter",
			applied: []
		};
		
		var stateFilter = {
			name: "State",
			type: "com.intrepia.luma.ListCampaigns$CampaignStateFilter"
		};
		
		var unitFilter = {
			name: "Unit",
			type: "com.intrepia.luma.ListCampaigns$CampaignGroupFilter",
			groupType: "Unit"
		};
		
		var regionFilter = {
			name: "Region",
			type: "com.intrepia.luma.ListCampaigns$CampaignGroupFilter",
			groupType: "Region"
		};
		
		var branchFilter = {
			name: "Branch",
			type: "com.intrepia.luma.ListCampaigns$CampaignGroupFilter",
			groupType: "Branch"
		};
		
		$scope.list.registerFilter(dateFilter);
		$scope.list.registerFilter(keywordFilter);
		$scope.list.registerFilter(searchFilter);
		$scope.list.registerFilter(stateFilter);
		$scope.list.registerFilter(unitFilter);
		$scope.list.registerFilter(regionFilter);
		$scope.list.registerFilter(branchFilter);
		
		$scope.list.setService($scope.list.getService() || "ListCampaigns");
		
		$scope.list.setParameter("listing-sort", "campaign-created");
		$scope.list.setParameter("listing-order", "Descending");
		
		switch ($scope.list.getService() ) {
			case "ListCampaignsForRole":			
				$scope.list.setParameter("domainID", domain.id);
				$scope.list.setParameter("includeSubDomains", true);
				$scope.list.setParameter("enforcements", "Strict");
				break;
				
			default:
				$scope.list.setParameter("domainID", domain.id);
				break;
		}
	};
	
	$scope.initProcesses = function () {
		var onSuccess = function (response) {
			$scope.processes = response.data;
		};
		
		var list = listService.instantiate("ListProcessesForDomain");
		
		list.setParameter("domainID", canopySession.getActiveDomain().id);
		// list.setParameter("siteID", canopySession.getActiveSite().id);
		list.setParameter("type", "Campaign");
		list.setParameter("ignoreDomains", true);
		list.setParameter("role", "Orderer");
		
		list.load().then(onSuccess);
	};
	
	$scope.onCampaignExpandToggle = function (campaign) {
		if (campaign.ui) {
			campaign.ui.expanded = !campaign.ui.expanded;
		}
		else {
			campaign.ui = {
				expanded: true
			};
		}
		
		if (campaign.projects == undefined) {		
			var onSuccess = function (response) {
				campaign.projects = response.data.table;
			};
		
			var onError = function (response) {
			};
		
			var list = listService.instantiate("ListProjects");
		
			list.setParameter("domainID", canopySession.getActiveDomain().id);
			list.setParameter("campaignIDs", campaign.id);
			list.setParameter("includeSubdomains", true);
			list.setParameter("listing-style", "Page");
			list.setParameter("listing-sort", "asset-created");
			list.setParameter("listing-order", "Descending");
		
			list.load().then(onSuccess, onError);
		}
	};
		
	$scope.init();
	$scope.initProcesses();
});