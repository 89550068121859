"use strict";

canopy.directive("canopyBreadcrumb", function () {
	return {
		restrict: "A",
		controller: function ($scope, canopySession) {
			$scope.breadcrumb = {
				site:  null,
				nodes: []
			};
				
			var buildTrail = function (root) {
				$scope.breadcrumb.nodes = [];
				
				var current = root;
						
				while (current) {
					$scope.breadcrumb.nodes.push(current);
					
					if (current.parent) {
						current = current.parent;
					}
					else {
						current = null;
					}
				}
				
				$scope.breadcrumb.nodes = $scope.breadcrumb.nodes.reverse();
			};
			
			$scope.onSiteSelect = function () {
				canopySession.setActiveSite($scope.breadcrumb.site, {
					cascade: true 
				});
			};
			
			$scope.onNodeSelect = function (node) {				
				canopySession.setActiveNode(node);
			};
			
			$scope.$watch("activeSite", function (site) {
				if (site) {
					$scope.breadcrumb.site = site;
				}
			});
			
			$scope.$watch("activeNode", function (node) {
				if (node) {
					buildTrail(node);
				}
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/breadcrumb/breadcrumb.html",
		scope: {
			activeSite: "=",
			activeNode: "="
		}
	}
});