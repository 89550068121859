// 'use strict';

var canopyUtils = angular.module("canopyUtils", ["angularFileUpload"]);

canopyUtils.factory("canopyModals", ["$uibModal", "FileUploader", function ($uibModal, FileUploader) {
	return {
		instantiate: function ( modalDefintion ) {
			var modalInstance = $uibModal.open({
				templateUrl: modalDefintion.templateUrl,
				resolve: modalDefintion.resolve,
				controller: modalDefintion.controller,
				windowClass: modalDefintion.windowClass,
				size: modalDefintion.size || null
			});
			
			return modalInstance;
		},
		definitions: {
			message: function ( content ) {
				return {
					templateUrl: "modalMessage.html",
					resolve: {
						content: function () {
							return content;
						}
					},
					controller: function ( $scope, $uibModalInstance, content ) {
						$scope.content = content;
						
						$scope.dismiss = function () {
							$uibModalInstance.dismiss();
						};
				
						$scope.confirm = function () {
							$uibModalInstance.close();
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			},
			serverError: function ( response ) {
				return {
					templateUrl: "modalServerError.html",
					resolve: {
						response: function () {
							return response;
						}
					},
					controller: function ( $scope, $uibModalInstance, response ) {
						$scope.response = response;
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
				
						$scope.confirm = function () {
							$uibModalInstance.close();
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			},
			form: function ( content ) {
				return {
					templateUrl: "modalForm.html",
					resolve: {
						content: function () {
							return content;
						}
					},
					controller: function ( $scope, $uibModalInstance, content ) {
						$scope.content = content;
						$scope.fields  = content.fields || {};
						$scope.form    = content.values || {};
						
						var reassignIds = function () {
							$scope.content.items.forEach(function (element, index) {
								element.id = index;
							});
						};
						
						if ($scope.content.items) {
							$scope.active = {
								index: 0
							};
							
							reassignIds();
						}
						
						// TODO: This absolutely shouldn't be here.
						$scope.removeItem = function () {
							$scope.content.items.splice($scope.active.index, 1);
							
							reassignIds();
						};
						
						$scope.addItem = function () {
							reassignIds();
							
							var nextID = $scope.content.items.length;
							
							$scope.content.items.push({
								id: $scope.content.items.length
							});
							
							$scope.active.index = nextID;
						};
						
						$scope.dismiss = function () {
							$uibModalInstance.dismiss();
						};
						
						$scope.forms = [];
	
						$scope.registerForm = function (element, name) {
							$scope.forms.push({
								name: name,
								node: element
							});
						};
					
						$scope.confirm = function () {						
							if ($scope.isSubmitting) {
								return;
							}
							
							$scope.isSubmitting = true;
							$scope.$broadcast("submit");
		
							var invalid = false;
							
							$scope.forms.forEach(function (element) {
								var form = element.node[element.name];
								
								if (form.$invalid) {
									element.node.$parent.isOpen = true;
									invalid = true;
								}
							});
		
							if (invalid) {
								$scope.isSubmitting = false;
								return;
							}
						
							$scope.isSubmitting = false;
							$uibModalInstance.close($scope.form);
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			},
			pageVersions: function ( node, options) {
				return {
					windowClass: "sidebar-modal",
					size: "",
					templateUrl: "modalPageVersions.html",
					resolve: {
						node: function() {
							return node;
						},
						options: function() {
							return options;
						}
					},
					controller: function(node, $scope, $uibModalInstance, pageWidgets, contentManagement, canopyModals, pageVersion) {
						$scope.node = node;
				
						$scope.dismiss = function () {
							$uibModalInstance.dismiss();
						};

						$scope.dismissWithModal = function() {
							var modal = canopyModals.definitions.message({
								header: "Are you sure?",
								body: "Are you sure you would like to close the panel?",
								confirm: "Confirm",
								cancel: "Cancel"
							});
						
							canopyModals.instantiate(modal).result.then(function () {
								$scope.dismiss();
							});	
						};	

						$scope.versions = [];
						$scope.pagination = {};
						$scope.pagination.currentPage = 1;
						$scope.pagination.itemsPerPage = 10;
						$scope.pagination.filteredArray = [];
						$scope.pagination.begin = 0;
						$scope.pagination.end = $scope.pagination.itemsPerPage;
						$scope.pagination.maxSize = 5;
						$scope.pagination.onPageChange = function() {
							$scope.pagination.begin = (($scope.pagination.currentPage - 1) * $scope.pagination.itemsPerPage);
							$scope.pagination.end = $scope.pagination.begin + $scope.pagination.itemsPerPage;
							$scope.pagination.filteredArray = $scope.versions.slice($scope.pagination.begin, $scope.pagination.end);	
						};

						$scope.previewRevision = function(revision, andEdit) {
							contentManagement.getSiteMenuPage($scope.node.id, revision.revision).then(
								function successCallback(response) {
									pageVersion.setCurrentRevision(revision);
									$scope.node.page.widgets = angular.copy(pageWidgets.parseServerFragments(response.data.fragments));

									// After setting the data to the page we want to instantiate the edit modal...
									if (andEdit) {
										canopyModals.instantiate(canopyModals.definitions.pageEditor(node));
									}
								}, function errorCallback(response) {
									canopyModals.instantiate(canopyModals.definitions.serverError(response.data));
								}
							);
						};


						$scope.previewAndEditRevision = function(revision) {
							// Preview with separate argument to edit the page once data has been retrieved
							$scope.previewRevision(revision, true);
							$scope.dismiss();
						};


						$scope.publishRevision = function(revision) {
							contentManagement.activateSiteMenuPageVersion($scope.node.id, revision.revision).then(
								function successCallback(response) {
									// Now that we have set the 'active' revision we should preview it:
									$scope.previewRevision(revision);
									retrievePageVersions();
								}, function errorCallback(response) {
									canopyModals.instantiate(canopyModals.definitions.serverError(response.data));
								}
							);
						};



						function retrievePageVersions() {
							if ($scope.node.page) {
								contentManagement.listSiteMenuPageVersions($scope.node.id).then(
									function successCallback(response) {
										$scope.versions = response.data;
										$scope.pagination.onPageChange();
									}, function errorCallback(response) {
										canopyModals.instantiate(canopyModals.definitions.serverError(response.data));
									}
								);
							}
						}

						$scope.init = function() {
							retrievePageVersions();
						}();
					},
					handleResult: function(result) {
						return result;
					}
				};
			},
			pageEditor: function ( node, options ) {
				return {
					templateUrl: "modalPageEditor.html",
					resolve: {
						node: function () {
							return node;
						},
						options: function () {
							return options;
						}
					},
					controller: function ( $scope, $uibModalInstance, node, canopyWidgetDictionary, canopyEvents, pageWidgets, contentManagement, canopyModals, pageVersion) {
						$scope.REVISION_MICRO = contentManagement.REVISION_MICRO;
						$scope.REVISION_MINOR = contentManagement.REVISION_MINOR;
						$scope.REVISION_MAJOR = contentManagement.REVISION_MAJOR;

                        // Currently edited revision (returns immediately):
                        $scope.editedRevision = pageVersion.getCurrentRevision();

                        // Current published revision (returns eventually):
                        $scope.publishedRevision = undefined;
                        pageVersion.getActiveRevisionPromise(node.id).then(function(response){ 
                            $scope.publishedRevision = response;
                        });

						$scope.reinstantiate = function(opts) {
                            $scope.dismiss();

							$uibModalInstance.closed.then(function modalClosed() {
                                canopyModals.instantiate(canopyModals.definitions.pageEditor($scope.node, opts));
                            });
							
						};

						$scope.savePage = function(publish, revisionType, coalesce) {
							
							var pageData = pageWidgets.getPageWidgets($scope.node.page);

							if (!$scope.publishedRevision && !publish) {
                                // We have a pristine page and we're not attempting to publish a revision.
                                // The first revision must always be published...
								canopyModals.instantiate(canopyModals.definitions.message({
                                    header: "Pristine page",
								    body: "The first revision for a content managed page must always be published. You can publish an empty page and then create revisions / drafts that are unpublished.",
								    confirm: "OK"
								}));
								
								return;
							}
							
							if ($scope.node.page) {
								contentManagement.saveSiteMenuPage($scope.node.id, pageData, publish, revisionType, coalesce).then(function successCallback(response) {
                                    
                                    // We might want to do separate things depending on if we publish or not, but the same, for now...
									if (publish) {
										pageVersion.setCurrentRevisionToActiveRevisionPromise($scope.node.id).then(function(response) {
											$scope.reinstantiate();
										});
									} else {
										pageVersion.setCurrentRevisionToLatestRevisionPromise($scope.node.id).then(function(response) {
                                            $scope.reinstantiate();
                                        });
									}

									canopyEvents.dispatch("genericEvent", {
										message: "Changes have been saved"
									});
								}, function errorCallback(response) {
									canopyModals.instantiate(canopyModals.definitions.serverError(response.data));
								});
							}
						};

						$scope.clearChangesWithModal = function() {

                            if (!$scope.publishedRevision) {
                                // Can't clear changes and go back to published revision if there isn't a published revision available
                                canopyModals.instantiate(canopyModals.definitions.message({
                                    header: "Pristine page",
								    body: "Unable to clear changes as there is not a published revision to revert back to.",
								    confirm: "OK"
                                }));
                                
                                return;
                            }

							var modal = canopyModals.definitions.message({
								header: "Are you sure?",
								body: "Any unsaved changes will be lost and the page will revert to the currently Published version.",
								confirm: "Confirm",
								cancel: "Cancel"
							});
						
							canopyModals.instantiate(modal).result.then(function () {
								$scope.clearChanges();
							});	
						};

						$scope.clearChanges = function() {

                            if (!$scope.publishedRevision) {
                                // Can't clear changes and go back to published revision if there isn't a published revision available
                                canopyModals.instantiate(canopyModals.definitions.message({
                                    header: "Pristine page",
								    body: "Unable to clear changes as there is not a published revision to revert back to.",
								    confirm: "OK"
                                }));
                                
                                return;
                            }

							// retrieve current active version:
							contentManagement.getSiteMenuPage($scope.node.id).then(
								function successCallback(response) {
									$scope.node.page.widgets = angular.copy(pageWidgets.parseServerFragments(response.data.fragments));
									
									pageVersion.setCurrentRevisionToActiveRevisionPromise(node.id).then(function(response) {
										$scope.reinstantiate();
									});
									
								}, function errorCallback(response) {
									canopyModals.instantiate(canopyModals.definitions.serverError(response.data));
								}
							);
						};

						$scope.init = function () {
							$scope.node = node;
							$scope.page = node.page;
							$scope.form = {};
						
							$scope.active = {
								widget: null,
								facet: null
							};
						
							$scope.view = {
								modes: {
									EDIT_WIDGET: 0,
									EDIT_FACET: 1
								},
								mode: null
							};
							
							$scope.available = {
								widgets: canopyWidgetDictionary.getAvailableWidgets()
							};
						
							if ($scope.page.widgets && $scope.page.widgets.length) {
								$scope.page.widgets.forEach(function (widget) {
									var definition = canopyWidgetDictionary.getDefinition(widget.widget);
							
									if (definition) {
										widget.widget = definition;
									}
								});
								
								$scope.onWidgetSelected($scope.page.widgets[0]);
							}
						};
						
						$scope.onWidgetSelected = function (widget) {
							$scope.active.widget = widget;
							
							if (widget.facets && widget.facets.length) {
								$scope.onFacetSelected(widget.facets[0]);
							}
							
							$scope.view.mode = $scope.view.modes.EDIT_WIDGET;
						};
						
						$scope.onEditWidget = function () {							
							$scope.view.mode = $scope.view.modes.EDIT_WIDGET;
						};
						
						$scope.onFacetSelected = function (facet) {
							$scope.active.facet = facet;
							
							$scope.view.mode = $scope.view.modes.EDIT_FACET;
						};
						
						$scope.dismiss = function () {
							$uibModalInstance.dismiss();
						};

						$scope.dismissWithModal = function() {
							var modal = canopyModals.definitions.message({
								header: "Are you sure?",
								body: "Are you sure you would like to close the editor? Any unsaved changes will still be visible, but unsaved.",
								confirm: "Confirm",
								cancel: "Cancel"
							});
						
							canopyModals.instantiate(modal).result.then(function () {
								$scope.dismiss();
							});	
						};
						
						$scope.onAddWidget = function () {
							if ($scope.page.widgets instanceof Array != true) {
								$scope.page.widgets = [];
							}
							
							$scope.page.widgets.push({
								title: "Widget #" + ($scope.page.widgets.length + 1)
							});
						};
						
						$scope.onRemoveWidget = function (index) {
							var spliceIndex = -1;
							
							if (index) {
								spliceIndex = index;
							}
							else {							
								$scope.page.widgets.forEach(function (element, index) {
									if (element == $scope.active.widget){
										spliceIndex = index;
									}
								});
							}
							
							if (spliceIndex >= 0) {
								$scope.page.widgets.splice(spliceIndex, 1);
								$scope.active.widget = $scope.page.widgets[0];
							}
						};
						
						$scope.onShiftWidget = function (shift) {
							var item  = $scope.active.widget;
							var index = $scope.page.widgets.indexOf(item);
							
							var newIndex = index + shift;
							
							if (newIndex < 0) {
								newIndex = 0;
							}
							if (newIndex >= $scope.page.widgets.length) {
								newIndex  = $scope.page.widgets.length - 1;
							}
							
						    $scope.page.widgets.splice(newIndex, 0, $scope.page.widgets.splice(index, 1)[0]);
						};
						
						$scope.onAddFacet = function () {
							if ($scope.active.widget) {
								if ($scope.active.widget.facets) {
									$scope.active.widget.facets.push({
									});
								}
								else {
									$scope.active.widget.facets = [{
									}];
								}
							}
						};
						
						$scope.onRemoveFacet = function (index) {
							var spliceIndex = -1;
							
							if (index) {
								spliceIndex = index;
							}
							else {							
								$scope.active.widget.facets.forEach(function (element, index) {
									if (element == $scope.active.facet) {
										spliceIndex = index;
									}
								});
							}
							
							if (spliceIndex >= 0) {
								$scope.active.widget.facets.splice(spliceIndex, 1);
								$scope.active.facet = $scope.active.widget.facets[0];
							}
						};
						
						$scope.onShiftFacet = function (shift) {
							var item  = $scope.active.facet;
							var index = $scope.active.widget.facets.indexOf(item);
							
							var newIndex = index + shift;
							
							if (newIndex < 0) {
								newIndex = 0;
							}
							if (newIndex >= $scope.active.widget.facets.length) {
								newIndex  = $scope.active.widget.facets.length - 1;
							}
							
						    $scope.active.widget.facets.splice(newIndex, 0, $scope.active.widget.facets.splice(index, 1)[0]);
						};
				
						$scope.confirm = function () {
							$uibModalInstance.close($scope.form);
						};
						
						$scope.init();
					},
					handleResult: function ( result ) {
						return result;
					},
					size: "lg"
				};
			},
			selection: function ( content ) {
				return {
					templateUrl: "modalSelection.html",
					resolve: {
						content: function () {
							return content;
						}
					},
					controller: function ( $scope, $uibModalInstance, content ) {
						$scope.content = content;
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
				
						$scope.confirm = function () {
							var selected = $scope.content.options.filter(function (option) {
								return option.isSelected;
							});
							
							$uibModalInstance.close(selected);
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			},
			assetDownload: function (args) {
				return {
					templateUrl: "modalAssetDownload.html",
					resolve: {
						args: function () {
							return args;
						}
					},
					controller: function ($scope, assets, downloads, basket, $uibModalInstance, args) {
						var init = function () {
							$scope.assets = null;
							
							if (args.selection === "basket") {
							}
							else {
								$scope.assets = args.selection;
							}
							
							$scope.derivatives = null;
							
							$scope.bundle = {
								count: 0,
								size: 0
							};
						
							var onSuccess = function (response) {
								$scope.derivatives = response.data;
							};
						
							var onError = function (response) {
								$scope.error = true;
							};
		
							if (args.selection === "basket") {		
						 		basket.getBasketDerivativeMenu().then(onSuccess);
							}
							else {
								var assetIDs = $scope.assets.map( function (element) {
									return element.id;
								});
								
								assets.getAssetDerivativeMenu(assetIDs).then(onSuccess);
							}
						};
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
						
						$scope.onDownload = function () {							
							var selected = $scope.derivatives.filter(function (derivative) {
								return derivative.isSelected;
							});
							
							var transformIDs = selected.map(function (element) {
								return element.id;
							});
				
							if (args.selection === "basket") {
								downloads.shrinkWrapBasketDerivatives(transformIDs);
							}
							else {
								var assetIDs = $scope.assets.map( function (element) {
									return element.id;
								});
								
								downloads.shrinkWrapAssetDerivatives(assetIDs, transformIDs);
							}
							
							$uibModalInstance.close();
						};
						
						$scope.$watch("derivatives", function (derivatives) {
							var sizeFilter = function (element) {
								return element.isSelected;
							};
							
							var sizeReducer = function (acc, element) {
								return {
									count: acc.count + element.count,
									size: acc.size + element.size
								};
							};
							
							if (derivatives) {
								$scope.bundle = $scope.derivatives.filter(sizeFilter).reduce(sizeReducer, {
									count: 0,
									size: 0
								});
							}
						}, true);
						
						init();
					},
					handleResult: function (result) {
						return result;
					}
				};
			},
			dateSelector: function ( dates ) {
				return {
					templateUrl: "modalDateSelector.html",
					resolve: {
						dates: function () {
							return dates;
						}
					},
					controller: function ( $scope, $uibModalInstance, utilities ) {
						$scope.dates = dates;
			
						$scope.presets = [
							{
								"title": "All Time",
								"start": null,
								"end":   null
							},
							{
								"title": "Last 30 days",
								"start": new utilities.date().offset({ days: -30 }).startOf("day").toDate(),
								"end":   null
							},
							{
								"title": "Today",
								"start": new utilities.date().startOf("day").toDate(),
								"end":   null
							},
							{
								"title": "This Month",
								"start": new utilities.date().startOf("month").toDate(),
								"end":   new utilities.date().endOf("month").toDate()
							},
							{
								"title": "This Year",
								"start": new utilities.date().startOf("year").toDate(),
								"end":   new utilities.date().endOf("year").toDate()
							}
						];
						
						$scope.onSelectPreset = function (preset) {
							if (preset === undefined) {
								return;
							}
							
							$scope.dates.start = preset.start || null;
							$scope.dates.end   = preset.end   || null;
						};
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
			
						$scope.confirm = function () {
							$uibModalInstance.close( $scope.dates );
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			},
			dateRange: function ( dates, presets ) {
				return {
					templateUrl: "modalDateRange.html",
					resolve: {
						dates: function () {
							return dates;
						},
						presets: function () {
							return presets;
						}
					},
					controller: function ( $scope, $uibModalInstance, utilities ) {
						$scope.range = {
							start: dates.start || null,
							end: dates.end || null
						};
                        
                        // Check date strings and convert to avoid Safari errors:
                        if (typeof $scope.range.start === "string" && isNaN(new Date($scope.range.start).getTime())) {
                            $scope.range.start = $scope.range.start.replace(/-/g, "/");
                        }
                
                        if (typeof $scope.range.end === "string" &&isNaN(new Date($scope.range.end).getTime())) {
                            $scope.range.end = $scope.range.end.replace(/-/g, "/");   
                        }

						$scope.presets = {
							available: presets || [
								{
									title: "All time",
									start: null,
									end:   null
								},
								{
									title: "Last 30 days",
									start: new utilities.date().offset({ days: -30 }).startOf("day").toDate(),
									end:   new utilities.date().endOf("day").toDate()
								},
								{
									title: "Today",
									start: new utilities.date().startOf("day").toDate(),
									end:   new utilities.date().endOf("day").toDate()
								},
								{
									title: "This month",
									start: new utilities.date().startOf("month").toDate(),
									end:   new utilities.date().endOf("month").toDate()
								},
								{
									title: "This year",
									start: new utilities.date().startOf("year").toDate(),
									end:   new utilities.date().endOf("year").toDate()
								}
							],
							selected: null
						};
						
						$scope.onPresetSelected = function (preset) {
							if (preset === undefined) {
								return;
							}
							
							$scope.range.start = preset.start || null;
							$scope.range.end   = preset.end   || null;
							
//							$scope.presets.selected = preset;
						};
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
			
						$scope.confirm = function () {
							$uibModalInstance.close({
								range: $scope.range,
								preset: $scope.presets.selected
							});
						};
					},
					handleResult: function ( result ) {
						return result;
					},
					size: "lg"
				};
			},
			fileUpload: function ( args ) {
				return {
					templateUrl: "modalFileUpload.html",
					resolve: {
						args: function () {
							return args;
						}
					},
					controller: function ( $scope, $uibModalInstance, args, listService, canopySession ) {
						// TODO: Since this now depends on the canopySession service. It's no longer suitable as a general utility.
						
						$scope.args = args;						
						$scope.uploader = new FileUploader({    	
							url: Luma.paths.context + "/servlet/interface/com.intrepia.luma." + args.service,
							alias: args.alias
						});
						
						if (args.hoppers) {
							$scope.ancillary = {
								hoppers: {
									available: args.hoppers || [],
									selected: null
								}
							};
						
							$scope.ancillary.hoppers.selected = $scope.ancillary.hoppers.available[0];
						}
						
						$scope.uploader.onBeforeUploadItem = function (item) {
							for (var key in args.data) {
								var data = {};
								data[key] = args.data[key];
								
								item.formData.push(data);
							}
							
							if (args.hoppers) {
								var hopperData = {
									hopperID: $scope.ancillary.hoppers.selected.id
								}; 
							}
							
							item.formData.push(hopperData);
						};				
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
				
						$scope.confirm = function () {
							if ($scope.isUploading) {
								return;
							}
							
							$scope.isUploading = true;
							
							$scope.uploader.onCompleteAll = function () {
								$scope.isUploading = false;
								
								$uibModalInstance.close();
							};
												
							$scope.uploader.uploadAll();
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			},
			submitBudgetReimbursement: function ( args ) {
				return {
					templateUrl: "modalSubmitBudgetReimbursement.html",
					size: "lg",
					resolve: {
						args: function () {
							return args;
						}
					},
					controller: function ( $scope, $uibModalInstance, args, reimbursements ) {
						$scope.form = {
						};
						
						$scope.uploader = new FileUploader({    	
							url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.SubmitBudgetReimbursement",
							alias: "receipt",
							queueLimit: 1
						});
						
						var getData = function () {
							return {							
								value: $scope.form.value,
								reference: $scope.form.reference,
								notes: $scope.form.notes,
								invoiced: $scope.form.invoiced ? $scope.form.invoiced.toString() : null
							};
						};
						
						$scope.uploader.onBeforeUploadItem = function (item) {
							var data = getData();
							
							data.transactionID = args.transactionID;
							
							item.formData.push(data);
						};				
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
						
						var onComplete = function (data) {
							$scope.isUploading = false;

							$uibModalInstance.close(data);
						};
				
						$scope.confirm = function () {
							$scope.$broadcast("submit");
							
							if (!$scope.form.value || !$scope.form.reference) {
								$scope.isUploading = false;
								
								return;
							}
							
							if (!parseFloat($scope.form.value)) {
								$scope.isUploading = false;
								
								return;
							}
							
							$scope.isUploading = true;
							
							$scope.uploader.onCompleteAll = function () {
								onComplete();
							};
						
							if ($scope.uploader.queue.length) {
								$scope.uploader.uploadAll();
							}
							else {
								var data = getData();
								
								reimbursements.submitBudgetReimbursement(args.transactionID, data).then(onComplete(data));
							}
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			},
			submitBudgetReimbursements: function ( args ) {
				return {
					templateUrl: "modalSubmitBudgetReimbursements.html",
					size: "lg",
					resolve: {
						args: function () {
							return args;
						}
					},
					controller: function ( $scope, $uibModalInstance, args, reimbursements ) {
						$scope.form = {
						};

						$scope.state = {
							error: undefined,
							valueReimbursable: 0,
							remainingValueReimbursable: 0,
							page: "reimbursement-invoice" // reimbursement-values
						};

						$scope.nextState = function() {
							if ($scope.state.page === "reimbursement-invoice") {

								if (getDataIsValid()) {
									$scope.state.page = "reimbursement-values";
									$scope.state.valueReimbursable = $scope.form.value;
									$scope.state.remainingValueReimbursable = $scope.form.value;
								} else {

								}
							}
						};

						// Create local version of transactions:
						$scope.transactions = angular.copy(args.transactions).filter(function(transaction) {
							return transaction.reimbursable && transaction.state === "Confirmed";
						});

						// Calculates how much of your invoice value you have left for reimbursements in this batch operation...
						$scope.calculateRemainingReimbursable = function(transaction) {
							$scope.state.remainingValueReimbursable = $scope.state.valueReimbursable - $scope.transactions.map(function(t) { return t.amountReimbursed || 0; }).reduce(function(t1, t2) { return t1 + t2; });

							if ($scope.state.remainingValueReimbursable < 0) {
								$scope.state.error = "reimbursed-too-much";
							}
						};

						var setInitialReimbursementValues = function() {
							$scope.calculateRemainingReimbursable();

							$scope.transactions.forEach(function(transaction) {
								transaction.amountReimbursed = Math.floor($scope.state.remainingValueReimbursable / $scope.transactions.length);
							});
						};

						// Watch transactions list changes
						$scope.$watch("transactions", function(newVal, oldVal) {
							// Reset errors:
							$scope.state.error = undefined;
							
							// Recalculate:
							$scope.calculateRemainingReimbursable();
						}, true);

						// Watch state (page):
						$scope.$watch("state.page", function(newVal, oldVal) {
							if (newVal === "reimbursement-values") {
								// we navigated to reimbursement values page, set initial reimbursement values:
								setInitialReimbursementValues();
							}
						});

						$scope.uploader = new FileUploader({    	
							url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.SubmitBudgetReimbursement",
							alias: "receipt",
							queueLimit: $scope.transactions.length // Should only allow one file, until we progress to next screen!
						});
						
						var getData = function () {
							return {							
								value: $scope.form.value,
								reference: $scope.form.reference,
								notes: $scope.form.notes,
								invoiced: $scope.form.invoiced ? $scope.form.invoiced.toString() : null
							};
						};
						
						var getDataIsValid = function() {
							$scope.state.error = undefined;

							if (!$scope.form.value || !$scope.form.reference) {
								$scope.state.error = "missing-value-or-reference";
								return false;
							}
							
							if (!parseFloat($scope.form.value)) {
								$scope.state.error = "value-not-a-number";
								return false;
							}
							
							return true;
						};

						$scope.uploader.onBeforeUploadItem = function (item) {
							var data = getData();
							
							data.value = item.transaction.amountReimbursed;
							data.transactionID = item.transaction.id;
							
							item.formData.push(data);
						};
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
						
						var onComplete = function (data) {
							$scope.isUploading = false;

							$uibModalInstance.close(data);
						};
				
						var createReimbursementFileUploadQueue = function() {
							var first = angular.extend({}, $scope.uploader.queue[0]);
							
							$scope.transactions.forEach(function(transaction, index){ 
								if (index === 0) {
									// add transaction prop to existing queued item
									$scope.uploader.queue[0].transaction = transaction;
								} else {
									// Copy first queued item and push copies of it for each transaction, including the individual transactin data for each...
									var newFile = new FileUploader.FileItem($scope.uploader, 
									{
										lastModifiedDate: first.file.lastModifiedDate,
										size: first.file.size,
										type: first.file.type,
										name: index + "-" + first.file.name
									});
		
									newFile._file = new File([first._file], index + "-" + first.file.name, { type: first.file.type });
									newFile.progress = 0;
									newFile.isUploaded = false;
									newFile.isSuccess = false;
									newFile.transaction = transaction;

									$scope.uploader.queue.push(newFile);
								}
							});
						};

						$scope.doReimbursements = function () {
							$scope.$broadcast("submit");
							
							if (!getDataIsValid()) {
								return;
							}
							
							$scope.isUploading = true;
							
							$scope.uploader.onCompleteAll = function () {
								onComplete();
							};
						
							if ($scope.uploader.queue.length) {
								// We have an upload queue of any length, duplicate the queued item so that each reimbursement request gets the same file uploaded.
								createReimbursementFileUploadQueue();
								$scope.uploader.uploadAll();
							} else {
								// We have no queued items to upload, just submit the reimbursements as-is...
								var data = getData();
								reimbursements.submitBudgetReimbursements($scope.transactions, data).then(onComplete($scope.transactions));
							}
						};
					},
					handleResult: function ( result ) {
						return result;
					}
				};
			},
			assetSelect: function (options) {
				// TODO: This really shouldn't be part of canopyUtils - it's not useful in public facing pages.
				
				return {
					templateUrl: "modalAssetSelect.html",
					resolve: {
						options: function () {
							return options;
						}
					},
					controller: function ( $scope, $timeout, $uibModalInstance, canopySession, listService, utilities, options ) {
						$scope.luma = Luma;
						
						$scope.data = {
							domains: {
								selected: null,
								avaialble: []
							},
							assets: [],
							loading: true
						};
						
						$scope.search = {
							value: null
						};
						
						canopySession.promise.then(function (data) {	
							$scope.list = listService.instantiate("ListAssets");
						
							var lensID;
						
							try {
								lensID = canopySession.getActiveSite().metadata.configuration.assetSelectorLensID;
							}
							catch (e) {
								lensID = null;
							}
						
							$scope.list.setParameter("tree", null);
							$scope.list.setParameter("lensID", lensID);
							$scope.list.setParameter("listing-style", "Page");
							
							$scope.list.registerFilter({
								name: "keywordFilter",
								type: "com.intrepia.luma.KeywordFilter",
								keywordIDs: []
							});
						
							$scope.data.domains.available = canopySession.getAvailableDomains();
							
							if ($scope.data.domains.available.length) {
								$scope.onDomainSelection($scope.data.domains.available[0]);
							}
						});
						
						$scope.load = function () {
							$scope.data.assets = [];
							$scope.data.loading = true;
														
							$scope.list.load().then(function (response) {
								$scope.results = response.data;
								$scope.data.assets = response.data.table;
								$scope.data.loading = false;
							});
						};
						
						$scope.onDomainSelection = function (domain) {
							$scope.data.domains.selected = domain;
							
							$scope.list.setParameter("domainID", domain.id);
							$scope.load();
						};
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
			
						$scope.confirm = function () {
							$uibModalInstance.close( asset );
						};
						
						$scope.onAssetSelect = function (asset) {
							$uibModalInstance.close( asset );
						};
						
						$scope.onKeywordSelect = function (keyword) {
							$scope.list.toggleFilter("keywordFilter", keyword);
							$scope.load();
						};
						
						$scope.$watch("search.value", function (value) {
							if (value != null) {
								if ($scope.searchTimer) {
									$timeout.cancel($scope.searchTimer);
								}
								
								$scope.searchTimer = $timeout(function () {
									$scope.list.setParameter("search", value);
									$scope.load();
								}, 250);
							}
						});
					},
					size: "lg"
				};
			},
			filterSelector: function ( filters ) {
				// TODO: This really shouldn't be part of canopyUtils - it's not useful in public facing pages.
				
				return {
					templateUrl: "modalFilterSelector.html",
					resolve: {
						filters: function () {
							return filters;
						}
					},
					controller: function ( $scope, $uibModalInstance, utilities ) {
						$scope.filters = filters;
						
						$scope.dismiss = function () {
							$uibModalInstance.close();
						};
			
						$scope.confirm = function () {
							$uibModalInstance.close( $scope.filters );
						};
					}
				};
			}
		}
	};
}]);

canopyUtils.service("canopyValidation", function () {
	var rules = {
		currency: function (input) {
			var regex = /^[0-9]+(\.[0-9]{1,2})?$/;
			
			if (input && input.match(regex)) {
				return {
					passed: true
				};
			}
			
			return {
				passed: false,
				reason: "Please enter a numeric value, eg. 12.34"
			};
		}
	};
	 
	return {
		getRule: function (name) {
			return rules[name];
		},
		setRule: function (name, validator) {
			rules[name] = validator;
		}
	};
});

canopyUtils.service("canopyProcessing", function () {
	var processors = {};
	 
	return {
		getProcessor: function (name) {
			return processors[name];
		},
		setProcessor: function (name, processor) {
			processors[name] = processor;
		}
	};
});

canopyUtils.directive("canopyInclude", function ($timeout) {
	return {
		restrict: "A",
		replace: true,
		controller: function ($scope, $compile, $element, $http) {
			var onSuccess = function (response) {
				var element = $compile(response.data)($scope.$parent);
				$element.parent().append(element);
				
				$timeout(function () {
					$scope.isLoading = false;
				});
			};
			
			var onError = function () {
				
				$scope.isLoading = false;
				
			};
		
			$scope.isLoading = true;
			
			if ($scope.path) {
				$http.get($scope.path).then(onSuccess).catch(onError);
			}
		},
		scope: {
			path: "@"
		},
		template: "<div ng-if='isLoading' style='text-align: center;'>Loading content... <i class='fa fa-fw fa-circle-o-notch fa-spin'></i></div>"
	};
});

canopyUtils.directive("canopyFormField", function ($compile) {
	return {
		restrict: "A",
		replace: true,
		controller: function ($scope, $location, $timeout, canopyValidation) {
			$scope.$on("submit", function () {
				$scope.innerForm.$setValidity("canopy", validate());
			});
			
			$scope.onChange = function () {
				validate();
			};
			
			$scope.isDebug   = $location.search().debug === "true";
			$scope.isVisible = true;
			$scope.maxLength = Number.MAX_SAFE_INTEGER;
			$scope.fieldColumnClass = "col-xs-12";

			// Return a new random field ID:
			function getOrMakeFieldId() {
				var number = Math.random() 
				var id = number.toString(36).substr(2, 9); 
				id.length >= 9; 
				$scope.fieldId = id;
				return $scope.fieldId;
			}

			// Assign a new ID or an existing ID to a field:
			$scope.assignId = function(fType, fRows) {
				// We have rows (a textarea):
				if (fRows && fRows != undefined && fRows > 1) {
					// Check if textarea and text matches
					if (fType == "textarea" && $scope.fieldType == "text") {
						
						// Do we already have a field ID?
						if ($scope.fieldId) {
							return $scope.fieldId;
						}

						// Generate and return a new one:
						return getOrMakeFieldId();
					} else if (fType.indexOf("text") !== -1) {
						// We have multiple rows and 'text' input which means it's hidden
						return undefined;
					}
				}

				// We don't have rows, so a single line 'text' type input:
				if (!fRows || fRows == undefined || fRows == 1) {
					// Handle 'textarea' with no rows - shouldn't get an ID:
					if (fType == "textarea" && $scope.fieldType == "text"){ 
						return undefined;
					}
				}

				// General rule: ID should only be assigned to a field that matches the canopyFormField fieldType
				if (fType.indexOf($scope.fieldType) == -1) {
					return undefined;
				}

				// Return an existing ID if one exists
				if ($scope.fieldId) {
					return $scope.fieldId;
				}
				
				// Generate a new ID as it's the first time this field runs this function:
				return getOrMakeFieldId();
			};

			var validate = function () {		
				$scope.validation = {
					required: {
						passed: true,
						reason: ""
					},
					minimumLength: {
						passed: true,
						reason: ""
					},
					maximumLength: {
						passed: true,
						reason: ""
					},
					pattern: {
						passed: true,
						reason: ""
					},
					client: {
						passed: true,
						reason: ""
					}
				};
				
				$scope.hasErrors = false;
				
				if ($scope.fieldRequired && ($scope.fieldModel === null || $scope.fieldModel === undefined || $scope.fieldModel.length <= 0)) {
					$scope.validation.required.passed = false;
					$scope.validation.required.reason = "Required field";
				}
				
				if ($scope.fieldMinimumLength && $scope.fieldModel.length < $scope.fieldMinimumLength) {
					$scope.validation.minimumLength.passed = false;
					$scope.validation.minimumLength.reason = "Minimum " + $scope.fieldMinimumLength + " characters";
				}
			
				if ($scope.fieldMaximumLength && $scope.fieldModel.length > $scope.fieldMaximumLength) {
					$scope.validation.maximumLength.passed = false;
					$scope.validation.maximumLength.reason = "Maximum " + $scope.fieldMaximumLength + " characters";
				}
				
				if ($scope.fieldValidationPattern) {
					var regex = new RegExp($scope.fieldValidationPattern, "g");
					
					if (!regex.test($scope.fieldModel)) {
						$scope.validation.pattern.passed = false;
						$scope.validation.pattern.reason = "Invalid format";
					}
				}
				
				if ($scope.fieldValidation) {
					var test = canopyValidation.getRule($scope.fieldValidation);
					
					if (test) {
						$scope.validation.client = test($scope.fieldModel);
					}
				}
				
				for (var key in $scope.validation) {
					if ($scope.validation[key].passed !== true) {
						$scope.hasErrors = true;
					}
				}
				
				return $scope.hasErrors != true;
			};
			
			var setVisibility = function () {
				if ($scope.isDebug) {
					$scope.isVisible = true;
				}
				else {		
					$scope.isVisible = true;
					
					if ($scope.fieldType === "select") {
						if ($scope.fieldOptions === undefined) {
							$scope.isVisible = false;
						}
						else {
							if ($scope.fieldOptions.length <= 1 && $scope.fieldHideOnSingleOption) {
								$scope.isVisible = false;
								$scope.fieldModel = $scope.fieldOptions[0];
							}
						}
					}
				}
			};
			
			$scope.$watch("fieldWidth", function(fieldWidth) {
				if (!fieldWidth) {
					// no value set:
					$scope.fieldColumnClass = "col-xs-12";
				} else if (fieldWidth === "full") {
					// Full width:
					$scope.fieldColumnClass = "col-xs-12";
				} else if (fieldWidth === "half") {
					// Half width:
					$scope.fieldColumnClass = "col-xs-6";
				} else if (fieldWidth === "third") {
					// Third
					$scope.fieldColumnClass = "col-xs-4";
				} else if (fieldWidth === "quarter") {
					// Quarter
					$scope.fieldColumnClass = "col-xs-3";
				}
			});

			$scope.$watch("fieldOptions", function (options) {
				if (options instanceof Array && options.length) {
					var property = $scope.fieldValueProperty;
					
					if (!$scope.fieldModel) {
					}
					else {
						var value = $scope.fieldModel[property];

						var model = options.find(function (element) {
							if (property) {
								return element[property] == value;
							}
							else {
								return element == $scope.fieldModel;
							}
						});
					
						if (model == null) {
							model = options[0];
						}
					
						$scope.fieldModel = model;
					}
				}
				
				setVisibility();
			});
			
			$scope.$watch("fieldModel", function (model) {
				
				if ($scope.fieldType == "date" && model &&Object.prototype.toString.call(model) !== "[object Date]") {
					$scope.fieldModel = moment($scope.fieldModel, Dates.formats.universal).toDate();
				}
			});

			$scope.$watch("fieldOptionIndex", function (val) {
			
				if (!val) {
					return
				}

				var valIndex = function() {
					if (parseInt(val) >= 0 && $scope.fieldOptions && $scope.fieldOptions.length) {

						if ($scope.fieldOptions && $scope.fieldOptions.length ) {
							$scope.fieldModel = $scope.fieldOptions[parseInt(val)];
						}
					}
				}

				$timeout(valIndex);
			});
		},
		scope: {
			fieldRequired: "=",
			fieldId: "@",
			fieldModel: "=",
			fieldName:  "@",
			fieldType: "@",
			fieldLabel: "@",
			fieldLabelStyle: "@",
			fieldWidth: "@",
			fieldRows: "@",
			fieldPlaceholder: "@",
			fieldDisabled: "=",
			fieldOptions: "=",
			fieldOptionIndex: "@",
			fieldValueProperty: "@",
			fieldTitleProperty: "@",
			fieldHideOnSingleOption: "@",
			fieldMinimumLength: "@",
			fieldMaximumLength: "@",
			fieldValidationPattern: "@",
			fieldValidation: "@"
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/form/field.html"
	};
});

canopyUtils.directive("canopyGroupManager", function () {
	return {
		restrict: "A",
		controller: function ($scope) {
			$scope.state = {
				selected: {
					permission: null,
					unit: null,
					region: null,
					branch: null
				},
				primary: null,
				hasVisibleGroups: true
			};
			
			$scope.$watch("state", function (state) {
			}, true);
			
			$scope.config = {
				permission: {
					hidden: false,
					multiSelect: false
				},
				unit: {
					hidden: false,
					multiSelect: false
				},
				region: {
					hidden: false,
					multiSelect: false
				},
				branch: {
					hidden: false,
					multiSelect: false
				}
			};
			
			var onGroupsUpdated = function () {				
				var getGroupTypesFromString = function (str) {
					return str.toLowerCase().replace(/ /g, "").split(",");
				};
			
				if ($scope.groupsHidden) {
					var types = getGroupTypesFromString($scope.groupsHidden);
				
					types.forEach(function (type) {
						$scope.config[type].hidden = true;
					});
				
					$scope.state.hasVisibleGroups = ($scope.groups.permission.length && !$scope.config.permission.hidden) ||
													($scope.groups.unit.length && !$scope.config.unit.hidden) ||
													($scope.groups.region.length && !$scope.config.region.hidden) ||
													($scope.groups.branch.length && !$scope.config.branch.hidden);
				}
			
				if ($scope.groupsMultiSelect) {
					var types = getGroupTypesFromString($scope.groupsMultiSelect);
				
					types.forEach(function (type) {
						$scope.config[type].multiSelect = true;
					});
				}
			
				if ($scope.groupsAutoSelect) {
					var types = getGroupTypesFromString($scope.groupsAutoSelect);
				
					types.forEach(function (type) {
						var groups      = $scope.groups[type];
						var multiSelect = $scope.config[type].multiSelect;
				
						if (groups && groups.length) {
							if (multiSelect) {	
								groups.forEach(function (group) {
									group.isSelected = true;
								});
							}
							else {
								$scope.state.selected[type] = groups[0];
							}
						}
					});
				}
			};
			
 			$scope.onGroupSelected = function (group) {	
 				if (group.isSelected) {
 				}
 				else {
 					if ($scope.state.primary == group) {
 						$scope.state.primary = null;
 					}
 				}
 			};

 			$scope.onPrimaryGroupChanged = function (group) {
 			};
 			
 			var handleSingularGroupSelection = function (group) {
 				var groupType = group.type;
 				var groups    = $scope.groups[groupType.toLowerCase() ];
 				
 				if (!groups instanceof Array) {
 					return;
 				}
 				
 				groups.forEach(function (element) {
 					element.isSelected = false;
 					
 					if ($scope.state.primary == element) {
 						$scope.state.primary = null;
 					}
 					
 					if (element.id == group.id) {
 						element.isSelected = true;
 					}
 				});
 			};
 			
 			$scope.$watch("state.selected.permission", function (group) {
 				if (group) {
 					handleSingularGroupSelection(group);
 				}
 			});
 			
 			$scope.$watch("state.selected.unit", function (group) {
 				if (group) {
 					handleSingularGroupSelection(group);
 				}
 			});
 			
 			$scope.$watch("state.selected.region", function (group) { 				
 				if (group) {
 					handleSingularGroupSelection(group);
 				}
 			});
 			
 			$scope.$watch("state.selected.branch", function (group) {
 				if (group) {
 					handleSingularGroupSelection(group);
 				}
 			});
			
			$scope.$watch("state.primary", function (group) {
				var group = group || {};
					
				for (var groupType in $scope.groups) {
					var groups = $scope.groups[groupType];
					
					if (groups instanceof Array) {
						groups.forEach(function (element) {
							if (element.id == group.id) {
								element.isPrimary = true;
							}
							else {
								element.isPrimary = false;
							}
						});
					}
				}
			});
			
			$scope.$watch("groups", function (groups) {
				if (groups) {
					onGroupsUpdated();
				}
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/group-manager/group-manager.html",
		scope: {
			groups: "=",
			callbacks: "=",
			groupsHidden: "@",
			groupsAutoSelect: "@",
			groupsMultiSelect: "@"
		}
	};
});

canopyUtils.directive("canopyRegistrationGroupManager", function () {
	return {
		restrict: "A",
		controller: function ($scope, $rootScope) {
			$scope.state = {
				selected: {
					permission: null,
					unit: null,
					region: null,
					branch: null
				},
				primary: null,
				hasVisibleGroups: true,
				userHasMadePrimaryGroupSelection: null
            };
			
			$scope.titleLabel = "Select your group membership:";

            $scope.activeGroupTypeView = {
                name: null
			};
            
            $scope.groupTypesRequired = [];
			
			$scope.hideClearSelectionBtn = false;

            var primaryMemory = null;

			$scope.groupTypeHasAnySelection = function(type) {
				var hasSelected = $scope.groups[type].find(function(element) {
					return element.isSelected === true;
				});

				return hasSelected;
			};

            $scope.getAllGroupTypesCompleted = function() {
                if (!$scope.groupTypesRequired) {
                    return true;
                }

                return $scope.groupTypesRequired.every(function(groupType) 
                {
                    return $scope.getGroupTypeCompleted(groupType); 
                });
            };

			$scope.visibleAndRequiredGroups = [];

			function setNumVisibleAndRequiredGroupTypes() {

				$scope.visibleAndRequiredGroups = [];

				$scope.groupTypesRequired.forEach(function(groupType) {
					if (!$scope.groups || !$scope.groups[groupType]) {
						return;
					}

					if ($scope.groups[groupType].length && $scope.config[groupType].hidden !== true) {
						$scope.visibleAndRequiredGroups.push(groupType);
					}
				});	
			}

            $scope.getGroupTypeCompleted = function(groupType) {                
                if (!$scope.groups) {
                    return false;
                }

                if (!$scope.groups[groupType]) {
                    return false;
                }

                if (!$scope.groups[groupType].length) {
                    return true;
                }

                var hasSelection = $scope.groups[groupType].find(function(value) {
                    if (value.hasOwnProperty("isSelected")) {
                        return value.isSelected === true;
                    }
                }); 

                if (hasSelection) {
                    return true;
                }

                return false;

            };

			$scope.$watch("state", function (state) {
			}, true);
			
			$scope.config = {
				permission: {
					hidden: false,
					multiSelect: false
				},
				unit: {
					hidden: false,
					multiSelect: false
				},
				region: {
					hidden: false,
					multiSelect: false
				},
				branch: {
					hidden: false,
					multiSelect: false
				}
            };

            $scope.candidatesForPrimaryGroup = [];

            var setDefaultActiveGroupTypeViewState = function() {
                // select the first group type, if something not already selected
                // This is called whenever groups are updated so the current selection
                // should remain, unless there are no groups to select in that group type.

                if (!$scope.groups) {
                    return;
                }
                
                if ($scope.groups.hasOwnProperty($scope.activeGroupTypeView.name) && $scope.groups[$scope.activeGroupTypeView.name].length) {
                    return;
                }

                for (var groupTypeName in $scope.groups) {
                    if (!$scope.groups.hasOwnProperty(groupTypeName)) {
                        break;
                    }

                    var groups = $scope.groups[groupTypeName];
                    var hidden = $scope.config[groupTypeName].hidden;
                    
                    if (groups.length && !hidden) {
                        $scope.activeGroupTypeView.name = groupTypeName;
                        break;
                    }
                }                
            };

            var updateCandidatesForPrimaryGroup = function() {
                $scope.candidatesForPrimaryGroup = [];

                for (var groupName in $scope.groups) {

                    var groupType = $scope.groups[groupName];

                    if (Array.isArray(groupType) && groupType.length){ 
                        groupType.forEach(function(group, index) {
							if (group.isSelected) {
                                $scope.candidatesForPrimaryGroup.push(group);
							}
							
							if ($scope.state.userHasMadePrimaryGroupSelection && group.uuid === $scope.state.userHasMadePrimaryGroupSelection.uuid) {
								// Unselected group was the primary group selection?
								if (!group.isSelected) {
									$scope.state.userHasMadePrimaryGroupSelection = null;
								}
							}
                        });
                    }
                }

                if ($scope.candidatesForPrimaryGroup.length === 1) {
                    $scope.candidatesForPrimaryGroup[0].isSelected = true;
                    $scope.state.primary = $scope.candidatesForPrimaryGroup[0];
                } else if (primaryMemory) {
                    var group = $scope.candidatesForPrimaryGroup.find(function(group, index) {
                        return ($scope.state.userHasMadePrimaryGroupSelection && group.uuid === $scope.state.userHasMadePrimaryGroupSelection.uuid);
                    });

                    if (group) {    
                        group.isPrimary = true;
                        group.isSelected = true;
                        $scope.state.primary = primaryMemory;
                    }
                }
                
                determineValidity();
            };
            
			var onGroupsUpdated = function () {
                $scope.state.primary = null;

    			var getGroupTypesFromString = function (str) {
					return str.toLowerCase().replace(/ /g, "").split(",");
				};
				
				if ($scope.groupTypesHidden) {
					var types = getGroupTypesFromString($scope.groupTypesHidden);
				
					types.forEach(function (type) {
						$scope.config[type].hidden = true;
					});

					if ($scope.groups) {
						$scope.state.hasVisibleGroups = 
						($scope.groups.permission && $scope.groups.permission.length ? true : false && !$scope.config.permission.hidden) || 
						($scope.groups.unit && $scope.groups.unit.length ? true : false && !$scope.config.unit.hidden) || 
						($scope.groups.region && $scope.groups.region.length ? true : false && !$scope.config.region.hidden) || 
						($scope.groups.branch && $scope.groups.branch.length ? true : false && !$scope.config.branch.hidden);
					}
				}
			
				if ($scope.groupsMultiSelect) {
					var types = getGroupTypesFromString($scope.groupsMultiSelect);
				
					types.forEach(function (type) {
						$scope.config[type].multiSelect = true;
					});
				}
			
				if ($scope.groupsAutoSelect) {
					var types = getGroupTypesFromString($scope.groupsAutoSelect);
				
					types.forEach(function (type) {
						var groups      = $scope.groups[type];
						var multiSelect = $scope.config[type].multiSelect;
				
						if (groups && groups.length) {
							if (multiSelect) {	
								groups.forEach(function (group) {
									group.isSelected = true;
								});
							}
							else {
								$scope.state.selected[type] = groups[0];
							}
						}
                    });
                }

                if ($scope.groupsAutoSelectIfSingle) {
					var types = getGroupTypesFromString($scope.groupsAutoSelectIfSingle);
                
					types.forEach(function (type) {
						var groups = $scope.groups[type];
				
						if (groups && groups.length === 1) {
							$scope.state.selected[type] = groups[0];
						}
					});
                }

                updateCandidatesForPrimaryGroup();
                setDefaultActiveGroupTypeViewState();
                determineValidity();
            };
            
            $scope.clearSelectionForGroupType = function(type) {
                var groups = $scope.groups[type];

                $scope.state.selected[type] = null;

                groups.forEach(function (group) {
                    group.isSelected = false;
                    group.isPrimary = false;

                    if ($scope.state.primary && $scope.state.primary.uuid === group.uuid) {
                        $scope.state.primary = null;
                    }
                });

                updateCandidatesForPrimaryGroup();
                determineValidity();
            };

            $scope.getGroupTypeDescription = function(groupType) {
                if ($scope.groupTypeDescriptions) {
                    return $scope.groupTypeDescriptions[groupType] ? $scope.groupTypeDescriptions[groupType] : "nothing";
                }
            };
			
 			$scope.onGroupSelected = function (group) {	
                primaryMemory = $scope.state.primary;
                $scope.state.primary = null;

 				if (group.isSelected) {

 				} else {
 					if ($scope.state.primary == group) {
 						$scope.state.primary = null;
 					}
                 }
                 
                 updateCandidatesForPrimaryGroup();
                 determineValidity();
 			};

 			$scope.onPrimaryGroupChanged = function (group) {
				$scope.state.userHasMadePrimaryGroupSelection = group;
            };
              			
 			var handleSingularGroupSelection = function (group) {
 				var groupType = group.type;
 				var groups    = $scope.groups[groupType.toLowerCase() ];
 				
 				if (!groups instanceof Array) {
 					return;
 				}
                 
                primaryMemory = $scope.state.primary;
                $scope.state.primary = null;

 				groups.forEach(function (element) {
 					element.isSelected = false;
 					
 					if (element.id == group.id) {
 						element.isSelected = true;
 					}
                 });
                 
                 updateCandidatesForPrimaryGroup();
                 determineValidity();
 			};
             
            $scope.$watch("groupTypesRequired", function(val) {
                if (typeof val === "string") {

                    if (!val.length) {
                        $scope.groupTypesRequired = [];
                    } else {
                        $scope.groupTypesRequired = val.toLowerCase().split(",");
                    }
                }
            });

            $scope.$watch("groupTypeDescriptions", function(val) {
                if (typeof val === "string") {
                    $scope.groupTypeDescriptions = JSON.parse(val);
                }
            });

 			$scope.$watch("state.selected.permission", function (group) {
 				if (group) {
 					handleSingularGroupSelection(group);
 				}
 			});
 			
 			$scope.$watch("state.selected.unit", function (group) {
 				if (group) {
 					handleSingularGroupSelection(group);
 				}
 			});
 			
 			$scope.$watch("state.selected.region", function (group) { 				
 				if (group) {
 					handleSingularGroupSelection(group);
 				}
 			});
 			
 			$scope.$watch("state.selected.branch", function (group) {
 				if (group) {
 					handleSingularGroupSelection(group);
 				}
 			});
			
			$scope.$watch("state.primary", function (group) {
				var group = group || {};
					
				for (var groupType in $scope.groups) {
					var groups = $scope.groups[groupType];
					
					if (groups instanceof Array) {
						groups.forEach(function (element) {
							if (element.id == group.id) {
								element.isPrimary = true;
							}
							else {
								element.isPrimary = false;
							}
						});
					}
                }
                
                determineValidity();
			});
			
			$scope.$watch("groups", function (groups) {
				if (groups) {
					onGroupsUpdated();
					setNumVisibleAndRequiredGroupTypes();
				}
			});

			$scope.$watch("hideClearSelectionBtn", function (val) {
				if (angular.isString(val)) {
					if (val.toLowerCase() === "true") {
						$scope.hideClearSelectionBtn = true;
					} else {
						$scope.hideClearSelectionBtn = false;
					}
				}
			});
			
			$scope.$watch("primaryGroupDescription", function(description) {
				if (description) {
				}
			});

			
			$scope.$watch("titleLabel", function(titleLabel) {
				if (titleLabel) {
					$scope.titleLabel = titleLabel;
				}
			});
            
            var determineValidity = function() {
                
                // No groups = valid
                if (!$scope.state.hasVisibleGroups) {
                    $scope.valid = true;
                    $rootScope.$broadcast("canopyRegistrationGroupManagerValidityEvent", {"valid": $scope.valid});
                    return;
                }

                // Required group type empty = invalid
                if (!$scope.getAllGroupTypesCompleted()) {
                    $scope.valid = false;
                    $rootScope.$broadcast("canopyRegistrationGroupManagerValidityEvent", {"valid": $scope.valid});
                    return;
                }


                // No primary group = invalid
                if (!$scope.state.primary) {
                    $scope.valid = false;
                    $rootScope.$broadcast("canopyRegistrationGroupManagerValidityEvent", {"valid": $scope.valid});
                    return;
                }

                $scope.valid = true;
                $rootScope.$broadcast("canopyRegistrationGroupManagerValidityEvent", {"valid": $scope.valid});
            };
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/group-manager/registration-group-manager.html",
		scope: {
			groups: "=",
			callbacks: "=",
			hideClearSelectionBtn: "@",
			titleLabel: "@",
			groupTypesHidden: "@",
			groupsAutoSelect: "@",
            groupsMultiSelect: "@",
            groupTypeDescriptions: "@",
            groupTypesRequired: "@",
			groupsAutoSelectIfSingle: "@",
			primaryGroupDescription: "@",
            valid: "@"
		}
	};
});

canopyUtils.directive("canopyInputPanel", function ($timeout) {
	return {
		restrict: "A",
		replace: true,
		controller: function ($scope) {
			$scope.init = function () {
				$scope.model = {
					input:  "",
					isOpen: false
				};
			};
			
			$scope.onSubmit = function () {
				if ($scope.model.isOpen) {
					if ($scope.callbacks) {
						if ($scope.callbacks.onSubmit) {
							$scope.callbacks.onSubmit($scope.model.input);
						}
					}
					
					$scope.init();
				}
				else {
					$scope.model.isOpen = true;
				}
			};
			
			$scope.onCancel = function () {
				$scope.init();
			};
			
			$scope.init();
		},
		scope: {
			buttonLabel: "@",
			callbacks: "="
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/io/input_panel.html"
	};
});

canopyUtils.directive("canopyOverflowWatch", function ($timeout, $window) {
	return {
		restrict: "A",
		replace: true,
		link: function (scope, element, attrs) {
			var initialChildren = [];
			
			$timeout( function () {
				initialChildren = Array.prototype.slice.call(element[0].children).slice(0);
			
				scope.$watch("items", function (newValue, old) {
					checkOverflow();
				});
			});
			
			var onResize = function () {
				var children = element[0].children;

				for (var i = 0; i != children.length; ++i) {
					children[i].style["display"] = "inline-block";
				}
				
				checkOverflow();
			};
		
			var window = angular.element($window);
	
			window.on("resize", onResize);
			scope.$on("$destroy", function (e) {
				 window.unbind("resize", onResize);
			});
			
			var checkOverflow = function () {
				var children  = element[0].children;
				var lastIndex = 0;
				
				var isOverflowing = false;
				
				var width = 0;
				
				for (var i = 0; i != children.length; ++i) {
					width = width + children[i].clientWidth + parseInt(children[i].style.marginRight);
					
					if (width > element[0].clientWidth) {
						lastIndex = i;
						isOverflowing = true;
						break;
					}
				}
				
				if (lastIndex > 0) {
					for (var i = lastIndex; i != children.length; ++i) {
						children[i].style["display"] = "none";
					}
				}
				
				if (isOverflowing) {
					if (scope.onOverflow) {
						scope.onOverflow();
					}
				}
				else {
					if (scope.onUnderflow) {
						scope.onUnderflow();
					}
				}
			};
		},
		scope: {
			items: "=",
			onOverflow: "=",
			onUnderflow: "="
		}
	};
});


canopy.factory('utilities', [function() {
	return {
		StateTracker: function (states) {
			var _states = states;
			var _state  = states.length ? states[0] : undefined;
	
			return {
				setState: function (state) {
					if (typeof(state) == "string" ) {
						for (var i = 0; i != _states.length; ++i) {
							if ( _states[i].name && _states[i].name == state) {
								_state = states[i];
							}
						}
					}
					else {
						if ( _states.indexOf(state) >= 0 ) {
							_state = state;
						}
					}
				},
				getState: function () {
					return _state;
				},
				getStates: function () {
					return _states;
				}
			};
		},
		SelectionTracker: function () {
			var _selected = [];

			var findSelectionById = function ( id ) {
				for ( var i = 0; i != _selected.length; ++i ) {
					if ( _selected[i].id == id ) {
						return i;
					}
				}

				return -1;
			};

			return {
				isSelected: function ( object ) {
					if ( !object.id ) {
						// Object doesn't have an id, can't track.
						throw "No object ID";
					}
			
					return findSelectionById( object.id ) >= 0;
				},
				toggleSelection: function ( object ) {		
					if ( !object.id ) {
						// Object doesn't have an id, can't track.
						throw "No object ID";
					}
			
					var index = findSelectionById( object.id );

					if (index >= 0) {
						_selected.splice(index, 1);
					}
					else {
						_selected.push( object );
					}
				},
				selection: _selected
			};
		},
		browserCheck: {
			isMobile: {
				Android: function() {
					return navigator.userAgent.match(/Android/i);
				},
				BlackBerry: function() {
					return navigator.userAgent.match(/BlackBerry/i);
				},
				iOS: function() {
					return navigator.userAgent.match(/iPhone|iPad|iPod/i);
				},
				Opera: function() {
					return navigator.userAgent.match(/Opera Mini/i);
				},
				Windows: function() {
					return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
				},
				any: function() {
					return (this.Android() || this.BlackBerry() || this.iOS() || this.Opera() || this.Windows());
				}
			}
		},
		date: function (value) {
			var _wrapped = moment();
			
			if (value) {
				if (typeof value === "string" || typeof value === "object") {
					_wrapped = moment(value);
				}
				else {
					if (parseInt(value)) {
						_wrapped = moment.unix(value);
					}
				}
			}
	
			return {
				offset: function (offset) {
					if (offset) {
						if (offset.years) {
							_wrapped.add(offset.years, "years");
						}
						if (offset.months) {
							_wrapped.add(offset.months, "months");
						}
						if (offset.days) {
							_wrapped.add(offset.days, "days");
						}
						if (offset.hours) {
							_wrapped.add(offset.hours, "hours");
						}
						if (offset.minutes) {
							_wrapped.add(offset.minutes, "minutes");
						}
						if (offset.seconds) {
							_wrapped.add(offset.seconds, "seconds");
						}
					}
			
					return this;
				},
				startOf: function (unit) {
					_wrapped.startOf(unit);
			
					return this;
				},
				endOf: function (unit) {
					_wrapped.endOf(unit);
			
					return this;
				},
				toString: function () {
					return _wrapped.toString();
				},
				toISOString: function () {
					return _wrapped.toISOString();
				},
				toDate: function () {
					return _wrapped.toDate();
				},
				toUnix: function () {
					return _wrapped.unix();
				},
				difference: function (date) {
					date = moment(date.toDate ? date.toDate() : date);
					
					return {
						years: 	 _wrapped.diff(date, "years"),
						months:  _wrapped.diff(date, "months"),
						days: 	 _wrapped.diff(date, "days"),
						hours: 	 _wrapped.diff(date, "hours"),
						minutes: _wrapped.diff(date, "minutes"),
						seconds: _wrapped.diff(date, "seconds")
					};
				}
			};
		},
		test: function () {
			var local = this;
			
			var testDates = function () {
				var testValue  = "Fri Jan 02 2015 03:04:05 GMT+0000 (GMT)";
				var testOffset = {
					years:   -1,
					months:   2,
					days:    -3,
					hours:    4,
					minutes: -5,
					seconds:  6
				};
		
				var testDate             = local.date(testValue);
				var testDateOffset       = local.date(testValue).offset(testOffset);
				var testDifferenceNone   = testDate.difference(testDate);
				var testDifferenceOffset = testDate.difference(testDateOffset);
								
				return 	testDate.toString() 		 === "Fri Jan 02 2015 03:04:05 GMT+0000" &&
						testDateOffset.toString()	 === "Thu Feb 27 2014 06:59:11 GMT+0000" && 
						testDifferenceNone.years  	 === 0 &&
						testDifferenceNone.months	 === 0 &&
						testDifferenceNone.days  	 === 0 &&
						testDifferenceNone.hours     === 0 &&
						testDifferenceNone.minutes   === 0 &&
						testDifferenceNone.seconds   === 0 &&				
						testDifferenceOffset.years   === 0 &&
						testDifferenceOffset.months  === 10 &&
						testDifferenceOffset.days 	 === 308 &&
						testDifferenceOffset.hours 	 === 7412 &&
						testDifferenceOffset.minutes === 444724 &&
						testDifferenceOffset.seconds === 26683494;
			};
		}
	};
}]);

canopyUtils.filter("canopyDate", function () {
	return function (input, format) {
		var str = "";
		
		if (input) {
			var datePattern = "YYYY-MM-DD HH:mm:ss [GMT]ZZ";
			
			if (format) {
				str = moment(input, datePattern).format(format);
			}
			else {
				str = moment(input, datePattern).format("D MMMM YYYY");
			}
		}
		
		return str;
	};
});

canopyUtils.filter("canopyCurrency", function () {
	var currency_symbols = {
		"GBP": "£",
		"EUR": "€",
		"USD": "$",
		"NZD": "$",
		"AUD": "$"
	};
	
	return function (input, args) {
		var str = "";
		
		if (input != undefined) {
			var locale = window.navigator.userLanguage || window.navigator.language || "en-GB";
			var code   = null;
			var value  = parseFloat(input);
			
			if (args && args.code) {
				code = args.code;
			}

			if (code) {
				var symbol = currency_symbols[code];
				
				if (symbol) {
					str = symbol + value.toLocaleString(locale, { minimumFractionDigits: 2 }) + " " + code;
				}
				else {
					str = value.toLocaleString(locale, { minimumFractionDigits: 2 }) + " " + code;
				}
			}
			else {
				str = value.toLocaleString(locale, { minimumFractionDigits: 2 });
			}
			
		}
		
		return str;
	};
});

canopyUtils.filter("canopyFileSize", function () {
	return function(bytes, precision) {
		if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) {
			return "-";
		}
		
		if (typeof precision === "undefined") {
			precision = 1;
		}
		
		var units  = ["bytes", "kB", "MB", "GB", "TB", "PB"];
		var number = Math.floor(Math.log(bytes) / Math.log(1000));
		
		return (bytes / Math.pow(1000, Math.floor(number))).toFixed(precision) +  " " + units[number];
	};
});

canopyUtils.filter("canopyFileExtension", function () {
	return function (input) {
		var str = input;
		
		if (input) {
			switch (input) {
				case "jpg":
					str = "JPEG";
					break;
				
				case "pdf":
					str = "PDF";
					break;
			}
		}
		
		return str;
	};
});

canopyUtils.filter("canopyBooleanFilter", function () {
    return function (input, column, value) {
    	var results = [];
        
        if (!angular.isDefined(value)) {
        	value = true;
        }
        
        angular.forEach(input, function (element) {
            if (angular.isDefined(element[column]) && element[column] === value) {
                results.push(element);
            }
        });
        
        return results;
    };
});

canopyUtils.service("canopyDictionary", function () {
	var _dictionary = {
		"site.name": "Canopy"
	};
	
	return {
		assign: function (property, value) {
			_dictionary[property] = value;
		},
		resolve: function (property) {
			return _dictionary[property];
		}
	};
});

canopyUtils.filter("canopyDictionary", function (canopyDictionary) {
	return function (input) {
		return canopyDictionary.resolve(input);
	};
});

canopyUtils.filter("timeago", function() {
     //time: the time
	//local: compared to what time? default: now
	//raw: wheter you want in a format of "5 minutes ago", or "5 minutes"
	return function (time, local, raw) {

        var backupTime = time;

		if (!time) return "never";

		if (!local) {
			(local = Date.now());
		}

		if (angular.isDate(time)) {
			time = time.getTime();
		} else if (typeof time === "string") {
            time = new Date(time).getTime();
            
            if (isNaN(time)) {
                time = new Date(backupTime.replace(/-/g, "/")).getTime();
            }
		}

		if (angular.isDate(local)) {
			local = local.getTime();
		}else if (typeof local === "string") {
			local = new Date(local).getTime();
		}

		if (typeof time !== 'number' || typeof local !== 'number') {
			return;
		}

		var
			offset = Math.abs((local - time) / 1000),
			span = [],
			MINUTE = 60,
			HOUR = 3600,
			DAY = 86400,
			WEEK = 604800,
			MONTH = 2629744,
			YEAR = 31556926,
			DECADE = 315569260;

		if (offset <= MINUTE)              span = [ '', raw ? 'now' : 'less than a minute' ];
		else if (offset < (MINUTE * 60))   span = [ Math.round(Math.abs(offset / MINUTE)), 'min' ];
		else if (offset < (HOUR * 24))     span = [ Math.round(Math.abs(offset / HOUR)), 'hr' ];
		else if (offset < (DAY * 7))       span = [ Math.round(Math.abs(offset / DAY)), 'day' ];
		else if (offset < (WEEK * 52))     span = [ Math.round(Math.abs(offset / WEEK)), 'week' ];
		else if (offset < (YEAR * 10))     span = [ Math.round(Math.abs(offset / YEAR)), 'year' ];
		else if (offset < (DECADE * 100))  span = [ Math.round(Math.abs(offset / DECADE)), 'decade' ];
		else                               span = [ '', 'a long time' ];

		span[1] += (span[0] === 0 || span[0] > 1) ? 's' : '';
		span = span.join(' ');

		if (raw === true) {
			return span;
		}
		return (time <= local) ? span + ' ago' : 'in ' + span;
	};
});

canopy.service("eventDispatcher", function () {
	var _isActive = false;

	var _createEvent = function(type, detail) {
		return new CustomEvent(type, { detail: detail });
	};

	var _dispatchEvent = function(event) {
		window.dispatchEvent(event);
	};

	this.activate = function() {
		_isActive = true;
	};

	this.deactivate = function() {
		_isActive = false;
	};
	
	this.fireEvent = function(type, detail) {
		if (_isActive) {
			var wrapper = { data: angular.copy(detail) };
			_dispatchEvent(_createEvent(type, wrapper));
		}
	};
});

canopy.factory("canopyEventProvider", function() {
	return {
		"NAVIGATION_END": "com.intrepia.canopy.event.navigationEnd",
		"PROJECT_CREATE": "com.intrepia.canopy.event.projectCreate",
		"PROJECT_CREATED": "com.intrepia.canopy.event.projectCreated",
		"WORKFLOW_TRANSITION": "com.intrepia.canopy.event.workflowTransition",
		"LIST_SEARCH_TERM": "com.intrepia.canopy.event.listSearchTerm",
		"ASSET_DETAIL": "com.intrepia.canopy.event.assetDetail",
	};
});