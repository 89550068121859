canopy.service("listService", function ($http, $q) {
	var list = function (service) {
		var _service    = service;
		var _parameters = {};
		var _filters    = [];
		var _abort      = $q.defer();
		
		var getFilterIndex = function (filterName) {
			for (var i = 0; i != _filters.length; ++i) {
				if (_filters[i].name == filterName) {
					return i;
				}
			}
			
			return -1;
		};
		
		var getAppliedOptionIndex = function (filter, optionName) {
			for (var i = 0; i != filter.applied.length; ++i) {
				if (filter.applied[i] == optionName) {
					return i;
				}
			}
			
			return -1;
		};
		
		return {
			load: function (isExport) {
				if ( _service == undefined ) {
					throw new Error("List service not defined");
				}
				
				_parameters.filters = "";
				
				if ( _filters.length ) {
					_parameters.filters = _filters;
					
					_parameters.filters.forEach(function (filter) {
						if (filter.type == "com.intrepia.luma.KeywordFilter") {
							filter.keywordIDs = filter.applied;
						}
						if (filter.type == "com.intrepia.luma.AdvancedSearchFilter") {
							filter.clauses = filter.applied;
						}
					});
					
					_parameters.filters = JSON.stringify( _parameters.filters );
				}
				
				_abort.resolve();
				_abort = $q.defer();
				
				if (isExport != true) {
					this.prime();
				}
				
				return $http({
					url: Luma.paths.context + "/servlet/interface/com.intrepia.luma" + "." + _service,
					method: "POST",
					data: $.param(_parameters),
					headers: {"Content-Type": "application/x-www-form-urlencoded"} 
				}); 
			},
			loadMore: function () {
				if (_parameters["listing-style"] != "Page") {
					return;
				}
				
				_parameters["listing-vector"] = ( _parameters["listing-vector"] || 0 ) + 1;
				_parameters["listing-offset"] = ( _parameters["listing-offset"] || 0 ) + _parameters["listing-limit"];
				
				return this.load(false);
			},
			prime: function () {
				
			},
			clearOffset: function () {
				_parameters["listing-vector"] = 0;
				_parameters["listing-offset"] = 0;
			},
			getService: function () {
				return _service;
			},
			setService: function (value) {
				_service = value;
			},
			setParameter: function (key, value) {
				if (value instanceof Array) {
					_parameters[key] = JSON.stringify(value);
				}
				else {
					_parameters[key] = value;
				}
			},
			registerFilter: function (filter) {
				filter.applied = filter.applied || [];
				
				if (_filters[filter.name]) {
					_filters[filter.name] = filter;
				}
				else {
					_filters.push(filter);
				}
			},
			registerFilters: function (filters) {
				if (filters instanceof Array) {
					for (var i = 0; i != filters.length; ++i) {
						this.registerFilter(filters[i]);
					}
				}
			},
			toggleFilter: function (filter, option) {
				var callbacks = {
					"com.intrepia.luma.KeywordFilter": {
						finder: function (element) {
							return element == option.id;
						},
						reducer: function (element) {
							return option.id;
						}
					},
					"com.intrepia.luma.ProcessFilter": {
						finder: function (element) {
							return element == option.id;
						},
						reducer: function (element) {
							return option.id;
						}
					},
					"com.intrepia.luma.AdvancedSearchFilter": {
						finder: function (element) {
							return element.value == option.value;
						},
						reducer: function (element) {
							return option;
						}
					},
					"com.intrepia.luma.ListOrders$StateFilter": {
						finder: function (element) {
							return element == option.name;
						},
						reducer: function (element) {
							return option.name;
						}
					},
					"com.intrepia.luma.ListOrders$ProcessFilter": {
						finder: function (element) {
							return element == option.id;
						},
						reducer: function (element) {
							return option.id;
						}
					},
					"com.intrepia.luma.ListOrders$OrderGroupFilter": {
						finder: function (element) {
							return element == option.id;
						},
						reducer: function (element) {
							return option.id;
						}
					},
					"com.intrepia.luma.ListRegistrations$GroupFilter": {
						finder: function (element) {
							return element == option.id;
						},
						reducer: function (element) {
							return option.id;
						}
                    },
					"com.intrepia.luma.ListRegistrations$KeyFilter": {
						finder: function (element) {
							return element == option.name;
						},
						reducer: function (element) {
							return option.name;
						}
					},
					"com.intrepia.luma.ListBudgets$StateFilter": {
						finder: function (element) {
							return element == option.name;
						},
						reducer: function (element) {
							return option.name;
						}
					},
					"com.intrepia.luma.ListBudgets$GroupFilter": {
						finder: function (element) {
							return element == option.id;
						},
						reducer: function (element) {
							return option.id;
						}
					},
					"com.intrepia.luma.ListProjects$ProjectStateFilter": {
						finder: function (element) {
							return element == option.name;
						},
						reducer: function (element) {
							return option.name;
						}
					},
					"com.intrepia.luma.ListProjects$ProjectGroupFilter": {
						finder: function (element) {
							return element == option.id;
						},
						reducer: function (element) {
							return option.id;
						}
					},
					"com.intrepia.luma.ListProjects$ProjectCampaignFilter": {
						finder: function (element) {
							return element == option.id;
						},
						reducer: function (element) {
							return option.id;
						}
					},
					"com.intrepia.luma.ListCampaigns$CampaignStateFilter": {
						finder: function (element) {
							return element == option.name;
						},
						reducer: function (element) {
							return option.name;
						}
					},
					"com.intrepia.luma.ListCampaigns$CampaignGroupFilter": {
						finder: function (element) {
							return element == option.id;
						},
						reducer: function (element) {
							return option.id;
						}
					},
					"com.intrepia.luma.ListBudgetReimbursements$StateFilter": {
						finder: function (element) {
							return element == option.name;
						},
						reducer: function (element) {
							return option.name;
						}
					}
				};
				
				if (filter === undefined || option === undefined) {
					return;
				}
				
				var filter = this.getFilter(filter.name || filter);
				
				if (filter) {
					var funcs = callbacks[filter.type];
					
					if (funcs) {
						var finder  = funcs.finder;
						var reducer = funcs.reducer;
					
						if (finder && reducer) {
							var index = filter.applied.findIndex(finder, option);
				
							if (index >= 0) {
								filter.applied.splice(index, 1);
							}
							else {
								filter.applied.push(reducer(option));
							}
						}
					}
					else {
						console.log("ERROR: Attempting to toggle filter on unknown filter type: " + filter.type);
						console.dir(option);
					}
				}				
			},
			clearFilters: function () {
				for (var i = 0; i != _filters.length; ++i) {
					_filters[i].applied = [];
				}
			},
			getFilter: function (filterName) {
				var filterIndex = getFilterIndex(filterName);
			
				if (filterIndex >= 0) {
					return _filters[filterIndex];
				}
			},
			setDateFilter: function (from, to, filterName) {
				if (filterName) {	
					for (var i = 0; i != _filters.length; ++i) {
						if (_filters[i].name == filterName) {
							_filters[i].until = Dates.encode(to);
							_filters[i].from  = Dates.encode(from);
						}
					}
				}
			},
			exportList: function () {				
				_parameters["listing-style"] = "Spreadsheet";
				
				delete _parameters["listing-limit"];
				delete _parameters["listing-offset"];
				delete _parameters["listing-vector"];
				
				return this.load(true);
			},
			retrieveExport: function (uuid) {
				// This should probably be in a separate service.
				return $http({
					url: Luma.paths.context + "/servlet/interface/com.intrepia.luma.Retriever",
					method: "POST",
					data: $.param({
						uuid: uuid
					}),
					headers: {"Content-Type": "application/x-www-form-urlencoded"} 
				}); 
			},
			serialiseFilterState: function () {
				var state = [];
				
				var isDateFilter = function (filter) {
					return filter.type === "com.intrepia.luma.PeriodFilter" ||
						   filter.type === "com.intrepia.luma.ListOrders$PeriodFilter";
				};
				
				state = _filters.filter(function (filter) {
					return (isDateFilter(filter) && (filter.from || filter.until)) || filter.applied.length;
				});
				
				state = state.map(function (filter) {
					if (isDateFilter(filter)) {
						return {
							name:  filter.name,
							type:  filter.type,
							from:  filter.from,
							until: filter.until
						};
					}
					else {
						return filter;
					}
			
					return filter;
				});
				
				if (state.length) {
					return btoa(JSON.stringify(state));
				}
				else {
					return null;
				}
			},
			parseFilterState: function (state) {
				state.forEach(function (filter) {					
					var match = _filters.find(function (element) {
						return element.name == filter.name;
					});
					
					if (match) {
						for (var key in filter) {
							match[key] = filter[key];
						}
					}
				});
			}
		};
	};
	
	return {
		instantiate: function (service) {
			return new list(service);
		}
	};
});