canopy.factory("pageVersion", function(contentManagement, $q) {
    var currentRevision = {
        "active": false,
        "created": "",
        "modified": "",
        "revision": "",
        "user-email": "",
        "user-forename": "",
        "user-surname": "",
        uuid: ""
    };

    function getCurrentRevision() {
        return currentRevision;
    }

    function setCurrentRevision(revision) {
        currentRevision = angular.copy(revision);
    }

    function getActiveRevisionPromise(nodeId) {
        var deferred = $q.defer();
        var response = undefined;

        if (nodeId) {
            contentManagement.listSiteMenuPageVersions(nodeId).then(
                function successCallback(resp) {
                    response = resp.data.find(function(obj) {
                        return obj.active === true;
                    });

                    deferred.resolve(response);
                },
                function errorCallback(resp) {
                    deferred.reject(response);
                }
            );
        } else {
            deferred.reject("nodeId parameter required.")
        }

        response = deferred.promise;

        return $q.when(response);
    }

    function setCurrentRevisionToActiveRevision(nodeId) {
        if (nodeId) {
            contentManagement.listSiteMenuPageVersions(nodeId).then(
                function successCallback(response) {
                    currentRevision = response.data.find(function(obj) {
                        return obj.active === true;
                    });
                },
                function errorCallback(response) {
                }
            );
        }
    }

    function setCurrentRevisionToActiveRevisionPromise(nodeId) {
        var deferred = $q.defer();
        var response = undefined;

        if (nodeId) {
            contentManagement.listSiteMenuPageVersions(nodeId).then(
                function successCallback(response) {
                    currentRevision = response.data.find(function(obj) {
                        return obj.active === true;
                    });

                    deferred.resolve(currentRevision);
                },
                function errorCallback(response) {
                    deferred.reject(currentRevision);
                }
            );
        } else {
            deferred.reject("nodeId parameter required.")
        }

        response = deferred.promise;

        return $q.when(response);
    }

    function setCurrentRevisionToLatestRevision(nodeId) {
        if (nodeId) {
            contentManagement.listSiteMenuPageVersions(nodeId).then(
                function successCallback(response) {
                    currentRevision = response.data[0];
                },
                function errorCallback(response) {
                    console.log("page_version.js: setCurrentRevisionToActiveRevision: error: ", response);
                }
            );
        }
    }

    function setCurrentRevisionToLatestRevisionPromise(nodeId) {
        var deferred = $q.defer();
        var response = undefined;

        if (nodeId) {
            contentManagement.listSiteMenuPageVersions(nodeId).then(
                function successCallback(response) {
                    currentRevision = response.data[0];
                    deferred.resolve(currentRevision);
                },
                function errorCallback(response) {
                    deferred.reject(currentRevision);
                }
            );
        } else {
            deferred.reject("nodeId parameter required.")
        }

        response = deferred.promise;

        return $q.when(response);
    }

    return {
        revision: currentRevision,
        getCurrentRevision: getCurrentRevision,
        setCurrentRevision: setCurrentRevision,
        getActiveRevisionPromise: getActiveRevisionPromise,
        setCurrentRevisionToActiveRevision: setCurrentRevisionToActiveRevision,
        setCurrentRevisionToActiveRevisionPromise: setCurrentRevisionToActiveRevisionPromise,
        setCurrentRevisionToLatestRevision: setCurrentRevisionToLatestRevision,
        setCurrentRevisionToLatestRevisionPromise: setCurrentRevisionToLatestRevisionPromise
    };
});
