canopy.controller("BudgetDetailController", function ($scope, $stateParams, menuNode, lumaPaths, budgets, listService, $window, canopyModals, canopySession, $timeout) {
	var initBudget = function () {
		$scope.isInit = true;
		
		var onSuccess = function (response) {
			$scope.budget = response.data;
				
			initBudgetTransactions();
			initBudgetReimbursements();
			initBudgetHistory();
			
			$scope.isInit = false;
		};
		
		budgets.getBudget($stateParams.budgetID).then(onSuccess);
	};
		
	$scope.transactionList = listService.instantiate("ListBudgetTransactions");
	$scope.transactionList.setParameter("budgetID", $stateParams.budgetID);

	$scope.transactionList.filters = {
		type: [{
			label: "Order",
			active: false
		}, {
			label: "Project",
			active: false
		}, {
			label: "AdHoc",
			active: false
		}, {
			label: "Adjustment",
			active: false
		}],
		state: [{
			label: "Cancelled",
			active: false
		}, {
			label: "Closed",
			active: false
		}, {
			label: "Confirmed",
			active: false
		}, {
			label: "Pending",
			active: false
		}]
	};
	
	var initBudgetTransactions = function () {
		var filterActive = function (filter) {
			return filter.active;
		};
		
		var filterMap = function (filter) {
			return filter.label;
		};
		
		var onSuccess = function (response) {
			$scope.transactionList.loaded = true;
			$scope.budget.transactions = response.data;
		};
		
		var states = $scope.transactionList.filters.state.filter(filterActive).map(filterMap);
		var types  = $scope.transactionList.filters.type.filter(filterActive).map(filterMap);
		
		$scope.transactionList.setParameter("states", states.toString());
		$scope.transactionList.setParameter("types", types.toString());
		
		$scope.transactionList.load().then(onSuccess);
	};
	
	$scope.exportTransactionList = function () {
		if ($scope.isExporting) {
			return;
		}
		
		$scope.isExporting = true;
		
		var onSuccess = function (response) {
			if (response.data) {
				window.location.assign(lumaPaths.serviceURI + '.Retriever?name=Statistics&uuid=' + response.data.replace(/\"/g, ""));
			}
			
			$scope.isExporting = false;
		};
		
		var onError = function () {
			$scope.isExporting = false;
		};
		
		$scope.transactionList.exportList().then(onSuccess).catch(onError);
	};
	
	var initBudgetReimbursements = function () {
		var onSuccess = function (response) {
			var reimbursementFilter = function (state) {
				return function (element) {
					return element.state == state;
				};
			};
			
			var reimbursementReducer = function (acc, element) {
				return parseFloat(acc) + parseFloat(element.value);
			};
			
			$scope.budget.reimbursements = {
				unapproved: response.data.filter(reimbursementFilter("Unapproved")).reduce(reimbursementReducer, 0),
 				approved:   response.data.filter(reimbursementFilter("Approved")).reduce(reimbursementReducer, 0),
 				paid:       response.data.filter(reimbursementFilter("Paid")).reduce(reimbursementReducer, 0)
			};
		};
		
		var list = listService.instantiate("ListBudgetReimbursements");
		list.setParameter("budgetID", $stateParams.budgetID);
		
		list.load().then(onSuccess);
	};
	
	var initBudgetHistory = function () {
		var onSuccess = function (response) {
			$scope.budget.history = response.data;
		};
		
		budgets.listBudgetHistory($stateParams.budgetID).then(onSuccess);
	};
	
	$scope.init = function () {
		initBudget();
		initActions();
	};
	
	var initActions = function () {
		$scope.actions = [{
			label: "Edit",
			actions: [{
				label: "New transaction (debit budget)",
				execute: function () {
					var modal = canopyModals.definitions.form({
						header: "Debit budget",
						form: Luma.paths.context + "/system/mantle/marquee/site/forms/set-value.html"
					});
					
					canopyModals.instantiate(modal).result.then(function (form) {
						var onSuccess = function () {
							$scope.init();
						};
						
						budgets.addTransaction($scope.budget.id, form.value * -1, form.description).then(onSuccess);
					});
				},
				permitted: function () {
					return canopySession.getUser().hasService("budget.transaction.add");
				}
			}, {
				label: "New transaction (credit budget)",
				execute: function () {
					var modal = canopyModals.definitions.form({
						header: "Credit budget",
						form: Luma.paths.context + "/system/mantle/marquee/site/forms/set-value.html"
					});
					
					canopyModals.instantiate(modal).result.then(function (form) {
						var onSuccess = function () {
							$scope.init();
						};
						
						budgets.addTransaction($scope.budget.id, form.value, form.description).then(onSuccess);
					});
				},
				permitted: function () {
					return canopySession.getUser().hasService("budget.transaction.add");
				}
			}, {
				label: "Adjust budget",
				execute: function () {
					var modal = canopyModals.definitions.form({
						header: "Adjust budget",
						form: Luma.paths.context + "/system/mantle/marquee/site/forms/set-value.html"
					});
					
					canopyModals.instantiate(modal).result.then(function (form) {
						var onSuccess = function () {
							$scope.init();
						};
						
						budgets.adjustBudget($scope.budget.id, form.value, form.description).then(onSuccess);
					});
				},
				permitted: function () {
					return canopySession.getUser().hasService("budget.adjust");
				}
			}]
		}];
		
		$scope.actions.forEach(function (category) {
			category.actions = category.actions.filter(function (action) {
				return action.permitted();
			});
		});
	};
	
	$scope.$watch("transactionList.filters", function (filters) {
		if ($scope.transactionList.loaded == undefined || filters == undefined) {
			return;
		}
		
		initBudgetTransactions();
	}, true);
	
	$scope.init();
});