canopy.controller("RegistrationDetailController", function ($scope, $stateParams, menuNode, lumaPaths, canopySession, listService, budgets, registrations) {
	var initRegistration = function () {
		var onSuccess = function (response) {
			$scope.registration = response.data;
			
			initRegistrationGroups();
			initActions();
		};
		
		registrations.getRegistration($stateParams.registrationID).then(onSuccess);
	};
	
	var initRegistrationGroups = function () {
		var onSuccess = function (response) {
			$scope.registration.groups = response.data;
		};
		
		registrations.getRegistrationGroups($scope.registration).then(onSuccess);
	};
	
	var initActions = function () {
		var user = canopySession.getUser();
		
		$scope.actions = [];
		
		var approveAction = {
			label: "Approve this registration",
			execute: function () {
				var onSuccess = function () {
					$scope.registration.state = "Approved";
					$scope.init();
				};
				
				registrations.approveRegistration($scope.registration).then(onSuccess);
			},
			permitted: function () {
				return $scope.registration.state == "Unapproved";
			}
		};
		
		var rejectAction = {
			label: "Reject this registration",
			execute: function () {
				var onSuccess = function () {
					$scope.registration.state = "Rejected";
					$scope.init();
				};
				
				registrations.rejectRegistration($scope.registration).then(onSuccess);
			},
			permitted: function () {
				return $scope.registration.state == "Unapproved"
			}
		};
		
		$scope.actions = [{
			label: "Approval",
			actions: [
				approveAction,
				rejectAction
			]
		}];
		
		$scope.actionCount = 0;
		
		$scope.actions.forEach(function (category) {
			category.actions = category.actions.filter(function (action) {
				return action.permitted();
			});
			
			$scope.actionCount += category.actions.length;
		});
	};
	
	$scope.init = function () {
		initRegistration();
	};
	
	$scope.init();
});