canopy.directive("canopyMedia", function () {
	return {
		controller: function ($scope, assets, canopyBrowser) {
			$scope.init = function () {
				var onSuccess = function (response) {
					var adaptor, src, transform = null;
					
					if ($scope.asset) {
						if ($scope.asset.atom) {
							adaptor = $scope.asset.atom.adaptor;
						}
						else {
							adaptor = $scope.asset.adaptor;
						}
					}
					
					$scope.adaptor = adaptor;
					
					if ($scope.adaptor == "Video") {
						transform = response.data.find(function (transform) {
							return transform.name === "video_preview";
						});
						
						if (transform) {
							src = Luma.paths.context + "/servlet/interface/com.intrepia.luma.PreviewVideoAssetDerivative?assetID=" + $scope.asset.id + "&transformID=" + transform.id;
						}
					}
					if ($scope.adaptor == "Audio") {
						transform = response.data.find(function (transform) {
							return transform.name === "audio_preview";
						});
						
						if (transform) {
							src = Luma.paths.context + "/servlet/interface/com.intrepia.luma.DownloadAsset?assetID=" + $scope.asset.id + "&transformID=" + transform.id;
						}
					}
					
					if (src) {			
						$scope.media = {
							src: src
						};
						
						if (canopyBrowser.indexOf("Safari") >= 0) {
							$scope.media.src = $scope.media.src + "&direct=true&com.intrepia.luma.Download.anonymous=true";
						}
						
						if ($scope.displayControls) {
						}
						else {
							$scope.media.paused = true;
						}
					}
				};
				
				assets.getAssetDerivatives($scope.asset.id).then(onSuccess);
			};
			
			$scope.$watch("asset", function (asset) {
				if (asset) {
					$scope.init();
				}
			});
		},
		link: function (scope, element) {
			scope.adaptor = null;

			if (scope.asset) {
				if (scope.asset.atom) {
					scope.adaptor = scope.asset.atom.adaptor;
				}
				else {
					scope.adaptor = scope.asset.adaptor;
				}
			}
			
			if (!scope.adaptor) {
				return;
			}
			
			scope.$watch("displayControls", function (display) {			
				var wrapperElement = element[0];
				var mediaElement   = element.find(scope.adaptor.toLowerCase() );
			
				if (mediaElement) {
					mediaElement = mediaElement[0];
				}
							
				if (display) {
					mediaElement.controls = true;
				}
				else {
					element.css("cursor", "pointer");
				
					wrapperElement.onclick = function () {
						if (mediaElement) {
							if (mediaElement.paused) {
								mediaElement.play();
							}
							else {
								mediaElement.pause();
							}
						
							scope.$apply(function () {
								scope.media.paused = mediaElement.paused;
							});
						}
					};
					
					mediaElement.controls = false;
				}
			});
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/media/media.html",
		scope: {
			asset: "=",
			displayControls: "="
		},
		replace: true
	};
});