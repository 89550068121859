canopy.directive("canopyVideo", function () {
	return {
		controller: function ($scope, assets, canopyBrowser) {
			$scope.init = function () {
				var onSuccess = function (response) {
					var videoTransform = response.data.find(function (transform) {
						return transform.name === "video_preview";
					});
					
					if (videoTransform) {				
						$scope.video = {
							src: Luma.paths.context + "/servlet/interface/com.intrepia.luma.PreviewVideoAssetDerivative?assetID=" + $scope.asset.id + "&transformID=" + videoTransform.id
						};
						
						if (canopyBrowser.indexOf("Safari") >= 0) {
							$scope.video.src = $scope.video.src + "&direct=true&com.intrepia.luma.Download.anonymous=true";
						}
						
						if ($scope.displayControls) {
						}
						else {
							$scope.video.paused = true;
						}
					}
				};
				
				assets.getAssetDerivatives($scope.asset.id).then(onSuccess);
			};
			
			$scope.$watch("asset", function (asset) {
				if (asset) {
					$scope.init();
				}
			});
		},
		link: function (scope, element) {
			var wrapperElement = element[0];
			var videoElement   = element.find("video");
			
			if (videoElement) {
				videoElement = videoElement[0];
			}
			
			if (scope.displayControls) {
				videoElement.controls = true;
			}
			else {
				element.css("cursor", "pointer");
				
				wrapperElement.onclick = function () {
					if (videoElement) {
						if (videoElement.paused) {
							videoElement.play();
						}
						else {
							videoElement.pause();
						}
						console.log("setting player on scope");
						
						scope.$apply(function () {
							scope.video.paused = videoElement.paused;
					  	});
					}
				};
			}
		},
		templateUrl: Luma.paths.context + "/system/mantle/marquee/site/templates/video/video.html",
		scope: {
			asset: "=",
			displayControls: "="
		},
		replace: true
	};
});